import React, {useEffect, useRef, useState} from 'react'
import store from "./store"
import {
    checkData,
    checkData0,
    checkDataArray,
    checkDataBool,
    checkDataFunction,
    checkDataObject,
    checkDataString,
    connectClass,
    dispatch,
    getFullNum,
    isNotNull,
    isNotNullOrEmpty,
    requireDefault,
    square,
    ternaryArray,
    ternaryObject,
    numberPointFixed,
    numberToFixed,
    currentCoin,
    currentCoins,
    toFixedNum, getFullNum18, numberToFixed18
} from 'functions'
import {Navigate, Route, Routes} from 'react-router-dom'
import {EthereumClient, w3mConnectors, w3mProvider} from '@web3modal/ethereum'
import {configureChains, createConfig, useAccount, useChainId, WagmiConfig} from 'wagmi'
import {
    mainnet,
    base,
    linea,
    scroll,
    optimism,
    arbitrum,
    polygonZkEvm,
    arbitrumSepolia,
} from 'wagmi/chains'
import blast from "./otherChains/blast";
import bob from "./otherChains/bob";
import taiko from "./otherChains/taiko";
import bnb from "./otherChains/bnb";
import polygonZkevmCardona from "./otherChains/polygonZkevmCardona";
// import bobTestnet from "./otherChains/bobTestnet";
import bnbTestnet from "./otherChains/bnbTestnet";
import vizing from "./otherChains/vizing";
import vizingTestnet from "./otherChains/vizingTestnet";
import {prepareWriteContract, readContract, signMessage, writeContract, waitForTransaction} from '@wagmi/core'
import {message} from 'antd'
import axios from 'axios'
import Web3 from "web3";
import {formatEther, parseEther} from "viem";
import {divide as npDivide, minus as npMinus, plus as npPlus, times as npTimes,} from 'number-precision'
import {jsonRpcProvider} from "wagmi/providers/jsonRpc";
import Chart from "./Chart";
import {createWeb3Modal, defaultWagmiConfig, useWeb3Modal} from "@web3modal/wagmi/react";
import {QRCodeSVG} from 'qrcode.react';
import SwiperCore, {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.min.css'
import SuperGif from 'libgif'
import {OKXUniversalProvider} from "@okxconnect/universal-provider";

let lastBlock
const firstCoinsPage = 1
let coinsPage = firstCoinsPage,
    contestCapCoinsPage = firstCoinsPage,
    contestHoldersCoinsPage = firstCoinsPage,
    claimFeeCoinsPage = firstCoinsPage
let superGif
let listedPhaseId
let claimFeeCoins1 = []
let currentCoinLongSymbol1
let okxUniversalProvider
const telegramminiapp = new URLSearchParams(window.location.search).get('telegramminiapp')
isNotNullOrEmpty(telegramminiapp) && sessionStorage.setItem('telegramminiapp', telegramminiapp);
const sessionDev = sessionStorage.getItem('dev'),
    isTelegramMiniApp = sessionStorage.getItem('telegramminiapp'),
    masterChain = sessionDev ? vizingTestnet : vizing,
    {id: masterChainId} = masterChain,
    isMasterContractFunc = chainId => [vizingTestnet, vizing].map(v => v['id']).includes(Number(chainId)),
    contractDatas = {
        // 测试
        421614: 'ETH',
        2442: 'ETH',
        28516: 'ETH',
        97: 'BNB',
        // 正式
        1: 'ETH',
        56: 'BNB',
        28518: 'ETH',
        8453: 'ETH',
        59144: 'ETH',
        534352: 'ETH',
        42161: 'ETH',
        10: 'ETH',
        1101: 'ETH',
        81457: 'ETH',
        60808: 'ETH',
        167000: 'ETH',
    },
    setTransactionChainId = chainId => Object.keys(contractDatas).includes(checkDataString(chainId).toString()) ? chainId : masterChainId,
    memeLogo = logo => `${sessionDev
        ? 'https://test-s3.likwid.meme/token/logo'
        : 'https://s3.likwid.meme/token/logo'}/${logo}`,
    prdChains = [base, linea, scroll, arbitrum, optimism, polygonZkEvm, blast, bob, taiko, bnb, mainnet],
    memeSlaveContracts = sessionDev
        ? [
            arbitrumSepolia,
            polygonZkevmCardona,
            bnbTestnet
        ]
        : prdChains,
    memeContracts = [
        [masterChain.id, masterChain.name],
        ...memeSlaveContracts.map(v => [v.id, v.name])
    ],
    launchPrdOpen = true || sessionDev,
    swapPrdOpen = true || sessionDev,
    portfolioPrdOpen = true || sessionDev,
    votePrdOpen = true || sessionDev,
    likwidMemeTG = 'https://t.me/likwid_meme',
    topSocialLinks = [
        ['twitter', 'https://twitter.com/LIKWID_MEME'],
        ['telegram', likwidMemeTG]
    ],
    socialLinks = [
        ['medium', 'https://medium.com/@likwidofficial'],
        ...topSocialLinks,
        ['github', 'https://github.com/likwidmeme/likwid-contract']
    ],
    {location, open: windowOpen} = window,
    windowReplace = path => location.href = path,
    chains = [
        ...memeSlaveContracts,
        ...ternaryArray(portfolioPrdOpen && location.pathname.slice(1).split('/')[0] === 'portfolio', [masterChain])
    ],
    defaultChain = chains[0],
    {id: defaultChainId} = defaultChain,
    chainIds = chains.map(v => v.id),
    initData = {},
    projectId = '2006745ac580b61f1610d79f53496c97',
    // wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors: w3mConnectors({chains, projectId, version: 2}),
    //     publicClient: configureChains(chains, [w3mProvider({projectId})]).publicClient,
    // }),
    // wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors: w3mConnectors({chains, projectId, version: 2}),
    //     publicClient: configureChains(chains, [
    //         jsonRpcProvider({
    //             rpc: chain => ({
    //                 http: require(`./rpc/${chain.id}`).default
    //             })
    //         })
    //     ]).publicClient,
    // }),
    wagmiConfig = (() => {
        const dWC = defaultWagmiConfig({
            chains,
            projectId,
            metadata: {
                name: 'LIKWID',
                description: 'LIKWID',
                url: 'https://likwid.meme',
                icons: ['https://likwid.meme/logo.png'],
            },
            enableAnalytics: true,
        })
        dWC.setPublicClient(configureChains(chains, [
            jsonRpcProvider({
                rpc: chain => ({
                    http: require(`./rpc/${chain.id}`).default
                })
            })
        ]).publicClient)
        return dWC
    })(),
    ethereumClient = new EthereumClient(wagmiConfig, chains),
    height = '.6rem',
    heightPhone = '2rem',
    leaveOut = (str, start = 2, end = 4) => str ? [str.slice(0, start), str.slice(str.length - end)].join('...') : '',
    // coins = [
    //     {
    //         name: 'ORBGUY',
    //         wantTotalDeposit: sessionDev ? 0.002 : 10,
    //         totalSupply: sessionDev ? 20000 : 20000000,
    //     },
    //     // 13
    //     // {
    //     //     name: 'LIKWID',
    //     //     wantTotalDeposit: 0.002,
    //     //     totalSupply: 20000,
    //     // }
    // ].map(v => ({
    //     ...v,
    //     uint: `$${v.name}`
    // })),
    timeSlots = [
        ['24H', 1, 24],
        ['3D', 3, 50],
        ['1W', 7, 90],
        ['ALL', undefined, 360],
    ],
    // quotaData = ['Quota', 'quota', [
    //     'Primary  xy = (x + Δx)(y + Δy)',
    //     'Multiplicative  xy ^2= z',
    //     'Shortable Token'
    // ]],
    howToVoteData = ['How to vote', 'howToVote', [
        'Telegram voting',
        'On-chain voting'
    ]],
    percentage = (num = 0) => `${npTimes(num, 100)}%`,
    airdrop04 = .04,
    tokenomicsList = (earmarkedPresale = 0, presale = 0) => [
        [
            [.5, 'pool locked in trading curve'],
            [.5, 'presale'],
        ],
        [
            [.5, 'pool locked in trading curve'],
            [.4, 'presale'],
            [.1, 'earmarked presale for airdrop'],
        ],
        [
            [.5, 'pool locked in trading curve'],
            [.45, 'presale'],
            (() => {
                const earmarkedPresale = .05
                return [
                    earmarkedPresale,
                    `earmarked presale (${percentage(npMinus(earmarkedPresale, airdrop04))} for creator, ${percentage(airdrop04)} for airdrop)`
                ]
            })(),
        ],
        (() => {
            const tradingCurve = .5, airdrop = .05
            return [
                [tradingCurve, 'pool locked in trading curve'],
                [1 - tradingCurve - airdrop - earmarkedPresale, `presale, ${percentage(earmarkedPresale)} earmarked presale`],
                [
                    airdrop,
                    'airdrop'
                ],
            ]
        })(),
        [
            [.1, 'presale'],
            [.9, 'pool locked in trading curve'],
        ],
        [
            [presale, 'presale'],
            [1 - presale, 'pool locked in trading curve'],
        ],
    ],
    tokenomicsListLength = tokenomicsList().length,
    tokenomicsTextList = (...params) => tokenomicsList(...params).map(
        v => v.map(v => [`${percentage(checkData0(v, 0))}`, v[1]])
    ),
    tokenomicsData = (...params) => ['Tokenomics', 'tokenomics',
        tokenomicsTextList(...params).map(v =>
            v.map(v => v.join(' ')).join(', ')
        )
    ],
    defaultTokenomicsNum = 6,
    defaultTokenomicsIndex = defaultTokenomicsNum - 1,
    airdropProportion = (tokenomics, ...params) => tokenomics === defaultTokenomicsIndex ? airdrop04 : checkData0(tokenomicsList(...params), tokenomics, 2, 0),
    defaultTokenomicsPercentage = (...params) => checkData([tokenomicsTextList(...params), defaultTokenomicsIndex, 2, 0]),
    isTokenomicsAuto = tokenomics => tokenomics === 4,
    tgUrl = 'https://t.me/',
    xUrl = 'https://x.com/',
    totalPointsText = `What are DROPLETS?
Drip drip drip... What's that sound? It's the sound of us leaking this information to you guys... Behold, Likwid's Points System!

DROPLETS are the loyalty points of the LIKWID platform. The more points you accumulate, the higher the proportion of Likwid tokens you will receive in our future airdrops.`,
    contractStatuData = () => JSON.parse(store.getState().contractStatuData),
    withdrawIndex = 1,
    defaultAuthorizePoints = 110,
    twitterAuthorizePoints = defaultAuthorizePoints,
    tGAuthorizePoints = defaultAuthorizePoints,
    twitterAuthorizeData = {
        authorizeKey: 'x',
        authorizePoints: twitterAuthorizePoints,
        title: 'Follow & Share',
        needBind: true,
    },
    tGAuthorizeAuthorizeKey = 'tg',
    {authorizeKey: twitterAuthorizeAuthorizeKey} = twitterAuthorizeData,
    isTwitterAuthorizeAuthorize = aK => aK === twitterAuthorizeAuthorizeKey,
    pingFeeFunctionName = ['vote', 'create', 'crossTo', 'ethCrossTo'],
    buySellFunctionNames = ['swapExactETHForTokens', 'swapExactTokensForETH', 'buy', 'sell'],
    pongFeeFunctionName = ['claim', 'deposit', 'claimFee', ...buySellFunctionNames],
    isNewMemeFunc = coinName => coinName !== 'ORBGUY',
    isNewMemeNoGATOFunc = coinName => isNewMemeFunc(coinName) && coinName !== 'GATO',
    isNewMemeNoTAIBOIFunc = coinName => isNewMemeNoGATOFunc(coinName) && coinName !== 'TAIBOI',
    gasMultiple = 5,
    xCodeSplit = '##',
    commonChainTime = 5000,
    crossChainTimeSeconds = 30,
    pingCrossChainTime = 20000,
    depositTotalPrompt = 'Top-up limit on the omni chain: ',
    addressZero = '0x0000000000000000000000000000000000000000',
    contestLaunchFunds = '0.3',
    presaleList = [
        [10, 1], [20, 3], [30, 5], [40, 7], [50, 10],
    ],
    maxSlippageMin = 0.1,
    maxSlippageMax = 10,
    Profile = connectClass(props => {
            const pathname = location.pathname.slice(1),
                pNs = pathname.split('/'),
                pNs0 = pNs[0],
                pNs1 = pNs[1],
                isHome = pNs0 === '',
                isContest = pNs0 === 'contest',
                isAbout = pNs0 === 'about',
                isLaunch = launchPrdOpen && pNs0 === 'launch',
                isSwap = swapPrdOpen && pNs0 === 'swap',
                isPortfolio = portfolioPrdOpen && pNs0 === 'portfolio',
                isVotePage = votePrdOpen && pNs0 === 'vote',
                showPath = isContest ? '' : pNs0,
                [messageApi, contextHolder] = message.useMessage(),
                toast = content => messageApi.open({content, style: {marginTop: '1.5rem'}}),
                toastEmpty = () => toast('Amount cannot be empty'),
                toastSuccessful = success => toast(success ? 'Successful' : 'Failed'),
                toastSuccessfulHash = success => toast(success ? 'The transaction is on-chain; please wait for completion.' : 'Failed'),
                toastVerifyHuman = () => toast('Verify you are human'),
                copyText = (text = '', func) => {
                    const input = document.createElement('input')
                    input.value = text
                    document.body.appendChild(input)
                    input.select()
                    document.execCommand('Copy')
                    document.body.removeChild(input)
                    isNotNull(func) ? func() : toastSuccessful(true)
                },
                minBigInt = 0.000001,
                parseEtherWei = num => {
                    const numNumber = Number(num)
                    return parseEther(((isNaN(numNumber) || numNumber < minBigInt) ? 0 : num).toString())
                },
                numberETH = (numWei = '0') => numberPointFixed(formatEther(numWei)),
                numberFormatEther = (numWei = '0') => Number(formatEther(numWei)),
                numberParseEtherWei = (numETH = '0') => Number(parseEtherWei(numETH)),
                {isPhoneClientWidth} = store.getState(), [data, stateData] = useState({...initData}),
                setData = (d, clean) => stateData(data => ({...(clean ? {} : data), ...d})),
                toggleShowNotYet = showNotYet => setData({showNotYet}),
                toggleShowNotYetTrue = () => toggleShowNotYet(true),
                // noContractMemes = checkDataArray(data, 'noContractMemes'),
                pNsSymbol = (() => {
                    const pNSStr = checkDataString(pNs1)
                    return isNotNullOrEmpty(pNSStr)
                        ? pNSStr
                        : ((isSwap || isPortfolio) && !isNotNullOrEmpty(checkData([currentCoin(), 'symbol'])) ? 'SHORTHAIR' : pNSStr)
                })(),
                pNsSymbolUpperCase = pNsSymbol.toLocaleUpperCase(),
                pNsSymbolIsNotNullOrEmpty = isNotNullOrEmpty(pNsSymbol),
                pNsSymbolIsNotExist = checkData([data, 'pNsSymbolIsNotExist']),
                cC = (() => {
                    const obj = currentCoin(),
                        symbol = checkDataString(obj, 'symbol'),
                        pNS = symbol.toLocaleUpperCase() === pNsSymbolUpperCase ? symbol : pNsSymbol
                    return {
                        ...obj,
                        ...ternaryObject(
                            !pNsSymbolIsNotExist && pNsSymbolIsNotNullOrEmpty,// && !noContractMemes.includes(symbol),
                            {symbol: pNS, name: pNS}
                        )
                    }
                })(),
                loadCoins = checkDataArray(data, 'coins'),
                cCIsIncludes = loadCoins.map(v => checkDataString(v, 'name').toLocaleUpperCase()).includes(checkDataString(cC, 'name').toLocaleUpperCase()),
                isLaunchSearch = checkDataBool(data, 'isLaunchSearch'),
                cCIsNotNull = isNotNullOrEmpty(Object.keys(cC)) && (isLaunchSearch || cCIsIncludes),
                coins = [
                    ...ternaryArray(cCIsNotNull && !cCIsIncludes && !isLaunchSearch && !isHome, [cC]),
                    ...loadCoins
                ],
                catTip = (toggleFunc, show, title, text) => show &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.7)',
                         }}>
                        <div style={{width: `${isPhoneClientWidth ? 20 : 8}rem`}}
                             className={'positionRelative flexColumnCenter'}>
                            {isNotNull(toggleFunc) && <div className={'width100 flex justifyContentFlexEnd'}>
                                <img className={'cursorPointer'}
                                     onClick={() => checkDataFunction(toggleFunc)()}
                                     src={requireDefault('closeWhite')} alt=""
                                     style={{width: '5%'}}/>
                            </div>}
                            <img className={'zIndex1'}
                                 style={{width: '70%', marginBottom: `${isPhoneClientWidth ? -2.1 : -.7}rem`}}
                                 src={requireDefault('cat5')} alt=""/>
                            <div
                                className={`width100 ${isPhoneClientWidth
                                    ? 'childrenSpacingTopPhone boxSizingBorderBox borderRadiusPhone'
                                    : 'childrenSpacingTopBig boxSizingBorderBox'} flexColumnCenter boxSizingBorderBox`}
                                style={isPhoneClientWidth
                                    ? {
                                        padding: '2.1rem',
                                        borderRadius: '1.5rem',
                                        border: 'solid .15rem white',
                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                    }
                                    : {
                                        padding: '.7rem',
                                        borderRadius: '.5rem',
                                        border: 'solid .05rem white',
                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                    }}
                            >
                                {title && <div className={`saf ${isPhoneClientWidth ? '' : 'bigFontSize'} textAlignCenter`}>
                                    {title}
                                </div>}
                                {isNotNullOrEmpty(text) && <pre
                                    className={`textAlignCenter ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{text}</pre>}
                            </div>
                        </div>
                    </div>,
                showDeployment = checkDataBool(data, 'showDeployment'),
                toggleShowDeployment = showDeployment => setData({showDeployment}),
                {
                    wantTotalDeposit = 0,
                    name: currentCoinName,
                    uint: currentCoinUint,
                    totalSupply: currentCoinTotalSupply,
                    launchFunds: currentCoinLaunchFunds,
                    hardcap: currentCoinHardcap,
                    launchCountdown: currentCoinLaunchCountdown,
                    logo: currentCoinLogo,
                    desc: currentCoinDesc,
                    tg: currentCoinTG,
                    x: currentCoinX,
                    tokenomics: currentCoinTokenomics,
                    quota: currentCoinQuota,
                    presale: currentCoinPresale,
                    creator: currentCoinCreator,
                    version: currentCoinAbiVersion,
                    addressList: currentCoinContracts = {},
                    initialDate: currentCoinInitialDate,
                    launchTime: currentCoinLaunchTime,
                    cmCap: currentCoinCMCap,
                    holders: currentCoinHolders,
                    enableShort: currentEnableShort,
                    longSymbol: currentCoinLongSymbol,
                    shortSymbol: currentCoinShortSymbol,
                    shortMasterAbi: currentShortMasterAbi,
                    shortMasterAddress: currentShortMasterAddress,
                    longMasterAbi: currentLongMasterAbi,
                    longMasterAddress: currentLongMasterAddress,
                    initialPrice: currentCoinInitialPrice,
                } = cCIsNotNull ? cC : checkDataObject(coins, 0),
                currentCoinNameIsNotNull = isNotNull(currentCoinName),
                cCTgIsNotNull = isNotNullOrEmpty(currentCoinTG),
                cCXIsNotNull = isNotNullOrEmpty(currentCoinX),
                longShortTrue = currentEnableShort || currentCoinLongSymbol,
                notYet = checkDataBool(data, 'showNotYet') &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.7)',
                         }}>
                        {
                            (() => {
                                const notYetWidth = isPhoneClientWidth ? 20 : 8
                                return [
                                    <div className={'flex justifyContentFlexEnd'} style={{width: `${notYetWidth}rem`}}>
                                        <img className={'cursorPointer'}
                                             onClick={() => toggleShowNotYet()}
                                             src={requireDefault('closeWhite')} alt=""
                                             style={{width: `${npDivide(notYetWidth, 20)}rem`}}/>
                                    </div>,
                                    <img src={requireDefault('notYet')} alt=""
                                         style={{width: `${notYetWidth}rem`}}/>
                                ]
                            })()
                        }
                    </div>,
                drip = catTip(undefined, !sessionDev && checkDataBool(data, 'showDrip'), 'DRIPI DRIP!', checkDataString(data, 'dripText')),
                navigations = [
                    ['home', () => windowReplace('/'), 31],
                    ['launch', () => launchPrdOpen ? windowReplace('/launch') : toggleShowNotYetTrue(), 37],
                    ['swap', () => swapPrdOpen ? windowReplace('/swap') : toggleShowNotYetTrue(), 52],
                    ['about', () => windowReplace('/about'), 64.5],
                    ['portfolio', () => portfolioPrdOpen ? windowReplace('/portfolio') : toggleShowNotYetTrue(), 79]
                ],
                devCount = checkData0(data, 'devCount'),
                deposit = checkData0(data, 'deposit'),
                omniChain = checkData0(data, 'omniChain'),
                numberETHOmniChain = numberETH(omniChain),
                ethBalance = checkData0(data, 'ethBalance'),
                formChartIndex = checkData0(data, 'formChartIndex'),
                isShowChart = formChartIndex === 0,
                tokenBalance = checkData0(data, 'tokenBalance'),
                estimatedInternetFees = checkDataString(data, 'estimatedInternetFees'),
                isSwapBuy = checkData([data, 'isSwapBuy'], true),
                omniDeposited = checkData0(data, 'omniDeposited'),
                isNewMeme = isNewMemeFunc(currentCoinName),
                isNewMemeNoGATO = isNewMemeNoGATOFunc(currentCoinName),
                isNewMemeNoTAIBOI = isNewMemeNoTAIBOIFunc(currentCoinName),
                isNewMemeNoUTKOrPUPPET = !currentCoinNameIsNotNull || (isNewMemeNoTAIBOI && currentCoinAbiVersion >= 5),
                isV7 = currentCoinAbiVersion >= 7 || currentCoinLongSymbol,
                isV8 = currentCoinAbiVersion >= (currentCoinLongSymbol ? 2 : 8),
                isMaxSlippage = isSwap && isV8,// && !currentCoinLongSymbol,
                depositWithdraw = checkData0(data, 'depositWithdraw'),
                isWithdraw = depositWithdraw === withdrawIndex,
                isLaunchSwapBuy = isLaunch ? !isWithdraw : isSwapBuy,
                signTrue = checkData([data, 'signTrue']),
                signTrueString = checkDataString(signTrue),
                swapLaunched = checkDataBool(data, 'launched'),
                buySignTrue = isNewMemeNoUTKOrPUPPET && isLaunchSwapBuy,
                signSwitch = checkDataBool(data, 'signSwitch'),
                buyMaxNoGas = buySignTrue && signSwitch && (!isSwap || !swapLaunched),
                buyCheckSignTrue = buyMaxNoGas && !signTrue,
                reserves = checkDataArray(data, 'reserves'),
                reserves0 = checkData0(reserves, 0),
                reserves1 = checkData0(reserves, 1),
                shortCurrentPrice = checkData0(data, 'shortCurrentPrice'),
                maxETHBalance = (() => {
                    const defaultMaxETHBalance = 5,
                        isDepositTotal = (isLaunch && isNewMemeNoTAIBOI && !isWithdraw) || (isSwap && buyCheckSignTrue),
                        mEB = (isSwap && !isSwapBuy)
                            ? defaultMaxETHBalance
                            : npMinus(
                                checkData([data, isDepositTotal ? 'depositTotal' : 'maxETHBalance'], defaultMaxETHBalance),
                                isDepositTotal ? omniDeposited : 0
                            ),
                        mEB1 = checkData0(data, 'paymentNativeMax')
                    return currentCoinLongSymbol && isSwap && isSwapBuy && (mEB1 < mEB)
                        ? mEB1
                        : mEB
                })(),
                getContractAbi = (chainId, version = currentCoinAbiVersion, cCS = currentCoinLongSymbol) => axios(`${sessionDev
                    ? 'https://test-s3.likwid.meme'
                    : 'https://s3.likwid.meme'}/${cCS ? 'short' : 'product'}-abi/v${version}/${cCS ? 'Short' : 'Token'}${isMasterContractFunc(chainId) ? 'Master' : 'Slave'}.json`).then(v => checkDataObject(v, 'data')),
                sendETHBalance = ethBalance > maxETHBalance ? maxETHBalance : ethBalance,
                parseEtherWeiEthBalance = parseEtherWei(sendETHBalance),
                timeSlotIndex = checkData([data, 'timeSlotIndex'], 3),
                address = isTelegramMiniApp ? checkData([data, 'okxAddress']) : useAccount().address,
                getClaimed = () => rC('claimed', address),
                devCountAdd = () => setData({
                    devCount: devCount + 1
                }),
                showList = checkDataBool(data, 'showList'), toggleShowList = () => setData({showList: !showList}),
                {
                    isOpen,
                    open = () => {
                    },
                    close = () => {
                    },
                } = checkDataObject(useWeb3Modal()),
                connectLoading = width => checkDataBool(data, 'showConnectLoading') &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <img src={requireDefault('loading')} alt="" style={{width: `${width}rem`}}/>
                    </div>,
                currentChainId = isTelegramMiniApp ? defaultChainId : checkData([ethereumClient.getNetwork().chain, 'id'], undefined),
                wrongNetwork = !chainIds.includes(Number(currentChainId)),
                chainValue = isTelegramMiniApp ? defaultChain : (wrongNetwork ? defaultChain : ethereumClient.getNetwork().chain),
                chainId = checkData([chainValue, 'id'], defaultChainId),
                contractStatus = checkDataObject(contractStatuData(), chainId, address),
                contractStatusKeys = () => Object.keys(contractStatus).reverse(),
                pingPongDone = (key, tx, obj) => {
                    obj = isNotNull(obj) ? obj : checkDataObject(contractStatus, tx, key)
                    return checkData([obj, 'status']) === 2
                        && checkData([obj, 'opStatus']) === 99
                        && isNotNullOrEmpty(checkData([obj, 'targetId']))
                },
                pingDone = tx => pingPongDone('ping', tx),
                pongDone = tx => pingPongDone('pong', tx),
                contractStatuList = (() => {
                    const cSc = contractStatusKeys().slice(0, 3),
                        showAllCSc = checkData([data, 'showAllCSc'], true),
                        arr = showAllCSc ? cSc : [...cSc].slice(0, 1)
                    return isNotNullOrEmpty(arr) &&
                        <div style={{background: 'linear-gradient(180deg, #FF7788 0%, #FFB9C2 100%)'}}
                             className={`${isPhoneClientWidth ? 'paddingBigger borderRadiusPhone' : 'padding borderRadius'} overflowVisible`}>
                            <div
                                className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                <div
                                    className={`saf colorWhite ${isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}`}>POWERED
                                    BY
                                </div>
                                {
                                    [
                                        'ORBITER FINANCE',
                                        'chainIcons/28518'
                                    ].map(v => <img src={requireDefault(v)} alt=""
                                                    style={{width: `${isPhoneClientWidth ? 1.2 : .4}rem`}}/>)
                                }
                                <img className={isPhoneClientWidth ? 'marginLeftBigPhone' : 'marginLeftBig'}
                                     src={requireDefault('start1')} alt=""
                                     style={{width: `${isPhoneClientWidth ? 1.8 : .6}rem`}}/>
                            </div>
                            <div className={isPhoneClientWidth ? 'childrenSpacingTopBig' : 'childrenSpacingTop'}>
                                {arr.map((tx, i) => {
                                    const piD = pingDone(tx),
                                        poD = pongDone(tx),
                                        showMoreIcon = cSc.length > 1 && i === 0
                                    return <div>
                                        {showMoreIcon &&
                                            <div
                                                className={`flex justifyContentFlexEnd ${isPhoneClientWidth ? 'paddingRightPhone' : 'paddingRight'}`}>
                                                <img className={'backgroundWhite cursorPointer'}
                                                     onClick={() => setData({showAllCSc: !showAllCSc})}
                                                     src={requireDefault(showAllCSc ? 'contractStatuTop' : 'contractStatuBottom')}
                                                     alt=""
                                                     style={{
                                                         padding: `0 ${isPhoneClientWidth ? .5 : .1}rem`,
                                                         borderTopRightRadius: `${isPhoneClientWidth ? .21 : .07}rem`,
                                                         borderTopLeftRadius: `${isPhoneClientWidth ? .21 : .07}rem`,
                                                         ...square(`${isPhoneClientWidth ? .8 : .2}rem`)
                                                     }}/>
                                            </div>}
                                        <div
                                            className={`${isPhoneClientWidth
                                                ? 'boxSizingBorderBox'
                                                : 'padding paddingTopBig childrenSpacingTopBig'} positionRelative`}
                                            style={{
                                                width: isPhoneClientWidth ? '15rem' : '5rem',
                                                borderRadius: `${isPhoneClientWidth ? .6 : .2}rem`,
                                                border: `${isPhoneClientWidth ? .15 : .05}rem solid white`,
                                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                                ...ternaryObject(
                                                    isPhoneClientWidth,
                                                    {padding: '.7rem .5rem .5rem .5rem'}
                                                )
                                            }}>
                                            <div className={'flexCenter'}>
                                                <div style={{width: isPhoneClientWidth ? '90%' : '85%'}}
                                                     className={'flexCenter positionRelative'}>
                                                    <div className={'flexCenter childrenFlexGrow1'}
                                                         style={{width: '95%'}}>
                                                        {
                                                            [true, piD].map(v => <div
                                                                style={{
                                                                    height: `${isPhoneClientWidth ? .3 : .1}rem`,
                                                                    background: v ? '#FF7789' : 'white'
                                                                }}/>)
                                                        }
                                                    </div>
                                                    <div style={{top: 0, bottom: 0, left: 0, right: 0}}
                                                         className={'positionAbsolute flexCenter justifyContentSpaceBetween width100'}>
                                                        {
                                                            [true, piD, poD].map(v =>
                                                                <img
                                                                    src={requireDefault(v ? 'contractStatuDone' : 'contractStatuNotDon')}
                                                                    alt=""
                                                                    style={{width: isPhoneClientWidth ? '7%' : '10%'}}/>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                lineHeight: .7,
                                                ...ternaryObject(
                                                    isPhoneClientWidth,
                                                    {marginTop: '.7rem'}
                                                )
                                            }}
                                                 className={'flexCenter justifyContentSpaceBetween childrenFlexGrow1'}>
                                                {
                                                    (() => {
                                                        const arr = ['Ordered', 'Processing', 'Completed']
                                                        return arr.map((v, i) => <div
                                                            style={ternaryObject(isPhoneClientWidth, {fontSize: '.5rem'})}
                                                            className={`${isPhoneClientWidth ? '' : 'smallerFontSize'} ${i === 0 ? '' : (i < arr.length - 1 ? 'textAlignCenter' : 'textAlignRight')}`}>{v}</div>)
                                                    })()
                                                }
                                            </div>
                                            {/*{showMoreIcon && isPhoneClientWidth &&*/}
                                            {/*    <img className={'backgroundWhite cursorPointer positionAbsolute'}*/}
                                            {/*         onClick={() => setData({showAllCSc: !showAllCSc})}*/}
                                            {/*         src={requireDefault(showAllCSc ? 'contractStatuTop' : 'contractStatuBottom')}*/}
                                            {/*         alt=""*/}
                                            {/*         style={{*/}
                                            {/*             top: 0,*/}
                                            {/*             right: '-1.5rem',*/}
                                            {/*             padding: '0 .2rem',*/}
                                            {/*             borderRadius: '.21rem',*/}
                                            {/*             ...square('.8rem')*/}
                                            {/*         }}/>}*/}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                })(),
                toggleShowLoading = showLoading => setData({showLoading}),
                showLoading = checkDataBool(data, 'showLoading'),
                loading = showLoading &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <img src={requireDefault('loading')} alt="" style={{width: `${isPhoneClientWidth ? 10 : 5}rem`}}/>
                    </div>,
                token = () => sessionStorage.getItem('token'),
                requestApi = (url, data, method, headers = {}) => {
                    const t = token()
                    return axios(
                        {
                            method,
                            url: `${sessionDev ? 'https://test-api.likwid.meme/v1/' : 'https://api.likwid.meme/v1/'}${url}`,
                            [method ? 'data' : 'params']: data,
                            headers: {
                                ...headers,
                                ...ternaryObject(t, {'Authorization': `Bearer ${t}`})
                            },
                        },
                    ).then(r => {
                        const d = checkDataObject(r, 'data'),
                            isSuccess = d.code == '1'
                        if (!isSuccess) {
                            toast(d.msg)
                            toggleShowLoading()
                        }
                        return isSuccess ? checkDataObject(d, 'data') : null
                    }).catch(e => {
                        toggleShowLoading()
                        toast('Unknown error')
                        console.error(`------------------------------\n`, e, `------------------------------\n`)
                    })
                },
                loginThenFunc = (func = () => {
                }, errorFunc = () => {
                }) => token()
                    ? func()
                    : new Promise(resolve => requestApi('login/code', {address}).then(r => {
                            setTimeout(() => {
                                const code = checkData([r, 'code'])
                                signMessage({message: code}).then(sign =>
                                    requestApi(
                                        'login/login',
                                        {
                                            code,
                                            sign,
                                            address,
                                            loginType: 'WEB',
                                        },
                                        'post'
                                    ).then(r => {
                                            const token = checkData([r, 'token'])
                                            if (token) {
                                                sessionStorage.setItem('token', token)
                                                resolve(func())
                                            }
                                        }
                                    )
                                ).catch(e => {
                                    errorFunc()
                                    toast(checkDataString(checkDataString(e, 'message').split('\n'), 0))
                                })
                            }, 1000)
                        })
                    ),
                isMasterContract = isMasterContractFunc(currentChainId),
                isBNBFunc = id => [bnbTestnet, bnb].map(v => v['id']).includes(Number(id)),
                isBNB = isBNBFunc(chainId),
                ethBNB = isBNB ? 'BNB' : 'ETH',
                estimatedInternetFeesShow = [['Estimated Internet Fees', `${estimatedInternetFees} ${ethBNB}`]],
                maxETHBalanceToast = () => toast(`The transaction amount cannot be greater than ${maxETHBalance} ${ethBNB} ！`),
                fM = isMasterContractFunc(chainId),
                chainName = checkData([chainValue, 'name'], defaultChain.name),
                deployment = catTip(
                    toggleShowDeployment,
                    showDeployment,
                    undefined,
                    `${currentCoinName} does not support ${chainName} yet, please switch to other networks`,
                ),
                addressIsNotNull = isNotNull(address),
                addressLeaveOut = leaveOut(address),
                createMemeContractSlave = require(`./createMemeContract${sessionDev ? 'Dev' : ''}/${chainId}`).default,
                slaveContract = isVotePage
                    ? createMemeContractSlave
                    : checkDataObject(data, 'slaveContract'),
                // require(`./slaveContract${sessionDev ? 'Dev' : ''}/${'ORBGUY'}/${chainId}`).default
                createMemeContractMaster = require(`./createMemeContract${sessionDev ? 'Dev' : ''}/master`).default,
                masterContract = isVotePage
                    ? createMemeContractMaster
                    : checkDataObject(data, 'masterContract'),
                rewardClaimContract = require(`./rewardClaimContract${sessionDev ? 'Dev' : ''}`).default,
                // createBlockNumber = checkData([masterContract, 'receipt', 'blockNumber']),
                // require(`./masterContract${sessionDev ? 'Dev' : ''}/${'ORBGUY'}/prd`).default
                {eth: masterContractETH} = new Web3(
                    checkData([masterContract, 'rpc'], masterChain.rpcUrls.default.http[0])
                ),
                {eth: slaveContractETH} = new Web3(require(`./rpc/${chainId}`).default),
                {eth: rewardClaimContractETH} = new Web3(rewardClaimContract.rpc),
                {address: slaveContractAddress, abi: slaveContractAbi} = slaveContract,
                {
                    address: masterContractAddress,
                    abi: masterContractAbi,
                    blockExplorersTX: masterContractBlockExplorersTX
                } = masterContract,
                {
                    address: createMemeContractMasterAddress,
                    abi: createMemeContractMasterAbi,
                } = createMemeContractMaster,
                {
                    address: rewardClaimContractAddress,
                    abi: rewardClaimContractAbi,
                } = rewardClaimContract,
                {
                    address: createMemeContractSlaveAddress,
                    abi: createMemeContractSlaveAbi,
                } = createMemeContractSlave,
                contestContract = require(`./contestContract${sessionDev ? 'Dev' : ''}`).default,
                {
                    address: contestContractAddress,
                    abi: contestContractAbi,
                } = contestContract,
                bscSwapContract = require(`./bscSwapContract${sessionDev ? 'Dev' : ''}`).default,
                {
                    address: bscSwapContractAddress,
                    abi: bscSwapContractAbi,
                } = bscSwapContract,
                channelContractSlave = require(`./channelContract${sessionDev ? 'Dev' : ''}/${chainId}`).default,
                {
                    address: channelContractSlaveAddress,
                    abi: channelContractSlaveAbi,
                } = channelContractSlave,
                channelContractMaster = require(`./channelContract${sessionDev ? 'Dev' : ''}/master`).default,
                {
                    address: channelContractMasterAddress,
                    abi: channelContractMasterAbi,
                } = channelContractMaster,
                blockTimestamp = second => masterContractETH.getBlock().then(block => npTimes(Number(block.timestamp), second ? 1 : 1000)),
                ethUSDT = checkData([data, 'ethUSDT']),
                ethUSDTIsNotNull = isNotNull(ethUSDT),
                buySellEvent = checkDataArray(data, 'buySellEvent'),
                firstSwapPage = 1,
                swapPage = checkData([data, 'swapPage'], firstSwapPage),
                swapPageIsFirst = swapPage === firstSwapPage,
                setFistSwapPage = () => setData({swapPage: firstSwapPage}),
                addSwapPage = () => {
                    toggleShowLoading(true)
                    setData({swapPage: swapPage + 1})
                },
                masterContractETHGetEvents = async (eventName, page = swapPage, step = 10) => {
                    const lB = checkData0(lastBlock), fromBlock = npPlus(npMinus(lB, step * (page + 1)), 1),
                        toBlock = npMinus(lB, step * page)
                    return (new masterContractETH.Contract(masterContractAbi, masterContractAddress)).getPastEvents(
                        eventName,
                        {
                            fromBlock: fromBlock < 0 ? 0 : fromBlock,
                            toBlock: toBlock < 0 ? 0 : toBlock,
                        }
                    )
                },
                contractCall = (eth, address, abi, funcName, ...params) => {
                    const func = checkData([new eth.Contract(abi, address), 'methods', funcName])
                    return func ? func(...params).call() : new Promise(resolve => resolve())
                },
                slaveContractCall = (...params) => contractCall(slaveContractETH, slaveContractAddress, slaveContractAbi, ...params),
                masterContractCall = (...params) => contractCall(masterContractETH, masterContractAddress, masterContractAbi, ...params),
                createMemeContractMasterCall = (funcName, ...params) =>
                    contractCall(new Web3(createMemeContractMaster.rpc).eth, createMemeContractMasterAddress, createMemeContractMasterAbi,
                        funcName, ...params
                    ),
                createMemeContractSlaveCall = (funcName, ...params) =>
                    contractCall(slaveContractETH, createMemeContractSlaveAddress, createMemeContractSlaveAbi,
                        funcName, ...params
                    ),
                rewardClaimContractCall = (funcName, ...params) => contractCall(
                    rewardClaimContractETH,
                    rewardClaimContractAddress,
                    rewardClaimContractAbi,
                    funcName,
                    ...params
                ),
                contestContractCall = (funcName, ...params) =>
                    contractCall(slaveContractETH, contestContractAddress, contestContractAbi,
                        funcName, ...params
                    ),
                bscSwapContractCall = (funcName, ...params) =>
                    contractCall(slaveContractETH, bscSwapContractAddress, bscSwapContractAbi,
                        funcName, ...params
                    ),
                channelContractSlaveCall = (funcName, ...params) =>
                    contractCall(slaveContractETH, channelContractSlaveAddress, channelContractSlaveAbi,
                        funcName, ...params
                    ),
                channelContractMasterCall = (funcName, ...params) =>
                    contractCall(masterContractETH, channelContractMasterAddress, channelContractMasterAbi,
                        funcName, ...params
                    ),
                longMasterCall = (funcName, ...params) =>
                    contractCall(masterContractETH, currentLongMasterAddress, currentLongMasterAbi,
                        funcName, ...params
                    ),
                shortMasterCall = (funcName, ...params) =>
                    contractCall(masterContractETH, currentShortMasterAddress, currentShortMasterAbi,
                        funcName, ...params
                    ),
                bnbGetEstimated = (num, func) => isBNB ? bscSwapContractCall(func, num) : new Promise(resolve => resolve(num)),
                ethToBNB = num => bnbGetEstimated(num, 'getEstimatedNativeForWETH'),
                bnbToETH = num => bnbGetEstimated(num, 'getEstimatedWETHForNative'),
                defaultTokenomics = tokenomics => {
                    tokenomics = Number(tokenomics)
                    return npMinus(((tokenomics === 0 || tokenomics > tokenomicsListLength) ? defaultTokenomicsNum : tokenomics), 1)
                },
                contestContractRoundClaimAmount = symbol => contestContractCall('roundClaimAmount', symbol, address).then(amount => amount > 0),
                montageLink = (v, url) => isNotNullOrEmpty(v) ? `${url}${v.slice(v.lastIndexOf('/') + 1)}` : undefined,
                montageTGLink = v => v ? montageLink(v, tgUrl) : topSocialLinks[1][1],
                montageXLink = v => v ? montageLink(v, xUrl) : topSocialLinks[0][1],
                namePrefix = '[LIKWID] ',
                memeLogoFunc = logo => isNotNullOrEmpty(logo) ? memeLogo(logo) : requireDefault('LIKWID'),
                memeData = (meme, isApi) => {
                    const {symbol, logo, votes, quota, hardcap, totalSupply} = meme,
                        launchFunds = checkData0(meme, 'launchFunds'),
                        ethNumFunc = isNewMemeNoGATOFunc(symbol) ? numberETH : Number,
                        cmCapFunc = cmCap => `$${npDivide(checkData0(cmCap), 1000).toFixed(2)}K`,
                        xData = checkDataString(meme, 'x').split(xCodeSplit),
                        {shortMasterAbi, shortMasterAddress} = meme,
                        shortMasterAbiAddressIsTrue = shortMasterAbi && shortMasterAddress
                    return {
                        ...isApi
                            ? {
                                votes,
                                quota,
                                hardcap,
                                totalSupply,
                                launchFunds,
                                shortMasterAbi,
                                shortMasterAddress,
                                status: meme.status,
                                holders: meme.holders,
                                version: meme.version,
                                launched: meme.launched,
                                maxHolders: meme.maxHolders,
                                longSymbol: meme.longSymbol,
                                wantTotalDeposit: launchFunds,
                                initialDate: meme.initialDate,
                                initialPrice: meme.initialPrice,
                                longMasterAbi: meme.longMasterAbi,
                                longMasterAddress: meme.longMasterAddress,
                                cmCap: cmCapFunc(checkData0(meme, 'cmCap')),
                                maxCmCap: cmCapFunc(checkData0(meme, 'maxCmCap')),
                                addressList: checkDataObject(meme, 'addressList'),
                                shortSymbol: shortMasterAbiAddressIsTrue ? meme.shortSymbol : null,
                                launchTime: Math.floor(npDivide(checkData0(meme, 'launchTime'), 1000)),
                                enableShort: shortMasterAbiAddressIsTrue && checkDataArray(meme, 'shortSymbols').length > 0,
                            }
                            : {
                                votes: Number(votes),
                                quota: numberETH(quota),
                                hardcap: numberETH(hardcap),
                                totalSupply: ethNumFunc(totalSupply),
                                launchFunds: ethNumFunc(launchFunds),
                                wantTotalDeposit: ethNumFunc(launchFunds),
                            },
                        symbol,
                        name: symbol,
                        desc: (() => {
                            const name = checkDataString(meme, 'name'),
                                namePrefixNoSpace = namePrefix.replace(/\s+/g, '')
                            return name.indexOf(namePrefix) === 0 ? name.substring(namePrefix.length) : (
                                name.indexOf(namePrefixNoSpace) === 0 ? name.substring(namePrefixNoSpace.length) : name
                            )
                        })(),
                        uint: `$${symbol}`,
                        creator: meme.creator,
                        logo: memeLogoFunc(logo),
                        x: montageXLink(xData[0]),
                        tg: montageTGLink(meme.tg),
                        presale: npDivide(checkData0(xData, 1), 100),
                        tokenomics: defaultTokenomics(meme.tokenomics),
                        launchCountdown: npDivide(Number(meme.launchCountdown), 3600),
                    }
                },
                memeDataIsApi = (...params) => memeData(...params, true),
                searchSymbol = checkData([data, 'searchSymbol']),
                searchSymbolIsNotNull = isNotNullOrEmpty(searchSymbol),
                setDataSearchSymbolEmpty = () => setData({searchSymbol: ''}),
                shortMemeDetailFunc = symbol => requestApi(`token/short/${symbol}`),
                tokenMemeDetailFunc1 = symbol => requestApi(`token/meme/${symbol}`),
                tokenMemeDetailFunc = (symbol, cCLS = currentCoinLongSymbol1) => (isSwap && cCLS) ? shortMemeDetailFunc(symbol) : tokenMemeDetailFunc1(symbol),
                tokenContracts = symbol => requestApi(`token/contract/${symbol}`).then(addressList => checkDataObject(addressList)),
                getShortSymbol = async (cCN = currentCoinName, shortSymbols = [], needAddress) => {
                    // console.log(shortSymbols);
                    let shortSymbol = checkData([shortSymbols, 0, 'symbol'])
                    if (shortSymbol) {
                        try {
                            let shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId]),
                                shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                            await (cCN === 'LSTD'
                                ? new Promise(reserves => reserves(true))
                                : contractCall(masterContractETH, shortMasterAddress, shortMasterAbi, 'checkCurrent')).then(async checkCurrent => {
                                    const shortSymbol1 = checkData([shortSymbols, 1, 'symbol'])
                                    // console.log(shortSymbol, shortMasterAddress, checkCurrent);
                                    if (!checkCurrent && isNotNull(shortSymbol1)) {
                                        shortSymbol = shortSymbol1
                                        if (needAddress) {
                                            shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId])
                                            shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                                        }
                                    }
                                }
                            )
                            // console.log(shortSymbol, shortMasterAddress);
                            return {shortSymbol, shortMasterAddress, shortMasterAbi}
                        } catch {
                            return {}
                        }
                    } else {
                        return {}
                    }
                },
                tokenMemeDetail = symbol => tokenMemeDetailFunc(symbol).then(async obj => memeDataIsApi(
                        {
                            ...obj,
                            ...isSwap
                                ? await (async () => {
                                    const shortSymbols = checkDataArray(obj, 'shortSymbols')
                                    return checkData([shortSymbols, 0, 'symbol'])
                                        ? await getShortSymbol(checkDataString(obj, 'symbol'), shortSymbols, true)
                                        // ? await (async () => {
                                        //     // let shortMasterAbi, shortMasterAddress
                                        //     // if (shortSymbol) {
                                        //     //     shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId])
                                        //     //     shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                                        //     //     await (currentCoinName === 'LSTD'
                                        //     //         ? new Promise(reserves => reserves(true))
                                        //     //         : contractCall(masterContractETH, shortMasterAddress, shortMasterAbi, 'checkCurrent')).then(async checkCurrent => {
                                        //     //             const shortSymbol1 = checkData([shortSymbols, 1, 'symbol'])
                                        //     //             if (!checkCurrent && isNotNull(shortSymbol1)) {
                                        //     //                 shortSymbol = shortSymbol1
                                        //     //                 shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId])
                                        //     //                 shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                                        //     //             }
                                        //     //         }
                                        //     //     )
                                        //     // }
                                        //     // console.log(shortSymbol, shortMasterAddress, '+++++++++++++++');
                                        //     return ()
                                        // })()
                                        : await (async () => {
                                            const longSymbol = checkData([obj, 'longSymbol'])
                                            return longSymbol
                                                ? {
                                                    longMasterAddress: checkData([await tokenContracts(longSymbol), masterChainId]),
                                                    longMasterAbi: await getContractAbi(masterChainId, (await tokenMemeDetailFunc(longSymbol, false)).version, false)
                                                }
                                                : {}
                                        })()
                                })()
                                : {}
                        }
                    )
                ),
                symbolExists = checkDataBool(data, 'symbolExists'),
                toggleSymbolExists = symbolExists => setData({symbolExists}),
                setCurrentCoin = (currentCoin, isClick) => {
                    const {symbol} = currentCoin
                    return tokenMemeDetail(symbol).then(obj => tokenContracts(symbol).then(addressList => {
                        if (isClick
                            && checkDataString(symbol).toLocaleUpperCase() !== checkDataString(currentCoinName).toLocaleUpperCase()) {
                            windowReplace(`/${pNs0}/${symbol}`)
                        } else {
                            // console.log('setCurrentCoin', obj);
                            obj = {...obj, addressList}
                            const cCs = JSON.stringify({...currentCoins(), [pNs0]: obj})
                            sessionStorage.setItem('currentCoins', cCs)
                            dispatch(props, {currentCoins: cCs})
                        }
                    }))
                },
                setCoinIndex = coinIndex => {
                    const coin = checkDataObject(coins, coinIndex),
                        sCC = () => setCurrentCoin(coin, true)
                    isLaunch
                        ? sCC()
                        : tokenContracts(coin.name).then(
                            addressList =>
                                Object.keys(addressList).length > 0
                                    ? sCC()
                                    : toggleShowDeployment(true)
                        ).catch(() => toggleShowDeployment(true))
                },
                sortTypeIndex = checkData0(data, 'sortTypeIndex'),
                isPositiveSequence = checkDataBool(data, 'isPositiveSequence'),
                phaseId = checkData([data, 'phaseId']),
                phases = checkData([data, 'phases'], Array.from({length: 4}, (_, i) => ({id: i + 1}))),
                phaseIndex = npMinus(checkData([phaseId], 1), 1),
                isMemesCreated = checkDataBool(data, 'isMemesCreated'),
                claimFeeAmount = checkData0(data, 'claimFeeAmount'),
                claimFeeCoinsKey = 'claimFeeCoins',
                claimFeeCoins = checkDataArray(data, claimFeeCoinsKey),
                claimFeeCoin = checkDataObject(data, 'claimFeeCoin'),
                {symbol: claimFeeCoinSymbol} = claimFeeCoin,
                claimFeeCoinAddress = checkData([data, 'claimFeeCoinAddress']),
                claimFeeCoinsLengthKey = 'claimFeeCoinsLength',
                setClaimFeeCoin = (i = 0, coins1 = claimFeeCoins) => {
                    const claimFeeCoin1 = checkDataObject(coins1, i)
                    tokenContracts(claimFeeCoin1.symbol).then(addressList => setData({
                        claimFeeCoin: claimFeeCoin1,
                        claimFeeCoinAddress: addressList[masterChainId],
                    }))
                },
                showCreatedClaim = checkDataBool(data, 'showCreatedClaim'),
                claimFeeParams = [
                    undefined, undefined,
                    claimFeeCoinsPage, coinsPage => {
                        claimFeeCoinsPage = coinsPage + 1
                    }, undefined, claimFeeCoins, claimFeeCoinsKey, claimFeeCoinsLengthKey, true
                ],
                getClaimFeeAmount = () => createMemeContractMasterCall('getClaimFeeAmount', claimFeeCoinAddress, address).then(claimFeeAmount =>
                    setData({claimFeeAmount})
                ),
                getListedMemeList = (
                    pageSize = isHome ? 15 : 50, afterCreate,
                    cP = coinsPage, cPAdd = () => {
                        coinsPage = coinsPage + 1
                    }, orderBy, lC = loadCoins, coinsKey = 'coins', coinsLengthKey = 'coinsLength', isMemesCreated
                ) => {
                    const shortSwap = isSwap && currentCoinLongSymbol1,
                        gLL = (page = cP, pS = pageSize, symbol) => requestApi(
                            (isLaunch || isMemesCreated) ? 'token/symbol' : (shortSwap ? 'token/short/swap' : ((isHome || isContest || isVotePage) ? 'token/symbol/launch' : 'token/symbol/swap')),
                            {
                                page,
                                pageSize: pS,
                                ...ternaryObject(
                                    isNotNullOrEmpty(symbol),
                                    {symbol}
                                ),
                                ...ternaryObject(
                                    searchSymbolIsNotNull,
                                    {symbol: checkDataString(searchSymbol).toLocaleUpperCase()}
                                ),
                                ...ternaryObject(
                                    isHome || isContest,
                                    {orderBy: isNotNull(orderBy) ? orderBy : `${sortTypeIndex + 1}_${isPositiveSequence ? 'asc' : 'desc'}`}
                                ),
                                ...ternaryObject(
                                    isContest && isNotNull(listedPhaseId),
                                    {phaseId: listedPhaseId}
                                ),
                                ...ternaryObject(
                                    isMemesCreated,
                                    {creator: address}
                                ),
                                ...ternaryObject(
                                    shortSwap,
                                    {longSymbol: currentCoinLongSymbol1}
                                )
                            }
                        )
                    return gLL().then(
                        r => {
                            toggleShowLoading()
                            const arr = checkDataArray(r, 'items'),
                                isFirstCoinsPage = cP === firstCoinsPage,
                                includesPNsSymbol = arr => arr.map(v => checkDataString(v, 'symbol').toLocaleUpperCase()).includes(pNsSymbolUpperCase);
                            // console.log(isFirstCoinsPage, '+++++++++++');
                            return (isFirstCoinsPage && pNsSymbolIsNotNullOrEmpty && !includesPNsSymbol(arr) && !searchSymbolIsNotNull && !isContest && !isMemesCreated
                                ? tokenMemeDetailFunc(pNsSymbolUpperCase).then(v => Object.values(checkDataObject(v)).length > 0 ? [v] : [])
                                // ? gLL(firstCoinsPage, 1, pNsSymbolUpperCase).then(r1 => checkDataArray(r1, 'items').filter(v => checkDataString(v, 'symbol').toLocaleUpperCase() === pNsSymbolUpperCase))
                                : new Promise(resolve => resolve([]))).then(
                                async other => {
                                    // console.log(other, isLaunchSearch, '++++++++++++');
                                    const arr1 = [
                                            ...other,
                                            ...(isFirstCoinsPage ? arr : arr.filter(v => checkDataString(v, 'symbol').toLocaleUpperCase() !== pNsSymbolUpperCase))
                                        ].map(v => memeDataIsApi(v)),
                                        cCSymbol = checkDataString(cC, 'symbol').toLocaleUpperCase(),
                                        arr1IncludesSymbol = arr1.map(v => checkDataString(v, 'symbol').toLocaleUpperCase()).includes(cCSymbol)
                                    cPAdd(cP)
                                    // console.log('pNsSymbolIsNotExist', pNsSymbolIsNotNullOrEmpty && !includesPNsSymbol(arr1));
                                    const coins1 = [
                                        ...ternaryArray(!isFirstCoinsPage, lC),
                                        ...await Promise.all(arr1.map(async (v, i) => {
                                                const isFirst = i === 0
                                                // console.log(v.symbol, cCSymbol, !cCIsNotNull);
                                                if (!isHome && !isContest && (afterCreate ? isFirst : (arr1IncludesSymbol ? (checkDataString(v, 'symbol').toLocaleUpperCase() === cCSymbol) : (isFirst && !cCIsNotNull)))) {
                                                    // console.log(arr1IncludesSymbol, v, 'aaaaa', '++++++++++++=');
                                                    // console.log('getMemes update', v.symbol, arr1IncludesSymbol, cCSymbol);
                                                    await setCurrentCoin(v)
                                                }
                                                return v
                                            }
                                        ))
                                    ]
                                    if (isMemesCreated && isFirstCoinsPage) {
                                        claimFeeCoins1 = coins1
                                    }
                                    return setData({
                                        ...ternaryObject(
                                            !isNotNull(pNsSymbolIsNotExist),
                                            {pNsSymbolIsNotExist: pNsSymbolIsNotNullOrEmpty && !includesPNsSymbol(arr1)}
                                        ),
                                        ...ternaryObject(
                                            isFirstCoinsPage,
                                            {[coinsLengthKey]: checkData0(r, 'total')}
                                        ),
                                        [coinsKey]: coins1
                                    })
                                }
                            )
                        }
                    )
                },
                reGetListedMemeList = (...params) => {
                    coinsPage = firstCoinsPage
                    return getListedMemeList(...params)
                },
                setSearchSymbol = e => setData({searchSymbol: e.target.value}),
                searchSymbolOnKeyUp = (e, func = () => {
                }) => e.keyCode === 13 && func(),
                isChannelPongPing = handelType => isV8 && ['buy', 'sell'].includes(handelType),
                pingEstimateGas = (callFunc, handelType, amount, ...params) =>
                    (isChannelPongPing(handelType) ? channelContractSlaveCall : callFunc)(`${handelType}PingEstimateGas`, amount, ...params).then(pingFee => Number(pingFee)),
                rcPingEstimateGas = (...params) => pingEstimateGas(rC, ...params),
                bnbAmountMultiple = amount => isBNB ? parseEtherWei(npTimes(numberFormatEther(amount), 1.001)) : amount,
                getEstimateGas = (otherParams = [], needAmount, handelType, ...params) => {
                    const [a] = params, iCPP = isChannelPongPing(handelType)
                    return (['buy', 'deposit'].includes(handelType) ? bnbToETH(a) : new Promise(resolve => resolve(a))).then(amount => {
                        params = [
                            ...ternaryArray(iCPP, [currentCoinName]),
                            address,
                            ...ternaryArray(
                                needAmount,
                                [amount, ...params.slice(1)]
                            )
                        ]
                        const getParams = (index, isPong) => {
                            const params1 = [params[2], checkData([otherParams, index, 0])]
                            return iCPP
                                ? [...params.slice(0, 2), ...(isPong && handelType === 'sell') ? params1.reverse() : params1, 0]
                                : params
                        }
                        // console.log([...ternaryArray(isNewMeme && !iCPP, [0]), chainId, ...getParams(0, true)], [...getParams(1)], '-------------------');
                        return isMasterContract
                            ? new Promise(resolve => resolve([parseEtherWei(0), 0]))
                            : (iCPP ? channelContractMasterCall : masterContractCall)(`${handelType}PongEstimateGas`, ...ternaryArray(isNewMeme && !iCPP, [0]), chainId, ...getParams(0, true)).then(pongFee =>
                                (isBNB ? channelContractSlaveCall('exactOutput', pongFee) : new Promise(resolve => resolve(pongFee))).then(pongFee => {
                                    pongFee = bnbAmountMultiple(pongFee)
                                    return rcPingEstimateGas(handelType, pongFee, ...getParams(1)).then(pingFee => {
                                        // console.log('estimateGas', handelType, pongFee, pingFee);
                                        return [pongFee, Math.ceil(npPlus(Number(pongFee), pingFee))]
                                    })
                                })
                            )
                    })
                },
                getEstimateGasClaimFee = (handelType, ...params) => isMasterContract
                    ? new Promise(resolve => resolve([parseEtherWei(0), 0]))
                    : createMemeContractMasterCall(`${handelType}PongEstimateGas`, 0, chainId, ...params).then(pongFee =>
                        (isBNB ? createMemeContractSlaveCall('exactOutput', pongFee) : new Promise(resolve => resolve(pongFee))).then(pongFee => {
                            pongFee = bnbAmountMultiple(pongFee)
                            return createMemeContractSlaveCall(`${handelType}PingEstimateGas`, pongFee, masterChainId, ...params, claimFeeAmount).then(pingFee => Number(pingFee)).then(pingFee => {
                                // console.log('estimateGas', handelType, pongFee, pingFee);
                                return [pongFee, Math.ceil(npPlus(Number(pongFee), pingFee))]
                            })
                        })
                    ),
                estimateGas = checkData([data, 'estimateGas']),
                getDeadline = async () => BigInt(npPlus(Number((await slaveContractETH.getBlock()).timestamp), 30 * 60)),
                getDeposit = () => rC('deposited', address),
                setOmniDeposited = () => masterContractCall('omniDeposited', address).then(v => {
                    setData({omniDeposited: numberETH(v)})
                }),
                getBalance = async () => numberETH(await slaveContractETH.getBalance(address)),
                getTokenBalance = async () => {
                    const tokenBalanceWei = await rC('balanceOf', address)
                    currentCoinLongSymbol && setData({tokenBalanceWei})
                    return numberETH(tokenBalanceWei)
                },
                getAmountOutFunc = async (ethAmount, iSB = isSwapBuy, noBNB) => {
                    ethAmount = parseEtherWei(ethAmount)
                    const bnbETH = await bnbToETH(ethAmount)
                    iSB && isBNB && setData({bnbETH: numberETH(bnbETH)})
                    const {amount} = await masterContractCall('getAmountOut', iSB ? bnbETH : ethAmount, iSB)
                    return (isBNB && !iSB && !noBNB) ? (await ethToBNB(amount)) : amount
                },
                getAmountOut = async (...params) => numberETH(await getAmountOutFunc(...params)),
                getLocked = () => masterContractCall('locked', address).then(locked => {
                    const lockedFunc = index => numberETH(locked[index])
                    setData({
                        lockedETH: lockedFunc(0),
                        lockedToken: lockedFunc(1)
                    })
                }),
                eventDate = (timestamp, joinText = ' ', getYear, noSeconds) => {
                    const date = new Date(npTimes(Number(timestamp), 1000))
                    return [
                        date.toLocaleDateString('en-US', {month: 'short', day: 'numeric'}),
                        ...ternaryArray(getYear, [date.getFullYear()]),
                        ['getHours', 'getMinutes', ...ternaryArray(!noSeconds, ['getSeconds'])].map(v => {
                            v = date[v]()
                            return `${v < 10 ? 0 : ''}${v}`
                        }).join(':')
                    ].join(joinText)
                },
                isMyTrade = address ? checkData0(data, 'isMyTrade') : 0,
                getSwapEvent = noShowLoading => {
                    let isBlock10 = false, buySellEvent1 = []
                    const form = () => requestApi(
                        `${currentCoinLongSymbol ? 'short' : 'point'}/swap/log/form`, {
                            page: swapPage,
                            pageSize: 20,
                            token: masterContractAddress.toLocaleLowerCase(),
                            ...ternaryObject(
                                isMyTrade,
                                {address}
                            )
                        }
                    ).then(v => {
                            !noShowLoading && toggleShowLoading();
                            const items = checkDataArray(v, ...ternaryArray(isMyTrade && currentCoinLongSymbol, ['pageData']), 'items'),
                                items1 = [...items];
                            (swapPageIsFirst
                                ? Promise.all(items.map(async item => await Promise.all(
                                    buySellEvent1.map(v => item.txHash.toLocaleLowerCase() === v.transactionHash.toLocaleLowerCase() &&
                                        items1.splice(items1.indexOf(item), 1))
                                )))
                                : new Promise(resolve => resolve())).then(() => setData({
                                    buySellEvent: [
                                        ...ternaryArray(swapPage > 1 || isBlock10, isBlock10 ? buySellEvent1 : buySellEvent),
                                        ...items1.map(v => {
                                            const ethAmount = numberToFixed(v.eth),
                                                tokenAmount = numberToFixed(v.token),
                                                chainId1 = setTransactionChainId(checkData([v, 'srcChainId'])),
                                                {swapType} = v,
                                                earnEth = getFullNum(checkData0(v, 'earnEth'))
                                            return {
                                                ethAmount,
                                                tokenAmount,
                                                transactionHash: v.txHash,
                                                swapType,
                                                earnEth: earnEth == 0 ? '' : `${earnEth} ETH`,
                                                isBuy: ['BUY', 'GIVE'].includes(v.swapType),
                                                date: eventDate(Math.floor(v.logTime / 1000)),
                                                native: (() => {
                                                    const native = numberPointFixed(checkData0(v, 'amountNative'))
                                                    return native == 0 ? '' : `${native} ${contractDatas[chainId1]}`
                                                })(),
                                                price: tokenAmount == 0 ? 0 : npDivide(Number(ethAmount), Number(tokenAmount)),
                                                chainId: chainId1,
                                                shortPrice: getFullNum(checkData0(v, 'price')),
                                                longPrice: getFullNum(checkData0(v, 'longPrice'))
                                            }
                                        })
                                    ],
                                    ...(() => {
                                        const inOutVo = checkDataObject(v, 'inOutVo')
                                        return ternaryObject(
                                            isMyTrade,
                                            {totalCost: numberToFixed(npMinus(checkData0(inOutVo, 'outEth'), checkData0(inOutVo, 'inEth')))}
                                        )
                                    })()
                                }
                            ))
                        }
                    )
                    swapPageIsFirst
                        ? masterContractETH.getBlockNumber().then(v => lastBlock = Number(v)).then(
                            () => {
                                const step = 10
                                return masterContractETHGetEvents(currentCoinLongSymbol ? 'Trade' : 'Swap', 0, step).then(v =>
                                    masterContractETHGetEvents('MessageReceived', 0, step).then(
                                        messageReceivedList => {
                                            const messageReceivedChainIds = messageReceivedList.map(v =>
                                                    setTransactionChainId(checkData([v, 'returnValues', '_srcChainId']))),
                                                messageReceivedTransactionHashs = messageReceivedList.map(v => v.transactionHash)
                                            const list = []
                                            return Promise.all(v.map(async v => {
                                                const {transactionHash, blockHash, blockNumber} = v
                                                v = checkDataObject(v, 'returnValues')
                                                const isBuy = currentCoinLongSymbol ? ([1n, 3n].includes(v._action)) : Number(checkData0(v, 'amount0In')) > 0 || Number(checkData0(v, 'amount1Out')) > 0,
                                                    ethAmount = numberETH(currentCoinLongSymbol ? checkData0(v, '_native') : checkData0(v, isBuy ? 'amount0In' : 'amount0Out')),
                                                    tokenAmount = numberETH(currentCoinLongSymbol ? checkData0(v, '_token') : checkData0(v, isBuy ? 'amount1Out' : 'amount1In'))
                                                // list.push({
                                                //     isBuy,
                                                //     ethAmount,
                                                //     tokenAmount,
                                                //     blockNumber,
                                                //     transactionHash,
                                                //     price: getFullNum(npDivide(Number(ethAmount), Number(tokenAmount)), true),
                                                //     date: eventDate((await masterContractETH.getBlock(blockHash)).timestamp),
                                                //     chainId: messageReceivedTransactionHashs.includes(transactionHash)
                                                //         ? checkData([
                                                //             messageReceivedChainIds,
                                                //             messageReceivedTransactionHashs.indexOf(transactionHash)
                                                //         ], masterChainId)
                                                //         : masterChainId
                                                // })
                                            })).then(() => {
                                                    isBlock10 = true;
                                                    !noShowLoading && toggleShowLoading();
                                                    buySellEvent1 = list.sort((a, b) => Number(b.blockNumber) - Number(a.blockNumber))
                                                }
                                            ).then(() => form()).catch(() => !noShowLoading && toggleShowLoading())
                                        }
                                    )
                                )
                            }
                        ).catch(() => !noShowLoading && toggleShowLoading())
                        : form()
                },
                reloadForm = noShowLoading => {
                    !noShowLoading && toggleShowLoading(true);
                    if (swapPageIsFirst) {
                        getSwapEvent(noShowLoading)
                    } else {
                        setFistSwapPage()
                    }
                },
                getTask = () => requestApi('point/task', {address}),
                getInitialPrice = () => currentCoinLongSymbol
                    ? new Promise(reserves => reserves(checkData0(currentCoinInitialPrice)))
                    : masterContractCall('presaleNative').then(presaleNative =>
                        masterContractCall('presaleSupply').then(presaleSupply =>
                            npDivide(Number(presaleNative), Number(presaleSupply))
                        )),
                // contractCreateTimestamp = () => masterContractETH.getBlock(createBlockNumber).then(v => v.timestamp),
                setChart = () => blockTimestamp().then(
                    async timestamp => {
                        setData({chartData: [], minEthPrice: 0, chartDataIndex: undefined})
                        const timeSlot = timeSlots[timeSlotIndex],
                            duration = timeSlot[1],
                            durationIsNotNull = isNotNull(duration)
                        let cCT = currentCoinInitialDate
                        cCT = isNotNull(cCT)
                            ? cCT
                            : npMinus(timestamp, 31536000000)
                        requestApi(
                            `${currentCoinLongSymbol ? 'short' : 'point'}/swap/log/kline`, {
                                endTime: timestamp,
                                count: timeSlot[2],
                                token: masterContractAddress.toLocaleLowerCase(),
                                startTime: durationIsNotNull
                                    ? (() => {
                                        const d = npTimes(86400000, duration)
                                        return d < npMinus(timestamp, cCT)
                                            ? Math.floor(npMinus(timestamp, d))
                                            : cCT
                                    })()
                                    : cCT,
                            }
                        ).then(async v => setData({
                            chartData: await (async () => {
                                let mEP = 0
                                return [
                                    ...ternaryArray(
                                        !durationIsNotNull,
                                        [{
                                            price: await getInitialPrice(),
                                            date: cCT
                                        }]
                                    ),
                                    ...checkDataArray(v).map(v => ({
                                        price: v.ethPrice,
                                        date: v.logTime
                                    })),
                                    {
                                        price: formatEther(await getAmountOutFunc(1, false, true)),
                                        date: timestamp
                                    }
                                ].map(v => {
                                    const {price} = v
                                    if (price > 0 && mEP === 0) {
                                        mEP = price
                                        setData({minEthPrice: price})
                                    }
                                    return {
                                        price,//: getFullNum(price).toString(),
                                        date: Math.floor(v.date / 1000)
                                    }
                                })
                            })()
                        }))
                    }
                ),
                maxSwapRatio = 1,
                // maxSwapRatio = 0.05,
                // getReserves = () => masterContractCall('getReserves').then(async reserves =>
                //     setData({reserves: reserves.map(v => numberETH(v))})),
                // const maxAmountFunc = index => npTimes(numberETH(checkData([reserves, index])), maxSwapRatio),
                //     // const maxAmountFunc = index => numberETH(npTimes(Number(checkData([reserves, index])), maxSwapRatio)),
                //     // totalSwapAmountFunc = (index, iSR) => getAmountOut(numberETH(checkData([reserves, index])), iSR),
                //     // totalSwapETHAmount = await totalSwapAmountFunc(1, false),
                //     // parseEtherWeiTotalSwapETHAmount = parseEtherWei(totalSwapETHAmount),
                //     maxETHAmount = maxAmountFunc(0)
                // getEstimateGas(undefined,true,'buy', parseEtherWei(maxETHAmount)).then(([pongFee, estimateGas]) =>
                //     setData({
                //         // maxETHAmount,
                //         // maxTokenAmount: maxAmountFunc(1),
                //         // maxETHAmountEstimateGas: numberToFixed(npPlus(maxETHAmount, numberFormatEther(estimateGas))),
                //         // totalSwapETHAmountNoEstimateGas: numberETH(parseEtherWeiTotalSwapETHAmount),
                //         // totalSwapTokenAmount: totalSwapAmountFunc(0, true),
                //         // totalSwapETHAmountNoEstimateGas: numberETH(parseEtherWeiTotalSwapETHAmount),
                //         // totalSwapETHAmount: numberETH(npPlus(Number(parseEtherWeiTotalSwapETHAmount), estimateGas)),
                //     })
                // )
                // }),
                getReserves = () => masterContractCall('getReserves'),
                getDepositTotal = () => rC('depositTotal', address),
                setOmniChain = () => (isNewMemeNoUTKOrPUPPET
                    ? getReserves().then(reserves => reserves[0])
                    : masterContractCall('presaleAccumulate')).then(omniChain => (isNewMeme
                    ? masterContractCall('launchHardCap')
                    : new Promise(resolve => resolve())).then(launchHardCap =>
                    setData({omniChain, launchHardCap}))),
                getRoundMemeList = () => {
                    toggleShowLoading(true)
                    let arr = []
                    const step = 100,
                        getFunc = (page = 0) => masterContractCall(
                            'getRoundMemeList',
                            npPlus(npTimes(page, step), 1), npTimes(npPlus(page, 1), step)
                        ).then(
                            currentRoundMemeList => {
                                // console.log(currentRoundMemeList, '+++++++++++++++++++++++++++++');
                                arr = [...arr, ...currentRoundMemeList]
                                if (currentRoundMemeList.length < step) {
                                    arr = arr.map(v => memeData(v)).filter(v => !checkDataString(v, 'symbol').includes('LIKTEST')).sort((a, b) => Number(b.votes) - Number(a.votes))
                                    setData({
                                        showLoading: false,
                                        hotMemes: arr.slice(0, 6),
                                        currentRoundMemeList: arr.map(
                                            (v, i) => ({...v, ranking: i})
                                        ).sort(() => Math.random() - 0.5)
                                    })
                                } else {
                                    getFunc(page + 1)
                                }
                            }
                        )
                    getFunc()
                },
                urlState = checkDataString(new URLSearchParams(location.search).get('state')).split('_'),
                voteSymbol = checkData([pNs1], checkDataString(urlState, 1)).toLocaleUpperCase(),
                isVoteDetail = isVotePage && isNotNullOrEmpty(voteSymbol),
                getRoundMeme = symbol => {
                    const symbolIsTure = rM => rM.symbol.toLocaleUpperCase() === symbol
                    return masterContractCall('roundMemesIndex', symbol).then(
                        roundMemeIndex => masterContractCall('roundMemes', roundMemeIndex).then(
                            roundMeme => {
                                if (symbolIsTure(roundMeme)) {
                                    setData({voteMemeNotInRound: false})
                                    return memeData(roundMeme)
                                } else {
                                    return masterContractCall('memesIndex', symbol).then(
                                        roundMemeIndex => masterContractCall('memes', roundMemeIndex).then(
                                            roundMeme => {
                                                if (symbolIsTure(roundMeme)) {
                                                    setData({voteMemeNotInRound: true})
                                                    return memeData(roundMeme)
                                                } else {
                                                    return undefined
                                                }
                                            }
                                        )
                                    )
                                }
                            }
                        )
                    )
                },
                getVoteMeme = () => getRoundMeme(voteSymbol).then(roundMeme => setData({voteMeme: roundMeme})),
                getAllVotePoints = () => masterContractCall('allVotePoints', voteSymbol).then(allVotePoints =>
                    setData({allVotePoints: Number(allVotePoints)})),
                getInvites = address => masterContractCall('invitesLength', voteSymbol, address).then(invitesLength =>
                    invitesLength <= 0
                        ? []
                        : masterContractCall('getInvites', voteSymbol, address, 1, invitesLength)),
                getVotePoints = () => masterContractCall('votePoints', voteSymbol, address).then(votePoints => setData({votePoints: Number(votePoints)})),
                getVotePointsChildrenVoted = () => {
                    getInvites(address).then(inviteRecords => setData({inviteRecords}))
                    getVotePoints()
                    masterContractCall('voted', address, voteSymbol).then(isVoted => setData({isVoted}))
                },
                isVoteSearch = checkDataBool(data, 'isVoteSearch'),
                votedMemeSymbols = checkDataArray(data, 'votedMemeSymbols'),
                isVotedMeme = symbol => votedMemeSymbols.includes(symbol),
                searchItemList = checkDataArray(data, 'searchItemList'),
                currentRoundMemeList = checkDataArray(data, 'currentRoundMemeList'),
                lastListingMeme = checkDataObject(coins, 0),
                searchCurrentRoundMeme = closeSearch => {
                    if (!closeSearch && searchSymbolIsNotNull) {
                        setData({isVoteSearch: true})
                        toggleShowLoading(true)
                        const arr = []
                        Promise.all(currentRoundMemeList.map((v, i) => v.symbol.toLocaleUpperCase().includes(searchSymbol.toLocaleUpperCase()) && arr.push({
                            ...v,
                            ranking: checkData([v, 'ranking'], i)
                        }))).then(
                            () => {
                                setData({searchItemList: arr})
                                toggleShowLoading()
                            }
                        )
                    } else {
                        setData({isVoteSearch: false})
                    }
                },
                canAccessWalletConnectUrl = (
                    func = () => {
                    },
                    falseFunc = () => {
                    }
                ) => {
                    func()
                    // const walletConnectUrl = 'https://walletconnect.com'
                    // axios(
                    //     {
                    //         timeout: 5000,
                    //         url: walletConnectUrl,
                    //     },
                    // ).then(() => func())
                    //     .catch(() => {
                    //         falseFunc()
                    //         toast(`Make sure your network can access to ${walletConnectUrl},Refresh and try again`)
                    //     })
                },
                openWeb3Modal = (...params) => {
                    if (addressIsNotNull) {
                        open(...params)
                    } else {
                        const toggleShowConnectLoading = sCL => setData({showConnectLoading: sCL})
                        toggleShowConnectLoading(true)
                        canAccessWalletConnectUrl(() => {
                            toggleShowConnectLoading()
                            open(...params)
                        }, () => toggleShowConnectLoading())
                    }
                },
                selectNetworkObj = {view: 'Networks'},
                getOkxAddress = v => checkDataString(v, 'namespaces', 'eip155', 'accounts', 0).split(':')[2],
                connect = () => isTelegramMiniApp
                    ? (
                        address
                            ? okxUniversalProvider.disconnect().then(() => setData({okxAddress: undefined}))
                            : okxUniversalProvider.connect({
                                namespaces: {
                                    eip155: {
                                        chains: prdChains.map(v => `eip155:${v.id}`),
                                        rpcMap: {
                                            1: 'https://cloudflare-eth.com' // set your own rpc url
                                        },
                                        defaultChain: defaultChainId.toString()
                                    }
                                },
                                optionalNamespaces: {
                                    eip155: {
                                        chains: [`eip155:${defaultChainId}`]
                                    }
                                },
                                sessionConfig: {
                                    // redirect: 'metamask://'
                                    redirect: 'tg://resolve'
                                }
                            }).then(v => setData({okxAddress: getOkxAddress(v)})).catch(e => toast(JSON.stringify(e)))
                    )
                    : (isOpen ? close() : openWeb3Modal(address ? undefined : selectNetworkObj)),
                selectNetwork = () => isOpen ? close() : openWeb3Modal(selectNetworkObj),
                switchEthereumChain = (
                    func = () => {
                    },
                    falseFunc = () => {
                    },
                    needConnect,
                    chain = {}
                ) => {
                    const pleaseConnect = () => {
                        const {name} = chain
                        toast(isNotNullOrEmpty(name) ? `Please connect ${name}` : 'Please connect to the network')
                        !isTelegramMiniApp && setTimeout(() => openWeb3Modal(selectNetworkObj), 1000)
                    }, {id: chainId1} = chain, chainId1IsNotNull = isNotNull(chainId1)
                    if (addressIsNotNull) {
                        if (
                            (!chainId1IsNotNull || (chainId1IsNotNull && chainId === chainId1)) &&
                            !wrongNetwork
                        ) {
                            func()
                        } else {
                            falseFunc()
                            pleaseConnect()
                        }
                    } else {
                        falseFunc()
                        if (needConnect) {
                            pleaseConnect()
                        }
                    }
                },
                getPoints = (v, key, point = true) => parseInt(checkData0(v, key, ...ternaryArray(point, ['point']))),
                authorizeData = checkData([data, 'authorizeData'], twitterAuthorizeData),
                {authorizeKey} = authorizeData,
                bindCheck = (aK = authorizeKey) => requestApi(`${aK}/bind/check`),
                toggleShowAuthorize = (showAuthorize, isCloudflare, aD = authorizeData) => showAuthorize
                    ? switchEthereumChain(
                        () => {
                            toggleShowLoading(true)
                            const aK = aD.authorizeKey
                            loginThenFunc(() => bindCheck(aK).then(isBinded => setData({
                                ...ternaryObject(
                                    isTwitterAuthorizeAuthorize(aK),
                                    {isTwitterBinded: isBinded}
                                ),
                                isCloudflare,
                                showAuthorize,
                                authorizeData: aD,
                                showLoading: false,
                            })), toggleShowLoading)
                        },
                        undefined,
                        true
                    )
                    : setData({showAuthorize}),
                newAmount = checkDataString(data, 'newAmount'),
                newAmountNumber = Number(newAmount),
                newAmountNumberGT0 = newAmountNumber > 0,
                parseEtherWeiNewAmount = parseEtherWei(newAmount),
                amountReplace = (nSP = 0) => nSP.toString().replace(/[^\d.]/g, '').replace(/\.{2,}/g, ".")
                    .replace(/^\./g, "").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
                    .replace(/^(\-)*(\d+)\.(\d{9}).*$/, '$1$2.$3'),
                setNewAmount = (nSP = 0, key = 'newAmount') => {
                    const num = amountReplace(nSP)
                    setData({
                        [key]: (num > 0 && num < minBigInt) ? 0 : num
                    })
                },
                setNewAmountEmpty = () => setData({newAmount: ''}),
                setMaxETH = (funcName, handelType, paramsFunc = () => [], noGas, otherPingParams) =>
                    Number(ethBalance) > 0
                        ? (ethBalance < maxETHBalance
                            ? getEstimateGas(
                                otherPingParams, true,
                                checkData([handelType], funcName), parseEtherWeiEthBalance).then(([pongFee, estimateGas] = []) =>
                                (noGas ? new Promise(resolve => resolve(0)) : slaveContractETH.estimateGas({
                                    from: address,
                                    to: slaveContractAddress,
                                    value: parseEtherWeiEthBalance,
                                    data: (new slaveContractETH.Contract(slaveContractAbi, slaveContractAddress)).methods[funcName](pongFee, ...paramsFunc(estimateGas)).encodeABI(),
                                })).then(gas =>
                                    slaveContractETH.getGasPrice().then(gasPrice => {
                                        const value = npMinus(Number(sendETHBalance), npPlus(
                                            numberFormatEther(estimateGas),
                                            npTimes(numberFormatEther(npTimes(Number(gas), Number(gasPrice))), gasMultiple)))
                                        setNewAmount(value > 0 ? value : 0)
                                    })
                                ).catch(e => toast(checkData([e, 'innerError', 'message'], e.message))))
                            : setNewAmount(maxETHBalance))
                        : setNewAmount(0),
                // setMaxETH=(estimateGasFuncName,estimateGasParams=[])=>getEstimateGas(undefined,true,estimateGasFuncName, address, ).then((estimateGas = []) => {
                //     const value = parseEtherWei(newAmount)
                //     slaveContractETH.estimateGas({
                //         from: address,
                //         to: slaveContractAddress,
                //         value: Number(value),
                //         data: (new slaveContractETH.Contract(slaveContractAbi, slaveContractAddress)).methods.deposit(value).encodeABI(),
                //     }).then(gas =>
                //         slaveContractETH.getGasPrice().then(gasPrice => {
                //             const value = npMinus(Number(ethBalance), npPlus(numberFormatEther(estimateGas), npTimes(numberFormatEther(npTimes(gas, gasPrice)), 1.5)))
                //             setNewAmount(value > 0 ? value : 0)
                //         })
                //     ).catch(e => toast(checkData([e, 'innerError', 'message'], e.message)))
                // }),
                sMC = currentCoinShortSymbol ? shortMasterCall : masterContractCall,
                sMCCondition = currentCoinShortSymbol || currentCoinLongSymbol,
                shortMasterLaunchedLiquidated = () => sMC('launched').then(
                    shortMasterLaunched => sMC('liquidated').then(
                        liquidated => [shortMasterLaunched, liquidated])).catch(() => []),
                swapStatusEndReload = () => {
                    getReserves().then(async reserves => setData({reserves: [0, 1].map(v => numberETH(reserves[v]))}))
                    if (sMCCondition) {
                        sMC('initLongPrice').then(
                            initLongPrice => (currentCoinLongSymbol ? longMasterCall : masterContractCall)('getCurrentPrice').then(
                                longPrice => shortMasterLaunchedLiquidated().then(
                                    ([shortMasterLaunched, liquidated]) => {
                                        longPrice = numberETH(longPrice)
                                        setData({
                                            longPrice,
                                            liquidated,
                                            shortMasterLaunched,
                                            initLongPrice: initLongPrice === 0n ? longPrice : numberETH(initLongPrice),
                                        })
                                    })));
                    }
                },
                setContractStatuData = (tx, contractAddress, functionName, hasPong, txObj = {}, remove) => {
                    let cSD = {...contractStatuData()}
                    const cSDC = checkDataObject(cSD, chainId)
                    let cSDCA = checkDataObject(cSDC, address)
                    const cSDCATX = checkDataObject(cSDCA, tx)
                    if (remove) {
                        delete cSDCA[tx]
                        if (isSwap && masterContractAddress && slaveContractAddress && address && buySellFunctionNames.includes(checkData([cSDCATX, 'functionName']))) {
                            setChart()
                            swapStatusEndReload()
                            reloadForm(true)
                            getBalance().then(ethBalance => getTokenBalance().then(tokenBalance => setData({
                                ethBalance,
                                tokenBalance
                            })))
                        }
                    }
                    cSD[chainId] = {
                        ...cSDC,
                        [address]: {
                            ...cSDCA,
                            ...ternaryObject(
                                !remove,
                                {
                                    [tx]: {
                                        ...cSDCATX,
                                        ...ternaryObject(
                                            !isNotNull(cSDCATX.lastTime),
                                            {lastTime: npPlus(Date.now(), 10800000)}
                                        ),
                                        ...ternaryObject(
                                            isNotNullOrEmpty(contractAddress),
                                            {contractAddress}
                                        ),
                                        ...ternaryObject(
                                            isNotNullOrEmpty(functionName),
                                            {functionName}
                                        ),
                                        ...ternaryObject(
                                            !hasPong,
                                            {
                                                ping: {
                                                    status: 2,
                                                    opStatus: 99,
                                                    targetId: tx,
                                                }
                                            }
                                        ),
                                        ...txObj
                                    }
                                }
                            )
                        }
                    }
                    cSD = JSON.stringify(cSD)
                    localStorage.setItem('contractStatuData', cSD)
                    dispatch(props, {contractStatuData: cSD})
                },
                wC = (contractAddress, contractAbi, functionName, value, args = [], successFunc = () => {
                }, failFunc = () => {
                }) => {
                    dispatch(props, {contractStatuData: localStorage.getItem('contractStatuData') || '{}'})
                    const toggleShowLoadingToast = text => {
                            toggleShowLoading()
                            text && toast(text)
                        },
                        wCFunc = () => {
                            toggleShowLoading(true)
                            prepareWriteContract({
                                args,
                                value,
                                functionName,
                                abi: contractAbi,
                                address: contractAddress,
                                walletClient: {account: address},
                            }).then(r => writeContract(r.request).then(r => {
                                const hash = checkData([r, 'hash'])
                                if (hash && [...pingFeeFunctionName, ...pongFeeFunctionName].includes(functionName)) {
                                    setContractStatuData(hash, contractAddress, functionName, pongFeeFunctionName.includes(functionName))
                                }
                                return successFunc ? successFunc(hash) : toggleShowLoadingToast(hash ? 'Successful' : 'Failed')
                            })
                                .catch(e => toggleShowLoadingToast(e.message.split('\n')[0])))
                                .catch(e => {
                                    const errorStr = [1, 2].map(v => e.message.split('\n')[v]).join('\n'),
                                        obj = {}
                                    let eStr
                                    Object.keys(obj).map(v => {
                                        if (errorStr.includes(v)) eStr = obj[v]
                                    })
                                    toggleShowLoadingToast(eStr || errorStr)
                                    checkDataFunction(failFunc)()
                                })
                        }
                    switchEthereumChain(
                        () => {
                            if (Object.values(checkDataObject(contractStatuData(), chainId, address)).map(v => v.functionName).includes(functionName)) {
                                setData({showPreviousTips: true, previousTipsFunc: wCFunc})
                            } else {
                                wCFunc()
                            }
                        },
                        toggleShowLoadingToast,
                        true
                    )
                },
                slaveWriteContract = (...params) => wC(slaveContractAddress, slaveContractAbi, ...params),
                createMemeMasterWriteContract = (...params) => wC(createMemeContractMasterAddress, createMemeContractMasterAbi, ...params),
                createMemeSlaveWriteContract = (...params) => wC(createMemeContractSlaveAddress, createMemeContractSlaveAbi, ...params),
                contestContractWriteContract = (...params) => wC(contestContractAddress, contestContractAbi, ...params),
                pageFootPhone = <div className={'paddingPhone childrenSpacingTopPhone backgroundBlack'}>
                    <div className={'flexCenter justifyContentSpaceBetween'}>
                        <img src={requireDefault('logo2')} alt="" style={{height: '1.5rem'}}/>
                        <div className={'flex childrenSpacingLeftPhone'}>
                            {
                                socialLinks.map(v =>
                                    <img src={requireDefault(`${v[0]}2`)} alt="" className={'cursorPointer'}
                                         onClick={() => windowOpen(v[1])}
                                         style={{width: '1.5rem', height: '1.5rem'}}/>)
                            }
                        </div>
                    </div>
                    <div onClick={devCountAdd}
                         className={'textAlignCenter'}
                         style={{fontSize: '.5rem', color: 'rgba(255,255,255,.5)'}}
                    >
                        © All rights reserved, 2024
                    </div>
                </div>,
                totalPoints = checkData0(data, 'totalPoints'),
                showTaskContent = checkDataBool(data, 'showTaskContent'),
                toggleShowTaskContent = (showTaskContent, taskContentText) => setData({showTaskContent, ...ternaryObject(showTaskContent, {taskContentText})}),
                taskContent = showTaskContent &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <div
                            className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                            style={{
                                width: isPhoneClientWidth ? '84%' : '10rem',
                                maxHeight: '100vh',
                                ...ternaryObject(
                                    !isPhoneClientWidth,
                                    {borderRadius: '.5rem'}
                                ),
                                padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                            }}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'}
                                     style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    INFORMATION
                                </div>
                                <img onClick={() => toggleShowTaskContent()}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <pre style={{lineHeight: 2}}
                                 className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}>{checkDataString(data, 'taskContentText')}</pre>
                        </div>
                    </div>,
                redirectURI = `https://likwid.meme/${pNs0}`,
                isTwitterBinded = checkDataBool(data, 'isTwitterBinded'),
                isTwitterAuthorized = checkData0(data, 'twitterAuthorizePoints') > 0,
                isTGAuthorized = checkData0(data, 'tGAuthorizePoints') > 0,
                twitterCode = checkData([new URLSearchParams(location.search).get('code')]),
                showAuthorize = checkDataBool(data, 'showAuthorize'),
                cloudflareHumanDiv = <div
                    className={'childrenDisplayBlock flexCenter'}
                    style={{
                        transform: `scale(${document.body.clientWidth / (isPhoneClientWidth ? 600 : 1500)})`,
                    }}
                    id={'cloudflareHuman'}/>,
                {authorizePoints} = authorizeData,
                verify = () => requestApi(
                    `${authorizeKey}/verify`,
                    undefined,
                    'post'
                ).then(r => {
                    toggleShowLoading()
                    if (isNotNull(r)) {
                        toastSuccessful(true)
                        getTask().then(v => setData({
                            tGAuthorizePoints: getPoints(v, 5),
                            twitterAuthorizePoints: getPoints(v, 4),
                            totalPoints: getPoints(v, -1, false),
                        }))
                        toggleShowAuthorize()
                    } else if (authorizeKey === tGAuthorizeAuthorizeKey) {
                        toast('Send my code on LIKWID Community')
                        setData({tGShareDone: false})
                    }
                }),
                authorizeContent = showAuthorize &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <div
                            className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                            style={{
                                width: isPhoneClientWidth ? '84%' : '8.5rem',
                                maxHeight: '100vh',
                                ...ternaryObject(
                                    !isPhoneClientWidth,
                                    {borderRadius: '.5rem'}
                                ),
                                padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                            }}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'}
                                     style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    {authorizeData.title}
                                </div>
                                <img onClick={() => toggleShowAuthorize()}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <div
                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBigger'}>
                                <div
                                    className={`${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} flexCenter`}>
                                    <img src={requireDefault('integral')} alt=""
                                         style={{width: `${isPhoneClientWidth ? 2.7 : .9}rem`}}/>
                                    <div
                                        className={`saf1 ${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'}`}>
                                        {authorizePoints}
                                    </div>
                                </div>
                                {
                                    (isTwitterBinded || !authorizeData.needBind)
                                        ? <div
                                            className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTop'}>
                                            {
                                                {
                                                    x: () => {
                                                        const twitterFollowDone = checkDataBool(data, 'twitterFollowDone'),
                                                            twitterShareDone = checkDataBool(data, 'twitterShareDone')
                                                        return [
                                                            ['Follow @LIKWID_MEME on X', 'Follow',
                                                                () => {
                                                                    setData({twitterFollowDone: true})
                                                                    windowOpen('https://x.com/intent/user?screen_name=LIKWID_MEME')
                                                                }, true, twitterFollowDone],
                                                            ['Share likwid platform on X', 'Share',
                                                                () => {
                                                                    setData({twitterShareDone: true})
                                                                    windowOpen(`https://x.com/share?text=${encodeURIComponent(`🎉✨"Excited to join @LIKWID_MEME with 9 L2s #Arbitrum #Optimism #Base #Linea #Scroll #Blast #Bob #Polygon #Vizing - the ultimate boutique meme creator and trading platform! Pump up the fun and start earning rewards today! 🚀💰 

likwid.meme

#LIKWID #Droplets #meme`)}`)
                                                                }, twitterFollowDone, twitterShareDone],
                                                            [`Get ${twitterAuthorizePoints} droplets`, 'Verify', () => {
                                                                toggleShowLoading(true)
                                                                verify()
                                                            }, twitterFollowDone && twitterShareDone],
                                                        ]
                                                    },
                                                    tg: () => {
                                                        const tGFollowDone = checkDataBool(data, 'tGFollowDone'),
                                                            tGShareDone = checkDataBool(data, 'tGShareDone')
                                                        return [
                                                            ['Join the LIKWID Community', 'Join',
                                                                () => {
                                                                    setData({tGFollowDone: true})
                                                                    windowOpen(likwidMemeTG)
                                                                }, true, tGFollowDone],
                                                            ['Send my code on LIKWID Community', 'Send',
                                                                () => {
                                                                    setData({tGShareDone: true})
                                                                    requestApi('tg/code', {address}).then(code =>
                                                                        (isPhoneClientWidth ? windowReplace : windowOpen)(`https://telegram.me/share/url?url=%2fcode%20likwid_${code}`))
                                                                }, tGFollowDone, tGShareDone],
                                                            [`Get ${tGAuthorizePoints} droplets`, 'Verify', () => {
                                                                toggleShowLoading(true)
                                                                verify()
                                                            }, tGFollowDone && tGShareDone],
                                                        ]
                                                    }
                                                }[authorizeKey]().map(v => {
                                                    const isTrue = v[3], isDone = v[4]
                                                    return <div className={'flexCenter justifyContentSpaceBetween'}>
                                                        <div
                                                            className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>{v[0]}</div>
                                                        <div style={{
                                                            padding: `${isPhoneClientWidth ? .2 : .1}rem 0`,
                                                            width: `${isPhoneClientWidth ? 4 : 1.3}rem`,
                                                            ...ternaryObject(
                                                                isDone,
                                                                {background: '#FF7789'}
                                                            )
                                                        }}
                                                             onClick={() => isTrue && !isDone && v[2]()}
                                                             className={`${isTrue ? 'backgroundBlack' : 'backgroundWhite'} flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'} borderRadius999 cursorPointer`}>
                                                            <div
                                                                className={`${isTrue ? 'colorWhite' : 'colorGray'} ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}`}>{isDone ? 'Done' : v[1]}</div>
                                                            {isDone &&
                                                                <img src={requireDefault('Ellipse 45')} alt=""
                                                                     style={{width: `${isPhoneClientWidth ? .7 : .25}rem`}}
                                                                />}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        : [
                                            <div
                                                className={`textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>
                                                Complete {authorizeKey.toLocaleUpperCase()} authorization before starting the
                                                task
                                            </div>,
                                            checkDataBool(data, 'isCloudflare')
                                                ? cloudflareHumanDiv
                                                : <div
                                                    onClick={() => {
                                                        const code_challenge = require('uuid').v4().slice(0, 10)
                                                        windowReplace(
                                                            'https://twitter.com/i/oauth2/authorize?response_type=code' +
                                                            '&client_id=MUpkUS14Y3VFaWNGV1hFQnpoNUk6MTpjaQ' +
                                                            `&redirect_uri=${redirectURI}` +
                                                            '&scope=offline.access%20tweet.read%20users.read' +
                                                            `&state=${[
                                                                code_challenge,
                                                                ...ternaryArray(isVotePage, [voteSymbol])
                                                            ].join('_')}` +
                                                            `&code_challenge=${code_challenge}` +
                                                            '&code_challenge_method=plain'
                                                        )
                                                    }}
                                                    className={`borderRadius999 saf backgroundBlack activeColor flexCenter cursorPointer width100 ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBig'}`}
                                                    style={{
                                                        padding: `${isPhoneClientWidth ? .75 : .25}rem 0`,
                                                        marginTop: `${isPhoneClientWidth ? 1.1 : .28}rem`
                                                    }}>
                                                    Authorize
                                                </div>
                                        ]
                                }
                            </div>
                        </div>
                    </div>,
                pageTopPhone = <div className={'positionFixed zIndex999'} style={{left: 0, right: 0}}>
                    <div
                        className={'width100 boxSizingBorderBox flex justifyContentSpaceBetween alignItemsCenter paddingLeftRightBigPhone backgroundActiveColor'}
                        style={{height: '3rem'}}>
                        <img onClick={replaceHome} src={requireDefault('logo')}
                             style={{height: '1.5rem'}} alt=""/>
                        <div className={'flex childrenSpacingLeftBig alignItemsCenter flexGrow1'}>
                            <div className={'flexGrow1 flex justifyContentFlexEnd paddingLeftPhone boxSizingBorderBox'}>
                                {address &&
                                    <div className={'flexCenter childrenSpacingLeft cursorPointer'}
                                         style={{maxWidth: '100%'}}
                                         onClick={() => toggleShowTaskContent(
                                             true,
                                             totalPointsText)}
                                    >
                                        <img src={requireDefault('integral')} alt="" style={{width: '1rem'}}/>
                                        <div className={'saf textOverflowEllipsis'}
                                             style={{fontSize: '.8rem'}}>
                                            {totalPoints}
                                        </div>
                                    </div>}
                            </div>
                            <div className={'flex childrenSpacingLeftBig alignItemsCenter'}>
                                {address && !wrongNetwork &&
                                    <div onClick={selectNetwork}
                                         className={'borderRadius999 saf backgroundWhite flexCenter childrenSpacingLeft cursorPointer'}
                                         style={{
                                             height: '1.5rem',
                                             padding: '0 .5rem',
                                             border: '.08rem solid black',
                                         }}>
                                        <img src={requireDefault(`chainIcons/${chainId}`)}
                                             alt=""
                                             style={{width: '.9rem'}}/>
                                        <div style={{fontSize: '.5rem'}}
                                             className={'saf1'}>{chainName}</div>
                                    </div>}
                                {
                                    address
                                        ? <div onClick={connect} className={'positionRelative cursorPointer'}>
                                            {
                                                wrongNetwork
                                                    ? <div
                                                        className={'borderRadius999 saf flexCenter cursorPointer smallerFontSizePhone'}
                                                        style={{
                                                            color: '#E832CC',
                                                            height: '1.5rem',
                                                            padding: '0 .5rem',
                                                            border: '.08rem solid #E832CC',
                                                        }}>
                                                        Wrong network
                                                    </div>
                                                    : <div
                                                        className={'borderRadius999 saf flexCenter cursorPointer smallerFontSizePhone'}
                                                        style={{
                                                            border: '.08rem solid',
                                                            height: '1.5rem',
                                                            padding: '0 .5rem',
                                                        }}>
                                                        {addressLeaveOut}
                                                    </div>
                                            }
                                        </div>
                                        : <div onClick={connect}
                                               className={'borderRadius999 saf flexCenter cursorPointer smallerFontSizePhone'}
                                               style={{
                                                   border: '.08rem solid',
                                                   height: '1.5rem',
                                                   padding: '0 .5rem',
                                               }}>
                                            CONNECT WALLET
                                        </div>
                                }
                            </div>
                            <div className={'flexColumn justifyContentFlexEnd'}>
                                <img onClick={toggleShowList} src={requireDefault(showList ? 'list2' : 'list')}
                                     style={{height: heightPhone}}
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                    <div style={{top: '2.2rem', left: 0, right: 0, height: 0}}
                         className={'positionAbsolute width100 flexColumn alignItemsCenter boxSizingBorderBox paddingPhone zIndex-1'}>
                        {contractStatuList}
                    </div>
                    {
                        showList && <div onClick={toggleShowList}
                                         style={{height: '100vh', background: 'rgba(0, 0, 0, 0.5)'}}
                        >
                            <div className={'backgroundActiveColor overflowYScroll'}
                                 style={{
                                     maxHeight: '85vh',
                                     borderBottomRightRadius: '1rem',
                                     borderBottomLeftRadius: '1rem'
                                 }}
                            >
                                {
                                    navigations.map(v => {
                                        const path = v[0],
                                            isCurrent = (path === 'home' ? '' : path) === showPath
                                        return <div
                                            className={`flexColumnCenter childrenSpacingTopPhone ${isCurrent ? '' : 'colorWhite'}`}
                                            style={{padding: '1rem'}}
                                            onClick={() => {
                                                v[1]()
                                                toggleShowList()
                                            }}
                                        >
                                            <div className={'saf'}>{path}</div>
                                            <div className={'borderRadius999 backgroundBlack'}
                                                 style={{
                                                     width: '25%',
                                                     height: '.4rem',
                                                     ...ternaryObject(!isCurrent, {opacity: 0})
                                                 }}/>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>,
                pageTopFootPhone = (content, showEnd = true) => <div>
                    {pageTopPhone}
                    {content}
                    {showEnd && pageFootPhone}
                    {connectLoading(10)}
                </div>,
                pageTop = [
                    <div
                        className={`ttf positionFixed flex alignItemsCenter zIndex999`}
                        style={{
                            left: 0,
                            right: 0,
                            height: '1.2rem',
                            paddingLeft: '2.5rem'
                        }}>
                        {(() => {
                            return [
                                <img onClick={replaceHome} src={requireDefault('logo')}
                                     style={{height}} alt="" className={'cursorPointer'}/>,
                                <div className={'flex alignItemsCenter flexGrow1'}>
                                    <div style={{height}}
                                         className={'flex justifyContentFlexEnd alignItemsCenter flexGrow1 positionRelative'}
                                    >
                                        {
                                            navigations.map((v, i) => {
                                                const path = v[0],
                                                    isCurrent = (path === 'home' ? '' : path) === showPath
                                                return <div
                                                    style={{
                                                        top: 0,
                                                        bottom: 0,
                                                        left: `${v[2]}%`,
                                                        fontSize: '.45rem', marginLeft: i !== 0 ? '.95rem' : 0
                                                    }}
                                                    className={`cursorPointer flexCenter positionAbsolute saf flexColumn alignItemsCenter ${isCurrent ? '' : 'colorWhite'}`}
                                                    onClick={checkDataFunction(v, 1)}
                                                >
                                                    {path}
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div
                                        className={'flex childrenSpacingLeftBig alignItemsCenter justifyContentSpaceBetween'}
                                        style={{width: '10rem'}}
                                    >
                                        <div
                                            className={'flex flexGrow1 alignItemsCenter justifyContentFlexEnd textOverflowEllipsis paddingLeft'}>
                                            {address && <div className={'flexCenter childrenSpacingLeft cursorPointer'}
                                                             style={{maxWidth: '100%'}}
                                                             onClick={() => toggleShowTaskContent(
                                                                 true,
                                                                 totalPointsText)}
                                            >
                                                <img src={requireDefault('integral')} alt="" style={{width: '.5rem'}}/>
                                                <div className={'saf textOverflowEllipsis'}
                                                     style={{fontSize: '.4rem'}}>{totalPoints}</div>
                                            </div>}
                                        </div>
                                        <div className={'flexCenter childrenSpacingLeft paddingRight'}>
                                            {address && !wrongNetwork &&
                                                <div onClick={selectNetwork}
                                                     className={'borderRadius999 saf backgroundWhite flexCenter childrenSpacingLeft cursorPointer'}
                                                     style={{
                                                         padding: '0 .3rem',
                                                         height: '.7rem',
                                                         border: '.08rem solid black',
                                                     }}>
                                                    <img src={requireDefault(`chainIcons/${chainId}`)}
                                                         alt=""
                                                         style={{width: '.5rem'}}/>
                                                    <div
                                                        className={'saf1 smallFontSize'}>{chainName}</div>
                                                </div>}
                                            {
                                                address
                                                    ? <div onClick={connect} className={'positionRelative cursorPointer'}>
                                                        {
                                                            wrongNetwork
                                                                ?
                                                                <div className={'borderRadius999 saf flexCenter cursorPointer'}
                                                                     style={{
                                                                         color: '#E832CC',
                                                                         padding: '0 .3rem',
                                                                         height: '.7rem',
                                                                         border: '.08rem solid #E832CC',
                                                                     }}>
                                                                    Wrong network
                                                                </div>
                                                                :
                                                                <div className={'borderRadius999 saf flexCenter cursorPointer'}
                                                                     style={{
                                                                         border: '.08rem solid',
                                                                         padding: '0 .3rem',
                                                                         height: '.7rem',
                                                                     }}>
                                                                    {addressLeaveOut}
                                                                </div>
                                                        }
                                                    </div>
                                                    : <div onClick={connect}
                                                           className={'borderRadius999 saf flexCenter cursorPointer'}
                                                           style={{
                                                               border: '.08rem solid',
                                                               padding: '0 .3rem',
                                                               height: '.7rem',
                                                           }}>
                                                        CONNECT WALLET
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>,
                            ]
                        })()}
                        <img className={'positionAbsolute width100 zIndex-1'}
                             src={(() => {
                                 try {
                                     return requireDefault(`navigation${showPath}`)
                                 } catch {
                                     return requireDefault('navigation')
                                 }
                             })()}
                             style={{top: 0, bottom: 0, left: 0, right: 0}} alt=""/>
                        {isHome && <img className={'positionAbsolute zIndex-1'} src={requireDefault('waterNavigation')}
                                        style={{width: '3.7rem', left: 0, top: '1.235rem'}} alt=""/>}
                        <div style={{top: '1.5rem', left: 0, right: 0, height: 0}}
                             className={'positionAbsolute width100 flexColumn alignItemsFlexEnd boxSizingBorderBox paddingRight'}>
                            {contractStatuList}
                        </div>
                    </div>,
                    connectLoading(5)
                ],
                pageFoot = <div style={{height: '1rem', padding: '0 2rem'}}
                                className={'width100 backgroundBlack flexCenter justifyContentSpaceBetween boxSizingBorderBox'}
                >
                    <img src={requireDefault('logo2')} alt="" style={{height: '50%'}}/>
                    <div onClick={devCountAdd} className={'saf2 colorWhite'}>
                        © All rights reserved, 2024
                    </div>
                    <div className={'flex childrenSpacingLeft'}>
                        {
                            socialLinks.map(v => <img src={requireDefault(`${v[0]}2`)} alt=""
                                                      onClick={() => windowOpen(v[1])}
                                                      className={'cursorPointer'}
                                                      style={{width: '.5rem', height: '.5rem'}}/>)
                        }
                    </div>
                </div>,
                searchListedMeme = (closeSearch, rGLML = reGetListedMemeList) => {
                    if (!closeSearch && searchSymbolIsNotNull) {
                        setData({isLaunchSearch: true})
                        toggleShowLoading(true)
                        rGLML().catch(() => {
                            setData({coins: undefined})
                            toggleShowLoading()
                        })
                    } else {
                        isLaunchSearch && setDataSearchSymbolEmpty()
                    }
                },
                showSelectCoins = checkDataBool(data, 'showSelectCoins'),
                toggleShowSelectCoins = showSelectCoins => {
                    setData({showSelectCoins})
                    if (showSelectCoins) {
                        setDataSearchSymbolEmpty()
                        searchListedMeme(true)
                    }
                },
                selectFunc = (isToggleFunc, toggleFunc, showValue, currentIcon, currentName, list, isCurrentFunc, setIndexFunc, logoFunc, nameFunc, isChains, shortToken) =>
                    <div onClick={() => isToggleFunc && toggleFunc(!showValue)}
                         className={`${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeft'} flexCenter positionRelative ${isToggleFunc ? 'cursorPointer' : ''}`}>
                        <div className={`positionRelative ${isChains ? '' : 'borderRadius999'}`}
                             style={square(`${isChains ? (isPhoneClientWidth ? .9 : .3) : (isPhoneClientWidth ? 1.5 : .7)}rem`)}>
                            <img className={'width100 height100'} src={currentIcon}
                                 alt=""/>
                            {shortToken && currentCoinLongSymbol &&
                                <img className={'width100 height100 positionAbsolute'} style={{top: 0, left: 0}}
                                     src={requireDefault('shortToken')} alt=""/>}
                        </div>
                        <div className={isChains ? 'saf' : 'saf1'}
                             style={{fontSize: `${isPhoneClientWidth ? .9 : .3}rem`}}>{currentName}</div>
                        {
                            isToggleFunc && [
                                <img
                                    src={requireDefault((isChains && showValue) ? 'toTop' : 'toBottom')}
                                    alt=""
                                    style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>,
                                ...ternaryArray(
                                    isChains && showValue,
                                    [
                                        <div
                                            className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingPhone' : 'borderRadius padding'} backgroundActiveColor positionAbsolute zIndex1`}
                                            style={{
                                                left: 0,
                                                marginLeft: 0,
                                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                                top: `${isChains ? (isPhoneClientWidth ? 2.5 : .75) : (isPhoneClientWidth ? 2.45 : 1)}rem`,
                                                width: `${isChains ? (isPhoneClientWidth ? 10.5 : 3.5) : (isPhoneClientWidth ? 8 : 3)}rem`,
                                            }}
                                        >
                                            {
                                                list.map((v, i) => <div onClick={() => setIndexFunc(v, i)}
                                                                        className={`${isPhoneClientWidth ? 'paddingPhone childrenSpacingLeftSmallPhone' : 'padding childrenSpacingLeftSmall'} flex cursorPointer alignItemsCenter  borderRadius999 
                                                                                            ${isCurrentFunc(v, i) ? 'backgroundWhite' : ''}`}>
                                                    <img
                                                        style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}
                                                        src={logoFunc(v, i)}
                                                        alt=""/>
                                                    <div
                                                        className={'saf1'}
                                                        style={{fontSize: `${isPhoneClientWidth ? .55 : .2}rem`}}
                                                    >
                                                        {nameFunc(v, i)}
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    ]
                                )
                            ]
                        }
                    </div>,
                selectCoinsFunc = (toggleShowSelectCoins, coins, currentCoinLogo, currentCoinName, setCoinFunc = () => {
                }) => selectFunc(
                    true,
                    toggleShowSelectCoins,
                    showSelectCoins,
                    currentCoinLogo,
                    currentCoinName,
                    coins,
                    v => currentCoinName === v.name,
                    (v, i) => setCoinFunc(i),
                    v => v.logo,
                    v => v.name,
                    undefined,
                    true
                ),
                selectCoins = selectCoinsFunc(toggleShowSelectCoins, coins, currentCoinLogo, currentCoinName, setCoinIndex),
                coinsScroll = React.createRef(),
                coinsScrollFunc = (cC = coinsScroll, c = coins, coinsLengthKey = 'coinsLength', ...getListedMemeListParams) => {
                    const node = cC.current,
                        userWidth = isPhoneClientWidth && isLaunch
                    if (
                        c.length < checkData0(data, coinsLengthKey) &&
                        (node[userWidth ? 'scrollWidth' : 'scrollHeight'] -
                            node[userWidth ? 'scrollLeft' : 'scrollTop'] - 1 <=
                            node[userWidth ? 'clientWidth' : 'clientHeight']) && !showLoading
                    ) {
                        toggleShowLoading(true)
                        getListedMemeList(...getListedMemeListParams)
                    }
                },
                shortOverStatusList = checkDataObject(data, 'shortOverStatusList'),
                selectCoinsContentFunc = (coins, showSelectCoins, toggleShowSelectCoins, coinsScrollFunc = () => {
                }, setCoinFunc = () => {
                }, rGLML, isNotAll) => showSelectCoins &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <div
                            className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} flexColumn boxSizingBorderBox`}
                            style={{
                                height: `${isPhoneClientWidth ? 29 : 10}rem`,
                                width: isPhoneClientWidth ? '87%' : '10rem',
                                ...ternaryObject(
                                    !isPhoneClientWidth,
                                    {borderRadius: '.5rem'}
                                ),
                                padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                            }}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'}
                                     style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    Select a Token
                                </div>
                                <img onClick={() => toggleShowSelectCoins()}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <div className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTopBig'}>
                                <div style={{borderTop: `solid ${isPhoneClientWidth ? .09 : .03}rem white`}}/>
                                <div
                                    className={`borderRadius999 backgroundWhite saf flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}
                                    style={{
                                        height: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                        padding: `${isPhoneClientWidth ? .15 : .05}rem`
                                    }}>
                                    <div onClick={() => searchListedMeme(undefined, rGLML)}
                                         className={'height100 borderRadius999 backgroundActiveColor flexCenter cursorPointer'}
                                         style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                        <img style={{width: '50%'}}
                                             src={requireDefault('search')} alt=""/>
                                    </div>
                                    <input value={searchSymbol} type="text"
                                           onChange={setSearchSymbol}
                                           placeholder={'Search name or paste address'}
                                           className={`flexGrow1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}
                                           onKeyUp={e => searchSymbolOnKeyUp(e, () => searchListedMeme(undefined, rGLML))}
                                    />
                                    {searchSymbolIsNotNull && <img onClick={() => {
                                        setDataSearchSymbolEmpty()
                                        searchListedMeme(true, rGLML)
                                    }} style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}} className={'cursorPointer'}
                                                                   src={requireDefault('searchDisabled')}
                                                                   alt=""/>}
                                </div>
                            </div>
                            <div ref={coinsScroll}
                                 onScroll={coinsScrollFunc}
                                 className={`${isPhoneClientWidth ? 'childrenSpacingTopBig' : 'childrenSpacingTop'} flexGrow1Column overflowYScroll`}>
                                {coins.map((v, i) => (!isNotAll || !checkDataString(v, 'symbol').includes('LIKTEST') || currentCoinLongSymbol) &&
                                    <div style={{
                                        borderRadius: `${isPhoneClientWidth ? .9 : .3}rem`,
                                        border: `solid ${isPhoneClientWidth ? .09 : .03}rem white`,
                                        background: 'linear-gradient(270deg, #FFB9C2 0%, #FFF9F0 100%)'
                                    }}
                                         onClick={() => {
                                             setCoinFunc(i)
                                             toggleShowSelectCoins()
                                         }}
                                         className={`${isPhoneClientWidth ? 'paddingPhone paddingLeftRightBigPhone' : 'padding paddingLeftRightBig'} flexCenter justifyContentSpaceBetween childrenSpacingLeft cursorPointer`}>
                                        <div className={'childrenSpacingLeft flexCenter'}>
                                            <img className={'borderRadius999'} src={v.logo} alt=""
                                                 style={square(`${isPhoneClientWidth ? 2.1 : .7}rem`)}/>
                                            {isSwap && currentCoinLongSymbol && (() => {
                                                const shortOverStatus = [
                                                    ['Next', 1],
                                                    ['Available', .7],
                                                    ['Over', .5],
                                                ][checkData([shortOverStatusList, v.name], 2)]
                                                return Object.values(shortOverStatusList).length > 0 && <div
                                                    className={'borderRadius999 flexCenter saf1 backgroundBlack activeColor'}
                                                    style={{
                                                        background: `rgba(0, 0, 0, ${shortOverStatus[1]})`,
                                                        width: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                        height: `${isPhoneClientWidth ? .9 : .3}rem`,
                                                        fontSize: `${isPhoneClientWidth ? .45 : .15}rem`,
                                                        border: `solid ${isPhoneClientWidth ? .03 : .01}rem #FFB9C2`,
                                                    }}
                                                >{shortOverStatus[0]}</div>
                                            })()}
                                            <div
                                                className={`saf1 ${isPhoneClientWidth ? '' : 'bigFontSize'}`}>{v.name}</div>
                                        </div>
                                        <img src={requireDefault('toRight')} alt=""
                                             style={square(`${isPhoneClientWidth ? 1.5 : .5}rem`)}/>
                                    </div>)}
                            </div>
                        </div>
                    </div>,
                selectCoinsContent = selectCoinsContentFunc(coins, showSelectCoins, toggleShowSelectCoins, () => coinsScrollFunc(), setCoinIndex, undefined, true),
                percentageToFixed = (num1, num2) => {
                    const num = npTimes(npDivide(num1, num2), 100),
                        toFixedNum = 2,
                        denominator = 10 ** toFixedNum
                    return `${Number(npDivide(Math.floor(npTimes(Number(isNaN(num) ? 0 : num), denominator)), denominator).toFixed(toFixedNum))}%`
                },
                defaultCountdown = ['00', '00', '00', '00'],
                countdown = checkData([data, `${currentCoinName}countdown`], defaultCountdown),
                countdownEnds = checkDataBool(data, `${currentCoinName}countdownEnds`),
                countdownEndsTrue = checkData([data, `${currentCoinName}countdownEnds`], true),
                setCountdown = (timestamp, blockNow, setIntervalCountdownKey = 'setIntervalCountdown', countdownEndsKey = 'countdownEnds', countdownKey = 'countdown') => {
                    timestamp = Number(timestamp)
                    const blockNowIsNotNull = isNotNull(blockNow)
                    let blockSeconds = npMinus(timestamp, checkData0(blockNow))
                    const setIntervalCountdown = checkData([data, setIntervalCountdownKey]),
                        setIntervalCountdownIsNotNull = isNotNull(setIntervalCountdown),
                        interval = setInterval(async () => {
                            const seconds = blockNowIsNotNull ? blockSeconds : (timestamp - Math.floor(Date.now() / 1000)),
                                d = Math.floor(seconds / (3600 * 24)),
                                h = Math.floor((seconds % (3600 * 24)) / 3600),
                                m = Math.floor((seconds % 3600) / 60),
                                s = seconds % 60,
                                countdownEnds = d <= 0 && h <= 0 && m <= 0 && s <= 0
                            setData({
                                [`${currentCoinName}${countdownEndsKey}`]: countdownEnds,
                                [`${currentCoinName}${countdownKey}`]: [d, h, m, s].map(v => v < 0 ? '00' : (v < 10 ? `0${v}` : v))
                            })
                            blockNowIsNotNull && blockSeconds--
                            if (countdownEnds) {
                                clearInterval(interval)
                                setIntervalCountdownIsNotNull && clearInterval(setIntervalCountdown)
                            }
                        }, 1000)
                    if (setIntervalCountdownIsNotNull) {
                        clearInterval(setIntervalCountdown)
                    }
                    setData({[setIntervalCountdownKey]: interval})
                },
                countdownContent = (v, i) => <div
                    className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeftBig'}`}>
                    {i !== 0 && (i === 1
                        ? <div
                            className={`saf ${isPhoneClientWidth ? '' : 'bigFontSize'}`}>DAYS</div>
                        : <img
                            src={requireDefault('Union')}
                            alt=""
                            style={{width: `${isPhoneClientWidth ? .25 : .1}rem`}}/>)}
                    <div className={'positionRelative'}>
                        <img
                            src={requireDefault('Rectangle')}
                            alt=""
                            style={{width: `${isPhoneClientWidth ? 2.5 : 1}rem`}}/>
                        <div
                            className={`saf positionAbsolute flexCenter width100 height100 top0 ${isPhoneClientWidth ? 'bigFontSizePhone' : 'biggerFontSize'}`}>
                            {v}
                        </div>
                    </div>
                </div>,
                inviteRecords = checkDataArray(data, 'inviteRecords'),
                inviteRecordsIsNotNullOrEmpty = isNotNullOrEmpty(inviteRecords),
                inviteRecordIndex = checkData0(data, 'inviteRecordIndex'),
                holders = checkData0(data, 'holders'),
                portfolioChains = memeSlaveContracts.filter(v => !isBNBFunc(v.id)),
                portfolioChainIndex = checkData0(data, 'portfolioChainIndex'),
                fromMaster = checkDataBool(data, 'fromMaster'),
                portfolioChain = portfolioChains[portfolioChainIndex],
                currentPortfolioChain = fromMaster ? portfolioChain : chainValue,
                crossToChainId = (fromMaster
                    ? currentPortfolioChain
                    : masterChain).id,
                setClaimAmount = () => {
                    const rCCC = funcName => rewardClaimContractCall(funcName, currentCoinName, address)
                    rCCC('getClaimAmount').then(claimAmount => rCCC('roundClaimAmount').then(roundClaimAmount => setData({
                        claimAmount,
                        claimed: numberETH(roundClaimAmount) > 0
                    })))
                },
                isClaimed = checkDataBool(data, 'claimed'),
                crossMax = checkData0(data, 'crossMax'),
                crossList = [
                    [isNewMeme ? currentCoinName : '', 'depositSelected', 'crossTo', slaveWriteContract,
                        () => getTokenBalance().then(tokenBalance => setData({tokenBalance})),
                        () => {
                            const setCrossToTokenBalance = tokenBalance =>
                                setData({crossToTokenBalance: numberETH(tokenBalance)})
                            if (fM) {
                                const portfolioChainId = portfolioChain.id
                                getContractAbi(portfolioChainId).then(slaveContractAbi => contractCall(
                                    new Web3(require(`./rpc/${portfolioChainId}`).default).eth,
                                    currentCoinContracts[portfolioChainId],
                                    slaveContractAbi,
                                    'balanceOf',
                                    address
                                ).then(setCrossToTokenBalance))
                            } else {
                                masterContractCall('balanceOf', address).then(setCrossToTokenBalance)
                            }
                        },
                        tokenBalance,
                        checkData0(data, 'crossToTokenBalance'),
                        rC,
                        'Token cross-chain',
                    ],
                    [isBNB ? '' : 'ETH', 'withdrawSelected', 'ethCrossTo', (...params) => wC(createMemeContractSlaveAddress, createMemeContractSlaveAbi, ...params),
                        () => getBalance().then(ethBalance => setData({ethBalance})),
                        () => (fM ? new Web3(require(`./rpc/${portfolioChain.id}`).default).eth : masterContractETH).getBalance(address).then(
                            ethBalance => setData({crossToETHBalance: numberETH(ethBalance)})),
                        ethBalance,
                        checkData0(data, 'crossToETHBalance'),
                        (funcName, ...params) => contractCall(slaveContractETH, createMemeContractSlaveAddress, createMemeContractSlaveAbi,
                            funcName, ...params
                        ),
                        `ETH cross-chain (LIMIT ${crossMax} ETH)`
                    ],
                ],
                crossItemIndex = isBNB ? 0 : (isNewMeme ? checkData0(data, 'crossItemIndex') : 1),
                crossItem = crossList[crossItemIndex],
                getCrossBalance = checkDataFunction(crossItem, 4),
                getCrossToBalance = checkDataFunction(crossItem, 5),
                crossCall = checkDataFunction(crossItem, 8),
                crossFunctionName = crossItem[2],
                isEthCrossTo = crossFunctionName === 'ethCrossTo',
                showPreviousTips = checkDataBool(data, 'showPreviousTips'),
                crossCallEstimateGas = amount => crossCall(
                    `${crossFunctionName}EstimateGas`,
                    crossToChainId,
                    address,
                    amount
                ),
                toggleShowPreviousTips = (showPreviousTips, closeShowLoading) => setData({
                    showPreviousTips,
                    ...ternaryObject(
                        closeShowLoading,
                        {showLoading: false}
                    )
                }),
                previousTips = showPreviousTips &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}
                    >
                        <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone boxSizingBorderBox borderRadiusPhone paddingBigPhone' : 'childrenSpacingTopBigger boxSizingBorderBox'}
                            style={isPhoneClientWidth
                                ? {
                                    width: '25rem',
                                    border: 'solid .15rem white',
                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                }
                                : {
                                    width: '12rem',
                                    padding: '.7rem',
                                    borderRadius: '.5rem',
                                    border: 'solid .05rem white',
                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                }}
                        >
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'}
                                     style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    Tips
                                </div>
                                <img onClick={() => toggleShowPreviousTips(false, true)}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <div
                                className={isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopBigPhone' : 'paddingBigger childrenSpacingTop'}>
                                <div className={'flexCenter'}>
                                    <img src={requireDefault('previousTips')} alt=""
                                         style={{width: `${isPhoneClientWidth ? 3.6 : 1.2}rem`}}/>
                                </div>
                                <pre className={`textAlignCenter ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{`The previous transaction has not been completed. 
Do you want to continue the new transaction?`}</pre>
                            </div>
                            <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBigger'} flexCenter childrenFlexGrow1`}>
                                {
                                    [
                                        ['cancel', () => {
                                            toggleShowPreviousTips(false, true)
                                        }],
                                        ['continue', () => {
                                            toggleShowPreviousTips()
                                            checkDataFunction(data, 'previousTipsFunc')()
                                        }],
                                    ].map(v => <div
                                        onClick={v[1]}
                                        className={'borderRadius999 backgroundBlack saf flexCenter cursorPointer width100 activeColor'}
                                        style={{
                                            padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                            fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                        }}
                                    >{v}</div>)
                                }
                            </div>
                        </div>
                    </div>,
                cloudflareHuman = document.getElementById('cloudflareHuman'),
                signBiz = (biz, symbol, token, amount = 0, needData) => {
                    if (address) {
                        toggleShowLoading(true)
                        return requestApi(
                            'sign/biz',
                            {
                                biz,
                                amount,
                                symbol,
                                sender: address,
                                ...ternaryObject(
                                    token,
                                    {token}
                                ),
                            },
                            'post'
                        ).then(signTrue => {
                            setData({
                                signTrue,
                                ...ternaryObject(
                                    !needData,
                                    {showLoading: false}
                                )
                            })
                            return signTrue
                        })
                    }
                },
                titleTip = (text, ...params) => <div style={{background: 'rgba(255, 221, 225, .6)'}}
                                                     className={`${isPhoneClientWidth ? 'paddingPhone borderRadiusPhone childrenSpacingLeftSmallPhone' : 'padding borderRadius childrenSpacingLeft'} flex alignItemsCenter`}>
                    <img src={requireDefault('previousTips')} alt=""
                         style={{width: `${isPhoneClientWidth ? .6 : .3}rem`}}/>
                    <div style={{fontSize: `${isPhoneClientWidth ? .55 : .25}rem`}}>
                        {text}
                        {params}
                    </div>
                </div>,
                slippage = false && titleTip('During a short period of intense trading, a large slippage may occur.'),
                gasIsHigh = (functionName, estimateGas) => {
                    const estimateGasMax = pingFeeFunctionName.includes(functionName)
                        ? (isBNB ? 0.0025 : 0.00016)
                        : (pongFeeFunctionName.includes(functionName) ? (isBNB ? 0.005 : 0.000368) : null)
                    return isNotNull(estimateGasMax) && estimateGas > estimateGasMax && titleTip('The network is congested and the gas fee may be high.')
                },
                messagesIncludeCount = checkData0(data, 'messagesIncludeCount'),
                isMessagesInclude = messagesIncludeCount > 50,
                messagesInclude = isMessagesInclude && titleTip(
                    'The current queued transaction messages include: ',
                    <span style={{fontSize: `${isPhoneClientWidth ? .55 : .25}rem`}}
                          className={'saf1'}>{messagesIncludeCount}</span>,
                    <div
                        style={{fontSize: `${isPhoneClientWidth ? .55 : .25}rem`}}>{'If you continue to trade, it may take longer to receive the results.'}</div>
                ),
                isNext = checkData([shortOverStatusList, currentCoinName]) === 0,
                nextTip = isNext && titleTip(
                    'At present, the Token of this serial number cannot be purchased in principle, if it is purchased in advance, if the short selling has not been opened, the funds will be returned'
                ),
                showCancel = isMessagesInclude || isNext,
                confirmExchange = (toggleFunc, show, logo, logo1, name, name1, amount, amount1, functionName, estimateGas, func) => show &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <div
                            className={`${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                            style={{
                                width: `${isPhoneClientWidth ? 25 : 12}rem`,
                                padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                            }}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'} style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    Confirm exchange
                                </div>
                                <img onClick={() => toggleFunc()}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <div style={{borderRadius: `${isPhoneClientWidth ? 1.2 : .4}rem`}}
                                 className={`backgroundWhite ${isPhoneClientWidth ? 'borderRadiusPhone childrenSpacingTopBigPhone paddingBigPhone' : 'borderRadius childrenSpacingTopBig paddingBigger'}`}
                            >
                                <div
                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                    {
                                        [
                                            [logo, name, amount],
                                            [logo1, name1, amount1],
                                        ].map((v, i) => [
                                            ...ternaryArray(
                                                i !== 0,
                                                [
                                                    <div className={'flexCenter'}>
                                                        <img src={requireDefault('SWAP2')} alt=""
                                                             style={{width: `${isPhoneClientWidth ? 2.1 : .7}rem`}}/>
                                                    </div>
                                                ]
                                            ),
                                            <div
                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                <div
                                                    className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                    <img src={v[0]} alt=""
                                                         style={{width: `${isPhoneClientWidth ? 1.5 : .7}rem`}}/>
                                                    <div className={'saf'}
                                                         style={{fontSize: `${isPhoneClientWidth ? 1 : .5}rem`}}>
                                                        {v[1]}
                                                    </div>
                                                </div>
                                                <div style={{fontSize: `${isPhoneClientWidth ? 1 : .5}rem`}}
                                                     className={'saf'}
                                                >
                                                    {v[2]}
                                                </div>
                                            </div>
                                        ])
                                    }
                                </div>
                                <div style={{border: `solid ${isPhoneClientWidth ? .06 : .02}rem #D8D8D8`}}/>
                                <div
                                    className={'flexCenter justifyContentSpaceBetween'}>
                                    {['Estimated Internet Fees', `${estimateGas} ${ethBNB}`].map(v => <div
                                        style={{fontSize: `${isPhoneClientWidth ? .7 : .28}rem`}}>{v}</div>)}
                                </div>
                                {gasIsHigh(functionName, estimateGas)}
                            </div>
                            <div
                                onClick={func}
                                className={'borderRadius999 backgroundBlack saf flexCenter biggerFontSize cursorPointer width100 activeColor'}
                                style={{
                                    padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                    fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                }}>
                                CONFIRM
                            </div>
                        </div>
                    </div>,
                getAirdrop = `Airdropped tokens are purchased by the platform.

How can users receive a bigger airdrop?
Voting - ${checkData0(data, 'basicPoints')} points
Voting by your invitee - ${checkData0(data, 'parentsPoints')} points
Voting by your invitee's invitee - ${checkData0(data, 'grandpaPoints')} points

Claim upon listing: 
Total Airdrop Amount ➗ Total voting points ✖️ My voting points`,
                hardcapMax = checkData0(data, 'hardcapMax'),
                launchCountdownMin = checkData0(data, 'launchCountdownMin'),
                launchCountdownMax = checkData0(data, 'launchCountdownMax'),
                createMemeData = checkData(
                    [data, 'createMemeData'],
                    {
                        poh: 1,
                        swapPhase: 1,
                        enableShort: 1,
                        hardcap: hardcapMax,
                        tokenomics: defaultTokenomicsNum,
                        presale: presaleList[0][0],
                        launchFunds: presaleList[0][1],
                        launchCountdown: launchCountdownMin,
                        ...ternaryObject(
                            isContest,
                            {launchFunds: contestLaunchFunds}
                        )
                    }
                ),
                quotaInterval = checkDataArray(data, 'quotaInterval'),
                createMemeDataTokenomics = checkData0(createMemeData, 'tokenomics'),
                createMemeDataSymbol = checkData([createMemeData, 'symbol']),
                createMemeDataName = checkData([createMemeData, 'name']),
                createMemeDataTg = checkDataString(createMemeData, 'tg'),
                createMemeDataX = checkDataString(createMemeData, 'x'),
                createMemeDataTotalSupply = checkDataString(createMemeData, 'totalSupply'),
                createMemeNumberDataTotalSupply = Number(createMemeDataTotalSupply),
                symbolString = checkDataString(createMemeData, 'symbol'),
                createMemeDataLogo = checkDataString(createMemeData, 'logo'),
                createMemeDataLaunchCountdown = checkData([createMemeData, 'launchCountdown']),
                createMemeDataLogoIsNotNullOrEmpty = isNotNullOrEmpty(createMemeDataLogo),
                createMemeDataIsTokenomicsAuto = isTokenomicsAuto(createMemeDataTokenomics),
                createMemeDataLogoShow = name => createMemeDataLogoIsNotNullOrEmpty
                    ? memeLogo(createMemeDataLogo)
                    : requireDefault(name),
                createMemeDataQuota = checkData([createMemeData, 'quota'], createMemeDataIsTokenomicsAuto ? checkData0(quotaInterval, 0) : 0),
                createMemeDataQuotaETH = parseEtherWei(npDivide(createMemeDataQuota, 100)),
                createMemeDataLaunchFunds = checkData([createMemeData, 'launchFunds']),
                createMemeDataNumberLaunchFunds = Number(createMemeDataLaunchFunds),
                listingVotesMinRatio = checkData0(data, 'listingVotesMinRatio'),
                createMemeDataInvitationCode = checkDataString(createMemeData, 'invitationCode'),
                createMemeDataLaunchPhase = checkData([createMemeData, 'launchPhase']),
                createMemeDataNumberLaunchPhase = Number(createMemeDataLaunchPhase),
                presaleMin = 10,
                presaleMax = 50,
                createMemeDataPresale = checkData([createMemeData, 'presale'], presaleMin),
                totalSupplyMin = checkData0(data, 'totalSupplyMin'),
                totalSupplyMax = checkData0(data, 'totalSupplyMax'),
                launchFundsMin = checkData0(data, 'launchFundsMin'),
                launchFundsMax = checkData0(data, 'launchFundsMax'),
                createMemeDataNumberPresale = Number(createMemeDataPresale),
                launchPhaseMin = 0.001,
                launchPhaseMax = 5,
                symbolCheck = [/^[A-Z0-9]+$/.test(symbolString) && !symbolExists, symbolExists ? 'Already exists' : 'Symbol must be uppercase'],
                totalSupplyCheck = [createMemeNumberDataTotalSupply >= totalSupplyMin && createMemeNumberDataTotalSupply <= totalSupplyMax, 'Total supply error'],
                launchFundsCheck = [symbolString.indexOf('LIKTEST') === 0 || (createMemeDataNumberLaunchFunds >= launchFundsMin && createMemeDataNumberLaunchFunds <= launchFundsMax), 'Launch funds error'],
                presaleCheck = [true, 'Presale error'],
                launchPhaseCheck = [createMemeDataNumberLaunchPhase >= launchPhaseMin && createMemeDataNumberLaunchPhase <= launchPhaseMax, 'Limit error'],
                getCreatePriceParams = [
                    createMemeDataTokenomics,
                    createMemeDataQuotaETH,
                    parseEtherWei(checkData0(createMemeDataLaunchFunds))
                ],
                getCreateVotePrice = () => {
                    // console.log(getCreatePriceParams);
                    return createMemeContractSlaveCall(
                        isVoteDetail ? 'votePrice' : 'getCreateAmount',
                        ...ternaryArray(
                            !isVoteDetail,
                            getCreatePriceParams
                        )
                    ).then(price => ethToBNB(price).then(price => bnbAmountMultiple(price)))
                },
                showCreateMeme = checkDataBool(data, 'showCreateMeme'),
                toggleShowCreateMeme = showCreateMeme => {
                    // todo 活动
                    if (showCreateMeme && isContest) {
                        toast('We are going to postpone Phase 2 because LIKWID is now planning to launch a new version, which will have more benefits for creators')
                    } else {
                        const setShowCreateMeme = () => setData({showCreateMeme})
                        showCreateMeme
                            ? switchEthereumChain(setShowCreateMeme, undefined, true)
                            : setShowCreateMeme()
                    }
                },
                createVotePrice = checkData0(data, 'createVotePrice'),
                // createVotePrice = (() => {
                //     const cVP = checkData0(data, 'createVotePrice')
                //     return isVoteDetail ? cVP : parseEtherWei(npPlus(numberETH(cVP), 10 ** -toFixedNum))
                // })(),
                createVotePriceETH = numberETH(createVotePrice),
                voteMeme = checkDataObject(data, 'voteMeme'),
                inviteAddress = checkData(
                    [new URLSearchParams(location.search).get('invite')],
                    addressZero
                ),
                voteCreatFunctionName = isVoteDetail ? 'vote' : 'create',
                createMemeDataParams = [
                    address,
                    checkDataString(createMemeDataSymbol).toLocaleUpperCase(),
                    `${namePrefix}${createMemeDataName}`,
                    createMemeDataLogo,
                    parseEtherWei(createMemeNumberDataTotalSupply),
                    parseEtherWei(createMemeDataNumberLaunchFunds),
                    7200,//npTimes(Number(createMemeDataLaunchCountdown), 3600),
                    createMemeDataTg,
                    [
                        createMemeDataX, createMemeDataNumberPresale,
                        checkData([createMemeData, 'swapPhase'], 1),
                        createMemeDataLaunchPhase,
                        checkData([createMemeData, 'poh']) == 2 ? true : false,
                        checkData([createMemeData, 'enableShort']) == 2 ? true : false,
                    ].join(xCodeSplit),
                    createMemeDataIsTokenomicsAuto ? createMemeDataQuotaETH : 0,
                    parseEtherWei(npPlus(createMemeDataNumberLaunchFunds, 1)),//parseEtherWei(checkData0(createMemeData, 'hardcap')),
                    createMemeDataTokenomics,
                    0,
                ],
                toggleShowVote = async showVote => {
                    if (showVote) {
                        const errorFunc = () => {
                            toggleShowLoading()
                            toast('Unknown error')
                        }
                        if (isVoteDetail) {
                            // if (!signTrue) {
                            //     toastVerifyHuman()
                            //     return
                            // }
                        } else {
                            const symbolForbiddenWords = 'LIKWID'
                            if (symbolString.includes(symbolForbiddenWords)) {
                                toast(`Symbol cannot contain ${symbolForbiddenWords}`)
                                return
                            }
                            if (!isNotNullOrEmpty(symbolString)) {
                                toast('Symbol cannot be empty')
                                return
                            }
                            toggleShowLoading(true)
                            if (await tokenMemeDetailFunc(symbolString).then(v => {
                                const exists = Object.keys(checkDataObject(v)).length > 0;
                                exists && toggleShowLoading();
                                toggleSymbolExists(exists);
                                return exists
                            }).catch(errorFunc)) {
                                toast('Already exists')
                                toggleShowLoading()
                                return
                            }
                            if (!createMemeDataLogoIsNotNullOrEmpty) {
                                toast('Logo cannot be empty')
                                toggleShowLoading()
                                return
                            }
                            if (!symbolCheck[0]) {
                                toast(symbolCheck[1])
                                toggleShowLoading()
                                return
                            }
                            if (!totalSupplyCheck[0]) {
                                toast(totalSupplyCheck[1])
                                toggleShowLoading()
                                return
                            }
                            if (!launchFundsCheck[0]) {
                                toast(launchFundsCheck[1])
                                toggleShowLoading()
                                return
                            }
                            if (!presaleCheck[0]) {
                                toast(presaleCheck[1])
                                toggleShowLoading()
                                return
                            }
                            if (!launchPhaseCheck[0]) {
                                toast(launchPhaseCheck[1])
                                toggleShowLoading()
                                return
                            }
                        }
                        new Promise(resolve => resolve(true))
                            .then(async v => {
                                if (v) {
                                    const createVotePrice = await getCreateVotePrice().catch(errorFunc)
                                    switchEthereumChain(
                                        async () => {
                                            setData({
                                                showVote,
                                                createVotePrice,//: numberETH(createVotePrice),
                                                showLoading: false,
                                                estimateGas: npTimes(
                                                    await (pingEstimateGas(
                                                        createMemeContractSlaveCall,
                                                        voteCreatFunctionName,
                                                        createVotePrice,
                                                        ...(isVoteDetail ? [voteMeme.symbol, inviteAddress] : [createMemeDataParams])
                                                    ).catch(errorFunc)),
                                                    1
                                                )
                                            })
                                        }, errorFunc, true)
                                } else {
                                    toast('Invitation Code Error')
                                }
                            })
                    } else {
                        setData({showVote})
                    }
                },
                createMemeContent = (() => {
                    const setCreateMemeData = async cMD => setData({createMemeData: {...createMemeData, ...cMD}}),
                        quotaPercentage = `${npMinus(100, Number(createMemeDataQuota))}%`,
                        setInputCreateMemeData = (e, k) => {
                            const {value} = e.target,
                                isSymbol = k === 'symbol'
                            setCreateMemeData({
                                [k]: k === 'name'
                                    ? value.replace(/.{101,}/g, '') : (
                                        isSymbol
                                            ? value.replace(/.{15,}/g, '')//.replace(/[^a-zA-Z0-9]/g, '').replace(/.{11,}/g, '').toLocaleUpperCase()
                                            : (k === 'launchFunds'
                                                ? (() => {
                                                    const vN = Number(value)
                                                    return vN >= 1
                                                        ? value.replace(/[^0-9]/g, '')
                                                        : value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, ".")
                                                            .replace(/^\./g, "").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
                                                            .replace(/^(\-)*(\d+)\.(\d{1}).*$/, '$1$2.$3')
                                                })()
                                                : (['totalSupply', 'launchCountdown', 'hardcap'].includes(k)
                                                    ? value.replace(/[^0-9]/g, '')
                                                    : (
                                                        k === 'quota'
                                                            ? (quotaInterval.includes(Number(value))
                                                                ? value
                                                                : '')
                                                            : (
                                                                k === 'presale'
                                                                    ? value.replace(/[^0-9]/g, '').replace(/.{3,}/g, '')
                                                                    : (
                                                                        k === 'launchPhase'
                                                                            ? amountReplace((value))
                                                                            : value
                                                                    )
                                                            )
                                                    )))
                                    )
                            });
                            isSymbol && toggleSymbolExists(false)
                        },
                        voteCountdown = countdown.map(countdownContent),
                        radio = [
                            // quotaData,
                            // howToVoteData,
                            ['The Transaction Fee (Swap Phase) ', 'swapPhase', [
                                '1%  (The community will receive 0.5%)',
                                '2%  (The community will receive 1%)',
                            ]],
                            ['Enable real person verification（POH）', 'poh', [
                                'close',
                                'open',
                            ]],
                            ['Allow Shorting Token', 'enableShort', [
                                'close',
                                'open',
                            ]],
                            //
                        ],
                        radioContent = (v, noSelect) => <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                            <div className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{v[0]}</div>
                            {
                                (() => {
                                    const key = v[1],
                                        list = v[2],
                                        isTokenomics = key === 'tokenomics',
                                        radioText = (text, i, content) => <div
                                            onClick={isTokenomics ? undefined : (() => setCreateMemeData({[key]: i + 1}))}
                                            className={`flex ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} cursorPointer`}>
                                            {!noSelect && <div
                                                className={'borderRadius999 backgroundWhite flexCenter'}
                                                style={square(`${isPhoneClientWidth ? 1.2 : .4}rem`)}>
                                                {checkData0(createMemeData, key) - 1 === i &&
                                                    <div style={square('60%')}
                                                         className={'borderRadius999 backgroundRed'}/>}
                                            </div>}
                                            <div className={`flexGrow1`}>
                                                {text && <div
                                                    className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>{text}</div>}
                                                {content}
                                            </div>
                                        </div>
                                    return list.map((v1, i) =>
                                        isTokenomics
                                            ? i + 1 === defaultTokenomicsNum && radioText(
                                            undefined,
                                            i,
                                            <div className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>
                                                <div
                                                    style={{paddingTop: isPhoneClientWidth ? '.3rem' : '.1rem'}}/>
                                                <div
                                                    style={{borderRadius: `${isPhoneClientWidth ? .3 : .1}rem`}}
                                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                    {/*<input*/}
                                                    {/*    value={createMemeDataPresale}*/}
                                                    {/*    style={{width: `${isPhoneClientWidth ? 1.5 : 1}rem`}}*/}
                                                    {/*    onChange={e => setInputCreateMemeData(e, 'presale')}*/}
                                                    {/*    className={`height100 saf1 width100 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}*/}
                                                    {/*    type="text"/>*/}
                                                    {
                                                        presaleList.map(v => {
                                                            const [presale, launchFunds] = v,
                                                                isCurrent = createMemeDataPresale === presale
                                                            return <div
                                                                onClick={() => setCreateMemeData({presale, launchFunds})}
                                                                className={`saf1 ${isCurrent ? 'colorWhite' : ''} ${isPhoneClientWidth ? 'paddingSmallPhone smallFontSizePhone' : 'paddingSmall'} boxSizingBorderBox`}
                                                                style={isCurrent
                                                                    ? {background: '#FF7788'}
                                                                    : {
                                                                        background: 'rgba(255, 119, 136, .2)',
                                                                        border: `solid ${isPhoneClientWidth ? .15 : .05}rem rgba(255,255,255,.2)`
                                                                    }}
                                                            >{presale}%</div>
                                                        })
                                                    }
                                                    <div
                                                        className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>presale
                                                    </div>
                                                </div>
                                                <span
                                                    className={`saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>{`${npMinus(100, createMemeDataNumberPresale)}%`}</span>{' pool locked in trading curve'}
                                            </div>
                                        )
                                            : radioText(v1, i))
                                    // return [
                                    // ...,
                                    // ...ternaryArray(
                                    //     key === 'tokenomics',
                                    //     [
                                    //         radioText(
                                    //             'Presale (10% - 40%)',
                                    //             list.length,
                                    //             createMemeDataIsTokenomicsAuto &&
                                    //             <div>{`${tradingCurve}% pool locked in trading curve, `}<span
                                    //                 className={'saf1'}
                                    //                 style={{color: '#FF7788'}}>{quotaPercentage}</span>{' presale, '}
                                    //                 <div
                                    //                     style={{paddingTop: isPhoneClientWidth ? 0 : '.1rem'}}/>
                                    //                 <span
                                    //                     style={{borderRadius: `${isPhoneClientWidth ? .3 : .1}rem`}}
                                    //                     className={`saf1 ${isPhoneClientWidth ? 'paddingSmallPhone' : 'paddingSmall'} backgroundWhite`}><input
                                    //                     value={createMemeDataQuota}
                                    //                     style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}
                                    //                     onChange={e => setInputCreateMemeData(e, 'quota')}
                                    //                     className={`height100 saf1 textAlignCenter width100 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}
                                    //                     type="text"/></span>{` % earmarked presale for creator, ${airdrop}% airdrop `}
                                    //             </div>
                                    //         )
                                    //     ]
                                    // )
                                    // ]
                                })()
                            }
                        </div>
                    return showCreateMeme &&
                        <div className={'positionFixed flexColumnCenter'}
                             style={{
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 zIndex: 1000,
                                 background: 'rgba(0,0,0,.56)',
                             }}>
                            <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} 
                                        boxSizingBorderBox overflowYScroll`}
                                style={{
                                    width: isPhoneClientWidth ? '90%' : '12rem',
                                    maxHeight: '100vh',
                                    ...ternaryObject(
                                        !isPhoneClientWidth,
                                        {borderRadius: '.5rem'}
                                    ),
                                    padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                    border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                }}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'saf'}
                                         style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                        CREATE A MEME
                                    </div>
                                    <img onClick={() => toggleShowCreateMeme()}
                                         className={'cursorPointer'}
                                         src={requireDefault('close')}
                                         alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                </div>
                                <div
                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                    {
                                        [
                                            [['Name Or Description', undefined, undefined, true], 'name', createMemeDataName, 'Orbit guy is a flying saucer pilot from orbiter'],
                                            [['Symbol', undefined, undefined, ...symbolCheck], 'symbol', createMemeDataSymbol, 'ORBGUY'],
                                            ['Logo (SIZE: 500KB, TYPE: png, jpg, jpeg, gif)', 'logo'],
                                            [['Omnichain Total Supply', undefined, undefined, ...totalSupplyCheck], 'totalSupply', createMemeDataTotalSupply, [totalSupplyMin, totalSupplyMax].join(' - ')],
                                            ['Tokenomics (No Fund Lose in Presale）', 'tokenomics'],
                                            [[`Launch Funds`, 'ETH', true, ...launchFundsCheck], 'launchFunds', createMemeDataLaunchFunds, launchFundsMin],
                                            [['Limit For A Single Address (Launch Phase)', 'ETH', true, ...launchPhaseCheck], 'launchPhase', createMemeDataLaunchPhase, `${launchPhaseMin} - ${launchPhaseMax}`],
                                            // [`Launch Hardcap (MAX ${hardcapMax} ETH)`, 'hardcap', checkData([createMemeData, 'hardcap']), hardcapMax],
                                            // [`Launch Countdown (HOUR ${[launchCountdownMin, launchCountdownMax].join('-')})`, 'launchCountdown', createMemeDataLaunchCountdown, launchCountdownMax],
                                            [[`Telegram (optional)`, tgUrl, undefined, true], 'tg', createMemeDataTg, 'likwid_meme'],
                                            [[`X.com (optional)`, xUrl, undefined, true], 'x', createMemeDataX, 'LIKWID_MEME'],
                                            // ['Invitation Code', 'invitationCode', createMemeDataInvitationCode],
                                        ].map(v => {
                                            const [v0] = v, v0IsObject = typeof v0 === 'object',
                                                title = v0IsObject ? v0[0] : v0,
                                                key = v[1],
                                                value = v[2],
                                                checkFailed = v0IsObject && isNotNullOrEmpty(value) && !v0[3];
                                            return key === 'tokenomics'
                                                ? radioContent((() => {
                                                    const tD = [...tokenomicsData()]
                                                    tD[0] = title
                                                    return tD
                                                })(), true)
                                                : <div
                                                    className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                                    <div
                                                        className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                        <div
                                                            className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{title}</div>
                                                        {checkFailed && <div
                                                            className={`saf1 colorRed ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{v0[4]}</div>}
                                                        {/*{*/}
                                                        {/*    key === 'invitationCode' && <div*/}
                                                        {/*        style={{*/}
                                                        {/*            padding: `${isPhoneClientWidth ? .15 : .05}rem`,*/}
                                                        {/*            paddingRight: `${isPhoneClientWidth ? .3 : .1}rem`*/}
                                                        {/*        }}*/}
                                                        {/*        onClick={() => windowOpen('https://t.me/likwidofficial')}*/}
                                                        {/*        className={`backgroundWhite flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'} borderRadius999 cursorPointer`}>*/}
                                                        {/*        <img src={requireDefault('telegram1')} alt=""*/}
                                                        {/*             style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>*/}
                                                        {/*        <div*/}
                                                        {/*            className={`saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Telegram*/}
                                                        {/*        </div>*/}
                                                        {/*        <img src={requireDefault('toRightBlack')} alt=""*/}
                                                        {/*             style={{width: `${isPhoneClientWidth ? .6 : .2}rem`}}/>*/}
                                                        {/*    </div>*/}
                                                        {/*}*/}
                                                    </div>
                                                    {key !== 'logo'
                                                        ? <div className={'flexCenter childrenSpacingLeft'}>
                                                            <div
                                                                className={`${isPhoneClientWidth ? 'paddingPhone borderRadiusPhone childrenSpacingLeftPhone' : 'padding borderRadius childrenSpacingLeft'} backgroundWhite boxSizingBorderBox flexGrow1 flex alignItemsCenter`}
                                                                style={{height: `${isPhoneClientWidth ? 2.7 : .9}rem`, ...ternaryObject(checkFailed, {border: `${isPhoneClientWidth ? '.15rem' : '.05rem'} solid red`})}}>
                                                                {
                                                                    (() => {
                                                                        const text = v0[1], arr = [
                                                                            ...ternaryArray(
                                                                                v0IsObject && isNotNullOrEmpty(text),
                                                                                [
                                                                                    <div style={{
                                                                                        background: '#F7F7F7',
                                                                                        borderRadius: `${isPhoneClientWidth ? .3 : .1}rem`
                                                                                    }}
                                                                                         className={`${isPhoneClientWidth ? 'smallerFontSizePhone paddingSmallPhone' : 'paddingSmall'}`}>{text}</div>
                                                                                ]
                                                                            ),
                                                                            <input placeholder={v[3]}
                                                                                   value={v[2]}
                                                                                   disabled={v[4]}
                                                                                   onChange={e => setInputCreateMemeData(e, key)}
                                                                                   className={`height100 flexGrow1 saf1 width100 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}
                                                                                   type="text"/>
                                                                        ]
                                                                        return v0IsObject && v0[2] ? arr.reverse() : arr
                                                                    })()
                                                                }
                                                            </div>
                                                        </div>
                                                        : <div
                                                            className={`${isPhoneClientWidth ? 'paddingBigPhone borderRadiusPhone' : 'paddingBig borderRadius'} positionRelative backgroundWhite boxSizingBorderBox`}>
                                                            <div
                                                                className={'childrenSpacingTop flexColumnCenter'}>
                                                                <img src={createMemeDataLogoShow('upload')} alt=""
                                                                     style={{width: `${isPhoneClientWidth ? 6 : 2}rem`}}/>
                                                                <div
                                                                    className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>
                                                                    Drag and Drop file here or Choose file
                                                                </div>
                                                            </div>
                                                            <input type="file"
                                                                   id='uploadLogo'
                                                                   onChange={event => {
                                                                       toggleShowLoading(true)
                                                                       const formData = new FormData(),
                                                                           removeValue = () => document.getElementById('uploadLogo').value = null;
                                                                       formData.append('file', event.target.files[0]);
                                                                       requestApi(
                                                                           'upload/logo', formData, 'post',
                                                                           {'Content-Type': 'multipart/form-data'}
                                                                       ).then(logo => {
                                                                           if (isNotNullOrEmpty(logo)) {
                                                                               setCreateMemeData({logo})
                                                                           } else {
                                                                               removeValue()
                                                                           }
                                                                           toggleShowLoading()
                                                                       }).catch(removeValue)
                                                                   }}
                                                                   className={'backgroundRed positionAbsolute cursorPointer'}
                                                                   style={{
                                                                       top: 0,
                                                                       bottom: 0,
                                                                       left: 0,
                                                                       right: 0,
                                                                       opacity: 0,
                                                                   }}
                                                            />
                                                        </div>
                                                    }
                                                    {/*{key === 'launchFunds' && <div*/}
                                                    {/*    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone borderRadiusPhone paddingPhone' : 'childrenSpacingLeft borderRadius padding'}`}*/}
                                                    {/*    style={{background: 'rgba(255,255,255,.7)'}}*/}
                                                    {/*>*/}
                                                    {/*    <img src={requireDefault('tips')} alt=""*/}
                                                    {/*         style={square(`${isPhoneClientWidth ? .7 : .3}rem`)}/>*/}
                                                    {/*    {*/}
                                                    {/*        (() => {*/}
                                                    {/*            const nLF = isNaN(createMemeDataNumberLaunchFunds) ? 0 : createMemeDataNumberLaunchFunds*/}
                                                    {/*            return <div*/}
                                                    {/*                style={{*/}
                                                    {/*                    lineHeight: 1.5,*/}
                                                    {/*                    fontSize: `${isPhoneClientWidth ? .6 : .25}rem`*/}
                                                    {/*                }}>At least <span*/}
                                                    {/*                style={{*/}
                                                    {/*                    color: '#FF8998',*/}
                                                    {/*                    fontSize: `${isPhoneClientWidth ? .6 : .25}rem`*/}
                                                    {/*                }}*/}
                                                    {/*                className={`saf1`}>{Math.floor(npTimes(nLF, listingVotesMinRatio))}</span> votes*/}
                                                    {/*                are required to reach <span*/}
                                                    {/*                    style={{*/}
                                                    {/*                        color: '#FF8998',*/}
                                                    {/*                        fontSize: `${isPhoneClientWidth ? .6 : .25}rem`*/}
                                                    {/*                    }}*/}
                                                    {/*                    className={`saf1`}>{nLF} ETH</span> launch*/}
                                                    {/*                funds*/}
                                                    {/*            </div>*/}
                                                    {/*        })()*/}
                                                    {/*    }*/}
                                                    {/*</div>}*/}
                                                </div>
                                        })
                                    }
                                    {radio.map(v => radioContent(v))}
                                    {/*{*/}
                                    {/*    createMemeDataIsTokenomicsAuto &&*/}
                                    {/*    <div*/}
                                    {/*        className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingBigPhone childrenSpacingLeftPhone' : 'borderRadius paddingBig childrenSpacingLeft'} flex`}*/}
                                    {/*        style={{background: 'rgba(255,255,255,.7)'}}*/}
                                    {/*    >*/}
                                    {/*        <img src={requireDefault('previousTips')} alt=""*/}
                                    {/*             style={square(`${isPhoneClientWidth ? 1.2 : .4}rem`)}/>*/}
                                    {/*        <div style={{lineHeight: 1.5}}*/}
                                    {/*             className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}><span*/}
                                    {/*            className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}*/}
                                    {/*            style={{color: '#FF7788'}}>{createMemeDataQuota}% </span>Earmarked*/}
                                    {/*            presale*/}
                                    {/*            requires a payment of <div*/}
                                    {/*                className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}*/}
                                    {/*                style={{color: '#FF7788'}}>{createVotePriceETH} ETH</div>*/}
                                    {/*            <div*/}
                                    {/*                className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>If*/}
                                    {/*                your community voting*/}
                                    {/*                does*/}
                                    {/*                not reach*/}
                                    {/*                the estimated target, please consider this model*/}
                                    {/*                thoughtfully.*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*}*/}
                                </div>
                                <div
                                    onClick={() => toggleShowVote(true)}
                                    className={'borderRadius999 backgroundBlack saf flexCenter biggerFontSize cursorPointer width100 activeColor'}
                                    style={{
                                        padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                        fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                    }}>
                                    CONFIRM
                                </div>
                            </div>
                        </div>
                })(),
                voteCreateContent = (() => {
                    const voteContent = isVoteDetail ? voteMeme : createMemeData,
                        estimateGasETH = numberETH(estimateGas)
                    return checkDataBool(data, 'showVote') &&
                        <div className={'positionFixed flexColumnCenter'}
                             style={{
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 zIndex: 1000,
                                 background: 'rgba(0,0,0,.56)',
                             }}>
                            <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                                style={{
                                    width: isPhoneClientWidth ? '90%' : '12rem',
                                    maxHeight: '100vh',
                                    ...ternaryObject(
                                        !isPhoneClientWidth,
                                        {borderRadius: '.5rem'}
                                    ),
                                    padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                    border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                }}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'saf'}
                                         style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                        {isVoteDetail ? 'voting information' : 'CREATE A MEME'}
                                    </div>
                                    <img onClick={() => toggleShowVote()}
                                         className={'cursorPointer'}
                                         src={requireDefault('close')}
                                         alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                </div>
                                <div
                                    className={`borderRadiusPhone backgroundWhite ${isPhoneClientWidth ? 'paddingBigPhone' : 'paddingBigger'}
                                             ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}`}
                                    style={{
                                        ...ternaryObject(
                                            !isPhoneClientWidth,
                                            {borderRadius: '.5rem'}
                                        ),
                                    }}
                                >
                                    <div
                                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        <div style={square(`${isPhoneClientWidth ? 3 : 1}rem`)}>
                                            <img
                                                src={isVoteDetail ? voteContent.logo : createMemeDataLogoShow('LIKWID')}
                                                alt=""
                                                className={'width100 height100'}/>
                                        </div>
                                        <div
                                            className={`saf1 ${isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}`}>
                                            {voteContent.symbol}
                                        </div>
                                    </div>
                                    {
                                        [
                                            ['Total Supply', voteContent.totalSupply],
                                            ['Launch Funds (ETH)', voteContent.launchFunds],
                                        ].map(v => <div
                                            className={'flexCenter justifyContentSpaceBetween'}>
                                            {v.map(v => <div
                                                style={{fontSize: `${isPhoneClientWidth ? .9 : .28}rem`}}>{v}</div>)}
                                        </div>)
                                    }
                                    <div
                                        className={'flexCenter justifyContentSpaceBetween'}>
                                        {
                                            ['Payable', `${createVotePriceETH} ${ethBNB}`].map(v =>
                                                <div
                                                    className={'saf'}
                                                    style={{fontSize: `${isPhoneClientWidth ? 1.2 : .4}rem`}}>{v}</div>)}
                                    </div>
                                    <div style={{border: 'solid .02rem #D8D8D8'}}/>
                                    <div
                                        className={'flexCenter justifyContentSpaceBetween'}>
                                        {['Estimated Internet Fees', `${estimateGasETH} ${ethBNB}`].map(v =>
                                            <div
                                                style={{fontSize: `${isPhoneClientWidth ? .9 : .28}rem`}}>{v}</div>)}
                                    </div>
                                    {gasIsHigh(voteCreatFunctionName, estimateGasETH)}
                                </div>
                                {isVoteDetail && !signTrue && cloudflareHumanDiv}
                                <div
                                    onClick={async () => {
                                        const {symbol: voteMemeSymbol} = voteMeme
                                        if (isVoteDetail) {
                                            if (!signTrue) {
                                                toastVerifyHuman()
                                                return
                                            }
                                        } else {
                                            if (await createMemeContractMasterCall('memeIsListed', createMemeDataSymbol)) {
                                                toast('Already listing')
                                                return
                                            }
                                        }
                                        toggleShowLoading(true)
                                        // console.log(createMemeDataParams, '+++++++++++++=');
                                        createMemeSlaveWriteContract(
                                            voteCreatFunctionName,
                                            BigInt(
                                                npPlus(
                                                    createVotePrice,
                                                    estimateGas
                                                )),
                                            isVoteDetail ? [voteMemeSymbol, inviteAddress, signTrue] : [createMemeDataParams],
                                            hash => (() => {
                                                const tokenMeme = () => requestApi('token/meme', {
                                                    chainId,
                                                    tx: hash,
                                                    // code: createMemeDataInvitationCode,
                                                }, 'post')
                                                return waitForTransaction({
                                                    hash,
                                                    timeout: pingCrossChainTime
                                                }).then(tokenMeme).catch(tokenMeme)
                                            })().then(
                                                r => {
                                                    if (r && !isVotePage) {
                                                        windowReplace(`/launch/${createMemeDataSymbol}`)
                                                    } else {
                                                        if (isVoteDetail) {
                                                            getVoteMeme()
                                                            getAllVotePoints()
                                                            getVotePointsChildrenVoted()
                                                        } else if (isVotePage) {
                                                            getRoundMemeList()
                                                        } else if (!isContest) {
                                                            reGetListedMemeList(undefined, isLaunch && r)
                                                        }
                                                        toastSuccessfulHash(r)
                                                        toggleShowVote()
                                                        toggleShowCreateMeme()
                                                        toggleShowLoading()
                                                    }
                                                }
                                            )
                                        )
                                    }}
                                    className={'borderRadius999 backgroundBlack saf flexCenter biggerFontSize cursorPointer width100 activeColor'}
                                    style={{
                                        padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                        fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                    }}>
                                    CONFIRM
                                </div>
                            </div>
                        </div>
                })(),
                contractAddressContent = (() => {
                    const showContractAddress = checkDataBool(data, 'showContractAddress'),
                        // currentCoinContracts = checkDataObject(data, 'currentCoinContracts'),
                        toggleShowContractAddress = () => setData({showContractAddress: !showContractAddress})
                    return <div
                        className={`overflowHidden ${isPhoneClientWidth ? 'paddingPhone childrenSpacingTopPhone' : 'paddingBig childrenSpacingTopBig'}`}
                        style={{
                            border: `solid ${isPhoneClientWidth ? .06 : .03}rem rgba(255,255,255,.4)`,
                            borderRadius: `${isPhoneClientWidth ? 1.65 : .55}rem`,
                        }}>
                        <div onClick={toggleShowContractAddress}
                             className={'flexCenter justifyContentSpaceBetween cursorPointer'}>
                            <div className={`saf activeColor ${isPhoneClientWidth ? '' : 'bigFontSize'}`}>
                                Contract Address
                            </div>
                            <div style={square(`${isPhoneClientWidth ? 1.2 : .4}rem`)}
                                 className={'borderRadius999 backgroundActiveColor flexCenter'}>
                                <img
                                    src={requireDefault(showContractAddress ? 'toBottom' : 'toRight')}
                                    alt=""
                                    style={{width: '70%'}}/>
                            </div>
                        </div>
                        {showContractAddress &&
                            <div className={'overflowHidden'}
                                 style={{border: `solid ${isPhoneClientWidth ? .06 : .03}rem #343434`}}>
                                {
                                    memeContracts.map((v, i) => {
                                        const contractAddress = checkDataString(currentCoinContracts, v[0])
                                        return <div
                                            className={'flex alignItemsCenter colorWhite'}
                                            style={{
                                                height: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                ...ternaryArray(i !== 0, {borderTop: `solid ${isPhoneClientWidth ? .09 : .03}rem #343434`})
                                            }}
                                        >
                                            <div
                                                className={'height100 flexCenter'}
                                                style={{
                                                    width: `${isPhoneClientWidth ? 6 : 2}rem`,
                                                    fontSize: `${isPhoneClientWidth ? .45 : .15}rem`,
                                                    borderRight: `solid ${isPhoneClientWidth ? .09 : .03}rem #343434`
                                                }}
                                            >
                                                {v[1]}
                                            </div>
                                            <div style={ternaryObject(isPhoneClientWidth, {padding: '0 .5rem'})}
                                                 className={'paddingLeftRight height100 flex alignItemsCenter justifyContentSpaceBetween flexGrow1'}>
                                                <div
                                                    style={{fontSize: `${isPhoneClientWidth ? .55 : .21}rem`}}
                                                    className={'colorWhite'}>
                                                    {contractAddress}
                                                </div>
                                                <img
                                                    className={'cursorPointer'}
                                                    onClick={() => copyText(contractAddress)}
                                                    src={requireDefault('copy')}
                                                    alt=""
                                                    style={{height: '70%'}}/>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                })(),
                tokenomicsShow = v => {
                    const [text, ...textList] = v[1].split(', ')
                    return (isTokenomicsAuto(currentCoinTokenomics + 1) ? [
                        [v[0], text],
                        ...textList.map(v => {
                            const [percentage, text] = v.split('% ')
                            return [`${percentage}%`, text]
                        })
                    ] : [v])
                },
                buyTokenFuncName = currentCoinLongSymbol ? 'buy' : 'swapExactETHForTokens',
                sellTokenFuncName = currentCoinLongSymbol ? 'sell' : 'swapExactTokensForETH',
                buySellFunctionName = isLaunchSwapBuy ? buyTokenFuncName : sellTokenFuncName,
                maxSlippage = checkData([data, 'maxSlippage'], maxSlippageMax),
                maxSlippageNumber = Number(maxSlippage),
                amountOutMinimum = async (amount = newAmount) => {
                    let aOM = 0, aOM1 = 0
                    if (isV8) {
                        aOM = await getAmountOutFunc(Number(amount) > 0 ? amount : 0, isLaunchSwapBuy, true)
                        if (isMaxSlippage) {
                            aOM1 = Math.floor(npTimes(
                                aOM,
                                npDivide(npMinus(100 - maxSlippageNumber), 100)
                            ))
                        }
                    }
                    // console.log(ternaryArray(isV8, [[aOM], [aOM1]]), '++++++++++++');
                    return ternaryArray(isV8, [[aOM], [aOM1]])
                },
                setBuyMaxETH = () => getDeadline().then(async deadline => {
                        if (Number(ethBalance) > 0) {
                            const aOM = await amountOutMinimum(sendETHBalance)
                            return setMaxETH(
                                buyTokenFuncName,
                                'buy',
                                () => [
                                    address,
                                    ...ternaryArray(
                                        !isV7,
                                        [deadline],
                                    ),
                                    ...checkDataArray(aOM, 1),
                                    ...ternaryArray(isNewMemeNoUTKOrPUPPET, [addressZero])
                                ],
                                buyMaxNoGas,
                                aOM,
                            )
                        } else {
                            return setNewAmount(0)
                        }
                    }
                ),
                depositTotal = checkData0(data, 'depositTotal'),
                toggleShowSwapConfirm = (showSwapConfirm, successFunc = () => {
                }, errorFunc = () => {
                }) => {
                    if (slaveContractAddress) {
                        if (showSwapConfirm) {
                            if (newAmountNumber > 0) {
                                // if (newAmountNumber < Number(isLaunchSwapBuy ? ethBalance : tokenBalance)) {
                                const maxETHAmountEstimateGas = checkData([data, 'maxETHAmountEstimateGas']);
                                // (isNotNull(maxAmount) && newAmountNumber > maxAmount)
                                //     ? toast(`A single transaction cannot be larger than ${npTimes(maxSwapRatio, 100)}% of the pool`)
                                //     : (
                                ((isLaunchSwapBuy && !(isNotNull(maxETHAmountEstimateGas) && (maxETHAmountEstimateGas < maxETHBalance)) && newAmountNumber > maxETHBalance)
                                    || (!isLaunchSwapBuy && Number(checkData0(data, 'priceAmount')) > maxETHBalance))
                                    ? buyCheckSignTrue ? toast(`${depositTotalPrompt}${depositTotal} ETH`) : maxETHBalanceToast()
                                    : axios(`${
                                        sessionDev
                                            ? 'https://testnet-'
                                            : 'https://'
                                    }openapi.vizing.com/sdk/transaction/pending/count`).then(
                                        v => checkData0(v, 'data', 'result', 'count')).catch(() => undefined).then(
                                        messagesIncludeCount => {
                                            setData({messagesIncludeCount})
                                            successFunc(showSwapConfirm)
                                        }
                                    )
                                // )
                                // } else {
                                //     toast('Insufficient account balance')
                                // }
                            } else {
                                toastEmpty()
                            }
                        } else {
                            errorFunc(showSwapConfirm)
                        }
                    } else {
                        toggleShowDeployment(true)
                    }
                },
                buySellConfirm = async (toggleShow = () => {
                }) => {
                    if (buyCheckSignTrue) {
                        toastVerifyHuman()
                    } else {
                        toggleShowLoading(true)
                        const aOM = await amountOutMinimum()
                        getEstimateGas(aOM, true, isLaunchSwapBuy ? 'buy' : 'sell', ...[parseEtherWeiNewAmount]).then(([pongFee, estimateGas]) =>
                            getDeadline().then(deadline => slaveWriteContract(
                                buySellFunctionName,
                                !isLaunchSwapBuy && isMasterContract ? undefined : BigInt(npPlus(isLaunchSwapBuy ? numberParseEtherWei(newAmount) : 0, estimateGas)),
                                [
                                    pongFee,
                                    ...ternaryArray(!isLaunchSwapBuy, [parseEtherWeiNewAmount]),
                                    address,
                                    ...ternaryArray(
                                        !isV7,
                                        [deadline],
                                    ),
                                    ...checkDataArray(aOM, 1),
                                    ...ternaryArray(buySignTrue, [signTrueString])
                                ],
                                hash => {
                                    let count = 0
                                    const interval = (isLaunchSwapBuy ? setInterval : setTimeout)(() => (isLaunchSwapBuy ? getTokenBalance : getBalance)().then(balance => {
                                            count++;
                                            const isSuccess = balance !== (isLaunchSwapBuy ? tokenBalance : ethBalance)
                                            if (!isLaunchSwapBuy || isSuccess || count >= crossChainTimeSeconds) {
                                                if (isSwap && swapPageIsFirst) {
                                                    getSwapEvent()
                                                }
                                                (isLaunchSwapBuy ? getBalance : getTokenBalance)().then(otherBalance => {
                                                    setData({
                                                        newAmount: '',
                                                        ...ternaryObject(!swapPageIsFirst, {swapPage: firstSwapPage}),
                                                        [isLaunchSwapBuy ? 'tokenBalance' : 'ethBalance']: balance,
                                                        [isLaunchSwapBuy ? 'ethBalance' : 'tokenBalance']: otherBalance
                                                    });
                                                })
                                                isSwap && setChart()
                                                clearInterval(interval)
                                                toggleShow()
                                                toggleShowLoading();
                                                (!currentCoinLongSymbol || !isV8) && (isLaunch ? masterContractCall : rC)('launched').then(launched =>
                                                    setData({launched}));
                                                setOmniChain()
                                                setOmniDeposited()
                                                toastSuccessfulHash(hash);
                                            }
                                        }
                                    ), 1000 * (isLaunchSwapBuy ? 1 : crossChainTimeSeconds))
                                }
                            )).catch(() => toggleShowLoading())).catch(() => toggleShowLoading())
                    }
                },
                priceAmount = checkData0(data, 'priceAmount'),
                swiperRef = useRef(null),
                contestCapRef = useRef(null),
                contestHoldersRef = useRef(null),
                capHolder = (() => {
                    const contestCapCoinsKey = 'contestCapCoins', contestHoldersCoinsKey = 'contestHoldersCoins',
                        contestCapCoins = checkDataArray(data, contestCapCoinsKey),
                        contestHoldersCoins = checkDataArray(data, contestHoldersCoinsKey)
                    return [
                        ['Market Cap', 'highest',
                            contestCapCoins.filter(v => !checkDataString(v, 'symbol').includes('LIKTEST')).map((v, i) => [i + 1, [v.logo, v.symbol], v.cmCap, v.maxCmCap]), 'trophy', '#FFEE56',
                            contestCapRef,
                            'maxCmCapSymbol',
                            'maxCmCapAmount',
                            'maxCmCapHolder',
                            'maxCmCapCreator',
                            'maxCmCapLogo',
                            contestCapCoinsPage, coinsPage => contestCapCoinsPage = coinsPage + 1, '4_desc', contestCapCoins, contestCapCoinsKey, 'contestCapCoinsLength'],
                        ['Holders', 'most',
                            contestHoldersCoins.filter(v => !checkDataString(v, 'symbol').includes('LIKTEST')).map((v, i) => [i + 1, [v.logo, v.symbol], v.holders, v.maxHolders]), 'trophy1', '#56F5FF',
                            contestHoldersRef,
                            'maxHolderSymbol',
                            'maxHolderAmount',
                            'maxHolderHolder',
                            'maxHolderCreator',
                            'maxHolderLogo',
                            contestHoldersCoinsPage, coinsPage => contestHoldersCoinsPage = coinsPage + 1, '5_desc', contestHoldersCoins, contestHoldersCoinsKey, 'contestHoldersCoinsLength']
                    ]
                })(),
                getCreateContestSession = (pI = phaseId) => {
                    toggleShowLoading(true)
                    const pIIsNotNull = isNotNull(pI)
                    return requestApi('contest/CreateContestSession1').then(v => {
                        const {length} = phases, phases1 = [...phases],
                            // todo 活动
                            vStatus = v.filter(v => v.id === 1)//v.filter(v => v.status > 0)
                        let obj = {...phases[0]}
                        return Promise.all(v.map((v1, i) => {
                            phases1[i] = v1
                            // todo 活动
                            if (pIIsNotNull ? (v1.id === pI) : (false//(vStatus.length > 0 && v.length === length)
                                ? (v1.status > 0) : (i === 0))) {
                                obj = {...v1}
                            }
                        })).then(() => {
                            const phaseId1 = pIIsNotNull ? pI : obj.id
                            listedPhaseId = phaseId1
                            setData({
                                phases: phases1,
                                phaseId: phaseId1,
                                maxCmCapSymbol: obj.maxCmCapSymbol,
                                maxHolderSymbol: obj.maxHolderSymbol,
                                maxCmCapLogo: memeLogoFunc(obj.maxCmCapLogo),
                                maxHolderLogo: memeLogoFunc(obj.maxHolderLogo),
                            })
                            setCountdown(Math.floor(npDivide(obj.endTime, 1000)))
                            return Promise.all(capHolder.map(async v => {
                                    setData({[v[15]]: undefined})
                                    return await getListedMemeList(undefined, undefined, firstCoinsPage, ...v.slice(12))
                                }
                            )).then(() => toggleShowLoading())
                        })
                    })
                },
                paths = {
                    '/': (() => {
                        const homeChains = [...prdChains.slice(0, 1), vizing, ...prdChains.slice(1)].map(v => v.id),
                            homeChainsLength = homeChains.length,
                            banners = [
                                // todo 活动
                                // ['homeBanner1', () => windowReplace('contest/session1')]
                                ['homeBanner2', () => {
                                }]
                            ],
                            featuredGit = <img alt=""
                                               className={'width100'}
                                               src={requireDefault('featured', 'gif')}
                                               onLoad={v => {
                                                   superGif = new SuperGif({
                                                       gif: v.target,
                                                       loop_delay: 0,
                                                       loop_mode: false,
                                                       auto_play: false,
                                                       draw_while_loading: false,
                                                   })
                                                   superGif.load(() => document.querySelector('canvas').className = 'width100')
                                               }}
                            />,
                            sortTypes = ['Creation time', 'Market Cap', 'Holders'],
                            sortType = sortTypes[sortTypeIndex],
                            toggleSortTypeIndex = sortTypeIndex => setData({sortTypeIndex}),
                            showSortType = checkDataBool(data, 'showSortType'),
                            toggleShowSortType = showSortType => setData({showSortType}),
                            toggleIsPositiveSequence = isPositiveSequence => setData({isPositiveSequence}),
                            randomMeme = checkDataObject(data, 'randomMeme'),
                            memeGoToPath = launched => launched ? 'swap' : 'launch',
                            memeGoTo = meme => windowReplace(`/${memeGoToPath(meme.launched)}/${meme.symbol}`),
                            homeBannerSwiper = <Swiper loop
                                                       className={'positionRelative width100 height100'}
                                                       direction='vertical'
                                                       ref={swiperRef}
                                                       allowTouchMove={isPhoneClientWidth}
                                                       onSlideChangeTransitionEnd={v => setData({bannerIndex: v.realIndex})}
                                                       autoplay={banners.length > 1
                                                           ? {
                                                               pauseOnMouseEnter: true,
                                                               disableOnInteraction: false
                                                           }
                                                           : false}
                            >
                                {
                                    banners.map(v => <SwiperSlide
                                        className={'height100'}>
                                        <img src={requireDefault(v[0])} alt=""
                                             onClick={() => checkData([v, 1], () => toggleShowNotYet(true))()}
                                             className={'width100 height100 cursorPointer'}/>
                                    </SwiperSlide>)
                                }
                                <div style={{top: 0, bottom: 0, right: '.5rem', width: 0}}
                                     className={`flexColumnCenter alignItemsFlexEnd ${isPhoneClientWidth ? 'childrenSpacingTop' : 'childrenSpacingTop'} positionAbsolute zIndex1`}>
                                    {
                                        Array.from({length: banners.length}, (_, i) => i).map(v =>
                                            <img style={{width: `${isPhoneClientWidth ? .7 : .3}rem`}}
                                                 className={'cursorPointer'}
                                                 onMouseEnter={() => swiperRef.current.swiper.slideTo(v + 1)}
                                                 src={requireDefault(checkData0(data, 'bannerIndex') === v ? 'Ellipse 83' : 'Ellipse 84')}
                                                 alt=""/>)
                                    }
                                </div>
                            </Swiper>,
                            randomMemeContent = <div
                                className={`flexColumn justifyContentFlexEnd ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone paddingRight boxSizingBorderBox' : 'childrenSpacingTopBig'}`}
                                style={{width: isPhoneClientWidth ? '85%' : '6.5rem'}}>
                                <div
                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} width100 fadeAnimation${checkData([data, 'isVisible'], true) ? 'Visible' : 'Invisible'}`}>
                                    <img src={randomMeme.logo} alt=""
                                         className={'borderRadius999'}
                                         style={square(`${isPhoneClientWidth ? 4 : 1.5}rem`)}
                                    />
                                    <div
                                        className={`flexGrow1 ${isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'childrenSpacingTopSmall'}`}>
                                        <div
                                            className={`saf ${isPhoneClientWidth ? 'bigFontSizePhone' : 'biggerFontSize'}`}>{randomMeme.name}</div>
                                        <div
                                            className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>{randomMeme.desc}</div>
                                    </div>
                                </div>
                                <div style={{
                                    width: `${isPhoneClientWidth ? 13 : 3.5}rem`,
                                    marginLeft: `${isPhoneClientWidth ? 4.5 : 1.7}rem`,
                                    padding: isPhoneClientWidth ? '.8rem 1.2rem' : '.2rem .4rem'
                                }}
                                     onClick={() => memeGoTo(randomMeme)}
                                     className={`backgroundBlack borderRadius999 boxSizingBorderBox flex alignItemsFlexEnd justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} cursorPointer`}>
                                    <div className={'saf activeColor'} style={{lineHeight: .7}}>
                                        go to {memeGoToPath(randomMeme.launched)}
                                    </div>
                                    <img src={requireDefault('Stroke1')} alt=""
                                         style={{height: `${isPhoneClientWidth ? .45 : .15}rem`}}/>
                                </div>
                            </div>,
                            sortContent = <div style={{
                                width: '6rem',
                                ...ternaryObject(isPhoneClientWidth, {
                                    width: '100%',
                                    height: '3rem'
                                })
                            }}
                                               className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} height100 positionRelative zIndex1`}>
                                <div
                                    onClick={() => toggleShowSortType(!showSortType)}
                                    className={'cursorPointer positionRelative height100 flexGrow1'}>
                                    <div
                                        className={'borderRadius999 flexCenter boxSizingBorderBox justifyContentSpaceBetween childrenSpacingLeft width100 height100'}
                                        style={{
                                            padding: `0 ${isPhoneClientWidth ? 1.2 : .4}rem`,
                                            border: `solid ${isPhoneClientWidth ? .15 : .05}rem rgba(255,255,255,.2)`
                                        }}
                                    >
                                        <div style={{fontSize: `${isPhoneClientWidth ? 1.2 : .4}rem`}}
                                             className={'saf1 colorWhite'}>
                                            {sortType}
                                        </div>
                                        <img
                                            src={requireDefault('Group 427320353')}
                                            alt=""
                                            style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>
                                    </div>
                                    {showSortType && <div
                                        className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBig borderRadius'} width100 positionAbsolute backgroundBlack boxSizingBorderBox`}
                                        style={{
                                            left: 0,
                                            ...isPhoneClientWidth
                                                ? {
                                                    top: '3.9rem',
                                                    padding: '.6rem 1.2rem',
                                                    border: 'solid .15rem rgba(255,255,255,.2)'
                                                }
                                                : {
                                                    top: '1.3rem',
                                                    padding: '.2rem .4rem',
                                                    border: 'solid .05rem rgba(255,255,255,.2)'
                                                }
                                        }}>
                                        {
                                            sortTypes.map((v, i) =>
                                                <div
                                                    onClick={() => toggleSortTypeIndex(i)}
                                                    className={'saf1 colorWhite cursorPointer'}>{v}</div>)
                                        }
                                    </div>}
                                </div>
                                <div
                                    onClick={() => toggleIsPositiveSequence(!isPositiveSequence)}
                                    className={'flexCenter cursorPointer height100 boxSizingBorderBox'}
                                    style={isPhoneClientWidth
                                        ? {
                                            padding: '0 .7rem',
                                            borderRadius: '.9rem',
                                            border: 'solid .15rem rgba(255,255,255,.2)'
                                        }
                                        : {
                                            padding: '0 .25rem',
                                            borderRadius: '.3rem',
                                            border: 'solid .05rem rgba(255,255,255,.2)'
                                        }}>
                                    <img
                                        src={requireDefault(isPositiveSequence ? 'positiveSequence' : 'reverseOrder')}
                                        alt=""
                                        style={square(`${isPhoneClientWidth ? 1.5 : .5}rem`)}/>
                                </div>
                            </div>,
                            searchContent = height => <div
                                className={`borderRadius999 flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone boxSizingBorderBox' : 'childrenSpacingLeft'} height100`}
                                style={isPhoneClientWidth
                                    ? {
                                        width: '100%',
                                        padding: '0 1.2rem 0 0',
                                        border: 'solid .15rem rgba(255,255,255,.2)'
                                    }
                                    : {
                                        width: '4rem',
                                        marginLeft: '2rem',
                                        padding: '0 .4rem 0 0',
                                        border: 'solid .05rem rgba(255,255,255,.2)'
                                    }}
                            >
                                <div style={square(height)}
                                     onClick={() => searchListedMeme()}
                                     className={'backgroundActiveColor borderRadius999 flexCenter cursorPointer'}>
                                    <img src={requireDefault('search')}
                                         alt=""
                                         style={{width: '60%'}}/>
                                </div>
                                <input type="text"
                                       className={'saf1 flexGrow1 colorWhite'}
                                       value={searchSymbol}
                                       onChange={setSearchSymbol}
                                       placeholder={'Please Enter'}
                                       onKeyUp={e => searchSymbolOnKeyUp(e, searchListedMeme)}/>
                                {searchSymbolIsNotNull &&
                                    <img
                                        src={requireDefault('searchDisabled')}
                                        className={'cursorPointer'}
                                        alt=""
                                        onClick={() => {
                                            setDataSearchSymbolEmpty()
                                            searchListedMeme(true)
                                        }}
                                        style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>}
                            </div>,
                            coinsContent = <div className={'flex alignItemsCenter flexWrap'}>
                                {
                                    coins.map(v => !checkDataString(v, 'symbol').includes('LIKTEST') && v.symbol !== 'PUPPET' &&
                                        <div onClick={() => memeGoTo(v)}
                                             style={{
                                                 ...isPhoneClientWidth
                                                     ? {
                                                         width: '100%',
                                                         height: '8.5rem',
                                                         padding: '.1rem',
                                                         borderRadius: '1.5rem',
                                                         marginBottom: '.5rem',
                                                     }
                                                     : {
                                                         width: '8.35rem',
                                                         height: '4rem',
                                                         padding: '.05rem',
                                                         borderRadius: '.7rem',
                                                         margin: '.3rem .3rem 0 0',
                                                     },
                                                 background: 'linear-gradient(270deg, #181818 0%, #6B6B6B 100%)'
                                             }}
                                             className={'boxSizingBorderBox overflowHidden cursorPointer'}>
                                            <div className={'height100 backgroundBlack overflowHidden positionRelative'}
                                                 style={{borderRadius: `${isPhoneClientWidth ? 1.45 : .67}rem`}}>
                                                {!v.launched && <div style={
                                                    isPhoneClientWidth
                                                        ? {
                                                            top: '.4rem',
                                                            right: '.7rem',
                                                            borderWidth: '.09rem',
                                                            padding: '.15rem .4rem',
                                                        }
                                                        : {
                                                            top: '.2rem',
                                                            right: '.6rem',
                                                            borderWidth: '.03rem',
                                                            padding: '.07rem .2rem',
                                                        }
                                                }
                                                                     className={`positionAbsolute ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'} borderRadius999 backgroundBlack flexCenter activeColor borderActiveColor`}>
                                                    Launching
                                                </div>}
                                                <div
                                                    className={`height100 ${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingLeftPhone' : 'paddingBigger childrenSpacingLeft'} flexCenter boxSizingBorderBox`}
                                                    style={{background: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) 50%, rgba(255, 127, 119, 0.17) 100%)'}}
                                                >
                                                    <div
                                                        className={`${isPhoneClientWidth ? 'childrenSpacingTop' : 'childrenSpacingTop'} flexGrow1`}>
                                                        <div
                                                            className={`colorWhite ${isPhoneClientWidth ? 'bigFontSizePhone' : 'biggerFontSize'} saf`}>
                                                            {v.symbol}
                                                        </div>
                                                        <div className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}
                                                             style={{color: 'rgba(255,255,255,.5)'}}>{v.desc}</div>
                                                        {
                                                            [
                                                                ['Market Cap', checkData0(v, 'cmCap')],
                                                                ['Holders', v.holders],
                                                            ].map(v => <div
                                                                className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'} colorWhite`}>
                                                                <div
                                                                    className={`colorWhite ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>{v[0]}</div>
                                                                :
                                                                <div
                                                                    className={`colorWhite ${isPhoneClientWidth ? 'smallFontSizePhone' : ''} saf1`}>{v[1]}</div>
                                                            </div>)
                                                        }
                                                    </div>
                                                    <img src={v.logo} alt=""
                                                         className={'borderRadius999'}
                                                         style={square(`${isPhoneClientWidth ? 4 : 2}rem`)}/>
                                                </div>
                                            </div>
                                        </div>)
                                }
                            </div>
                        return <div>
                            {isPhoneClientWidth
                                ? <div ref={coinsScroll}
                                       onScroll={() => coinsScrollFunc()}
                                       className={'backgroundBlack overflowYScroll'} style={{height: '100vh'}}>
                                    {pageTopPhone}
                                    {
                                        (() => {
                                            const bodyMinHeight = '97rem'
                                            return <div style={{minHeight: bodyMinHeight}}
                                                        className={'positionRelative zIndex1'}>
                                                <div
                                                    className={'positionAbsolute zIndex-1 flexColumn justifyContentFlexEnd'}
                                                    style={{
                                                        height: bodyMinHeight,
                                                        top: 0, left: 0, right: 0,
                                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFD3D5 30%)'
                                                    }}
                                                >
                                                    <div className={'positionAbsolute zIndex-1 fullWidth'}
                                                         style={{top: '9rem', left: 0, right: 0}}>
                                                        {featuredGit}
                                                    </div>
                                                    <img src={requireDefault('homeBGPhone')} alt=""
                                                         className={'width100 displayBlock'}/>
                                                    <img src={requireDefault('Mask group')} alt=""
                                                         className={'positionAbsolute'}
                                                         style={{width: '10rem', top: '35.5rem', right: '7rem'}}
                                                    />
                                                </div>
                                                <div className={'childrenSpacingTopBigPhone'} style={{
                                                    padding: '5rem 2rem 2rem 2rem'
                                                }}>
                                                    <div className={'overflowHidden'}
                                                         style={{
                                                             height: '7rem',
                                                             borderRadius: '1.5rem'
                                                         }}>
                                                        {homeBannerSwiper}
                                                    </div>
                                                    <div className={'flexCenter justifyContentFlexEnd'}
                                                         style={{paddingTop: '6rem'}}>
                                                        {randomMemeContent}
                                                    </div>
                                                    <div style={{marginTop: '15rem'}}
                                                         className={'flex justifyContentSpaceBetween alignItemsFlexEnd'}>
                                                        <pre style={{lineHeight: .9}}
                                                             className={'saf activeColor biggerFontSizePhone'}>{`MEME 
LAUNCHPAD`}</pre>
                                                        <img src={requireDefault('CREATEBlack', 'gif')} alt=""
                                                             className={'cursorPointer'}
                                                             onClick={() => toggleShowCreateMeme(true)}
                                                             style={{width: '8rem'}}/>
                                                    </div>
                                                    {sortContent}
                                                    {searchContent('3rem')}
                                                    {coinsContent}
                                                </div>
                                            </div>
                                        })()
                                    }
                                    {pageFootPhone}
                                    {connectLoading(10)}
                                </div>
                                : <div ref={coinsScroll}
                                       onScroll={() => coinsScrollFunc()}
                                       className={'backgroundBlack overflowYScroll'} style={{height: '100vh'}}>
                                    {pageTop}
                                    {(() => {
                                        const bodyMinHeight = '28rem'
                                        return <div style={{minHeight: bodyMinHeight}}
                                                    className={'positionRelative zIndex1'}
                                        >
                                            <div className={'positionAbsolute zIndex-1 flexColumn justifyContentFlexEnd'}
                                                 style={{
                                                     height: bodyMinHeight,
                                                     top: 0, left: 0, right: 0,
                                                     background: 'linear-gradient(180deg, #FFF9F0 0%, #FFD3D5 30%)'
                                                 }}
                                            >
                                                <div className={'positionAbsolute zIndex-1'}
                                                     style={{width: '11rem', top: 0, right: 0}}>
                                                    {featuredGit}
                                                </div>
                                                <img src={requireDefault('homeBG')} alt=""
                                                     className={'width100 displayBlock'}/>
                                                <img src={requireDefault('Mask group')} alt=""
                                                     className={'positionAbsolute'}
                                                     style={{width: '5rem', top: '9.5rem', right: '7rem'}}
                                                />
                                            </div>
                                            <div style={{padding: '3rem 2rem 1.3rem 2rem'}}>
                                                <div className={'flex justifyContentSpaceBetween'}>
                                                    <div className={'overflowHidden'}
                                                         style={{
                                                             width: '15rem',
                                                             height: '4.15rem',
                                                             borderRadius: '1rem'
                                                         }}>
                                                        {homeBannerSwiper}
                                                    </div>
                                                    {randomMemeContent}
                                                </div>
                                                <div style={{marginTop: '4.5rem'}} className={'childrenSpacingTopBigger'}>
                                                    <div className={'childrenSpacingTopBig'}>
                                                        <div className={'saf activeColor'} style={{fontSize: '1rem'}}>
                                                            MEME LAUNCHPAD
                                                        </div>
                                                        {
                                                            (() => {
                                                                const height = '1rem'
                                                                return <div
                                                                    className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div className={'flex alignItemsCenter'}>
                                                                        <div
                                                                            className={'flexCenter childrenSpacingLeft'}
                                                                            style={{height}}
                                                                        >
                                                                            {sortContent}
                                                                            {searchContent(height)}
                                                                        </div>
                                                                    </div>
                                                                    <img src={requireDefault('CREATEBlack', 'gif')} alt=""
                                                                         className={'cursorPointer'}
                                                                         onClick={() => toggleShowCreateMeme(true)}
                                                                         style={{height}}/>
                                                                </div>
                                                            })()
                                                        }
                                                    </div>
                                                    {coinsContent}
                                                </div>
                                            </div>
                                        </div>
                                    })()}
                                    {pageFoot}
                                </div>}
                            {createMemeContent}
                            {voteCreateContent}
                            {taskContent}
                            {notYet}
                            {loading}
                            {previousTips}
                        </div>
                    })(),
                    'contest/*': (() => {
                        const homeChains = [...prdChains.slice(0, 1), vizing, ...prdChains.slice(1)].map(v => v.id),
                            homeChainsLength = homeChains.length,
                            togglePhaseIndex = phaseIndex => getCreateContestSession(npPlus(phaseIndex, 1)),
                            // contestCapCoins =
                            //     contestHoldersCoins =
                            creationContest = <div style={ternaryObject(isPhoneClientWidth, {height: '12rem'})}
                                                   className={'flexColumn justifyContentSpaceBetween'}>
                                <div className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                    <div className={'flex'}>
                                        <div
                                            className={`saf1 ${isPhoneClientWidth ? 'bigFontSizePhone' : ''} backgroundActiveColor borderRadius999 positionRelative overflowVisible`}
                                            style={{padding: isPhoneClientWidth ? '.3rem 1.2rem' : '.15rem .4rem'}}
                                        >
                                            Creation Contest Season 1
                                            <img src={requireDefault('Layer 14')} alt=""
                                                 style={{
                                                     width: `${isPhoneClientWidth ? 1.8 : .6}rem`,
                                                     top: `-${isPhoneClientWidth ? 1.05 : .35}rem`,
                                                     left: 0
                                                 }}
                                                 className={'positionAbsolute'}
                                            />
                                        </div>
                                    </div>
                                    <pre style={{color: 'rgba(255,255,255,.5)'}}
                                         className={isPhoneClientWidth ? '' : 'smallFontSize'}>{`The first season of the creative competition 
is divided into four phases`}</pre>
                                </div>
                                <div className={isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : ''}>
                                    <div style={{color: 'rgba(255,255,255,.5)'}}
                                         className={isPhoneClientWidth ? '' : 'smallFontSize'}>{'Date'}</div>
                                    <div style={ternaryObject(isPhoneClientWidth, {fontSize: '1.9rem'})}
                                         className={`colorWhite ${isPhoneClientWidth ? '' : 'bigFontSize'}`}
                                    >
                                        8.1 - 8.30 8AM (UTC+0)
                                    </div>
                                </div>
                            </div>,
                            prizePool = <div style={ternaryObject(!isPhoneClientWidth, {marginLeft: '3rem'})}
                                             className={`${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'} flexColumn alignItemsCenter`}>
                                <div className={'saf'}
                                     style={{
                                         color: '#56E6FF',
                                         fontSize: `${isPhoneClientWidth ? 5 : 2.1}rem`,
                                         lineHeight: .8
                                     }}>
                                    $16,000
                                </div>
                                <div
                                    className={`saf colorWhite ${isPhoneClientWidth ? 'bigFontSizePhone' : 'smallFontSizePhone'}`}>
                                    Prize Pool
                                </div>
                            </div>,
                            phaseSelected = <div style={{
                                height: `${isPhoneClientWidth ? 1.9 : 1.3}rem`,
                                width: isPhoneClientWidth ? '85%' : '21.5rem',
                            }}
                                                 className={'flex childrenFlexGrow1'}>
                                {phases.map((v, i) => {
                                    const {id} = v,
                                        // todo 活动
                                        status = id === 1 //|| checkData0(v, 'status') > 0
                                    return <div onClick={() => status && togglePhaseIndex(i)}
                                                style={{
                                                    backgroundSize: 'cover',
                                                    opacity: status ? 1 : 0,
                                                    zIndex: phases.length - i,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center center',
                                                    backgroundImage: `url(${requireDefault(`${id === phaseId ? 'phaseSelected' : 'phaseUnSelected'}${isPhoneClientWidth ? 'Phone' : ''}`)})`,
                                                    ...ternaryObject(
                                                        i > 0,
                                                        {marginLeft: `-${isPhoneClientWidth ? .7 : .5}rem`}
                                                    )
                                                }}
                                                className={`height100 flexCenter saf ${isPhoneClientWidth ? 'smallFontSizePhone' : 'biggerFontSize'} ${status ? 'cursorPointer' : ''}`}>{status ? `phase ${id}` : ''}</div>
                                })}
                            </div>,
                            capHolderContent = capHolder.map(v => [
                                ['Rank', 'Memes', v[0], `The ${v[1]} ever`].map(v =>
                                    <div className={isPhoneClientWidth ? 'biggerFontSize' : ''}>{v}</div>),
                                ...v[2].map(v => v.map((v, i) => {
                                    const isObj = typeof v === 'object'
                                    return [
                                        ...ternaryArray(
                                            i <= 1,
                                            (() => {
                                                const isRank = i === 0
                                                return [(!isRank || v === 1) && <img
                                                    className={'borderRadius999'}
                                                    src={isRank ? requireDefault('1st') : (isObj ? v[0] : v)}
                                                    alt=""
                                                    style={square(`${isPhoneClientWidth ? (isRank ? .7 : 1) : (isRank ? .4 : .5)}rem`)}/>]
                                            })()
                                        ),
                                        <div
                                            className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''} textOverflowEllipsis`}>{isObj ? v[1] : v}</div>
                                    ]
                                }))
                            ]).map((v, i) => {
                                const value = capHolder[i], ref = capHolder[i][5]
                                return <div
                                    ref={ref}
                                    onScroll={() => coinsScrollFunc(ref, value[14], value[16], ...[undefined, undefined, ...value.slice(11)])}
                                    style={{
                                        ...isPhoneClientWidth
                                            ? {
                                                height: '12rem',
                                                padding: '1.5rem',
                                                borderRadius: '1.5rem',
                                                border: 'solid .15rem white',
                                                boxShadow: '0 0 1.5rem #FFB9C2',
                                            }
                                            : {
                                                height: '5.3rem',
                                                padding: '.7rem',
                                                borderRadius: '.5rem',
                                                border: 'solid .05rem white',
                                                boxShadow: '0 0 .5rem #FFB9C2',
                                            },
                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                    }}
                                    className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBigger'} overflowYScroll`}>
                                    {
                                        v.map((v, i) => [
                                            <div
                                                className={'flex alignItemsCenter childrenSpacingLeftSmall'}>
                                                {
                                                    v.map((v, i) => {
                                                        const isFirst = i === 0
                                                        return <div
                                                            style={ternaryObject(
                                                                isFirst,
                                                                {width: '17%'}
                                                            )}
                                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone smallerFontSizePhone' : 'childrenSpacingLeftSmall'} ${isFirst ? '' : 'flexGrow1'}`}>
                                                            {v}
                                                        </div>
                                                    })
                                                }
                                            </div>,
                                            ...ternaryArray(
                                                i === 0,
                                                [<div style={{
                                                    height: '.03rem',
                                                    background: 'rgba(0,0,0,.3)'
                                                }}/>]
                                            )
                                        ])
                                    }
                                </div>
                            }),
                            capHolderClaimContent = capHolder.map((v, capHolderI) => {
                                const symbol = checkDataString(data, v[6]),
                                    amount = checkData0(data, v[7]),
                                    claimed = checkDataBool(data, `claimed${capHolderI}`)
                                return <div style={{
                                    ...isPhoneClientWidth
                                        ? {
                                            borderRadius: '1.5rem',
                                            border: 'solid .15rem white',
                                            boxShadow: '0 0 1.5rem #FFB9C2',
                                        }
                                        : {
                                            borderRadius: '.5rem',
                                            border: 'solid .05rem white',
                                            boxShadow: '0 0 .5rem #FFB9C2',
                                        },
                                    background: 'linear-gradient(180deg, #FFFFFF 0%, #FFD7D8 100%)',
                                }} className={'overflowHidden'}>
                                    <div className={'flex'}>
                                        <div
                                            className={'flex alignItemsCenter childrenSpacingLeft'}
                                            style={{
                                                background: v[4],
                                                ...isPhoneClientWidth
                                                    ? {
                                                        padding: '.3rem 1.2rem',
                                                        borderTopRightRadius: '.75rem',
                                                        borderBottomLeftRadius: '.75rem',
                                                        borderBottomRightRadius: '1.5rem',
                                                    }
                                                    : {
                                                        padding: '.2rem .6rem',
                                                        borderTopRightRadius: '.25rem',
                                                        borderBottomLeftRadius: '.25rem',
                                                        borderBottomRightRadius: '.5rem',
                                                    }
                                            }}>
                                            <img src={requireDefault(v[3])} alt=""
                                                 style={{width: `${isPhoneClientWidth ? 1 : .5}rem`}}/>
                                            <div
                                                className={`saf ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'bigFontSize'}`}>
                                                The {v[1]} {v[0]} ever
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{padding: isPhoneClientWidth ? '1rem 2rem' : '.5rem 1rem'}}
                                         className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTopBigger'}>
                                        <div
                                            className={'flex justifyContentSpaceBetween'}>
                                            <div
                                                className={'flexColumn justifyContentSpaceBetween'}>
                                                <div
                                                    className={`saf ${isPhoneClientWidth ? 'biggerFontSizePhone' : 'smallFontSizePhone'}`}>
                                                    {symbol}
                                                </div>
                                                <div
                                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                    {
                                                        (amount > 0
                                                            ? [
                                                                ['Creator', 'Creator', checkDataBool(data, v[9])],
                                                                ['Holder', 'Holder', checkDataBool(data, v[8])]
                                                            ]
                                                            : [['Not held', undefined, true]]).map(v =>
                                                            checkDataBool(v, 2) &&
                                                            <div
                                                                style={{
                                                                    border: `solid ${isPhoneClientWidth ? .09 : .03}rem #FF7788`,
                                                                    background: 'rgba(255, 119, 136, 0.1)'
                                                                }}
                                                                className={`borderRadius999 ${isPhoneClientWidth ? 'paddingSmallPhone childrenSpacingLeftSmallPhone' : 'paddingSmall childrenSpacingLeftSmall'} flexCenter`}>
                                                                {v[1] && <img
                                                                    src={requireDefault(v[1])}
                                                                    style={{width: `${isPhoneClientWidth ? .7 : .3}rem`}}
                                                                    alt=""/>}
                                                                <div
                                                                    className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>{v[0]}</div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <img className={'borderRadius999'}
                                                 src={checkData([data, v[10]])}
                                                 alt=""
                                                 style={square(`${isPhoneClientWidth ? 5 : 2}rem`)}/>
                                        </div>
                                        <div
                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                            <div className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}
                                                 style={{color: 'rgba(0,0,0,.6)'}}>
                                                {claimed ? 'Earnings' : 'Estimated earnings'}:
                                            </div>
                                            <div
                                                className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'bigFontSize'}`}>
                                                {amount} {symbol}
                                            </div>
                                        </div>
                                        <div
                                            className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                            <div style={{
                                                height: `${isPhoneClientWidth ? .09 : .03}rem`,
                                                background: 'rgba(0,0,0,.3)'
                                            }}/>
                                            <div className={'flexCenter'}>
                                                {
                                                    (() => {
                                                        const claimed = checkDataBool(data, `claimed${capHolderI}`),
                                                            cC = amount > 0 && !claimed && !checkData([data, `estimated${capHolderI}`], true)
                                                        return <div
                                                            style={ternaryObject(isPhoneClientWidth, {padding: '.5rem 3rem'})}
                                                            onClick={() => cC && switchEthereumChain(
                                                                () => requestApi(`tool/check/address/${address}`, {
                                                                    swapStart: 0,
                                                                    swapEnd: 0
                                                                }).then(v => {
                                                                    const xBind = checkDataBool(v, 'x'),
                                                                        tgBind = checkDataBool(v, 'tg');
                                                                    xBind && tgBind
                                                                        ? requestApi(
                                                                            'sign/prize',
                                                                            {
                                                                                phaseId,
                                                                                chainId,
                                                                                symbol,
                                                                                sender: address
                                                                            },
                                                                            'post'
                                                                        ).then(v => contestContractWriteContract('claimPrize', undefined, [
                                                                            v.tokenAddress,
                                                                            parseEther(formatEther(v.amount)),
                                                                            v.sign
                                                                        ], hash => setTimeout(
                                                                            () => {
                                                                                toggleShowLoading()
                                                                                toastSuccessful(hash)
                                                                                contestContractRoundClaimAmount(symbol).then(claimed => {
                                                                                    setData({
                                                                                        [`claimed${capHolderI}`]: claimed
                                                                                    })
                                                                                })
                                                                            },
                                                                            3000
                                                                        )))
                                                                        : toast([
                                                                            ...ternaryArray(!xBind, ['Users need to complete the certification X']),
                                                                            ...ternaryArray(!tgBind, ['Users need to complete the certification TG'])
                                                                        ].join('; '))
                                                                }),
                                                                undefined,
                                                                true,
                                                                sessionDev ? arbitrumSepolia : arbitrum
                                                            )}
                                                            className={`borderRadius999 ${isPhoneClientWidth ? '' : 'padding paddingLeftRightBig'} ${cC ? 'backgroundBlack activeColor' : 'backGroundColorLightGray colorWhite'}  flexCenter saf cursorPointer`}>
                                                            CLAIM{claimed ? 'ED' : ''}
                                                        </div>
                                                    })()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }),
                            rulesContent = <div
                                className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone' : 'childrenSpacingTopBigger'}>
                                {
                                    [
                                        [
                                            'Rules',
                                            [
                                                [
                                                    ['Meme token theme is unrestricted, and the launch fund must be '],
                                                    [`${contestLaunchFunds} ETH`, true],
                                                    [` Only`]
                                                ],
                                                [['Each phase will have only two Meme token winners']],
                                                [['LIKWID will buy 2000U Meme winner\'s tokens in Arbitrum to let users claim in Arbitrum']]
                                            ],
                                        ],
                                        [
                                            'Evaluation Criteria',
                                            [
                                                [['Meme Winner 1: The meme token with highest market value achieved during the event.']],
                                                [['Meme Winner 2: The meme token with most number of holders during the event.']]
                                            ]
                                        ],
                                        [
                                            'Prize Distribution',
                                            [
                                                [
                                                    ['The winning Meme token Creator will receive '], ['20%', true], [' of the total token prize']
                                                ],
                                                [
                                                    ['The remaining '], ['80%', true], [' will be airdropped proportionally based on winning token holdings at the end date (holders distributed proportionally)']
                                                ]
                                            ],
                                            [
                                                "LIKWID will use all prize to buy Meme winner's tokens, and awarded all to the ",
                                                'creator & holders'
                                            ]
                                        ]
                                    ].map(v => <div style={ternaryObject(isPhoneClientWidth, {lineHeight: 1.7})}
                                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                        <div
                                            className={`${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'} saf lineHeight1`}>{v[0]}:
                                        </div>
                                        {(() => {
                                            const arr = checkDataArray(v, 2)
                                            return isNotNullOrEmpty(arr) &&
                                                <div className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>
                                                    {arr[0]}
                                                    <span
                                                        className={`saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>{arr[1]}</span>
                                                </div>
                                        })()}
                                        {
                                            v[1].map(v => <div
                                                className={`flex ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                <div
                                                    className={'borderRadius'}
                                                    style={{
                                                        ...square(`${isPhoneClientWidth ? .45 : .15}rem`),
                                                        background: '#FF7788',
                                                        marginTop: `${isPhoneClientWidth ? .6 : .1}rem`
                                                    }}/>
                                                <div
                                                    className={`flexGrow1 ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>
                                                    {
                                                        v.map(v => <span
                                                            className={`${v[1] ? 'saf1' : ''} ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}
                                                            style={ternaryObject(v[1], {color: '#FF7788'})}>{v[0]}</span>)
                                                    }
                                                </div>
                                            </div>)
                                        }
                                    </div>)
                                }
                            </div>
                        return <div>
                            {isPhoneClientWidth
                                ? pageTopFootPhone(
                                    <div className={'backgroundBlack positionRelative zIndex1'}>
                                        <div className={'width100 positionAbsolute zIndex-1'}
                                             style={{top: 0, left: 0, right: 0}}
                                        >
                                            <img src={requireDefault('Group 427320419')} alt=""
                                                 className={'width100'}/>
                                            {
                                                [
                                                    ['3.5rem', '35.5rem', '3.5rem'],
                                                    ['2.5rem', '34rem', '8rem'],
                                                    ['3rem', '33rem', '11rem'],
                                                    ['3rem', '34.5rem', '16rem'],
                                                    ['2.5rem', '36.5rem', '21rem'],
                                                    ['2.5em', '34rem', '24rem'],
                                                    ['2rem', '37.5rem', '11.5rem'],
                                                ].map((v, i) => <img
                                                    src={checkData([capHolder, 0, 2, i, 1, 0], requireDefault('LIKWID'))}
                                                    className={'positionAbsolute borderRadius999'} alt=""
                                                    style={{...square(v[0]), top: v[1], left: v[2]}}/>)
                                            }
                                        </div>
                                        <div style={{padding: '4rem 2rem 2rem 2rem'}}
                                             className={'childrenSpacingTopBiggerPhone'}>
                                            <div className={'flex boxSizingBorderBox'}>
                                                <div onClick={() => replaceHome()}
                                                     className={'flexCenter childrenSpacingLeftPhone cursorPointer'}>
                                                    <img src={requireDefault('back')} alt=""
                                                         style={{width: '1.5rem'}}/>
                                                    <div className={'saf colorWhite'}>BACK</div>
                                                </div>
                                            </div>
                                            <div style={{marginTop: '2rem'}}>
                                                {creationContest}
                                            </div>
                                            {prizePool}
                                        </div>
                                        <div style={{marginTop: '24.5rem'}}>
                                            <div className={'flexCenter'}>
                                                {phaseSelected}
                                            </div>
                                            <div style={{
                                                borderTopLeftRadius: '1.5rem',
                                                borderTopRightRadius: '1.5rem',
                                                padding: `1.5rem`,
                                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFD3D5 100%)',
                                            }} className={'boxSizingBorderBox childrenSpacingTopBigPhone'}>
                                                <div className={'flex justifyContentFlexEnd'}>
                                                    <img className={'cursorPointer'}
                                                         onClick={() => toggleShowCreateMeme(true)}
                                                         src={requireDefault('CREATE', 'gif')} alt=""
                                                         style={{width: '10rem'}}/>
                                                </div>
                                                <div style={{
                                                    transform: 'scale(.85)',
                                                    transformOrigin: 'left'
                                                }}
                                                     className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                    {countdown.map(countdownContent)}
                                                </div>
                                                {capHolderContent}
                                                <div style={{marginTop: '3rem'}} className={'childrenSpacingTopBigPhone'}>
                                                    <div className={'saf biggerFontSizePhone'}>
                                                        My Rewards
                                                    </div>
                                                    {capHolderClaimContent}
                                                </div>
                                                <div style={{marginTop: '3rem'}}>
                                                    {rulesContent}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : <div className={'backgroundBlack'}>
                                    {pageTop}
                                    <div className={'positionRelative zIndex1'}>
                                        <div className={'positionAbsolute zIndex-1'}
                                             style={{top: 0, left: 0, right: 0}}
                                        >
                                            <img src={requireDefault('Group 427320406')} alt=""
                                                 className={'width100'}/>
                                            {
                                                [
                                                    ['1.5rem', '5.2rem', '10rem', 20],
                                                    ['1.3rem', '3.5rem', '8.5rem', 20],
                                                    ['1.1rem', '5.5rem', '8rem'],
                                                    ['1.1rem', '7rem', '8rem'],
                                                    ['1rem', '4.8rem', '7rem', 20],
                                                    ['1rem', '6.5rem', '6.2rem', -30],
                                                    ['.7rem', '5.5rem', '6rem', 20],
                                                ].map((v, i) => <img
                                                    src={checkData([capHolder, 0, 2, i, 1, 0], requireDefault('LIKWID'))}
                                                    className={'positionAbsolute borderRadius999'} alt=""
                                                    style={{
                                                        ...square(v[0]),
                                                        top: v[1],
                                                        right: v[2],
                                                        transform: `rotate(${checkData0(v, 3)}deg)`
                                                    }}/>)
                                            }
                                        </div>
                                        {
                                            (() => {
                                                const paddingLeft = '2rem'
                                                return [
                                                    <div style={{padding: `1.7rem ${paddingLeft} 0 ${paddingLeft}`}}>
                                                        <div className={'flex boxSizingBorderBox'}>
                                                            <div onClick={() => replaceHome()}
                                                                 className={'flexCenter childrenSpacingLeft cursorPointer'}>
                                                                <img src={requireDefault('back')} alt=""
                                                                     style={{width: '.5rem'}}/>
                                                                <div className={'saf colorWhite'}>BACK</div>
                                                            </div>
                                                        </div>
                                                        <div className={'flex'} style={{marginTop: '.8rem'}}>
                                                            {creationContest}
                                                            {prizePool}
                                                        </div>
                                                    </div>,
                                                    <div style={{marginTop: '4.5rem'}}>
                                                        <div style={{paddingLeft}}>
                                                            {phaseSelected}
                                                        </div>
                                                        <div style={{
                                                            borderTopLeftRadius: '1rem',
                                                            borderTopRightRadius: '1rem',
                                                            padding: `.5rem ${paddingLeft}`,
                                                            background: 'linear-gradient(180deg, #FFF9F0 0%, #FFD3D5 100%)',
                                                        }} className={'boxSizingBorderBox childrenSpacingTopBigPhone'}>
                                                            <div className={'childrenSpacingTopBigger'}>
                                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div
                                                                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                                        {countdown.map(countdownContent)}
                                                                    </div>
                                                                    <img className={'cursorPointer'}
                                                                         onClick={() => toggleShowCreateMeme(true)}
                                                                         src={requireDefault('CREATE', 'gif')} alt=""
                                                                         style={{width: '5rem'}}/>
                                                                </div>
                                                                <div
                                                                    className={'flex childrenFlexGrow1 childrenSpacingLeftBigger'}>
                                                                    {capHolderContent}
                                                                </div>
                                                            </div>
                                                            <div className={'childrenSpacingTop'}>
                                                                <div className={'saf smallFontSizePhone'}>My Rewards</div>
                                                                <div
                                                                    className={'flex childrenSpacingLeftBigger childrenFlexGrow1'}>
                                                                    {capHolderClaimContent}
                                                                </div>
                                                            </div>
                                                            <div className={'positionRelative zIndex1'}>
                                                                {rulesContent}
                                                                <img className={'positionAbsolute zIndex-1'}
                                                                     src={requireDefault('meme3-3')} alt=""
                                                                     style={{width: '6rem', top: '1rem', right: '1rem'}}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ]
                                            })()
                                        }
                                    </div>
                                    {pageFoot}
                                </div>}
                            {createMemeContent}
                            {voteCreateContent}
                            {taskContent}
                            {notYet}
                            {loading}
                            {previousTips}
                        </div>
                    })(),
                    about: (() => {
                        const homeChains = [...prdChains.slice(0, 1), vizing, ...prdChains.slice(1)].map(v => v.id),
                            homeChainsLength = homeChains.length
                        return <div>
                            {isPhoneClientWidth
                                ? pageTopFootPhone(
                                    <div className={'backgroundBlack'}>
                                        <div className={'positionRelative'}>
                                            <div className={'childrenSpacingTopBigPhone'} style={{
                                                padding: '5rem 2rem 2rem 2rem'
                                            }}>
                                                <div className={'flex justifyContentSpaceBetween'}>
                                                    <div className={'childrenSpacingTopPhone'}>
                                            <pre className={'saf activeColor lineHeight1'}
                                                 style={{fontSize: '2.5rem'}}
                                            >{`An omnichain 
meme platform`}</pre>
                                                        <div className={'saf colorWhite lineHeight1'}
                                                             style={{fontSize: '1.5rem'}}>with custom price curves
                                                        </div>
                                                    </div>
                                                    <div className={'childrenSpacingTopBigPhone'}>
                                                        {
                                                            topSocialLinks.map(v => <img src={requireDefault(v[0])}
                                                                                         alt=""
                                                                                         onClick={() => windowOpen(v[1])}
                                                                                         className={'cursorPointer displayBlock'}
                                                                                         style={{
                                                                                             width: '3rem',
                                                                                             height: '3rem'
                                                                                         }}/>)
                                                        }
                                                    </div>
                                                </div>
                                                <pre className={'colorWhite'} style={{fontSize: '1.05rem'}}>{`POWERED BY 
ORBITER FINANCE & VIZING`}</pre>
                                                <div className={'childrenSpacingTopBigPhone'}>
                                                    {
                                                        [
                                                            ['orbiter', 'https://www.orbiter.finance/'],
                                                            ['vizing', 'https://www.vizing.com/'],
                                                        ].map(v => <img className={'cursorPointer displayBlock'}
                                                                        onClick={() => windowOpen(v[1])}
                                                                        src={requireDefault(v[0])} alt=""
                                                                        style={{height: '3.5rem'}}/>)
                                                    }
                                                </div>
                                            </div>
                                            <div style={{padding: '2rem 0'}}>
                                                <img style={{boxShadow: '0 0 10rem rgba(255, 185, 194, .5)'}}
                                                     src={requireDefault('bannerPhone')}
                                                     alt=""
                                                     className={'width100 displayBlock'}/>
                                            </div>
                                            <img className={'positionAbsolute'} src={requireDefault('catCat')} alt=""
                                                 style={{
                                                     width: '13rem', right: '1rem', bottom: '12rem'
                                                 }}/>
                                            <img className={'positionAbsolute'} src={requireDefault('Thoughts')} alt=""
                                                 style={{
                                                     width: '4rem', right: '7rem', bottom: '21.9rem'
                                                 }}/>
                                        </div>
                                        <div className={'childrenSpacingTopBigPhone'} style={{
                                            padding: '1rem 2rem 2rem 2rem'
                                        }}>
                                            <div className={'flex justifyContentSpaceBetween'}>
                                                <img src={requireDefault('banner2Phone')} alt="" style={{width: '11rem'}}/>
                                                <div className={'childrenSpacingTopPhone'}>
                                                    <div>
                                                        <div className={'flex'}>
                                                    <pre className={'saf colorWhite lineHeight1'}
                                                         style={{fontSize: '1.9rem'}}
                                                    >{`An `}</pre>
                                                            <div className={'saf activeColor lineHeight1'}
                                                                 style={{fontSize: '1.9rem'}}>omni-chain
                                                            </div>
                                                        </div>
                                                        <div className={'saf colorWhite lineHeight1'}
                                                             style={{fontSize: '1.9rem'}}>meme platform
                                                        </div>
                                                    </div>
                                                    <div className={'saf colorWhite lineHeight1'}
                                                         style={{fontSize: '1.1rem'}}>with custom price curves
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'childrenSpacingTopBigPhone'}>
                                                {
                                                    [
                                                        `Once launched, all supported chains 
can automatically deploy meme coin 
contracts`,
                                                        `Adopts an innovative omni-chain asset 
trading method to maintain price 
consistency`
                                                    ].map(v => <pre
                                                        className={'width100 flexCenter borderRadiusPhone textAlignCenter colorWhite saf2 paddingPhone boxSizingBorderBox'}
                                                        style={{
                                                            height: '8rem',
                                                            fontSize: '1rem',
                                                            lineHeight: '1.3',
                                                            border: 'solid .03rem rgba(255,255,255,.4)'
                                                        }}
                                                    >{v}</pre>)
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className={'positionRelative childrenSpacingTopBigPhone boxSizingBorderBox marginTopBigPhone'}
                                            style={{
                                                height: '161rem',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                padding: '14rem 2rem 2rem 2rem',
                                                backgroundPosition: 'center center',
                                                backgroundImage: `url(${requireDefault('Maskgroup')})`,
                                            }}
                                        >
                                            <div>
                                                {
                                                    [
                                                        ['LIKWID.MEME'],
                                                        ['is now live on', '2.6rem'],
                                                        [`${homeChainsLength} chains`],
                                                    ].map(v => <div className={'saf'}
                                                                    style={{fontSize: checkData([v, 1], '2.8rem')}}
                                                    >
                                                        {v[0]}
                                                    </div>)
                                                }
                                            </div>
                                            <div className={'flex flexWrap childrenSpacingRightBigPhone'}>
                                                {
                                                    homeChains.map(v =>
                                                        <img className={'marginTopBigPhone'}
                                                             src={requireDefault(`aboutChainImages/${v}`)} alt=""
                                                             style={{height: '3.35rem'}}/>)
                                                }
                                            </div>
                                            <div style={{marginTop: '13rem'}}
                                                 className={'width100 flexColumnCenter'}>
                                                <pre style={{fontSize: '2.6rem'}} className={'saf textAlignCenter'}>{`LIKWID 
Contract Framework`}</pre>
                                                <img style={{marginTop: '3rem'}} className={'width100'}
                                                     src={requireDefault('LIKWID Contract Freamwork')}
                                                     alt=""/>
                                            </div>
                                            <div style={{marginTop: '16rem'}}
                                                 className={'width100 flexColumnCenter childrenSpacingTopBiggerPhone'}>
                                                <pre style={{fontSize: '2.6rem'}}
                                                     className={'saf textAlignCenter activeColor'}>{`LIKWID PROTOCOL`}</pre>
                                                <div className={'flexColumnCenter positionRelative'}>
                                                    <img src={requireDefault('formula')} alt=""
                                                         className={'width100'}/>
                                                    <div style={{width: '90%'}} className={'childrenSpacingTopBiggerPhone'}>
                                                        <div style={{
                                                            border: 'solid .15rem white',
                                                            background: 'linear-gradient(180deg, #FFD3D5 -14.92%, #FFF9F0 100%)',
                                                        }} className={'paddingPhone boxSizingBorderBox borderRadiusPhone'}>
                                                                <pre style={{fontSize: '.8rem', lineHeight: 1.5}}>{`The following is the price calculation formula for 
LIKWID, where x' and y' represent the number 
of tokens stored in the contract before the 
transaction is initiated, Δx is the number of 
tokens the user needs to exchange, and Δy is 
calculated using the formula shown above.`}</pre>
                                                        </div>
                                                        <img className={'width100'} src={requireDefault('comparisonChart')}
                                                             alt=""/>
                                                    </div>
                                                    <img className={'positionAbsolute'} src={requireDefault('catCat')}
                                                         style={{width: '40%', top: '10.4rem', right: '.5rem'}} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : <div className={'backgroundBlack'}>
                                    {pageTop}
                                    <div className={'positionRelative'}>
                                        <div className={'flex justifyContentSpaceBetween'}
                                             style={{padding: '2.7rem 2rem 1.3rem 2rem'}}>
                                            <div className={'flex alignItemsFlexEnd childrenSpacingLeftBigger'}>
                                                <div>
                                            <pre className={'saf activeColor'} style={{fontSize: '.9rem'}}>{`An omnichain 
meme platform`}</pre>
                                                    <div className={'saf colorWhite'} style={{fontSize: '.7rem'}}>with
                                                        custom price curves
                                                    </div>
                                                </div>
                                                <div className={'childrenSpacingTopBigger'}
                                                     style={{marginLeft: '.5rem'}}>
                                            <pre className={'saf2 colorWhite'} style={{fontSize: '.5rem'}}>{`POWERED BY 
ORBITER FINANCE & VIZING`}</pre>
                                                    <div className={'flex alignItemsCenter childrenSpacingLeftBigger'}>
                                                        {
                                                            [
                                                                ['orbiter', 'https://www.orbiter.finance/'],
                                                                ['vizing', 'https://www.vizing.com/'],
                                                            ].map(v => <img className={'cursorPointer'}
                                                                            onClick={() => windowOpen(v[1])}
                                                                            src={requireDefault(v[0])} alt=""
                                                                            style={{height: '1rem'}}/>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex childrenSpacingLeftBigger'}>
                                                {
                                                    topSocialLinks.map(v => <img src={requireDefault(v[0])} alt=""
                                                                                 onClick={() => windowOpen(v[1])}
                                                                                 className={'cursorPointer'}
                                                                                 style={{
                                                                                     width: '.8rem',
                                                                                     height: '.8rem'
                                                                                 }}/>)
                                                }
                                            </div>
                                        </div>
                                        <img style={{boxShadow: '0 0 10rem rgba(255, 185, 194, .5)'}}
                                             src={requireDefault('banner')}
                                             alt=""
                                             className={'width100 displayBlock'}/>
                                        <img className={'positionAbsolute'} src={requireDefault('catCat')} alt="" style={{
                                            width: '7rem', right: '.7rem', top: '5.9rem'
                                        }}/>
                                        <img className={'positionAbsolute'} src={requireDefault('Thoughts')} alt="" style={{
                                            width: '2.5rem', right: '6rem', top: '3.5rem'
                                        }}/>
                                    </div>
                                    <div style={{padding: '3rem'}}
                                         className={'flex justifyContentSpaceBetween positionRelative'}>
                                        <img src={requireDefault('banner2')} alt=""
                                             style={{width: '7rem'}}
                                        />
                                        <div className={'flexColumn justifyContentSpaceBetween'}>
                                            <div className={'childrenSpacingTop'}>
                                                <div>
                                                    <div className={'flex'}>
                                                    <pre className={'saf colorWhite lineHeight1'}
                                                         style={{fontSize: '1.6rem'}}
                                                    >{`An `}</pre>
                                                        <div className={'saf activeColor lineHeight1'}
                                                             style={{fontSize: '1.6rem'}}>omni-chain
                                                        </div>
                                                    </div>
                                                    <div className={'saf colorWhite lineHeight1'}
                                                         style={{fontSize: '1.6rem'}}>meme platform
                                                    </div>
                                                </div>
                                                <div className={'saf colorWhite lineHeight1'}
                                                     style={{fontSize: '1.25rem'}}>with custom price curves
                                                </div>
                                            </div>
                                            <div className={'flexCenter childrenSpacingLeftBigger'}>
                                                {
                                                    [
                                                        'Once launched, all supported chains can automatically deploy meme coin contracts',
                                                        'Adopts an innovative \nomni-chain asset trading method to maintain price consistency'
                                                    ].map(v => <pre
                                                        className={'flexCenter textAlignCenter colorWhite saf2 paddingBig boxSizingBorderBox'}
                                                        style={{
                                                            height: '5rem',
                                                            width: '7rem',
                                                            fontSize: '.4rem',
                                                            lineHeight: '1.3',
                                                            borderRadius: '.5rem',
                                                            border: 'solid .03rem rgba(255,255,255,.4)'
                                                        }}
                                                    >{v}</pre>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'positionRelative zIndex1'}
                                         style={{marginTop: '4rem', padding: '7rem 3rem 3rem 3rem'}}>
                                        <img src={requireDefault('Waves')} className={'width100 positionAbsolute zIndex-1'}
                                             alt=""
                                             style={{left: 0, right: 0, bottom: 0}}/>
                                        <div className={'childrenSpacingTopBigger'}
                                             style={{transform: 'translateY(-7rem)'}}>
                                    <pre className={'saf'} style={{fontSize: '1.5rem'}}>{`LIKWID.MEME
is now live on ${homeChainsLength} chains`}</pre>
                                            <div className={'saf2'} style={{fontSize: '.66rem'}}>
                                                Including the launch and swap of tokens
                                            </div>
                                            <div className={'flex flexWrap childrenSpacingRightBigger'}
                                                 style={{marginTop: 0}}
                                            >
                                                {
                                                    homeChains.map(v =>
                                                        <img src={requireDefault(`aboutChainImages/${v}`)} alt=""
                                                             style={{height: '.8rem', marginTop: '.7rem'}}/>)
                                                }
                                            </div>
                                        </div>
                                        <div style={{marginTop: '5rem', transform: 'translateY(-5rem)'}}
                                             className={'width100 flexColumnCenter'}>
                                            <div style={{fontSize: '1.5rem'}} className={'saf'}>
                                                LIKWID Contract Framework
                                            </div>
                                            <img style={{width: '90%', marginTop: '1rem'}}
                                                 src={requireDefault('LIKWID Contract Freamwork')}
                                                 alt=""/>
                                        </div>
                                        <div style={{marginTop: '5rem'}}
                                             className={'width100 flexCenter'}>
                                            <div className={'width100'}>
                                                <div style={{fontSize: '1.5rem'}}
                                                     className={'saf activeColor textAlignCenter'}>
                                                    LIKWID PROTOCOL
                                                </div>
                                                <div style={{marginTop: '1rem'}}
                                                     className={'flex alignItemsCenter'}>
                                                    <img style={{width: '52%'}} src={requireDefault('comparisonChart')}
                                                         alt=""/>
                                                    <div className={'flexGrow1 positionRelative'}
                                                         style={{paddingLeft: '1.5rem'}}>
                                                        <div className={'childrenSpacingTop'} style={{width: '91%'}}>
                                                            <img src={requireDefault('formula')} alt=""
                                                                 className={'width100'}/>
                                                            <div style={{
                                                                borderRadius: '.5rem',
                                                                border: 'solid .05rem white',
                                                                background: 'linear-gradient(180deg, #FFD3D5 -14.92%, #FFF9F0 100%)',
                                                            }} className={'paddingBigger'}>
                                                                <pre style={{fontSize: '.34rem', lineHeight: 1.5}}>{`The following is the price calculation 
formula for LIKWID, where x' and y' 
represent the number of tokens stored 
in the contract before the transaction 
is initiated, Δx is the number of tokens 
the user needs to exchange, and Δy 
is calculated using the formula shown above.`}</pre>
                                                            </div>
                                                        </div>
                                                        <img className={'positionAbsolute'} src={requireDefault('catCat')}
                                                             alt=""
                                                             style={{width: '45%', top: '3.43rem', right: 0}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {pageFoot}
                                </div>}
                            {taskContent}
                            {notYet}
                            {loading}
                            {previousTips}
                        </div>
                    })(),
                    ...ternaryObject(
                        launchPrdOpen,
                        {
                            'launch/*': (() => {
                                const launched = checkDataBool(data, 'launched'),
                                    launchCountdown = launched ? defaultCountdown : countdown,
                                    launchHardCap = checkData([data, 'launchHardCap']),
                                    depoisted = launched || countdownEnds ||
                                        (isNotNull(launchHardCap) && numberETHOmniChain > numberETH(launchHardCap)),
                                    refund = checkData0(data, 'refund'),
                                    coinAmount = checkData0(data, 'coinAmount'),
                                    claimed = !launched || (refund <= 0 && coinAmount <= 0) || isClaimed,
                                    showLaunchConfirm = checkDataBool(data, 'showLaunchConfirm'),
                                    pongFee = checkData([data, 'pongFee']),
                                    claimData = [
                                        ['REFUND', `${refund} ETH`],
                                        ['COIN', `${coinAmount} ${currentCoinName}`],
                                    ],
                                    launchData = [
                                        ...((isWithdraw && !isNewMemeNoUTKOrPUPPET) ? claimData : [['Amount', `${newAmount} ${ethBNB}`]]),
                                        ...estimatedInternetFeesShow
                                    ],
                                    launchDataLength = launchData.length - 1,
                                    toggleShowLaunchConfirm = showLaunchConfirm => {
                                        if (showLaunchConfirm) {
                                            const gEG = (otherPingParams, needAmount, showLaunchConfirm, params) => {
                                                toggleShowLoading(true)
                                                return getEstimateGas(otherPingParams, needAmount, ...params).then(([pongFee, estimateGas] = []) =>
                                                    setData({
                                                        pongFee,
                                                        estimateGas,
                                                        showLaunchConfirm,
                                                        showLoading: false,
                                                        estimatedInternetFees: numberETH(estimateGas),
                                                        ...ternaryObject(
                                                            !isWithdraw,
                                                            {signTrue: undefined}
                                                        ),
                                                    })
                                                ).catch(() => toggleShowLoading())
                                            }
                                            if (isNewMemeNoUTKOrPUPPET) {
                                                if (!launched) {
                                                    toggleShowSwapConfirm(
                                                        showLaunchConfirm,
                                                        showLaunchConfirm => getDeadline().then(async deadline => gEG(
                                                            await amountOutMinimum(),
                                                            true,
                                                            showLaunchConfirm,
                                                            [
                                                                isWithdraw ? 'sell' : 'buy',
                                                                numberParseEtherWei(newAmount),
                                                            ]
                                                        )),
                                                        showLaunchConfirm => setData({showLaunchConfirm})
                                                    )
                                                }
                                            } else {
                                                if (!(isWithdraw ? claimed : depoisted)) {
                                                    if (!isWithdraw && newAmountNumber > maxETHBalance) {
                                                        toast('The deposit amount exceeds the limit')
                                                    } else {
                                                        if (isWithdraw || newAmountNumber > 0) {
                                                            gEG(undefined, !isWithdraw, showLaunchConfirm, isWithdraw ? ['claim'] : ['deposit', numberParseEtherWei(newAmount)])
                                                        } else {
                                                            toastEmpty()
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            setData({showLaunchConfirm})
                                        }
                                    },
                                    maxFunc = () => isNewMemeNoUTKOrPUPPET
                                        ? (isWithdraw ? setNewAmount(tokenBalance) : setBuyMaxETH())
                                        : setMaxETH('deposit', undefined, estimateGas => [
                                            npMinus(Number(parseEtherWeiEthBalance), estimateGas),
                                            ...ternaryArray(
                                                isNewMemeNoTAIBOI,
                                                ['']
                                            )
                                        ], isNewMemeNoTAIBOI),
                                    depositClaim = () => isWithdraw
                                        ? slaveWriteContract(
                                            'claim',
                                            BigInt(estimateGas),
                                            [pongFee],
                                            hash => {
                                                let count = 0;
                                                const interval = setInterval(() => getClaimed().then(claimed => {
                                                        count++
                                                        if (claimed || count >= crossChainTimeSeconds) {
                                                            setData({claimed})
                                                            clearInterval(interval)
                                                            toggleShowLoading()
                                                            toggleShowLaunchConfirm()
                                                            toastSuccessfulHash(hash)
                                                        }
                                                    }
                                                ), 1000)
                                            }
                                        )
                                        : ((!isNewMemeNoTAIBOI || !signSwitch || signTrue) ?
                                            slaveWriteContract(
                                                'deposit',
                                                BigInt(npPlus(numberParseEtherWei(newAmount), estimateGas)),
                                                [pongFee, parseEtherWei(newAmount), ...ternaryObject(isNewMemeNoTAIBOI, [signTrueString])],
                                                hash => {
                                                    let count = 0;
                                                    const interval = setInterval(() => getDeposit().then(deposit1 => {
                                                            count++
                                                            const isSuccess = deposit1 !== deposit
                                                            if (isSuccess || count >= crossChainTimeSeconds) {
                                                                getBalance().then(ethBalance => setData({ethBalance}))
                                                                setOmniChain()
                                                                setOmniDeposited()
                                                                setData({deposit: deposit1})
                                                                clearInterval(interval)
                                                                toggleShowLoading()
                                                                toggleShowLaunchConfirm()
                                                                toastSuccessfulHash(hash)
                                                            }
                                                        }
                                                    ), 1000)
                                                }
                                            )
                                            : toastVerifyHuman()),
                                    depositData = [
                                        ['DEPOSITORS', holders],
                                        ['ALL USER FUNDS', `${(isNewMemeNoUTKOrPUPPET && launched) ? currentCoinLaunchFunds : numberETHOmniChain} ETH`, true],
                                    ],
                                    dataListPhone = v => {
                                        const isSmall = v[2]
                                        return <div
                                            className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                            <div style={ternaryObject(
                                                isSmall,
                                                {
                                                    fontSize: '.7rem',
                                                    color: 'rgba(0,0,0,.5)'
                                                }
                                            )}>{v[0]}:
                                            </div>
                                            <div className={'saf'}
                                                 style={ternaryObject(
                                                     isSmall,
                                                     {fontSize: '.9rem'}
                                                 )}
                                            >
                                                {v[1]}
                                            </div>
                                        </div>
                                    },
                                    dataListWeb = v => {
                                        const isSmall = v[2]
                                        return <div
                                            className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                            <div style={ternaryObject(
                                                isSmall,
                                                {
                                                    fontSize: '.25rem',
                                                    color: 'rgba(0,0,0,.5)'
                                                }
                                            )}>{v[0]}:
                                            </div>
                                            <div className={'saf'}
                                                 style={{
                                                     fontSize: '.5rem',
                                                     ...ternaryObject(
                                                         isSmall,
                                                         {fontSize: '.3rem'}
                                                     )
                                                 }}>
                                                {v[1]}
                                            </div>
                                        </div>
                                    },
                                    scheduleETHOmniChain = isNewMemeNoUTKOrPUPPET && launched ? wantTotalDeposit : numberETHOmniChain,
                                    depositExceed = npMinus(scheduleETHOmniChain, wantTotalDeposit),
                                    depositExceedETH = `${depositExceed} ETH`,
                                    depositExceedETHSize = isPhoneClientWidth ? .6 : .2,
                                    depositExceedETHMinWidth = `${npTimes(npTimes(depositExceedETH.length, depositExceedETHSize), .8)}rem`,
                                    depositExceedSchedule = percentageToFixed(depositExceed, scheduleETHOmniChain),
                                    isDepositExceed = depositExceed > 0,
                                    depositSchedule = percentageToFixed(scheduleETHOmniChain, wantTotalDeposit),
                                    wantTotalDepositText = <div style={{fontSize: '.2rem'}}>
                                        {
                                            (isNewMemeNoUTKOrPUPPET
                                                ? [
                                                    ['When the fundraising amount reaches '],
                                                    [`${wantTotalDeposit} ETH`, true],
                                                    [', the swap process will be automatically started'],
                                                ]
                                                : [
                                                    ['Any funds surpassing '],
                                                    [`${wantTotalDeposit} ETH`, true],
                                                    [' will be returned to users based on a pro rata distribution.'],
                                                    ...ternaryArray(
                                                        isDepositExceed,
                                                        [
                                                            [' The current refund rate is '],
                                                            [depositExceedSchedule, true],
                                                            ['.']
                                                        ]
                                                    ),
                                                ]).map(v => {
                                                const isHighlight = v[1]
                                                return <span
                                                    style={{
                                                        fontSize: `${depositExceedETHSize}rem`,
                                                        ...ternaryObject(isHighlight, {color: '#FF3C5B'})
                                                    }}
                                                    className={isHighlight ? 'saf1' : ''}>{v[0]}</span>
                                            })
                                        }
                                    </div>,
                                    schedule = <div
                                        className={`${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTopSmall marginTop0'}`}
                                        style={ternaryObject(
                                            isPhoneClientWidth,
                                            {marginTop: `-${npTimes(depositExceedETHSize, 1.7)}rem`}
                                        )}
                                    >
                                        {
                                            (() => {
                                                const size = `${npTimes(depositExceedETHSize, isPhoneClientWidth ? 1.5 : 1)}rem`
                                                return <div
                                                    className={'flexCenter justifyContentFlexEnd'}>
                                                    <div
                                                        style={{fontSize: size}}>{depositSchedule}</div>
                                                    {isDepositExceed &&
                                                        <img src={requireDefault('rise')} alt="" style={{height: size}}/>}
                                                </div>
                                            })()
                                        }
                                        <div className={'positionRelative'}
                                             style={{height: `${depositExceedETHSize}rem`}}
                                        >
                                            <div style={{background: '#E3E3E3'}}
                                                 className={'borderRadius999 positionRelative height100'}>
                                                <div
                                                    className={'height100 borderRadius999 backgroundActiveColor'}
                                                    style={{width: isDepositExceed ? '100%' : depositSchedule}}/>
                                            </div>
                                            {
                                                isDepositExceed &&
                                                <div
                                                    className={'positionAbsolute flexCenter justifyContentFlexEnd height100'}
                                                    style={{
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0
                                                    }}
                                                >
                                                    <div
                                                        className={'borderRadius999 colorWhite flexCenter saf1 boxSizingBorderBox'}
                                                        style={{
                                                            height: '150%',
                                                            background: '#FF7789',
                                                            width: depositExceedSchedule,
                                                            minWidth: depositExceedETHMinWidth,
                                                            fontSize: `${depositExceedETHSize}rem`,
                                                            padding: `0 ${npDivide(depositExceedETHSize, 2)}rem`,
                                                        }}>
                                                        {depositExceedETH}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>,
                                    showTotalSupply = checkDataBool(data, 'showTotalSupply'),
                                    toggleShowTotalSupply = showTotalSupply => setData({showTotalSupply}),
                                    totalSupplyContent = showTotalSupply &&
                                        <div className={'positionFixed flexColumnCenter'}
                                             style={{
                                                 top: 0,
                                                 left: 0,
                                                 right: 0,
                                                 bottom: 0,
                                                 zIndex: 1000,
                                                 background: 'rgba(0,0,0,.56)',
                                             }}
                                        >
                                            <div
                                                className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone boxSizingBorderBox borderRadiusPhone paddingBigPhone' : 'childrenSpacingTopBigger boxSizingBorderBox'}
                                                style={isPhoneClientWidth
                                                    ? {
                                                        width: '25rem',
                                                        border: 'solid .15rem white',
                                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                    }
                                                    : {
                                                        width: '12rem',
                                                        padding: '.7rem',
                                                        borderRadius: '.5rem',
                                                        border: 'solid .05rem white',
                                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                    }}
                                            >
                                                <div className={'flexCenter justifyContentFlexEnd'}>
                                                    <img onClick={() => toggleShowTotalSupply()}
                                                         className={'cursorPointer'}
                                                         src={requireDefault('close')}
                                                         alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                                </div>
                                                {
                                                    [
                                                        ['lock', '100% of the funds will be locked in the contract, and the contract owner will not have access to the funds.'],
                                                        ['safe', '100% of the deposited amount is fully secure and guarantees perpetual profitability.'],
                                                        ['fire', '100% fair launch and COMMUNITY BASED '],
                                                    ].map(v => <div
                                                        className={`flex ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                        <img src={requireDefault(v[0])} alt=""
                                                             style={square(`${isPhoneClientWidth ? 1.6 : .5}rem`)}/>
                                                        <div className={'saf1'}
                                                             style={{fontSize: `${isPhoneClientWidth ? 1 : .37}rem`}}>{v[1]}</div>
                                                    </div>)
                                                }
                                                <div
                                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBigger'}>
                                                    <div style={{border: '.02rem solid rgba(0,0,0,.3)'}}/>
                                                    <pre className={'saf1'}
                                                         style={{fontSize: `${isPhoneClientWidth ? .9 : .43}rem`}}>{`Total Supply: ${currentCoinTotalSupply}
                                                         
${checkDataString(tokenomicsData(currentCoinQuota, currentCoinPresale), 2, currentCoinTokenomics)}`}</pre>
                                                </div>
                                            </div>
                                        </div>,
                                    depositWithdrawContent = (() => {
                                        const toggleDepositWithdraw = depositWithdraw => setData({depositWithdraw}),
                                            depositMax = ternaryArray(
                                                isNotNull(checkData([data, 'depositTotal'])) && address && isNewMemeNoTAIBOI && !isWithdraw,
                                                (() => {
                                                    const isCreator = checkDataString(address).toLocaleLowerCase() === checkDataString(currentCoinCreator).toLocaleLowerCase()
                                                    return [<pre
                                                        className={`flexCenter borderRadius999`}
                                                        style={{
                                                            fontSize: `${isPhoneClientWidth ? .5 : .2}rem`,
                                                            padding: `${isPhoneClientWidth ? .3 : .1}rem 0`,
                                                            background: '#FFEAED',
                                                        }}>{isCreator
                                                        ? 'Creators have a higher deposit limit of '
                                                        : depositTotalPrompt
                                                    }<span
                                                        className={`saf1`}
                                                        style={{
                                                            fontSize: `${isPhoneClientWidth ? .5 : .2}rem`,
                                                            color: '#FF3C5B'
                                                        }}>{depositTotal}</span>{` ${ethBNB}`}{
                                                        !isCreator && [
                                                            '; creators: ',
                                                            <span
                                                                className={`saf1`}
                                                                style={{
                                                                    fontSize: `${isPhoneClientWidth ? .5 : .2}rem`,
                                                                    color: '#FF3C5B'
                                                                }}>{checkData0(data, 'creatorDepositTotal')}</span>,
                                                            ` ${ethBNB}`
                                                        ]
                                                    }</pre>]
                                                })()
                                            ),
                                            maxContent = <div
                                                className={`flexCenter justifyContentFlexEnd ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                <div>Balance: {isWithdraw ? tokenBalance : ethBalance}</div>
                                                <div
                                                    onClick={maxFunc}
                                                    className={`borderRadius999 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'} saf flexCenter cursorPointer backgroundActiveColor`}
                                                    style={{
                                                        border: '.05rem solid',
                                                        padding: '0 .2rem',
                                                        height: '.35rem',
                                                        ...ternaryObject(
                                                            isPhoneClientWidth,
                                                            {
                                                                padding: '0 .5rem',
                                                                height: '1.5rem',
                                                            }
                                                        )
                                                    }}>
                                                    MAX
                                                </div>
                                            </div>
                                        return <div className={'overflowHidden'}
                                                    style={{borderRadius: `${isPhoneClientWidth ? 1.2 : .4}rem`}}
                                        >
                                            <div style={{
                                                height: `${isPhoneClientWidth ? 4.5 : 1.5}rem`,
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center center',
                                                backgroundImage: `url(${requireDefault(isWithdraw ? 'withdrawSelected' : 'depositSelected')})`,
                                            }}
                                                 className={'flex childrenFlexGrow1'}>
                                                {
                                                    (isNewMemeNoUTKOrPUPPET ? ['BUY', 'SELL'] : ['DEPOSIT', 'WITHDRAW']).map((v, i) =>
                                                        <div
                                                            onClick={() => toggleDepositWithdraw(i)}
                                                            className={`flexCenter height100 saf ${isPhoneClientWidth ? 'bigFontSizePhone' : 'biggerFontSize'} cursorPointer`}>
                                                            {v}
                                                        </div>)
                                                }
                                            </div>
                                            <div
                                                className={`backgroundWhite ${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopBigPhone' : `${showDeployment ? 'paddingBigger' : 'paddingBig'} childrenSpacingTopBig`}`}
                                            >
                                                {
                                                    showDeployment
                                                        ? <div
                                                            className={`${isPhoneClientWidth ? 'borderRadiusPhone' : 'borderRadius'} ${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopPhone' : 'paddingBig childrenSpacingTop'} flexColumnCenter`}
                                                            style={{border: '.03rem solid #D8D8D8', background: '#F7F7F7'}}
                                                        >
                                                            <img src={requireDefault('contractDeployment')} alt=""
                                                                 style={square(isPhoneClientWidth ? '3rem' : '1rem')}/>
                                                            <div className={'flexCenter saf'}
                                                                 style={{fontSize: `${isPhoneClientWidth ? 1.2 : .5}rem`}}>
                                                                In contract deployment ...
                                                            </div>
                                                            <div
                                                                className={`textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>
                                                                when the deployment is complete, it is displayed in the list
                                                            </div>
                                                        </div>
                                                        : (isNewMemeNoUTKOrPUPPET
                                                            ? [
                                                                maxContent,
                                                                isPhoneClientWidth
                                                                    ? <div
                                                                        className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                        <div
                                                                            className={'borderRadius999 paddingPhone saf flexGrow1 flexCenter childrenSpacingLeftPhone'}
                                                                            style={{
                                                                                background: 'rgba(247, 247, 247, 1)',
                                                                                border: 'solid .02rem rgba(216, 216, 216, 1)'
                                                                            }}>
                                                                            <input className={'saf flexGrow1'}
                                                                                   type="text" name="" id=""
                                                                                   value={newAmount}
                                                                                   onChange={e => setNewAmount(e.target.value)}/>
                                                                            <div
                                                                                className={'saf'}>{isWithdraw ? currentCoinName : ethBNB}</div>
                                                                        </div>
                                                                    </div>
                                                                    : <div
                                                                        className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                        <div
                                                                            className={'borderRadius saf flexGrow1 flexCenter childrenSpacingLeft'}
                                                                            style={{
                                                                                padding: '.15rem .3rem',
                                                                                background: 'rgba(247, 247, 247, 1)',
                                                                                border: 'solid .02rem rgba(216, 216, 216, 1)'
                                                                            }}>
                                                                            <input style={{fontSize: '.5rem'}}
                                                                                   className={'saf flexGrow1'}
                                                                                   type="text" name="" id=""
                                                                                   value={newAmount}
                                                                                   onChange={e => setNewAmount(e.target.value)}/>
                                                                            <div style={{fontSize: '.5rem'}}
                                                                                 className={'saf'}>
                                                                                {isWithdraw ? currentCoinName : ethBNB}
                                                                            </div>
                                                                        </div>
                                                                    </div>,
                                                                ...isWithdraw
                                                                    ? [<div
                                                                        style={{height: `${isPhoneClientWidth ? 1.35 : .5}rem`}}/>]
                                                                    : depositMax,
                                                                ...ternaryArray(
                                                                    isNewMemeNoUTKOrPUPPET,
                                                                    [
                                                                        <div
                                                                            className={'flexCenter justifyContentSpaceBetween'}
                                                                            style={ternaryObject(!isNotNullOrEmpty(newAmount), {opacity: 0})}
                                                                        >
                                                                            {
                                                                                [
                                                                                    `${priceAmount} ${isWithdraw ? ethBNB : currentCoinName}`,
                                                                                    ...ternaryArray(
                                                                                        !isWithdraw && isBNB,
                                                                                        [`≈ ${checkData0(data, 'bnbETH')} ETH`]
                                                                                    )
                                                                                ].map(v => <div
                                                                                    className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}>{v}</div>)
                                                                            }
                                                                        </div>
                                                                    ]
                                                                ),
                                                                <div
                                                                    onClick={() => toggleShowLaunchConfirm(true)}
                                                                    className={`borderRadius999 ${launched ? 'backGroundColorLightGray colorWhite' : 'backgroundBlack activeColor'} saf flexCenter cursorPointer width100 `}
                                                                    style={{
                                                                        padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                                                        fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                                                    }}>
                                                                    {isWithdraw ? 'SELL' : 'BUY'}
                                                                </div>
                                                            ]
                                                            : [
                                                                isWithdraw
                                                                    ? <div
                                                                        className={`${isPhoneClientWidth ? 'paddingBigPhone' : 'paddingBig'} boxSizingBorderBox`}
                                                                        style={{
                                                                            background: '#F7F7F7',
                                                                            borderRadius: `${isPhoneClientWidth ? .9 : .3}rem`,
                                                                            ...ternaryObject(
                                                                                !launched,
                                                                                {
                                                                                    height: countdownEnds
                                                                                        ? `${isPhoneClientWidth ? 11 : 5}rem`
                                                                                        : `${isPhoneClientWidth ? 16.24 : 4.87}rem`
                                                                                }
                                                                            )
                                                                        }}>
                                                                        {
                                                                            launched
                                                                                ? <div
                                                                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                                                                    {claimData.map(isPhoneClientWidth ? dataListPhone : dataListWeb)}
                                                                                </div>
                                                                                : <div
                                                                                    className={`flexColumnCenter height100 ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}`}>
                                                                                    <img src={requireDefault('tips')}
                                                                                         alt=""
                                                                                         style={{width: `${isPhoneClientWidth ? 3 : 1}rem`}}/>
                                                                                    <div
                                                                                        className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>
                                                                                        After the launch is completed, you can claim
                                                                                    </div>
                                                                                    {countdownEnds &&
                                                                                        <div style={{transform: 'scale(.7)'}}
                                                                                             className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                                                            {checkData([data, `${currentCoinName}countdownWithdraw`], defaultCountdown).map(countdownContent)}
                                                                                        </div>}
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    : [
                                                                        <div
                                                                            className={isPhoneClientWidth ? 'paddingBigPhone' : 'paddingBig'}
                                                                            style={{
                                                                                background: '#F7F7F7',
                                                                                borderRadius: `${isPhoneClientWidth ? .9 : .3}rem`
                                                                            }}>
                                                                            <div
                                                                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                                                                {
                                                                                    [
                                                                                        ['OMNI-CHAIN', omniDeposited],
                                                                                        ['DEPOSITED', numberETH(deposit)],
                                                                                    ].map(v => [v[0], `${v[1]} ETH`]).map(isPhoneClientWidth ? dataListPhone : dataListWeb)
                                                                                }
                                                                            </div>
                                                                        </div>,
                                                                        ...ternaryArray(
                                                                            !depoisted,
                                                                            [
                                                                                maxContent,
                                                                                isPhoneClientWidth
                                                                                    ? <div
                                                                                        className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                                        <div
                                                                                            className={'borderRadius999 paddingPhone saf flexGrow1 flexCenter childrenSpacingLeftPhone'}
                                                                                            style={{
                                                                                                background: 'rgba(247, 247, 247, 1)',
                                                                                                border: 'solid .02rem rgba(216, 216, 216, 1)'
                                                                                            }}>
                                                                                            <input className={'saf flexGrow1'}
                                                                                                   type="text" name="" id=""
                                                                                                   value={newAmount}
                                                                                                   onChange={e => setNewAmount(e.target.value)}/>
                                                                                            <div className={'saf'}>ETH</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div
                                                                                        className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                                        <div
                                                                                            className={'borderRadius saf flexGrow1 flexCenter childrenSpacingLeft'}
                                                                                            style={{
                                                                                                padding: '.15rem .3rem',
                                                                                                background: 'rgba(247, 247, 247, 1)',
                                                                                                border: 'solid .02rem rgba(216, 216, 216, 1)'
                                                                                            }}>
                                                                                            <input style={{fontSize: '.5rem'}}
                                                                                                   className={'saf flexGrow1'}
                                                                                                   type="text" name="" id=""
                                                                                                   value={newAmount}
                                                                                                   onChange={e => setNewAmount(e.target.value)}/>
                                                                                            <div style={{fontSize: '.5rem'}}
                                                                                                 className={'saf'}>ETH
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>,
                                                                                ...depositMax
                                                                            ]
                                                                        ),
                                                                    ],
                                                                <div
                                                                    onClick={() => toggleShowLaunchConfirm(true)}
                                                                    className={`borderRadius999 ${isWithdraw
                                                                        ? (claimed ? 'backGroundColorLightGray' : 'backgroundBlack')
                                                                        : (depoisted ? 'backGroundColorLightGray' : 'backgroundBlack')} saf flexCenter cursorPointer width100 
                                                                                    ${isWithdraw
                                                                        ? (claimed ? 'colorWhite' : 'activeColor')
                                                                        : (depoisted ? 'colorWhite' : 'activeColor')}
                                                                                    `}
                                                                    style={{
                                                                        padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                                                        fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                                                    }}>
                                                                    {isWithdraw ? (`claim${isClaimed ? 'ed' : ''}`) : 'deposit'}
                                                                </div>
                                                            ])
                                                }
                                            </div>
                                        </div>
                                    })(),
                                    launchCoinData = [
                                        ['Omnichain Total Supply', currentCoinTotalSupply],
                                        ...ternaryArray(
                                            !isNewMemeNoUTKOrPUPPET,
                                            [['Launch Hardcap (ETH)', currentCoinHardcap]]
                                        ),
                                    ],
                                    launchCloudflareHuman = !isWithdraw && signSwitch && !signTrue && cloudflareHumanDiv
                                return <div>
                                    {
                                        isPhoneClientWidth
                                            ? pageTopFootPhone(
                                                <div className={'positionRelative zIndex1'}
                                                     style={{background: 'linear-gradient(180deg, #FFD3D5 0%, #FFF9F0 50%,#fff 100%)'}}>
                                                    <div className={'positionAbsolute activeColor saf textAlignCenter zIndex-1'}
                                                         style={{
                                                             top: '1rem',
                                                             left: 0,
                                                             right: 0,
                                                             fontSize: '8.5rem',
                                                             opacity: .5
                                                         }}>
                                                        LAUNCH
                                                    </div>
                                                    <div className={'childrenSpacingTopBigPhone'}
                                                         style={{padding: '4.3rem 2rem 2rem 2rem'}}>
                                                        <div
                                                            className={'borderRadius999 flexCenter childrenSpacingLeftPhone boxSizingBorderBox'}
                                                            style={{
                                                                height: '3rem',
                                                                background: '#FFD7D8',
                                                                border: 'solid .1rem white',
                                                                boxShadow: '0 0 .5rem #FFB9C2',
                                                            }}>
                                                            <div style={square('3rem')}
                                                                 onClick={() => searchListedMeme()}
                                                                 className={'paddingSmall cursorPointer flexCenter boxSizingBorderBox borderRadius999 backgroundWhite'}>
                                                                <img style={{width: '50%'}} src={requireDefault('search')}
                                                                     alt=""/>
                                                            </div>
                                                            <input type="text" className={'saf1 flexGrow1'}
                                                                   value={searchSymbol}
                                                                   onChange={setSearchSymbol}
                                                                   onKeyUp={e => searchSymbolOnKeyUp(e, searchListedMeme)}/>
                                                            {searchSymbolIsNotNull && <div style={square('3rem')}
                                                                                           className={'paddingSmall cursorPointer flexCenter boxSizingBorderBox'}>
                                                                <img style={{width: '70%'}}
                                                                     onClick={() => {
                                                                         setDataSearchSymbolEmpty()
                                                                         searchListedMeme(true)
                                                                     }}
                                                                     src={requireDefault('searchDisabled')}
                                                                     alt=""/>
                                                            </div>}
                                                        </div>
                                                        <div
                                                            className={'borderRadius999 flexCenter childrenSpacingLeftPhone boxSizingBorderBox'}
                                                            style={{
                                                                padding: '0 1rem',
                                                                background: '#FFD7D8',
                                                                border: 'solid .1rem white',
                                                                boxShadow: '0 0 .5rem #FFB9C2',
                                                            }}>
                                                            <img className={'cursorPointer'} style={{height: '1.9rem'}}
                                                                 onClick={() => toggleShowCreateMeme(true)}
                                                                 src={requireDefault('launchCreate')} alt=""/>
                                                            <div style={{
                                                                height: '3rem',
                                                                overflowY: 'hidden',
                                                                padding: '0.55rem 0'
                                                            }}
                                                                 ref={coinsScroll}
                                                                 onScroll={() => coinsScrollFunc()}
                                                                 className={'flex boxSizingBorderBox childrenSpacingLeftPhone overflowXScroll scrollbarColor'}>
                                                                {
                                                                    coins.map((v, i) => {
                                                                        const isCurrent = currentCoinName === v.name
                                                                        return !checkDataString(v, 'symbol').includes('LIKTEST') &&
                                                                            <div className={'flexShrink0'} style={{
                                                                                width: '6rem',
                                                                                height: '1.9rem'
                                                                            }}>
                                                                                <div
                                                                                    onClick={() => setCoinIndex(i)}
                                                                                    style={ternaryObject(!isCurrent, {background: 'rgba(255,255,255,.4)'})}
                                                                                    className={'borderRadius999 zIndex1 overflowVisible flexCenter childrenSpacingLeftPhone width100 height100 cursorPointer positionRelative'}>
                                                                                    {!v.launched && <div style={{
                                                                                        top: '-.4rem',
                                                                                        left: 0,
                                                                                        borderWidth: '.09rem',
                                                                                        padding: '.07rem .4rem',
                                                                                        fontSize: '.45rem'
                                                                                    }}
                                                                                                         className={`positionAbsolute borderRadius999 backgroundBlack flexCenter activeColor borderActiveColor`}>
                                                                                        Launching
                                                                                    </div>}
                                                                                    <img src={v.logo} alt=""
                                                                                         className={'borderRadius999'}
                                                                                         style={square('1rem')}/>
                                                                                    <div
                                                                                        className={'saf smallerFontSizePhone'}>{v.name}</div>
                                                                                    {
                                                                                        isCurrent &&
                                                                                        <img
                                                                                            className={'positionAbsolute width100 zIndex-1'}
                                                                                            src={requireDefault(('launchSelectedPhone'))}
                                                                                            style={{top: 0, left: '-.8rem'}}/>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                    })//.map(v => {v}</div>)
                                                                }
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={'childrenSpacingTopBigPhone boxSizingBorderBox positionRelative borderRadiusPhone'}
                                                            style={{
                                                                border: 'solid .05rem white',
                                                                boxShadow: '0 0 .5rem #FFB9C2',
                                                                padding: '2.5rem 1rem 1rem 1rem',
                                                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                            }}>
                                                            {
                                                                [
                                                                    ['OVERVIEW',
                                                                        [
                                                                            ...depositData.map(dataListPhone),
                                                                            schedule,
                                                                            launched
                                                                                ? <img src={requireDefault('launchedBanner')}
                                                                                       alt=""
                                                                                       className={'width100 displayBlock'}/>
                                                                                : (
                                                                                    isNewMemeNoUTKOrPUPPET
                                                                                        ? <div className={'positionRelative'}>
                                                                                            <img
                                                                                                src={requireDefault('launchBanner')}
                                                                                                alt=""
                                                                                                className={'width100 displayBlock'}/>
                                                                                            <div
                                                                                                className={'positionAbsolute boxSizingBorderBox'}
                                                                                                style={{
                                                                                                    top: 0,
                                                                                                    bottom: 0,
                                                                                                    left: 0,
                                                                                                    right: 0,
                                                                                                    padding: '1.5rem 2.4rem 0 2.4rem'
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className={'childrenSpacingTopSmallPhone'}>
                                                                                                    <div
                                                                                                        className={'flex alignItemsCenter childrenSpacingLeftSmallPhone'}>
                                                                                                        <img
                                                                                                            style={square('1.8rem')}
                                                                                                            src={currentCoinLogo}
                                                                                                            className={'borderRadius999'}
                                                                                                            alt=""/>
                                                                                                        <div
                                                                                                            className={'saf1 smallFontSizePhone'}>{currentCoinName}</div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={'smallFontSizePhone textOverflowEllipsis'}>{currentCoinDesc}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : (currentCoinNameIsNotNull ? <div
                                                                                            className={'backgroundWhite paddingBigPhone flexCenter childrenSpacingLeftPhone borderRadiusPhone'}
                                                                                            style={{background: 'rgba(247, 247, 247, 1)'}}
                                                                                        >
                                                                                            {launchCountdown.map(countdownContent)}
                                                                                        </div> : <div/>)
                                                                                ),
                                                                            <div
                                                                                className={'flex alignItemsCenter childrenSpacingRightSmallPhone flexWrap'}>
                                                                                {
                                                                                    (() => {
                                                                                        const arr = checkDataArray(tokenomicsTextList(currentCoinQuota, currentCoinPresale), currentCoinTokenomics), {length} = arr
                                                                                        return arr.map(
                                                                                            (v, i) => tokenomicsShow(v).map(v =>
                                                                                                <div
                                                                                                    className={'flex alignItemsCenter childrenSpacingRightSmallPhone'}>
                                                                                                    <div
                                                                                                        style={{color: '#FF7788'}}
                                                                                                        className={'saf smallerFontSizePhone'}>
                                                                                                        {v[0]}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={'smallerFontSizePhone'}>{v[1]}</div>
                                                                                                    {i < length - 1 ? ',' : ''}
                                                                                                </div>)
                                                                                        )
                                                                                    })()
                                                                                }
                                                                            </div>,
                                                                            <div
                                                                                className={'paddingPhone flexCenter childrenSpacingLeftPhone'}
                                                                                style={{
                                                                                    borderRadius: '.8rem',
                                                                                    background: 'rgba(255, 185, 194, .3)'
                                                                                }}
                                                                            >
                                                                                <img src={requireDefault('tips')} alt=""
                                                                                     style={{width: '1.2rem'}}/>
                                                                                {wantTotalDepositText}
                                                                            </div>
                                                                        ]
                                                                    ],
                                                                ].map((v, i) =>
                                                                    <div
                                                                        className={'backgroundWhite paddingPhone childrenSpacingTopPhone'}
                                                                        style={{borderRadius: '1rem'}}>
                                                                        <div
                                                                            className={'flexCenter justifyContentSpaceBetween childrenSpacingLeftPhone'}>
                                                                            <div
                                                                                className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                                                <div className={'saf'}
                                                                                     style={{fontSize: '1.5rem'}}>{v[0]}</div>
                                                                                {
                                                                                    i === 0 &&
                                                                                    <div
                                                                                        onClick={() => toggleShowTotalSupply(true)}
                                                                                        className={'saf borderRadius999 cursorPointer'}
                                                                                        style={{
                                                                                            marginTop: '.3rem',
                                                                                            padding: '.15rem .4rem',
                                                                                            border: '.09rem solid',
                                                                                            fontSize: '.51rem'
                                                                                        }}>
                                                                                        more info
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            style={{border: '.02rem solid rgba(216, 216, 216, 1)'}}/>
                                                                        {v[1]}
                                                                    </div>
                                                                )
                                                            }
                                                            {depositWithdrawContent}
                                                            <img className={'positionAbsolute'} src={requireDefault('catCat')}
                                                                 alt=""
                                                                 style={{width: '8rem', top: '-.4rem', right: '.5rem'}}/>
                                                        </div>
                                                    </div>
                                                    <div className={'positionRelative boxSizingBorderBox'} style={{
                                                        height: '52rem',
                                                        padding: '0 2rem 2rem 2rem',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center center',
                                                        backgroundImage: `url(${requireDefault('blackbg2')})`,
                                                    }}>
                                                        <div
                                                            className={'flexColumn justifyContentCenter childrenSpacingTopPhone'}
                                                            style={{transform: 'translateY(3rem)'}}
                                                        >
                                                            <div className={'flexCenter'}>
                                                                <img className={'borderRadius999'} src={currentCoinLogo} alt=""
                                                                     style={square('3.5rem')}/>
                                                            </div>
                                                            <div className={'flex childrenFlexGrow1 childrenSpacingLeftPhone'}>
                                                                {
                                                                    launchCoinData.map(v => <div
                                                                        style={{width: `${checkData([v, 2], 40)}%`}}
                                                                        className={'flexColumnCenter childrenSpacingTopSmallPhone'}>
                                                                        <div
                                                                            style={{
                                                                                color: 'rgba(255,255,255,.6)',
                                                                                fontSize: '.8rem'
                                                                            }}>{v[0]}
                                                                        </div>
                                                                        <div style={{fontSize: '.8rem'}}
                                                                             className={'colorWhite saf1'}>{v[1]}</div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                            <div
                                                                className={'flexCenter childrenFlexGrow1 childrenSpacingLeftPhone'}>
                                                                {[
                                                                    ['telegram1', currentCoinTG, 'Telegram'],
                                                                    ['twitter2', currentCoinX, 'Twitter'],
                                                                ].map(v => <div onClick={() => windowOpen(v[1])}
                                                                                style={{border: 'solid .06rem rgba(255,255,255,.4)'}}
                                                                                className={'flexCenter childrenSpacingLeftPhone paddingSmallPhone borderRadiusPhone cursorPointer'}>
                                                                    <img src={requireDefault(v[0])} alt=""
                                                                         style={{width: '2rem'}}/>
                                                                    <div
                                                                        className={'colorWhite smallFontSizePhone'}>{v[2]}</div>
                                                                </div>)}
                                                            </div>
                                                            {contractAddressContent}
                                                            <div className={'saf activeColor'}
                                                                 style={{fontSize: '3rem', lineHeight: .8}}>
                                                                NO-LOSE LAUNCH
                                                            </div>
                                                            <div className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                                {
                                                                    ['NO-LOSE', 'FAIR', 'TRUSTWORTHY'].map(v => <div
                                                                        className={'borderRadius999 colorWhite saf1 flexCenter borderActiveColor'}
                                                                        style={{
                                                                            fontSize: '.7rem',
                                                                            borderWidth: '.03rem',
                                                                            padding: '0 .7rem',
                                                                            height: '1.7rem',
                                                                        }}>
                                                                        {v}
                                                                    </div>)
                                                                }
                                                            </div>
                                                            <div className={'childrenSpacingTopPhone borderRadiusPhone'}
                                                                 style={{
                                                                     padding: '1rem',
                                                                     border: 'solid .03rem rgba(255, 185, 194, .4)'
                                                                 }}>
                                                                <div className={'saf activeColor'}>
                                                                    First-of-its-kind protocol
                                                                </div>
                                                                <div className={'saf2 activeColor'}
                                                                     style={{fontSize: '.65rem'}}
                                                                >
                                                                    This protocol guarantees that:
                                                                </div>
                                                                {
                                                                    [
                                                                        'Exchange price will not fall below your initial investment',
                                                                        'Users will never experience rug pulls',
                                                                        'All chains are capable of launching simultaneously'
                                                                    ].map(v => <div
                                                                        className={'flex alignItemsCenter childrenSpacingLeftSmallPhone'}>
                                                                        <div className={'borderRadius999 backgroundActiveColor'}
                                                                             style={square('.3rem')}/>
                                                                        <div className={'colorWhite'}
                                                                             style={{fontSize: '.65rem'}}>{v}</div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        showLaunchConfirm &&
                                                        <div className={'positionFixed flexColumnCenter'}
                                                             style={{
                                                                 top: 0,
                                                                 left: 0,
                                                                 right: 0,
                                                                 bottom: 0,
                                                                 zIndex: 1000,
                                                                 background: 'rgba(0,0,0,.56)',
                                                             }}>
                                                            <div
                                                                className={'childrenSpacingTopPhone boxSizingBorderBox borderRadiusPhone paddingPhone'}
                                                                style={{
                                                                    width: '25rem',
                                                                    border: 'solid .15rem white',
                                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                                }}>
                                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div className={'saf'} style={{fontSize: '1.5rem'}}>
                                                                        Confirm
                                                                    </div>
                                                                    <img onClick={() => toggleShowLaunchConfirm()}
                                                                         className={'cursorPointer'}
                                                                         src={requireDefault('close')}
                                                                         alt="" style={{width: '1.5rem'}}/>
                                                                </div>
                                                                <div
                                                                    className={'backgroundWhite paddingPhone childrenSpacingTopPhone borderRadiusPhone'}>
                                                                    {
                                                                        launchData.map((v, i) => [
                                                                            ...ternaryArray(
                                                                                i === launchDataLength,
                                                                                [<div
                                                                                    style={{border: 'solid .06rem #D8D8D8'}}/>]
                                                                            ),
                                                                            <div
                                                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                                                {v.map(v => <div
                                                                                    style={{fontSize: '.9rem'}}>{v}</div>)}
                                                                            </div>])
                                                                    }
                                                                    {gasIsHigh(isWithdraw ? 'claim' : 'deposit', estimatedInternetFees)}
                                                                    {messagesInclude}
                                                                </div>
                                                                {launchCloudflareHuman}
                                                                <div
                                                                    className={'flex alignItemsCenter childrenFlexGrow1 childrenSpacingLeftPhone'}>
                                                                    {
                                                                        [
                                                                            ...ternaryArray(showCancel, [['CANCEL', () => toggleShowLaunchConfirm()]]),
                                                                            ['CONFIRM', () => isNewMemeNoUTKOrPUPPET ? buySellConfirm(toggleShowLaunchConfirm) : depositClaim()]
                                                                        ].map(v => <div
                                                                            onClick={v[1]}
                                                                            className={'borderRadius999 backgroundBlack saf flexCenter cursorPointer activeColor'}
                                                                            style={{padding: '1rem 0', fontSize: '1.5rem'}}>
                                                                            {v[0]}
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                            : <div>
                                                {pageTop}
                                                <div
                                                    className={'positionRelative flexColumnCenter justifyContentSpaceBetween boxSizingBorderBox'}
                                                    style={{
                                                        minHeight: '100vh',
                                                        paddingTop: '2rem',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center center',
                                                        backgroundImage: `url(${requireDefault('launch-bg')})`,
                                                    }}>
                                                    {/*<div className={'positionAbsolute saf activeColor'}*/}
                                                    {/*     style={{*/}
                                                    {/*         opacity: .5,*/}
                                                    {/*         top: '6rem',*/}
                                                    {/*         left: '-3.6rem',*/}
                                                    {/*         fontSize: '3.1rem',*/}
                                                    {/*         transform: 'rotate(90deg)'*/}
                                                    {/*     }}>*/}
                                                    {/*    LAUNCH*/}
                                                    {/*</div>*/}
                                                    <div/>
                                                    <div
                                                        className={'flex width100 boxSizingBorderBox justifyContentSpaceBetween'}
                                                        style={{padding: '0 2rem 0 .3rem', alignItems: 'flex-start'}}>
                                                        <div className={'flex childrenSpacingLeftBig'}
                                                             style={{width: '50%', paddingBottom: '.7rem'}}
                                                        >
                                                            <div
                                                                className={'childrenSpacingTopBig paddingTopBottomBig flexColumn'}
                                                                style={{
                                                                    height: '14.5rem',
                                                                    background: '#FFD7D8',
                                                                    borderRadius: '.5rem',
                                                                    border: 'solid .05rem white',
                                                                    boxShadow: '0 0 .5rem #FFB9C2',
                                                                }}>
                                                                <div className={'paddingLeftRightBig childrenSpacingTopBig'}>
                                                                    {
                                                                        [
                                                                            <img className={'width100 height100 cursorPointer'}
                                                                                 onClick={() => toggleShowCreateMeme(true)}
                                                                                 src={requireDefault('launchCreate')} alt=""/>,
                                                                            <div
                                                                                className={'flexCenter childrenSpacingLeftSmall height100'}
                                                                                style={{borderBottom: 'solid .03rem rgba(0,0,0,.5)'}}
                                                                            >
                                                                                <img style={{height: '50%'}}
                                                                                     onClick={() => searchListedMeme()}
                                                                                     className={'cursorPointer'}
                                                                                     src={requireDefault('search')} alt=""/>
                                                                                <input style={{height: '50%'}}
                                                                                       className={'saf flexGrow1'}
                                                                                       type="text" name="" id=""
                                                                                       value={searchSymbol}
                                                                                       onChange={setSearchSymbol}
                                                                                       onKeyUp={e => searchSymbolOnKeyUp(e, searchListedMeme)}
                                                                                />
                                                                                {searchSymbolIsNotNull &&
                                                                                    <img style={{height: '50%'}}
                                                                                         className={'cursorPointer'}
                                                                                         onClick={() => {
                                                                                             setDataSearchSymbolEmpty()
                                                                                             searchListedMeme(true)
                                                                                         }}
                                                                                         src={requireDefault('searchDisabled')}
                                                                                         alt=""/>}
                                                                            </div>,
                                                                        ].map(v => <div
                                                                            style={{width: '2rem', height: '.7rem'}}>{v}</div>)
                                                                    }
                                                                </div>
                                                                <div style={{width: '2.5rem', overflowX: 'hidden'}}
                                                                     ref={coinsScroll}
                                                                     onScroll={() => coinsScrollFunc()}
                                                                     className={'paddingLeftRightBig paddingTop flexGrow1Column alignItemsCenter childrenSpacingTopBig boxSizingBorderBox overflowYScroll scrollbarColor'}>
                                                                    {
                                                                        coins.map((v, i) => {
                                                                            const isCurrent = currentCoinName === v.name
                                                                            return !checkDataString(v, 'symbol').includes('LIKTEST') &&
                                                                                <div
                                                                                    onClick={() => setCoinIndex(i)}
                                                                                    style={{
                                                                                        width: '2rem',
                                                                                        height: '.7rem',
                                                                                        ...ternaryObject(!isCurrent, {background: 'rgba(255,255,255,.4)'})
                                                                                    }}
                                                                                    className={'borderRadius999 zIndex1 overflowVisible flex alignItemsCenter childrenSpacingLeft paddingLeft boxSizingBorderBox cursorPointer positionRelative'}>
                                                                                    {!v.launched && <div style={{
                                                                                        top: '-.15rem',
                                                                                        left: 0,
                                                                                        borderWidth: '.03rem',
                                                                                        padding: '.03rem .1rem',
                                                                                        fontSize: '.13rem'
                                                                                    }}
                                                                                                         className={`positionAbsolute smallerFontSize borderRadius999 backgroundBlack flexCenter activeColor borderActiveColor`}>
                                                                                        Launching
                                                                                    </div>}
                                                                                    <img src={v.logo} alt=""
                                                                                         className={'borderRadius999'}
                                                                                         style={{
                                                                                             ...square('.34rem'),
                                                                                             marginLeft: 0
                                                                                         }}/>
                                                                                    <div
                                                                                        className={'saf smallerFontSize'}>{v.name}</div>
                                                                                    {
                                                                                        isCurrent &&
                                                                                        <img
                                                                                            className={'positionAbsolute height100 zIndex-1'}
                                                                                            src={requireDefault(('launchSelected'))}
                                                                                            style={{
                                                                                                top: '.01rem',
                                                                                                left: '-.2rem'
                                                                                            }}/>
                                                                                    }
                                                                                </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={'childrenSpacingTopBig boxSizingBorderBox positionRelative flexGrow1'}
                                                                style={{
                                                                    borderRadius: '.5rem',
                                                                    border: 'solid .05rem white',
                                                                    boxShadow: '0 0 .5rem #FFB9C2',
                                                                    padding: '1rem .7rem .7rem .7rem',
                                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                                }}>
                                                                {
                                                                    [
                                                                        ['OVERVIEW',
                                                                            [
                                                                                ...depositData.map(dataListWeb),
                                                                                schedule,
                                                                                launched
                                                                                    ?
                                                                                    <img src={requireDefault('launchedBanner')}
                                                                                         alt=""
                                                                                         className={'width100 displayBlock'}/>
                                                                                    : (
                                                                                        isNewMemeNoUTKOrPUPPET
                                                                                            ? <div className={'positionRelative'}>
                                                                                                <img
                                                                                                    src={requireDefault('launchBanner')}
                                                                                                    alt=""
                                                                                                    className={'width100 displayBlock'}/>
                                                                                                <div
                                                                                                    className={'positionAbsolute boxSizingBorderBox'}
                                                                                                    style={{
                                                                                                        top: 0,
                                                                                                        bottom: 0,
                                                                                                        left: 0,
                                                                                                        right: 0,
                                                                                                        padding: '.5rem .8rem 0 .8rem'
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className={'childrenSpacingTop'}>
                                                                                                        <div
                                                                                                            className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                                                            <img style={square('.6rem')}
                                                                                                                 src={currentCoinLogo}
                                                                                                                 className={'borderRadius999'}
                                                                                                                 alt=""/>
                                                                                                            <div
                                                                                                                className={'saf1'}>{currentCoinName}</div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className={'textOverflowEllipsis'}>{currentCoinDesc}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            : (currentCoinNameIsNotNull ? <div
                                                                                                className={'backgroundWhite paddingBigger flexCenter childrenSpacingLeftBig'}
                                                                                                style={{
                                                                                                    background: 'rgba(247, 247, 247, 1)',
                                                                                                    borderRadius: '.3rem'
                                                                                                }}
                                                                                            >
                                                                                                {launchCountdown.map(countdownContent)}
                                                                                            </div> : <div/>)
                                                                                    ),
                                                                                <div
                                                                                    className={'flex alignItemsCenter childrenSpacingRightSmall flexWrap'}>
                                                                                    {
                                                                                        (() => {
                                                                                            const arr = checkDataArray(tokenomicsTextList(currentCoinQuota, currentCoinPresale), currentCoinTokenomics), {length} = arr
                                                                                            return arr.map(
                                                                                                (v, i) => tokenomicsShow(v).map(v =>
                                                                                                    <div
                                                                                                        className={'flex alignItemsCenter childrenSpacingRightSmall'}>
                                                                                                        <div
                                                                                                            style={{color: '#FF7788'}}
                                                                                                            className={'saf smallFontSize'}>
                                                                                                            {v[0]}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className={'smallFontSize'}>{v[1]}</div>
                                                                                                        {i < length - 1 ? ',' : ''}
                                                                                                    </div>)
                                                                                            )
                                                                                        })()
                                                                                    }
                                                                                </div>,
                                                                                <div
                                                                                    className={'padding flexCenter childrenSpacingLeft'}
                                                                                    style={{
                                                                                        borderRadius: '.27rem',
                                                                                        background: 'rgba(255, 185, 194, .3)'
                                                                                    }}
                                                                                >
                                                                                    <img src={requireDefault('tips')} alt=""
                                                                                         style={{width: '.4rem'}}/>
                                                                                    {wantTotalDepositText}
                                                                                </div>
                                                                            ]
                                                                        ],
                                                                    ].map((v, i) =>
                                                                        <div
                                                                            className={'backgroundWhite paddingBig childrenSpacingTopBig'}
                                                                            style={{borderRadius: '.4rem'}}>
                                                                            <div
                                                                                className={'flexCenter justifyContentSpaceBetween childrenSpacingLeft'}>
                                                                                <div
                                                                                    className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                                    <div className={'saf'}
                                                                                         style={{fontSize: '.5rem'}}>{v[0]}</div>
                                                                                    {
                                                                                        i === 0 &&
                                                                                        <div
                                                                                            onClick={() => toggleShowTotalSupply(true)}
                                                                                            className={'saf borderRadius999 cursorPointer'}
                                                                                            style={{
                                                                                                marginTop: '.1rem',
                                                                                                padding: '.05rem .13rem',
                                                                                                border: '.03rem solid',
                                                                                                fontSize: '.17rem'
                                                                                            }}>
                                                                                            more info
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{border: '.02rem solid rgba(216, 216, 216, 1)'}}/>
                                                                            {v[1]}
                                                                        </div>
                                                                    )
                                                                }
                                                                {depositWithdrawContent}
                                                                <img className={'positionAbsolute'}
                                                                     src={requireDefault('catCat')}
                                                                     alt=""
                                                                     style={{width: '3.5rem', top: 0, right: '.5rem'}}/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={'flexColumn childrenSpacingTopBigger paddingBottom'}
                                                            style={{width: '43%'}}>
                                                            <div
                                                                className={'flexCenter justifyContentSpaceBetween childrenSpacingLeft'}>
                                                                <img className={'borderRadius999'} src={currentCoinLogo} alt=""
                                                                     style={square('2rem')}/>
                                                                <div className={'flex flexGrow1 flexWrap'}>
                                                                    {
                                                                        launchCoinData.map(v => <div
                                                                            style={{width: `${checkData([v, 2], 46)}%`}}
                                                                            className={'paddingLeftBig paddingBottomBig childrenSpacingTop'}>
                                                                            <div
                                                                                style={{color: 'rgba(255,255,255,.6)'}}>{v[0]}:
                                                                            </div>
                                                                            <div
                                                                                className={'colorWhite saf1 bigFontSize'}>{v[1]}</div>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={'flexCenter childrenSpacingLeftBiggerPhone'}>
                                                                {[
                                                                    ['telegram1', currentCoinTG, 'Telegram'],
                                                                    ['twitter2', currentCoinX, 'Twitter'],
                                                                ].map(v => <div onClick={() => windowOpen(v[1])}
                                                                                className={'flexCenter childrenSpacingLeft cursorPointer'}>
                                                                    <img src={requireDefault(v[0])}
                                                                         alt=""
                                                                         style={{width: '1rem'}}/>
                                                                    <div className={'colorWhite'}>{v[2]}</div>
                                                                </div>)}
                                                            </div>
                                                            {contractAddressContent}
                                                            <div className={'saf activeColor'} style={{fontSize: '1.35rem'}}>
                                                                NO-LOSE LAUNCH
                                                            </div>
                                                            <div
                                                                className={'flex alignItemsCenter childrenSpacingLeftSmallPhone'}>
                                                                {
                                                                    ['NO-LOSE', 'FAIR', 'TRUSTWORTHY'].map(v => <div
                                                                        className={'borderRadius999 bigFontSize colorWhite saf1 flexCenter borderActiveColor'}
                                                                        style={{
                                                                            borderWidth: '.03rem',
                                                                            padding: '0 .3rem',
                                                                            height: '.7rem',
                                                                        }}>
                                                                        {v}
                                                                    </div>)
                                                                }
                                                            </div>
                                                            <div className={'childrenSpacingTopBig'} style={{
                                                                borderRadius: '.5rem',
                                                                padding: '1rem .5rem',
                                                                border: 'solid .03rem rgba(255, 185, 194, .4)'
                                                            }}>
                                                                <div className={'saf activeColor'} style={{fontSize: '.5rem'}}>
                                                                    First-of-its-kind protocol
                                                                </div>
                                                                <div className={'saf2 activeColor'}>
                                                                    This protocol guarantees that:
                                                                </div>
                                                                {
                                                                    [
                                                                        'Exchange price will not fall below your initial investment',
                                                                        'Users will never experience rug pulls',
                                                                        'All chains are capable of launching simultaneously'
                                                                    ].map(v => <div
                                                                        className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                        <div className={'borderRadius999 backgroundActiveColor'}
                                                                             style={square('.15rem')}/>
                                                                        <div className={'colorWhite'}>{v}</div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'zIndex1 width100'}>{pageFoot}</div>
                                                    <img className={'positionAbsolute overflowHidden'}
                                                         src={requireDefault('catCat3')}
                                                         alt=""
                                                         style={{width: '4rem', bottom: 0, right: 0}}
                                                    />
                                                    <img className={'positionAbsolute'} src={requireDefault('waterDroplet')}
                                                         alt=""
                                                         style={{width: '.5rem', bottom: '2.5rem', right: '14.3rem'}}
                                                    />
                                                </div>
                                                {
                                                    showLaunchConfirm &&
                                                    <div className={'positionFixed flexColumnCenter'}
                                                         style={{
                                                             top: 0,
                                                             left: 0,
                                                             right: 0,
                                                             bottom: 0,
                                                             zIndex: 1000,
                                                             background: 'rgba(0,0,0,.56)',
                                                         }}>
                                                        <div className={'childrenSpacingTopBigger boxSizingBorderBox'}
                                                             style={{
                                                                 width: '12rem',
                                                                 padding: '.7rem',
                                                                 borderRadius: '.5rem',
                                                                 border: 'solid .05rem white',
                                                                 background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                             }}>
                                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                                <div className={'saf'} style={{fontSize: '.5rem'}}>Confirm
                                                                </div>
                                                                <img onClick={() => toggleShowLaunchConfirm()}
                                                                     className={'cursorPointer'}
                                                                     src={requireDefault('close')}
                                                                     alt="" style={{width: '.5rem'}}/>
                                                            </div>
                                                            <div style={{borderRadius: '.4rem'}}
                                                                 className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBig'}
                                                            >
                                                                {
                                                                    launchData.map((v, i) => [
                                                                        ...ternaryArray(
                                                                            i === launchDataLength,
                                                                            [<div style={{border: 'solid .02rem #D8D8D8'}}/>]
                                                                        ),
                                                                        <div
                                                                            className={'flexCenter justifyContentSpaceBetween'}>
                                                                            {v.map(v => <div
                                                                                style={{fontSize: '.28rem'}}>{v}</div>)}
                                                                        </div>])
                                                                }
                                                                {gasIsHigh(isWithdraw ? 'claim' : 'deposit', estimatedInternetFees)}
                                                                {messagesInclude}
                                                            </div>
                                                            {launchCloudflareHuman}
                                                            <div
                                                                className={'flex alignItemsCenter childrenFlexGrow1 childrenSpacingLeft'}>
                                                                {
                                                                    [
                                                                        ...ternaryArray(showCancel, [['CANCEL', () => toggleShowLaunchConfirm()]]),
                                                                        ['CONFIRM', () => isNewMemeNoUTKOrPUPPET ? buySellConfirm(toggleShowLaunchConfirm) : depositClaim()]
                                                                    ].map(v => <div
                                                                        onClick={v[1]}
                                                                        className={'borderRadius999 backgroundBlack saf flexCenter biggerFontSize cursorPointer activeColor'}
                                                                        style={{padding: '.25rem 0', fontSize: '.5rem'}}>
                                                                        {v[0]}
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                    }
                                    {createMemeContent}
                                    {voteCreateContent}
                                    {totalSupplyContent}
                                    {taskContent}
                                    {notYet}
                                    {loading}
                                    {previousTips}
                                    {drip}
                                </div>
                            })()
                        }
                    ),
                    ...ternaryObject(
                        swapPrdOpen,
                        {
                            'swap/*': (() => {
                                const showSwapConfirm = checkDataBool(data, 'showSwapConfirm'),
                                    // maxAmount = checkData([data, isSwapBuy ? 'maxETHAmount' : 'maxTokenAmount']),
                                    // maxTokenBalance = checkData([data, 'maxTokenBalance']),
                                    // maxTokenBalanceIsNotNull = isNotNull(maxTokenBalance),
                                    // isMaxTokenBalance = !isNotNull(maxAmount) || (maxTokenBalanceIsNotNull && maxTokenBalance < maxAmount),
                                    // totalSwapAmount = checkData([data, isSwapBuy ? 'totalSwapETHAmount' : 'totalSwapTokenAmount']),
                                    // toggleShowSwapConfirm = showSwapConfirm => false
                                    toggleIsSwapBuy = isSwapBuy => setData({isSwapBuy}),
                                    bSCoins = [
                                        [ethBNB, requireDefault(ethBNB), ethBalance],
                                        [currentCoinName, currentCoinLogo, tokenBalance, true],
                                    ],
                                    buySellCoins = isSwapBuy ? [...bSCoins] : [...bSCoins].reverse(),
                                    exchangeRate = checkDataString(data, 'exchangeRate'),
                                    // maxFunc = newAmount => isSwapBuy
                                    //     ? getDeadline().then(deadline =>
                                    //         setMaxETH(
                                    //             buyTokenFuncName,
                                    //             'buy',
                                    //             () => [address, ...ternaryArray(
                                    //                                 !isV7,
                                    //                                 [deadline],
                                    //                             ),],
                                    //         )
                                    //     )
                                    //     : setNewAmount(newAmount),
                                    maxFunc = balance => isSwapBuy
                                        ? setBuyMaxETH()
                                        : setNewAmount(balance),
                                    eventTypes = currentCoinLongSymbol
                                        ? [
                                            5,
                                            5,
                                            16.5,
                                            14.5,
                                            14.5,
                                            14.5,
                                            13,
                                            14.5,
                                            2,
                                        ]
                                        : [
                                            5,
                                            5,
                                            14.5,
                                            12.5,
                                            12.5,
                                            12.5,
                                            10,
                                            12.5,
                                            13,
                                            2,
                                        ], eventColors = ['rgba(255,255,255,.5)', '#22C55F', '#FF6064'],
                                    eventNames = [[['Chain', 'Type', currentCoinUint, 'ETH',
                                        ...currentCoinLongSymbol
                                            ? ['Price', `${currentCoinLongSymbol} Price`]
                                            : ['Price(USDT)', 'Price(ETH)'],
                                        'Date', 'Native',
                                        ...ternaryArray(
                                            !currentCoinLongSymbol,
                                            ['Earn']
                                        ),
                                        'Tx'], 0]],
                                    itemFunc = (v, fontSize) =>
                                        <div
                                            className={'childrenSpacingLeft flexCenter justifyContentSpaceBetween'}>
                                            {
                                                v[0].map((v1, i1) => <div
                                                    className={`saf1 flex alignItemsCenter textOverflowEllipsis ${[0, 1].includes(i1) ? 'justifyContentCenter' : 'justifyContentFlexEnd'}`}
                                                    style={{
                                                        fontSize: `${fontSize}rem`,
                                                        color: eventColors[v[1]],
                                                        width: `${eventTypes[i1]}%`,
                                                    }}>{v1}</div>)
                                            }
                                        </div>,
                                    itemFuncPhoneSize = .5,
                                    itemFuncWebSize = .22,
                                    itemFuncPhone = v => itemFunc(v, itemFuncPhoneSize),
                                    itemFuncWeb = v => itemFunc(v, itemFuncWebSize),
                                    buySellEventList = (iconSize, func) => buySellEvent.map(v => {
                                            const {price} = v
                                            return [[
                                                <img
                                                    src={requireDefault(`chainIcons/${v.chainId}`)}
                                                    alt=""
                                                    style={{width: `${iconSize * 1.3}rem`}}/>,
                                                v.swapType, v.tokenAmount, getFullNum(v.ethAmount),
                                                ...currentCoinLongSymbol
                                                    ? [
                                                        `${v.shortPrice} ETH`,
                                                        `${v.longPrice} ETH`,
                                                    ]
                                                    : [
                                                        ethUSDTIsNotNull ? (price == 0 ? 0 : getFullNum(npTimes(price, ethUSDT), true)) : '',
                                                        getFullNum18(price),
                                                    ],
                                                v.date, v.native,
                                                ...ternaryArray(
                                                    !currentCoinLongSymbol,
                                                    [v.earnEth]
                                                ),
                                                <img
                                                    onClick={() => windowOpen(`${masterContractBlockExplorersTX}${v.transactionHash}`)}
                                                    className={'cursorPointer'}
                                                    src={requireDefault('Group 427319817-2')}
                                                    alt=""
                                                    style={{width: `${iconSize}rem`}}/>
                                            ], v.isBuy ? 1 : 2]
                                        }
                                    ).map(func),
                                    alreadyOnClick = () => windowOpen('https://simplifyerc.com/'),
                                    coinLogo = isSwapBuy ? requireDefault(ethBNB) : currentCoinLogo,
                                    coinLogo1 = isSwapBuy ? currentCoinLogo : requireDefault(ethBNB),
                                    coinName = isSwapBuy ? ethBNB : currentCoinName,
                                    coinName1 = isSwapBuy ? currentCoinName : ethBNB,
                                    {clientWidth} = document.body,
                                    chartMultiple = isPhoneClientWidth ? 3 : 1,
                                    chartSmallMultiple = isPhoneClientWidth ? 2 : 1,
                                    oneETHPrice = isSwapBuy ? `1 ${ethBNB} = ${exchangeRate} ${currentCoinName}` : `1 ${currentCoinName} = ${exchangeRate} ${ethBNB}`,
                                    toggleTimeSlotIndex = timeSlotIndex => setData({timeSlotIndex}),
                                    chartData = checkDataArray(data, 'chartData'),
                                    chartDataIndex = (() => {
                                        const lastIndex = chartData.length - 1
                                        return checkData([data, 'chartDataIndex'], lastIndex < 0 ? 0 : lastIndex)
                                    })(),
                                    selectChartData = checkDataObject(chartData, chartDataIndex),
                                    selectChartPrice = checkData0(selectChartData, 'price'),
                                    chart = <Chart
                                        data={chartData.map(v => ({
                                            ...v,
                                            date: (() => {
                                                const date = new Date(npTimes(Math.floor(Number(v.date) / 3600) * 3600, 1000))
                                                return timeSlotIndex !== 0
                                                    ? date.toLocaleDateString('en-US', {month: 'short', day: 'numeric'})
                                                    : ['getHours', 'getMinutes'].map(v => {
                                                        v = date[v]()
                                                        return `${v < 10 ? 0 : ''}${v}`
                                                    }).join(':')
                                            })()
                                        }))}
                                        clientWidth={clientWidth}
                                        chartSmallMultiple={chartSmallMultiple}
                                        chartMultiple={chartMultiple} setData={setData}
                                    />,
                                    formCharts = [
                                        [
                                            'Line Chart',
                                            '#131722',
                                            (() => {
                                                const chartBuySellCoinNames = [['ETH', requireDefault('ETH'), bSCoins[0][2]], bSCoins[1]],
                                                    chartBuySellCoinNamesText = chartBuySellCoinNames.map(v => v[0]).join('/')
                                                return [
                                                    <div className={'flexCenter justifyContentSpaceBetween'}>
                                                        <div
                                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                            {chartBuySellCoinNames.map((v, i) => <div
                                                                className={'positionRelative borderRadius999'}
                                                                style={square(`${npTimes(.5, chartMultiple)}rem`)}>
                                                                <img
                                                                    className={'width100 height100'}
                                                                    src={v[1]}
                                                                    alt=""/>
                                                                {i !== 0 && currentCoinLongSymbol && <img
                                                                    className={'width100 height100 positionAbsolute'}
                                                                    style={{top: 0, left: 0}}
                                                                    src={requireDefault('shortToken')}
                                                                    alt=""/>}
                                                            </div>)}
                                                            <div className={'colorWhite'}
                                                                 style={{fontSize: `${npTimes(.3, chartMultiple)}rem`}}>{chartBuySellCoinNamesText}</div>
                                                        </div>
                                                        <div
                                                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                            <div
                                                                className={'borderRadius999 backgroundWhite flexCenter childrenFlexGrow1'}
                                                                style={{
                                                                    overflow: 'visible',
                                                                    width: `${npTimes(4, chartSmallMultiple)}rem`,
                                                                    height: `${npTimes(.6, chartSmallMultiple)}rem`
                                                                }}
                                                            >
                                                                {
                                                                    timeSlots.map((v, i) => {
                                                                        const isCurrent = timeSlotIndex === i
                                                                        return <div
                                                                            style={{
                                                                                fontSize: `${npTimes(.25, chartSmallMultiple)}rem`,
                                                                                ...ternaryObject(
                                                                                    isCurrent,
                                                                                    {boxShadow: '0 0 0 .01rem black'}
                                                                                )
                                                                            }}
                                                                            onClick={() => toggleTimeSlotIndex(i)}
                                                                            className={`flexCenter height100 borderRadius999 saf1 activeColor cursorPointer 
                                                                                     ${isCurrent ? 'backgroundBlack' : ''}`}>
                                                                            {v[0]}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            {
                                                                !isPhoneClientWidth &&
                                                                <div style={{padding: '.15rem', background: '#FFF9F0'}}
                                                                     onClick={() => setChart()}
                                                                     className={'flexCenter borderRadius999 cursorPointer'}>
                                                                    <img src={requireDefault('reload')} alt=""
                                                                         style={{width: '.35rem'}}/>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>,
                                                    ...isNotNullOrEmpty(chartData)
                                                        ? [
                                                            <div
                                                                className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                                <div className={'saf colorWhite'} style={{
                                                                    fontSize: `${npTimes(.6, chartSmallMultiple)}rem`,
                                                                    lineHeight: .9
                                                                }}>
                                                                    {getFullNum18(selectChartPrice)}
                                                                </div>
                                                                <div className={'saf1 colorWhite'}
                                                                     style={{fontSize: `${npTimes(.4, chartSmallMultiple)}rem`}}>
                                                                    {chartBuySellCoinNamesText}
                                                                </div>
                                                                {(() => {
                                                                    const minEthPrice = selectChartPrice === 0 ? 0 : checkData0(data, 'minEthPrice'),
                                                                        increaseInPrice = chartDataIndex === 0
                                                                            ? 0
                                                                            : numberToFixed18(npMinus(selectChartPrice, minEthPrice)),
                                                                        isRise = increaseInPrice >= 0,
                                                                        positive = isRise ? '+' : ''
                                                                    return <div className={'saf'} style={{
                                                                        color: isRise ? 'rgb(47,201,154)' : '#FF3C5B',
                                                                        fontSize: `${npTimes(.4, chartSmallMultiple)}rem`,
                                                                    }}>
                                                                        {`${positive}${getFullNum18(increaseInPrice)}(${positive}${percentageToFixed(increaseInPrice, minEthPrice)})`}
                                                                    </div>
                                                                })()}
                                                            </div>,
                                                            <div className={'colorWhite'}
                                                                 style={{fontSize: `${npTimes(.22, chartMultiple)}rem`}}>
                                                                {eventDate(selectChartData.date, ', ', true, true)}
                                                            </div>,
                                                            chart
                                                        ]
                                                        : [
                                                            <div className={'flexCenter width100 height100'}>
                                                                <img src={requireDefault('loading')} alt=""
                                                                     style={{width: `${isPhoneClientWidth ? 5 : 2.5}rem`}}/>
                                                            </div>
                                                        ]
                                                ]
                                            })()
                                        ],
                                        [
                                            'form',
                                            '#131722',
                                            (() => {
                                                const showData = currentCoinLongSymbol && isMyTrade,
                                                    shortCurrentPriceNumber = numberETH(shortCurrentPrice),
                                                    totalCost = checkData0(data, 'totalCost'),
                                                    loadMore = <div
                                                        className={`flexCenter ${showData ? 'justifyContentSpaceBetween' : ''}`}>
                                                        {showData && <div
                                                            className={isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'childrenSpacingTopSmall'}>
                                                            {
                                                                [
                                                                    [[`${currentCoinName}:`], [tokenBalance]],
                                                                    [[`${currentCoinName} Current price:`], [`${shortCurrentPriceNumber} ETH`]],
                                                                    [['Total cost:'], [`${getFullNum(totalCost)} ETH`]],
                                                                    [['Earn amount:'],
                                                                        (() => {
                                                                            let earnAmount = formatEther(checkData0(data, 'tokenBalanceWei')) * formatEther(shortCurrentPrice) - totalCost
                                                                            const isNegative = earnAmount < 0
                                                                            earnAmount = numberPointFixed(formatEther(parseEtherWei(earnAmount * (isNegative ? -1 : 1))) * (isNegative ? -1 : 1))
                                                                            const earnAmountGT0 = earnAmount >= 0
                                                                            return [`${earnAmountGT0 ? '+' : ''}${earnAmount} ETH`, earnAmountGT0 ? '#22C55F' : '#FF6064']
                                                                        })()
                                                                    ],
                                                                ].map(v => <div
                                                                    className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                    {v.map(v => <div
                                                                        className={`saf1 colorWhite ${isPhoneClientWidth ? '' : 'smallFontSize'}`}
                                                                        style={{
                                                                            color: v[1],
                                                                            ...ternaryObject(
                                                                                isPhoneClientWidth,
                                                                                {fontSize: '.5rem'}
                                                                            )
                                                                        }}>{v[0]}</div>)}
                                                                </div>)
                                                            }
                                                        </div>}
                                                        <div
                                                            className={`${showData ? '' : 'width100'} flexCenter`}>
                                                            <div onClick={addSwapPage}
                                                                 style={{
                                                                     background: '#FFF9F0',
                                                                     fontSize: `${isPhoneClientWidth ? itemFuncPhoneSize : itemFuncWebSize}rem`,
                                                                     padding: `${npDivide(isPhoneClientWidth ? itemFuncPhoneSize : itemFuncWebSize, 2)}rem ${npTimes(isPhoneClientWidth ? itemFuncPhoneSize : itemFuncWebSize, 1.1)}rem`,
                                                                 }}
                                                                 className={'borderRadius999 cursorPointer'}
                                                            >
                                                                LOAD MORE
                                                            </div>
                                                        </div>
                                                    </div>
                                                return isPhoneClientWidth
                                                    ? [
                                                        <div
                                                            className={'overflowXScroll scrollbarColor flexGrow1Column width100'}>
                                                            <div style={{width: '170%'}}
                                                                 className={'childrenSpacingTopBig height100 flexColumn'}>
                                                                {eventNames.map(itemFuncPhone)}
                                                                <div
                                                                    className={'childrenSpacingTopBig flexGrow1Column overflowYScroll scrollbarColor'}>
                                                                    {buySellEventList(itemFuncPhoneSize, itemFuncPhone)}
                                                                </div>
                                                            </div>
                                                        </div>,
                                                        loadMore
                                                    ]
                                                    : [
                                                        <div className={'flexCenter width100 justifyContentFlexEnd'}>
                                                            <div style={{padding: '.15rem', background: '#FFF9F0'}}
                                                                 onClick={() => reloadForm()}
                                                                 className={'flexCenter borderRadius999 cursorPointer'}>
                                                                <img src={requireDefault('reload')} alt=""
                                                                     style={{width: '.35rem'}}/>
                                                            </div>
                                                        </div>,
                                                        <div
                                                            className={'overflowXScroll scrollbarColor flexGrow1Column width100'}>
                                                            <div style={{width: '160%'}}
                                                                 className={'childrenSpacingTop height100 flexColumn'}>
                                                                {eventNames.map(itemFuncWeb)}
                                                                <div
                                                                    className={'childrenSpacingTop flexGrow1Column overflowYScroll scrollbarColor'}>
                                                                    {buySellEventList(itemFuncWebSize, itemFuncWeb)}
                                                                </div>
                                                            </div>
                                                        </div>,
                                                        loadMore
                                                    ]
                                            })(),
                                            <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                {
                                                    ['All', ...ternaryArray(address, ['My trade'])].map((v, i) => <div
                                                        className={`saf borderRadius999 cursorPointer ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}
                                                        onClick={() => setData({isMyTrade: i})}
                                                        style={{
                                                            padding: isPhoneClientWidth ? '.3rem .9rem' : '.1rem .3rem',
                                                            border: `solid ${isPhoneClientWidth ? .15 : .05}rem rgba(255,255,255,.2)`,
                                                            ...isMyTrade === i
                                                                ? {background: '#FFB9C2'}
                                                                : {
                                                                    background: '#131722',
                                                                    color: 'rgba(255,255,255,.5)'
                                                                }
                                                        }}>{v}</div>)
                                                }
                                            </div>,
                                        ],
                                    ],
                                    toggleFormChartIndex = formChartIndex => setData({formChartIndex}),
                                    formChartItem = formCharts[formChartIndex],
                                    formChartColor = formChartItem[1],
                                    formChart = formChartItem[2],
                                    omniSupply = checkData0(data, 'omniSupply'),
                                    reloadFormChart = () => {
                                        if (isShowChart) {
                                            setChart()
                                        } else {
                                            reloadForm()
                                        }
                                    },
                                    showEstimated = checkDataBool(data, 'showEstimated'),
                                    toggleShowEstimated = showEstimated => setData({showEstimated}),
                                    shortMasterLaunched = checkDataBool(data, 'shortMasterLaunched'),
                                    isOver = checkDataBool(data, 'liquidated'),
                                    canSwap = currentCoinLongSymbol
                                        ? !isOver
                                        : (!isNewMemeNoGATO || countdownEndsTrue),
                                    isShortOver = currentCoinLongSymbol && isOver,
                                    initialDateData = ['Initial Date', (() => {
                                        const date = new Date(currentCoinInitialDate)
                                        return isNotNull(currentCoinInitialDate) ? [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('.') : ''
                                    })()],
                                    swapCloudflareHuman = buyCheckSignTrue && cloudflareHumanDiv,
                                    cmCap = currentCoinCMCap,
                                    initialPrice = getFullNum(numberToFixed(
                                        npTimes(
                                            checkData0(data, 'initialPrice'),
                                            checkData0(ethUSDT)
                                        )
                                    )),
                                    toggleShortUnStart = shortUnStart => setData({shortUnStart}),
                                    shortStart = checkData0(data, 'shortStart'),
                                    shortUnStartTip = catTip(
                                        toggleShortUnStart,
                                        checkDataBool(data, 'shortUnStart'),
                                        undefined,
                                        `Short selling is not available yet. It will be enabled when ${currentCoinLongSymbol || currentCoinName} Pool Reserves reaches ${shortStart} ETH`,
                                    ),
                                    isShortStart = true,//shortMasterLaunched || Number(currentCoinLongSymbol ? checkData0(data, 'longReserves0') : reserves0) >= Number(shortStart),
                                    isShortStartBuy = shortMasterLaunched || Number(currentCoinLongSymbol ? checkData0(data, 'longReserves0') : reserves0) >= Number(shortStart),
                                    toggleShowSwapConfirmSwap = showSwapConfirm => !isMaxSlippage || (maxSlippageNumber >= maxSlippageMin && maxSlippageNumber <= maxSlippageMax)
                                        ? (
                                            (currentCoinLongSymbol && isSwapBuy && !isShortStartBuy)
                                                ? toggleShortUnStart(true)
                                                : toggleShowSwapConfirm(
                                                    showSwapConfirm,
                                                    showSwapConfirm => setData({
                                                            showSwapConfirm,
                                                            ...ternaryObject(
                                                                isSwapBuy,
                                                                {signTrue: undefined}
                                                            )
                                                        }
                                                    ),
                                                    showSwapConfirm => setData({showSwapConfirm})
                                                )
                                        )
                                        : toast(`Slippage is limited from ${maxSlippageMin}% to ${maxSlippageMax}%`),
                                    longPrice = checkData0(data, 'longPrice'),
                                    initLongPrice = checkData0(data, 'initLongPrice'),
                                    shortSchedule = isSmall => {
                                        let p = isOver ? 100 : (initLongPrice === 0 ? 0 : npTimes(npDivide(npMinus(longPrice, initLongPrice), initLongPrice), 100)).toFixed(2)
                                        p = Number(p) > 100 ? 100 : p
                                        const percentage = `${p}%`
                                        // p1 = 30,
                                        // percentage1 = `${p1}%`
                                        return <div style={{width: isPhoneClientWidth ? '26rem' : '9.5rem'}}
                                                    className={'childrenSpacingTopSmall'}>
                                            <div style={{paddingLeft: percentage}}
                                                 className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'} boxSizingBorderBox`}>
                                                <img
                                                    src={requireDefault(isSmall ? 'shortDownBlack' : 'shortDown')}
                                                    alt="" style={{
                                                    height: isSmall ? (isPhoneClientWidth ? '.8rem' : '.4rem') : (isPhoneClientWidth ? '.5rem' : '.25rem'),
                                                    marginLeft: isPhoneClientWidth ? '-.4rem' : '-.2rem'
                                                }}/>
                                                {p < 100 && <div
                                                    className={`${isSmall ? (isPhoneClientWidth ? 'bigFontSizePhone' : 'biggerFontSize') : (isPhoneClientWidth ? 'smallerFontSizePhone' : '')} ${isSmall ? '' : 'colorWhite'} saf1`}>
                                                    {percentage}
                                                </div>}
                                            </div>
                                            <div className={'positionRelative boxSizingBorderBox'}
                                                 style={{
                                                     height: isPhoneClientWidth ? '1.2rem' : '.4rem',
                                                     background: `rgba(255, 185, 194, ${isSmall ? 1 : .6})`,
                                                     ...ternaryObject(
                                                         isSmall,
                                                         {border: 'solid .05rem'}
                                                     )
                                                 }}
                                            >
                                                <div
                                                    className={'positionAbsolute flex alignItemsCenter height100'}
                                                    style={{
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        width: percentage
                                                    }}
                                                >
                                                    {/*<div*/}
                                                    {/*    style={{width: percentage1}}/>*/}
                                                    <div
                                                        className={'flexGrow1 flex alignItemsCenter height100'}>
                                                        {/*<div style={{*/}
                                                        {/*    height: '110%',*/}
                                                        {/*    width: isPhoneClientWidth ? '.3rem' : '.1rem',*/}
                                                        {/*    background: '#FF7788',*/}
                                                        {/*}}/>*/}
                                                        <div
                                                            style={ternaryObject(!isSmall, {borderColor: 'white'})}
                                                            className={'flexGrow1 height100 backgroundBlack borderSmallBlackLine boxSizingBorderBox'}/>
                                                    </div>
                                                </div>

                                                <div
                                                    className={'positionAbsolute flex alignItemsCenter height100'}
                                                    style={{
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                    }}
                                                >
                                                    <div style={{
                                                        height: '120%',
                                                        background: '#FF7788',
                                                        width: isPhoneClientWidth ? '.3rem' : '.1rem',
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className={'positionRelative flex justifyContentFlexEnd'}>
                                                {/*<div*/}
                                                {/*    style={{paddingLeft: `${p * p1 / 100}%`}}*/}
                                                {/*    className={'flex alignItemsCenter childrenSpacingLeftSmall boxSizingBorderBox'}>*/}
                                                {/*    <div style={{*/}
                                                {/*        marginLeft: isPhoneClientWidth ? '-4.5rem' : '-1.75rem',*/}
                                                {/*        ...ternaryObject(!isSmall, {color: '#FF7788'})*/}
                                                {/*    }}*/}
                                                {/*         className={`${isPhoneClientWidth ? 'smallerFontSizePhone' : ''} saf1`}>*/}
                                                {/*        Short init*/}
                                                {/*    </div>*/}
                                                {/*    <img*/}
                                                {/*        src={requireDefault('shortUp')}*/}
                                                {/*        alt="" style={{*/}
                                                {/*        height: isPhoneClientWidth ? '.5rem' : '.25rem',*/}
                                                {/*    }}/>*/}
                                                {/*</div>*/}
                                                <div
                                                    style={{marginRight: '-.2rem'}}
                                                    className={'flex alignItemsCenter childrenSpacingLeftSmall boxSizingBorderBox'}>
                                                    <div style={ternaryObject(!isSmall, {color: '#FF7788'})}
                                                         className={`${isSmall ? (isPhoneClientWidth ? 'bigFontSizePhone' : 'biggerFontSize') : (isPhoneClientWidth ? 'smallerFontSizePhone' : '')} saf1`}>
                                                        Liquidation threshold
                                                    </div>
                                                    <img
                                                        src={requireDefault('shortUp')}
                                                        alt=""
                                                        style={{height: isSmall ? (isPhoneClientWidth ? '.8rem' : '.4rem') : (isPhoneClientWidth ? '.5rem' : '.25rem')}}/>
                                                </div>
                                            </div>
                                        </div>
                                    },
                                    toShort = () => {
                                        toggleShowLoading(true)
                                        const cCSS = currentCoinLongSymbol ? currentCoinName : currentCoinShortSymbol,
                                            wRShortSymbol = (sS = cCSS) => windowReplace(`/swap/${sS}`)
                                        tokenMemeDetailFunc1(currentCoinLongSymbol ? currentCoinLongSymbol : currentCoinName).then(
                                            v => {
                                                getShortSymbol(undefined, checkDataArray(v, 'shortSymbols'))
                                                    .then(v => wRShortSymbol(checkData([v, 'shortSymbol'], cCSS)))
                                                    .catch(() => wRShortSymbol())
                                            }).catch(() => wRShortSymbol())
                                    },
                                    shortSelect = longShortTrue &&
                                        <div
                                            className={`flex alignItemsCenter cursorPointer ${isPhoneClientWidth ? 'width100' : ''}`}
                                            style={{
                                                height: isPhoneClientWidth ? '3.35rem' : '1.21rem',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center center',
                                                backgroundImage: `url(${requireDefault(currentCoinLongSymbol ? 'shortSelected' : 'shortUnSelected')})`,
                                            }}>
                                            <div
                                                onClick={() => currentCoinLongSymbol && windowReplace(`/swap/${currentCoinLongSymbol}`)}
                                                className={'height100 flexCenter saf'}
                                                style={{
                                                    width: '40%',
                                                    fontSize: isPhoneClientWidth ? '1.5rem' : '.5rem'
                                                }}>SWAP
                                            </div>
                                            <div
                                                onClick={() => {
                                                    if (isShortStart) {
                                                        if (currentCoinShortSymbol) {
                                                            toShort()
                                                            // shortMasterLaunchedLiquidated().then(([shortMasterLaunched, liquidated]) => {
                                                            //     if (isOver(liquidated)) {
                                                            //         tokenMemeDetailFunc1(currentCoinName).then(
                                                            //             v => {
                                                            //                 const shortSymbols = checkDataArray(v, 'shortSymbols').map(v => checkDataString(v, 'symbol'))
                                                            //                 wRShortSymbol(
                                                            //                     checkData([shortSymbols, shortSymbols.indexOf(currentCoinShortSymbol) - 1], currentCoinShortSymbol)
                                                            //                 )
                                                            //             }
                                                            //         ).catch(() => wRShortSymbol())
                                                            //     } else {
                                                            //         wRShortSymbol()
                                                            //     }
                                                            // }).catch(() => wRShortSymbol())
                                                        }
                                                    } else {
                                                        toggleShortUnStart(true)
                                                    }
                                                }}
                                                className={'height100 flexGrow1 boxSizingBorderBox flexCenter childrenSpacingLeftSmall'}>
                                                <div className={'saf'}
                                                     style={{fontSize: isPhoneClientWidth ? '1.5rem' : '.5rem'}}>Short
                                                </div>
                                                {isShortStart
                                                    ? <div
                                                        className={'boxSizingBorderBox'}
                                                        style={{
                                                            width: '50%',
                                                            // paddingLeft: isPhoneClientWidth ? '4.5rem' : '1.5rem',
                                                            transform: 'scale(.35)',
                                                            transformOrigin: 'left',
                                                        }}>
                                                        {shortSchedule(true)}
                                                    </div>
                                                    : <img src={requireDefault('shortStart')}
                                                           alt=""
                                                           style={{height: isPhoneClientWidth ? '1.5rem' : '.5rem'}}/>}
                                            </div>
                                        </div>,
                                    longShort = isInput => !isInput && !currentCoinLongSymbol && longShortTrue && isShortStart && shortMasterLaunched &&
                                        <div
                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'borderRadiusPhone paddingPhone childrenSpacingLeftSmallPhone' : 'borderRadius padding childrenSpacingLeftSmall'}`}
                                            style={{background: 'rgba(255, 185, 194, .3)'}}>
                                            <img
                                                src={requireDefault(isSwapBuy ? 'shortRise' : 'shortDecline')}
                                                style={{width: isPhoneClientWidth ? '.9rem' : '.3rem'}}
                                                alt=""/>
                                            <div
                                                className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>
                                                {isSwapBuy ? 'Long profits' : 'Short loss'} :
                                            </div>
                                            <div
                                                className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}`}
                                                style={{color: isSwapBuy ? '#3CD38C' : '#FF7789'}}>
                                                {(() => {
                                                    const profitsLoss = checkData0(data, 'profitsLoss'),
                                                        shortReserves0 = checkData0(data, 'shortReserves0')
                                                    return isSwapBuy ? (Number(profitsLoss) > Number(shortReserves0) ? shortReserves0 : profitsLoss) : profitsLoss
                                                })()} ETH
                                            </div>
                                        </div>,
                                    shortScheduleContent = <div
                                        className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'} width100`}>
                                        <div
                                            className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                            <div className={'saf activeColor'}
                                                 style={{fontSize: isPhoneClientWidth ? '1.5rem' : '.5rem'}}>
                                                {currentCoinLongSymbol}
                                            </div>
                                            {
                                                [
                                                    ['Starting price', initLongPrice],
                                                    ...ternaryArray(
                                                        canSwap,
                                                        [['Current price', longPrice]]
                                                    ),
                                                    ['Liquidation threshold', getFullNum(npTimes(initLongPrice, 2))],
                                                ].map(v => <div
                                                    className={'flexCenter justifyContentSpaceBetween'}>
                                                    {
                                                        v.map((v, i) => <div
                                                            className={'saf1 colorWhite'}
                                                            style={{fontSize: isPhoneClientWidth ? '.9rem' : '.3rem'}}>
                                                            {v} {i === 0 ? '' : 'ETH'}
                                                        </div>)
                                                    }
                                                </div>)
                                            }
                                        </div>
                                        {shortSchedule()}
                                    </div>,
                                    maxSlippageContent = isMaxSlippage && <div
                                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                        <div style={{fontSize: `${isPhoneClientWidth ? .6 : .2}rem`}}>
                                            Max. slippage
                                        </div>
                                        <div style={{
                                            padding: `${isPhoneClientWidth ? .3 : .1}rem`,
                                            borderRadius: `${isPhoneClientWidth ? .3 : .1}rem`
                                        }}
                                             className={`flex alignItemsCenter backGroundBlueGray ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                            <input type="text"
                                                   className={'saf1'}
                                                   value={maxSlippage}
                                                   placeholder={`${maxSlippageMin}-${maxSlippageMax}`}
                                                   style={{
                                                       fontSize: `${isPhoneClientWidth ? .6 : .2}rem`,
                                                       width: '.7rem'
                                                   }}
                                                   onChange={e => {
                                                       setData({
                                                           maxSlippage: checkDataString(e.target, 'value').replace(/[^\d.]/g, '').replace(/\.{2,}/g, ".")
                                                               .replace(/^\./g, "").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
                                                               .replace(/^(\-)*(\d+)\.(\d{1}).*$/, '$1$2.$3')
                                                       })
                                                   }}
                                            />
                                            <div
                                                style={{fontSize: `${isPhoneClientWidth ? .6 : .2}rem`}}>%
                                            </div>
                                        </div>
                                    </div>
                                return <div>
                                    {
                                        isPhoneClientWidth
                                            ? pageTopFootPhone(
                                                <div className={'positionRelative zIndex1 backgroundBlack'}>
                                                    <div style={{padding: '5rem 2rem 2rem 2rem'}}>
                                                        <div
                                                            className={'flexColumn alignItemsCenter childrenSpacingTopBigPhone'}>
                                                            {shortSelect}
                                                            <div
                                                                className={'width100 childrenSpacingTopBigPhone boxSizingBorderBox positionRelative'}
                                                                style={{
                                                                    border: 'solid .05rem white',
                                                                    padding: '1rem 1rem 1rem 1rem',
                                                                    ...longShortTrue
                                                                        ? {
                                                                            marginTop: 0,
                                                                            borderBottomLeftRadius: '1.5rem',
                                                                            borderBottomRightRadius: '1.5rem',
                                                                        }
                                                                        : {borderRadius: '1.5rem'},
                                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                                }}>
                                                                {!currentCoinLongSymbol && <div className={'saf'}
                                                                                                style={{fontSize: '1.5rem'}}>SWAP</div>}
                                                                <div className={'childrenSpacingTopBigPhone positionRelative'}>
                                                                    {
                                                                        buySellCoins.map((v, i) => {
                                                                            const isInput = i === 0
                                                                            return <div
                                                                                className={'backgroundWhite paddingPhone childrenSpacingTopBigPhone borderRadiusPhone'}>
                                                                                <div
                                                                                    className={'flexCenter justifyContentSpaceBetween'}>
                                                                                    <div
                                                                                        className={'smallerFontSizePhone'}>{isInput ? 'Payment' : 'Take over'}</div>
                                                                                    <div
                                                                                        className={'flexCenter justifyContentFlexEnd childrenSpacingLeftPhone'}>
                                                                                        <div
                                                                                            className={'smallerFontSizePhone'}>Balance: {v[2]}</div>
                                                                                        {isInput && <div
                                                                                            onClick={() => maxFunc(v[2])}
                                                                                            className={'borderRadius999 smallerFontSizePhone saf flexCenter cursorPointer backgroundActiveColor'}
                                                                                            style={{
                                                                                                border: '.05rem solid',
                                                                                                padding: '0 .3rem',
                                                                                                height: '1.2rem',
                                                                                            }}>
                                                                                            MAX
                                                                                        </div>}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={'flexCenter childrenSpacingLeftPhone'}>
                                                                                    {v[3]
                                                                                        ? selectCoins
                                                                                        : selectFunc(false, undefined, undefined, v[1], v[0])
                                                                                    }
                                                                                    <input style={{fontSize: '1.5rem'}}
                                                                                           disabled={!isInput}
                                                                                           value={isInput ? newAmount : priceAmount}
                                                                                           onChange={e => setNewAmount(e.target.value)}
                                                                                           className={'saf flexGrow1 textAlignRight colorBlackInput'}
                                                                                           type="text" name="" id=""/>
                                                                                </div>
                                                                                {longShort(isInput)}
                                                                            </div>
                                                                        })
                                                                    }
                                                                    <div style={{borderRadius: '1.2rem'}}
                                                                         className={'backgroundWhite paddingPhone childrenSpacingTopPhone'}>
                                                                        <div onClick={() => toggleShowEstimated(!showEstimated)}
                                                                             className={'flexCenter justifyContentSpaceBetween cursorPointer'}>
                                                                            <div style={{fontSize: '.6rem'}}>
                                                                                {oneETHPrice}
                                                                            </div>
                                                                            <div
                                                                                className={'flexCenter childrenSpacingLeftSmallPhone'}>
                                                                                <img src={requireDefault('cheerOn')} alt=""
                                                                                     style={{width: '.9rem'}}/>
                                                                                <div style={{fontSize: '.6rem'}}>
                                                                                    {estimatedInternetFees} {ethBNB}
                                                                                </div>
                                                                                <img
                                                                                    src={requireDefault(showEstimated ? 'toTop' : 'toBottom')}
                                                                                    alt=""
                                                                                    style={{width: '.9rem'}}/>
                                                                            </div>
                                                                        </div>
                                                                        {maxSlippageContent}
                                                                    </div>
                                                                    {
                                                                        showEstimated && <div
                                                                            className={'childrenSpacingTopPhone flexColumn justifyContentCenter borderRadius999'}
                                                                            style={{
                                                                                height: '2.6rem',
                                                                                marginTop: '.3rem',
                                                                                padding: '0 .9rem',
                                                                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFFFFF 50.46%)'
                                                                            }}>
                                                                            {
                                                                                estimatedInternetFeesShow.map(v =>
                                                                                    <div
                                                                                        className={'flexCenter justifyContentSpaceBetween'}>
                                                                                        {v.map(v => <div
                                                                                            style={{fontSize: '.8rem'}}>{v}</div>)}
                                                                                    </div>)
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <img onClick={() => toggleIsSwapBuy(!isSwapBuy)}
                                                                         className={'positionAbsolute cursorPointer'}
                                                                         src={requireDefault('SWAP')} alt=""
                                                                         style={{width: '2.5rem', top: '4.1rem', left: '44%'}}
                                                                    />
                                                                </div>
                                                                {!canSwap && !currentCoinLongSymbol && <div
                                                                    className={'flexCenter childrenSpacingLeftPhone borderRadiusPhone backgroundWhite paddingPhone'}>{countdown.map(countdownContent)}</div>}
                                                                <div
                                                                    className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                                    {
                                                                        [
                                                                            [currentCoinLongSymbol ? (canSwap ? (isSwapBuy ? 'BUY' : 'SELL') : 'Liquidation threshold over') : 'SWAP', () => canSwap && toggleShowSwapConfirmSwap(true),
                                                                                canSwap ? 'backgroundBlack activeColor' : 'backGroundColorLightGray colorWhite',
                                                                                3],
                                                                            ...ternaryArray(
                                                                                isShortOver,
                                                                                [
                                                                                    ['Next Short', toShort, 'backgroundBlack activeColor', 1]
                                                                                ]
                                                                            )
                                                                        ].map(v => <div
                                                                            onClick={v[1]}
                                                                            className={`borderRadius999 ${v[2]} saf flexCenter cursorPointer`}
                                                                            style={{
                                                                                padding: '1rem 0', fontSize: '1rem',
                                                                                flexGrow: v[3]
                                                                            }}>
                                                                            {v[0]}
                                                                        </div>)
                                                                    }
                                                                </div>
                                                                {!longShortTrue && <img className={'positionAbsolute'}
                                                                                        src={requireDefault('catCat2')}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '9rem',
                                                                                            top: '-3.5rem',
                                                                                            right: 0
                                                                                        }}/>}
                                                            </div>
                                                            <div className={'childrenSpacingTopPhone width100'}>
                                                                <div
                                                                    className={'flex alignItemsCenter childrenSpacingLeftBigPhone'}>
                                                                    {
                                                                        formCharts.map((v, i) => <div
                                                                            onClick={() => toggleFormChartIndex(i)}
                                                                            className={'childrenSpacingLeftSmallPhone flexCenter borderRadius999 cursorPointer'}
                                                                            style={{
                                                                                padding: '.3rem .6rem',
                                                                                background: formChartIndex === i ? '#FFB9C2' : '#FFF9F0'
                                                                            }}
                                                                        >
                                                                            <img src={requireDefault(v[0])} alt=""
                                                                                 style={{width: '1.5rem'}}/>
                                                                            <div
                                                                                className={'saf smallFontSizePhone'}>{v[0]}</div>
                                                                        </div>)
                                                                    }
                                                                    <div style={{padding: '.45rem', background: '#FFF9F0'}}
                                                                         onClick={reloadFormChart}
                                                                         className={'flexCenter borderRadius999 cursorPointer'}>
                                                                        <img src={requireDefault('reload')} alt=""
                                                                             style={{width: '1.2rem'}}/>
                                                                    </div>
                                                                </div>
                                                                {formChartItem[3]}
                                                                <div
                                                                    className={'childrenSpacingTopBig boxSizingBorderBox positionRelative borderRadiusPhone flexColumn'}
                                                                    style={{
                                                                        height: '15rem',
                                                                        background: formChartColor,
                                                                        border: 'solid .05rem white',
                                                                        boxShadow: '0 0 .5rem #FFB9C2',
                                                                        padding: '1rem 1rem 1rem 1rem',
                                                                    }}>
                                                                    {formChart}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'positionRelative boxSizingBorderBox'} style={{
                                                        backgroundSize: 'cover',
                                                        padding: '0 2rem 2rem 2rem',
                                                    }}>
                                                        <div className={'childrenSpacingTopBigPhone'}
                                                        >
                                                            {
                                                                currentCoinLongSymbol
                                                                    ? shortScheduleContent
                                                                    : <div
                                                                        className={'flex activeColor justifyContentSpaceBetween'}>
                                                                        <div
                                                                            className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                                            <img className={'borderRadius999'} src={currentCoinLogo}
                                                                                 alt=""
                                                                                 style={square('4rem')}/>
                                                                            <div className={'flexColumn justifyContentCenter'}>
                                                                                <div className={'saf activeColor'}
                                                                                     style={{fontSize: '1.5rem'}}>
                                                                                    {currentCoinName} Overview
                                                                                </div>
                                                                                <div className={'saf activeColor'}
                                                                                     style={{fontSize: '.7rem'}}>
                                                                                    {currentCoinDesc}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <div
                                                                className={'flexCenter childrenFlexGrow1 childrenSpacingLeftPhone'}>
                                                                {
                                                                    [
                                                                        ['Holders', currentCoinHolders],
                                                                        ['Omnichain Total Supply', omniSupply],
                                                                    ].map(v => <div className={'childrenSpacingTopSmallPhone'}>
                                                                        <div
                                                                            className={'colorWhite smallFontSizePhone'}>{v[0]}</div>
                                                                        <div className={'saf1 activeColor smallFontSizePhone'}>
                                                                            {v[1]}
                                                                        </div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                            <div className={'childrenSpacingTopSmallPhone'}>
                                                                <div className={'colorWhite smallFontSizePhone'}>
                                                                    Pool Reserves
                                                                </div>
                                                                <div
                                                                    className={'flexCenter childrenSpacingLeftPhone'}>
                                                                    <div
                                                                        className={'flex alignItemsCenter childrenSpacingLeftSmallPhone'}>
                                                                        <div
                                                                            className={'saf1 activeColor smallFontSizePhone'}>
                                                                            {reserves0}
                                                                        </div>
                                                                        <div
                                                                            className={'colorWhite saf1 smallFontSizePhone'}>ETH
                                                                        </div>
                                                                    </div>
                                                                    <img src={requireDefault('Rectangle 552')} alt=""
                                                                         style={{height: '.7rem'}}/>
                                                                    <div
                                                                        className={'flex alignItemsCenter childrenSpacingLeftSmallPhone flexGrow1'}>
                                                                        <div
                                                                            className={'saf1 activeColor smallFontSizePhone'}>
                                                                            {reserves1}
                                                                        </div>
                                                                        <div
                                                                            className={'colorWhite saf1 smallFontSizePhone'}>{currentCoinName}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={'flexCenter justifyContentSpaceBetween childrenSpacingLeftPhone'}>
                                                                {
                                                                    (() => {
                                                                        const arr = [
                                                                            ['Market Cap', `${ethUSDTIsNotNull
                                                                                ? cmCap
                                                                                : ''}`],
                                                                            ['Initial Price', `$${
                                                                                ethUSDTIsNotNull
                                                                                    ? initialPrice
                                                                                    : ''
                                                                            }`],
                                                                            initialDateData,
                                                                        ]
                                                                        return arr.map((v, i) => <div
                                                                            className={`childrenSpacingTopSmallPhone flexColumn ${
                                                                                i === arr.length - 1
                                                                                    ? 'alignItemsFlexEnd'
                                                                                    : (i === 0 ? '' : 'alignItemsCenter')
                                                                            }`}>
                                                                            <div
                                                                                className={'colorWhite smallFontSizePhone'}>{v[0]}</div>
                                                                            <div
                                                                                className={'saf1 colorWhite smallFontSizePhone'}>
                                                                                {v[1]}
                                                                            </div>
                                                                        </div>)
                                                                    })()
                                                                }
                                                            </div>
                                                            {contractAddressContent}
                                                            {!currentCoinLongSymbol &&
                                                                <div className={'childrenSpacingTopPhone'}>
                                                                    <div className={'saf1 colorWhite'}>Tokenomics</div>
                                                                    <div
                                                                        className={'overflowHidden paddingPhone flex alignItemsCenter childrenSpacingLeftPhone'}
                                                                        style={{
                                                                            borderRadius: '1.2rem',
                                                                            border: 'solid .09rem rgba(255,255,255,.4)',
                                                                        }}>
                                                                        {
                                                                            checkDataArray(tokenomicsTextList(currentCoinQuota, currentCoinPresale), currentCoinTokenomics).map(
                                                                                v => tokenomicsShow(v).map(v => <div
                                                                                    className={'flex alignItemsCenter childrenSpacingLeftSmallPhone'}>
                                                                                    <div
                                                                                        className={'activeColor saf'}>
                                                                                        {v[0]}
                                                                                    </div>
                                                                                    <div style={{fontSize: '.5rem'}}
                                                                                         className={'colorWhite'}>{v[1]}</div>
                                                                                </div>)
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>}
                                                            <div
                                                                className={'flexCenter childrenFlexGrow1 childrenSpacingLeftBigPhone'}>
                                                                {
                                                                    [
                                                                        ['telegram1', currentCoinTG],
                                                                        ['twitter2', currentCoinX],
                                                                    ].map(v => {
                                                                        const link = v[1]
                                                                        return isNotNullOrEmpty(link) && <div
                                                                            onClick={() => windowOpen(link)}
                                                                            className={'flexColumnCenter childrenSpacingTopPhone cursorPointer'}>
                                                                            <img src={requireDefault(v[0])} alt=""
                                                                                 style={{width: '3rem'}}/>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        showSwapConfirm &&
                                                        <div className={'positionFixed flexColumnCenter'}
                                                             style={{
                                                                 top: 0,
                                                                 left: 0,
                                                                 right: 0,
                                                                 bottom: 0,
                                                                 zIndex: 1000,
                                                                 background: 'rgba(0,0,0,.56)',
                                                             }}>
                                                            <div
                                                                className={'childrenSpacingTopPhone boxSizingBorderBox borderRadiusPhone paddingPhone'}
                                                                style={{
                                                                    width: '25rem',
                                                                    border: 'solid .15rem white',
                                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                                }}>
                                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div className={'saf'} style={{fontSize: '1.5rem'}}>
                                                                        Confirm exchange
                                                                    </div>
                                                                    <img onClick={() => toggleShowSwapConfirmSwap()}
                                                                         className={'cursorPointer'}
                                                                         src={requireDefault('close')}
                                                                         alt="" style={{width: '1.5rem'}}/>
                                                                </div>
                                                                <div
                                                                    className={'backgroundWhite paddingPhone childrenSpacingTopPhone borderRadiusPhone'}>
                                                                    <div>
                                                                        <div
                                                                            className={'flexCenter justifyContentSpaceBetween'}>
                                                                            <div
                                                                                className={'flexCenter childrenSpacingLeftPhone'}>
                                                                                <div
                                                                                    className={'positionRelative borderRadius999'}
                                                                                    style={square('2.1rem')}>
                                                                                    <img className={'width100 height100'}
                                                                                         src={coinLogo}
                                                                                         alt=""/>
                                                                                    {!isSwapBuy && currentCoinLongSymbol &&
                                                                                        <img
                                                                                            className={'width100 height100 positionAbsolute'}
                                                                                            style={{top: 0, left: 0}}
                                                                                            src={requireDefault('shortToken')}
                                                                                            alt=""/>}
                                                                                </div>
                                                                                <div className={'saf1'}
                                                                                     style={{fontSize: '1rem'}}>
                                                                                    {coinName}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{fontSize: '1.5rem'}}
                                                                                 className={'saf'}
                                                                            >
                                                                                {newAmount}
                                                                            </div>
                                                                        </div>
                                                                        <div className={'flexCenter'}>
                                                                            <img src={requireDefault('SWAP2')} alt=""
                                                                                 style={{width: '2.1rem'}}/>
                                                                        </div>
                                                                        <div
                                                                            className={'flexCenter justifyContentSpaceBetween'}>
                                                                            <div
                                                                                className={'flexCenter childrenSpacingLeftPhone'}>
                                                                                <div
                                                                                    className={'positionRelative borderRadius999'}
                                                                                    style={square('2.1rem')}>
                                                                                    <img className={'width100 height100'}
                                                                                         src={coinLogo1}
                                                                                         alt=""/>
                                                                                    {isSwapBuy && currentCoinLongSymbol &&
                                                                                        <img
                                                                                            className={'width100 height100 positionAbsolute'}
                                                                                            style={{top: 0, left: 0}}
                                                                                            src={requireDefault('shortToken')}
                                                                                            alt=""/>}
                                                                                </div>
                                                                                <div className={'saf1'}
                                                                                     style={{fontSize: '1rem'}}>
                                                                                    {coinName1}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{fontSize: '1.5rem'}}
                                                                                 className={'saf'}
                                                                            >
                                                                                {isNext ? 'Indeterminacy' : priceAmount}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {longShort()}
                                                                    {slippage}
                                                                    <div style={{border: 'solid .06rem #D8D8D8'}}/>
                                                                    {
                                                                        [
                                                                            ...estimatedInternetFeesShow,
                                                                            ...ternaryArray(
                                                                                isMaxSlippage,
                                                                                [['Max. slippage', `${maxSlippage} %`]]
                                                                            )
                                                                        ].map(v =>
                                                                            <div
                                                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                                                {v.map(v => <div
                                                                                    style={{fontSize: '.9rem'}}>{v}</div>)}
                                                                            </div>)
                                                                    }
                                                                    {gasIsHigh(buySellFunctionName, estimatedInternetFees)}
                                                                    {messagesInclude}
                                                                    {nextTip}
                                                                </div>
                                                                {swapCloudflareHuman}
                                                                <div
                                                                    className={'flex alignItemsCenter childrenFlexGrow1 childrenSpacingLeftPhone'}>
                                                                    {
                                                                        [
                                                                            ...ternaryArray(showCancel, [['CANCEL', () => toggleShowSwapConfirmSwap()]]),
                                                                            ['CONFIRM', () => buySellConfirm(toggleShowSwapConfirmSwap)]
                                                                        ].map(v => <div
                                                                            onClick={v[1]}
                                                                            className={'borderRadius999 backgroundBlack saf flexCenter cursorPointer activeColor'}
                                                                            style={{padding: '1rem 0', fontSize: '1.5rem'}}>
                                                                            {v[0]}
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                            : <div>
                                                {pageTop}
                                                <div
                                                    className={'positionRelative flexColumnCenter justifyContentSpaceBetween boxSizingBorderBox zIndex1 backgroundBlack'}
                                                    style={{
                                                        minHeight: '100vh',
                                                        paddingTop: '1rem',
                                                        // backgroundSize: 'cover',
                                                        // backgroundRepeat: 'no-repeat',
                                                        // backgroundPosition: 'center center',
                                                        // backgroundImage: `url(${requireDefault('swap-bg')})`,
                                                    }}>
                                                    <div/>
                                                    <div
                                                        className={'flex width100 boxSizingBorderBox justifyContentSpaceBetween'}
                                                        style={{padding: '2rem 2rem 1.3rem 2rem'}}>
                                                        <div style={{width: '62%'}}
                                                             className={'flexColumn alignItemsCenter childrenSpacingTopBigger'}>
                                                            <div className={'width100 childrenSpacingTopBig'}>
                                                                {
                                                                    formCharts.map(v => [
                                                                        v[3],
                                                                        <div
                                                                            className={'childrenSpacingTopBig boxSizingBorderBox positionRelative flexColumn'}
                                                                            style={{
                                                                                height: '10rem',
                                                                                padding: '.4rem',
                                                                                borderRadius: '.5rem',
                                                                                background: v[1],
                                                                                border: 'solid .05rem rgba(255,255,255,.2)',
                                                                            }}>
                                                                            {v[2]}
                                                                        </div>
                                                                    ])
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={'flexColumn alignItemsCenter'}
                                                             style={{width: '36%'}}>
                                                            <div
                                                                className={'width100 childrenSpacingTopBigger'}>
                                                                <div>
                                                                    {shortSelect}
                                                                    <div
                                                                        className={'width100 childrenSpacingTop boxSizingBorderBox positionRelative'}
                                                                        style={{
                                                                            padding: '.7rem',
                                                                            ...longShortTrue
                                                                                ? {
                                                                                    borderBottomLeftRadius: '.5rem',
                                                                                    borderBottomRightRadius: '.5rem',
                                                                                }
                                                                                : {borderRadius: '.5rem'},
                                                                            border: 'solid .05rem white',
                                                                            background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                                        }}>
                                                                        {!longShortTrue && <div className={'saf'}
                                                                                                style={{fontSize: '.5rem'}}>SWAP
                                                                        </div>}
                                                                        <div
                                                                            className={'childrenSpacingTopBig positionRelative'}>
                                                                            {
                                                                                buySellCoins.map((v, i) => {
                                                                                    const isInput = i === 0
                                                                                    return <div
                                                                                        className={'backgroundWhite paddingBig childrenSpacingTopBig'}
                                                                                        style={{borderRadius: '.4rem'}}>
                                                                                        <div
                                                                                            className={'flexCenter justifyContentSpaceBetween'}>
                                                                                            <div
                                                                                                className={'smallFontSize'}>{isInput ? 'Payment' : 'Take over'}</div>
                                                                                            <div
                                                                                                className={'flexCenter justifyContentFlexEnd childrenSpacingLeft'}>
                                                                                                <div
                                                                                                    className={'smallFontSize'}>Balance: {v[2]}</div>
                                                                                                {isInput && <div
                                                                                                    onClick={() => maxFunc(v[2])}
                                                                                                    className={'borderRadius999 smallFontSize saf flexCenter cursorPointer backgroundActiveColor'}
                                                                                                    style={{
                                                                                                        border: '.05rem solid',
                                                                                                        padding: '0 .2rem',
                                                                                                        height: '.35rem',
                                                                                                    }}>
                                                                                                    MAX
                                                                                                </div>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className={'flexCenter childrenSpacingLeft'}>
                                                                                            {
                                                                                                v[3]
                                                                                                    ? selectCoins
                                                                                                    : selectFunc(false, undefined, undefined, v[1], v[0])
                                                                                            }
                                                                                            <input style={{fontSize: '.5rem'}}
                                                                                                   disabled={!isInput}
                                                                                                   value={isInput ? newAmount : priceAmount}
                                                                                                   onChange={e => setNewAmount(e.target.value)}
                                                                                                   className={'saf flexGrow1 textAlignRight colorBlackInput'}
                                                                                                   type="text" name="" id=""/>
                                                                                        </div>
                                                                                        {longShort(isInput)}
                                                                                    </div>
                                                                                })
                                                                            }
                                                                            <div
                                                                                style={{borderRadius: '.4rem'}}
                                                                                className={'backgroundWhite paddingBig childrenSpacingTop'}>
                                                                                <div
                                                                                    onClick={() => toggleShowEstimated(!showEstimated)}
                                                                                    className={'flexCenter justifyContentSpaceBetween cursorPointer'}>
                                                                                    <div style={{fontSize: '.2rem'}}>
                                                                                        {oneETHPrice}
                                                                                    </div>
                                                                                    <div
                                                                                        className={'flexCenter childrenSpacingLeft'}>
                                                                                        <img src={requireDefault('cheerOn')}
                                                                                             alt=""
                                                                                             style={{width: '.3rem'}}/>
                                                                                        <div
                                                                                            style={{fontSize: '.2rem'}}>
                                                                                            {estimatedInternetFees} {ethBNB}
                                                                                        </div>
                                                                                        <img
                                                                                            src={requireDefault(showEstimated ? 'toTop' : 'toBottom')}
                                                                                            alt=""
                                                                                            style={{width: '.3rem'}}/>
                                                                                    </div>
                                                                                </div>
                                                                                {maxSlippageContent}
                                                                            </div>
                                                                            {
                                                                                showEstimated && <div
                                                                                    className={'childrenSpacingTopBig flexColumn justifyContentCenter'}
                                                                                    style={{
                                                                                        height: '1rem',
                                                                                        marginTop: '.1rem',
                                                                                        padding: '0 .3rem',
                                                                                        borderRadius: '.5rem',
                                                                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFFFFF 50.46%)'
                                                                                    }}>
                                                                                    {
                                                                                        estimatedInternetFeesShow.map(v =>
                                                                                            <div
                                                                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                                                                {v.map(v => <div
                                                                                                    style={{fontSize: '.28rem'}}>{v}</div>)}
                                                                                            </div>)
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <img onClick={() => toggleIsSwapBuy(!isSwapBuy)}
                                                                                 className={'positionAbsolute cursorPointer'}
                                                                                 src={requireDefault('SWAP')} alt=""
                                                                                 style={{
                                                                                     width: '.8rem',
                                                                                     top: '1.5rem',
                                                                                     left: '44%'
                                                                                 }}
                                                                            />
                                                                        </div>
                                                                        {!canSwap && !currentCoinLongSymbol &&
                                                                            <div style={{borderRadius: '.4rem'}}
                                                                                 className={'flexCenter childrenSpacingLeft backgroundWhite padding'}>{countdown.map(countdownContent)}</div>}
                                                                        <div
                                                                            className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                            {
                                                                                [
                                                                                    [currentCoinLongSymbol ? (canSwap ? (isSwapBuy ? 'BUY' : 'SELL') : 'Liquidation threshold over') : 'SWAP', () => canSwap && toggleShowSwapConfirmSwap(true),
                                                                                        canSwap ? 'backgroundBlack activeColor' : 'backGroundColorLightGray colorWhite',
                                                                                        3],
                                                                                    ...ternaryArray(
                                                                                        isShortOver,
                                                                                        [
                                                                                            ['Next Short', toShort, 'backgroundBlack activeColor', 1]
                                                                                        ]
                                                                                    )
                                                                                ].map(v => <div
                                                                                    onClick={v[1]}
                                                                                    className={`borderRadius999 ${v[2]} saf flexCenter cursorPointer`}
                                                                                    style={{
                                                                                        padding: '.25rem 0',
                                                                                        fontSize: '.3rem',
                                                                                        flexGrow: v[3]
                                                                                    }}>
                                                                                    {v[0]}
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                        {!longShortTrue &&
                                                                            <img className={'positionAbsolute'}
                                                                                 src={requireDefault('catCat2')}
                                                                                 alt=""
                                                                                 style={{
                                                                                     width: '4.7rem',
                                                                                     top: '-1.5rem',
                                                                                     right: '-.2rem'
                                                                                 }}/>}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={'width100 flexCenter justifyContentSpaceBetween'}>
                                                                    {
                                                                        currentCoinLongSymbol
                                                                            ? shortScheduleContent
                                                                            : [
                                                                                <div
                                                                                    className={'activeColor flex alignItemsCenter childrenSpacingLeft'}>
                                                                                    <div
                                                                                        className={'flexColumn justifyContentCenter'}>
                                                                                        <div className={'saf'}
                                                                                             style={{fontSize: '.6rem'}}>
                                                                                            {currentCoinName} Telegram
                                                                                        </div>
                                                                                        <div className={'saf'}
                                                                                             style={{fontSize: '.3rem'}}>
                                                                                            {currentCoinDesc}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>,
                                                                                cCTgIsNotNull && <div>
                                                                                    <div
                                                                                        className={'flexCenter zIndex1 positionRelative backgroundActiveColor'}
                                                                                        style={{
                                                                                            ...square('1.7rem'),
                                                                                            padding: '.03rem',
                                                                                            borderRadius: '.25rem',
                                                                                            border: 'solid .05rem white',
                                                                                        }}>
                                                                                        <img className={'zIndex-1 positionAbsolute'}
                                                                                             src={requireDefault('Rectangle 549')}
                                                                                             alt=""
                                                                                             style={{
                                                                                                 top: '41%',
                                                                                                 left: '-.14rem',
                                                                                                 width: '.3rem'
                                                                                             }}
                                                                                        />
                                                                                        <div
                                                                                            style={{borderRadius: '.2rem', ...square('98%')}}
                                                                                            className={'backgroundWhite paddingSmall boxSizingBorderBox'}>
                                                                                            <QRCodeSVG value={currentCoinTG}
                                                                                                       style={{...square('100%')}}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ]
                                                                    }
                                                                </div>
                                                                <div
                                                                    className={'flex activeColor justifyContentSpaceBetween childrenSpacingLeftBigger'}>
                                                                    <div
                                                                        className={'flexCenter flexGrow1 childrenFlexGrow1 childrenSpacingLeft'}>
                                                                        {
                                                                            [
                                                                                ['Holders', currentCoinHolders],
                                                                                ['Omnichain Total Supply', omniSupply],
                                                                            ].map(v => <div
                                                                                className={'childrenSpacingTopSmall'}>
                                                                                <div className={'colorWhite'}>{v[0]}</div>
                                                                                <div className={'saf1 activeColor'}
                                                                                     style={{fontSize: '.4rem'}}>
                                                                                    {v[1]}
                                                                                </div>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className={'childrenSpacingTopSmall'}>
                                                                    <div className={'colorWhite'}>Pool Reserves</div>
                                                                    <div className={'flexCenter childrenSpacingLeft'}>
                                                                        <div
                                                                            className={'flex alignItemsCenter childrenSpacingLeftSmall'}>
                                                                            <div className={'saf1 activeColor'}
                                                                                 style={{fontSize: '.4rem'}}>
                                                                                {reserves0}
                                                                            </div>
                                                                            <div className={'colorWhite saf1'}>ETH</div>
                                                                        </div>
                                                                        <img src={requireDefault('Rectangle 552')} alt=""
                                                                             style={{height: '.4rem'}}/>
                                                                        <div
                                                                            className={'flex alignItemsCenter flexGrow1 childrenSpacingLeftSmall'}>
                                                                            <div
                                                                                className={'saf1 activeColor textOverflowEllipsis'}
                                                                                style={{fontSize: '.4rem'}}>
                                                                                {reserves1}
                                                                            </div>
                                                                            <div
                                                                                className={'colorWhite saf1'}>{currentCoinName}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={'flexCenter justifyContentSpaceBetween childrenSpacingLeft'}>
                                                                    {
                                                                        (() => {
                                                                            const arr = [
                                                                                ['Market Cap', `${ethUSDTIsNotNull
                                                                                    ? cmCap
                                                                                    : ''}`],
                                                                                ['Initial Price', `$${
                                                                                    ethUSDTIsNotNull
                                                                                        ? initialPrice
                                                                                        : ''
                                                                                }`],
                                                                                initialDateData,
                                                                            ]
                                                                            return arr.map((v, i) => <div
                                                                                className={`childrenSpacingTopSmall flexColumn ${
                                                                                    i === arr.length - 1
                                                                                        ? 'alignItemsFlexEnd'
                                                                                        : (i === 0 ? '' : 'alignItemsCenter')
                                                                                }`}>
                                                                                <div className={'colorWhite'}>{v[0]}</div>
                                                                                <div className={'saf1 colorWhite'}>
                                                                                    {v[1]}
                                                                                </div>
                                                                            </div>)
                                                                        })()
                                                                    }
                                                                </div>
                                                                {contractAddressContent}
                                                                {!currentCoinLongSymbol &&
                                                                    <div className={'childrenSpacingTop'}>
                                                                        <div className={'saf1 colorWhite'}>Tokenomics</div>
                                                                        <div
                                                                            className={'overflowHidden paddingBig flex alignItemsCenter childrenSpacingLeft'}
                                                                            style={{
                                                                                borderRadius: '.4rem',
                                                                                border: 'solid .03rem rgba(255,255,255,.4)',
                                                                            }}>
                                                                            {
                                                                                checkDataArray(tokenomicsTextList(currentCoinQuota, currentCoinPresale), currentCoinTokenomics).map(
                                                                                    v => tokenomicsShow(v).map(v => <div
                                                                                        className={'flex alignItemsCenter childrenSpacingLeftSmall'}>
                                                                                        <div
                                                                                            className={'activeColor saf biggerFontSize'}>
                                                                                            {v[0]}
                                                                                        </div>
                                                                                        <div
                                                                                            className={'colorWhite smallFontSize'}>{v[1]}</div>
                                                                                    </div>)
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>}
                                                                <div
                                                                    className={'flexCenter childrenFlexGrow1 childrenSpacingLeftBig'}>
                                                                    {
                                                                        [
                                                                            ['telegram1', currentCoinTG],
                                                                            ['twitter2', currentCoinX],
                                                                        ].map(v => {
                                                                            const link = v[1]
                                                                            return isNotNullOrEmpty(link) && <div
                                                                                onClick={() => windowOpen(link)}
                                                                                className={'flexColumnCenter childrenSpacingTop cursorPointer'}>
                                                                                <img src={requireDefault(v[0])} alt=""
                                                                                     style={{width: '1rem'}}/>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'zIndex1 width100'}>{pageFoot}</div>
                                                </div>
                                                {
                                                    showSwapConfirm &&
                                                    <div className={'positionFixed flexColumnCenter'}
                                                         style={{
                                                             top: 0,
                                                             left: 0,
                                                             right: 0,
                                                             bottom: 0,
                                                             zIndex: 1000,
                                                             background: 'rgba(0,0,0,.56)',
                                                         }}>
                                                        <div className={'childrenSpacingTopBigger boxSizingBorderBox'}
                                                             style={{
                                                                 width: '12rem',
                                                                 padding: '.7rem',
                                                                 borderRadius: '.5rem',
                                                                 border: 'solid .05rem white',
                                                                 background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                             }}>
                                                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                                                <div className={'saf'} style={{fontSize: '.5rem'}}>
                                                                    Confirm exchange
                                                                </div>
                                                                <img onClick={() => toggleShowSwapConfirmSwap()}
                                                                     className={'cursorPointer'}
                                                                     src={requireDefault('close')}
                                                                     alt="" style={{width: '.5rem'}}/>
                                                            </div>
                                                            <div style={{borderRadius: '.4rem'}}
                                                                 className={'backgroundWhite paddingBigger borderRadius childrenSpacingTopBig'}
                                                            >
                                                                <div>
                                                                    <div
                                                                        className={'flexCenter justifyContentSpaceBetween'}>
                                                                        <div className={'flexCenter childrenSpacingLeft'}>
                                                                            <div className={'positionRelative borderRadius999'}
                                                                                 style={square('.7rem')}>
                                                                                <img className={'width100 height100'}
                                                                                     src={coinLogo}
                                                                                     alt=""/>
                                                                                {!isSwapBuy && currentCoinLongSymbol &&
                                                                                    <img
                                                                                        className={'width100 height100 positionAbsolute'}
                                                                                        style={{top: 0, left: 0}}
                                                                                        src={requireDefault('shortToken')}
                                                                                        alt=""/>}
                                                                            </div>
                                                                            <div className={'saf1'}
                                                                                 style={{fontSize: '.5rem'}}>
                                                                                {coinName}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{fontSize: '.5rem'}}
                                                                             className={'saf'}
                                                                        >
                                                                            {newAmount}
                                                                        </div>
                                                                    </div>
                                                                    <div className={'flexCenter'}>
                                                                        <img src={requireDefault('SWAP2')} alt=""
                                                                             style={{width: '.7rem'}}/>
                                                                    </div>
                                                                    <div
                                                                        className={'flexCenter justifyContentSpaceBetween'}>
                                                                        <div className={'flexCenter childrenSpacingLeft'}>
                                                                            <div className={'positionRelative borderRadius999'}
                                                                                 style={square('.7rem')}>
                                                                                <img className={'width100 height100'}
                                                                                     src={coinLogo1}
                                                                                     alt=""/>
                                                                                {isSwapBuy && currentCoinLongSymbol &&
                                                                                    <img
                                                                                        className={'width100 height100 positionAbsolute'}
                                                                                        style={{top: 0, left: 0}}
                                                                                        src={requireDefault('shortToken')}
                                                                                        alt=""/>}
                                                                            </div>
                                                                            <div className={'saf1'}
                                                                                 style={{fontSize: '.5rem'}}>
                                                                                {coinName1}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{fontSize: '.5rem'}}
                                                                             className={'saf'}
                                                                        >
                                                                            {isNext ? 'Indeterminacy' : priceAmount}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {longShort()}
                                                                {slippage}
                                                                <div style={{border: 'solid .02rem #D8D8D8'}}/>
                                                                {
                                                                    [
                                                                        ...estimatedInternetFeesShow,
                                                                        ...ternaryArray(
                                                                            isMaxSlippage,
                                                                            [['Max. slippage', `${maxSlippage} %`]]
                                                                        )
                                                                    ].map(v =>
                                                                        <div
                                                                            className={'flexCenter justifyContentSpaceBetween'}>
                                                                            {v.map(v => <div
                                                                                style={{fontSize: '.28rem'}}>{v}</div>)}
                                                                        </div>)
                                                                }
                                                                {gasIsHigh(buySellFunctionName, estimatedInternetFees)}
                                                                {messagesInclude}
                                                                {nextTip}
                                                            </div>
                                                            {swapCloudflareHuman}
                                                            <div
                                                                className={'flex alignItemsCenter childrenFlexGrow1 childrenSpacingLeft'}>
                                                                {
                                                                    [
                                                                        ...ternaryArray(showCancel, [['CANCEL', () => toggleShowSwapConfirmSwap()]]),
                                                                        ['CONFIRM', () => buySellConfirm(toggleShowSwapConfirmSwap)]
                                                                    ].map(v => <div
                                                                        onClick={v[1]}
                                                                        className={'borderRadius999 backgroundBlack saf flexCenter biggerFontSize cursorPointer activeColor'}
                                                                        style={{padding: '.25rem 0', fontSize: '.5rem'}}>
                                                                        {v[0]}
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                    }
                                    {shortUnStartTip}
                                    {selectCoinsContent}
                                    {taskContent}
                                    {deployment}
                                    {notYet}
                                    {loading}
                                    {previousTips}
                                    {drip}
                                </div>
                            })()
                        }
                    ),
                    ...ternaryObject(
                        portfolioPrdOpen,
                        {
                            'portfolio/*': (() => {
                                const lockedETH = checkData0(data, 'lockedETH'),
                                    lockedToken = checkData0(data, 'lockedToken'),
                                    lockedData = [
                                        ['ETH', lockedETH],
                                        ['COIN', lockedToken, true]
                                    ],
                                    canUnlock = isMasterContract && (lockedETH !== 0 || lockedToken !== 0),
                                    unlock = () => canUnlock && slaveWriteContract(
                                        'unlock', undefined, [address],
                                        hash => setTimeout(
                                            () => {
                                                getLocked()
                                                toggleShowLoading()
                                                toastSuccessfulHash(hash)
                                            },
                                            3000
                                        )
                                    ),
                                    pointsData = [
                                        ['Create a meme', checkData0(data, 'memePoints'), `The address that creates the token receives droplets

Total amount of transactions < 10E  (+30 droplets per transaction 1E)
Total transaction value >= 10E <100E (+50 droplets per transaction 1E)
Total transactions >= 100E earn (+60 droplets per transaction 1E)`],
                                        ['Launch (Presale) deposit', checkData0(data, 'depositPoints'), `The address used for depositing ETH will receive droplets.
For every 1E deposit, you'll earn +30 droplets.`],
                                        ['Swap Buy & Sell', checkData0(data, 'swapPoints'), `The address involved in transactions will receive droplets.

For each transaction of 1E, you'll earn +30 droplets. (0.5E will earn 15 droplets)

If weekly transaction volume exceeds 1E, an additional +20 droplets will be awarded for each 1E transaction.

If weekly transaction volume exceeds 2E but is less than 3E, an additional +40 droplets will be awarded for each 1E transaction.

If weekly transaction volume exceeds 3E, an additional +70 droplets will be awarded for each 1E transaction.`],
                                        ['Vote for a meme', checkData0(data, 'taskVotePoints'), `The address used for voted will receive droplets. 
For every one vote, you'll earn +10 droplets.`],
                                    ],
                                    crossChains = (() => {
                                        const arr = [
                                            [masterChain.name, requireDefault(`chainIcons/${masterChain.id}`)],
                                            [currentPortfolioChain.name, requireDefault(`chainIcons/${currentPortfolioChain.id}`), true],
                                        ]
                                        return fromMaster ? arr : arr.reverse()
                                    })(),
                                    toggleFromMaster = fromMaster => switchEthereumChain(
                                        undefined,
                                        undefined,
                                        true,
                                        fromMaster ? masterChain : currentPortfolioChain
                                    ),
                                    maxFunc = () => {
                                        if (isEthCrossTo) {
                                            ethBalance < crossMax
                                                ? crossCallEstimateGas(parseEtherWeiEthBalance).then(estimatedInternetFees =>
                                                    slaveContractETH.estimateGas({
                                                        from: address,
                                                        to: createMemeContractSlaveAddress,
                                                        value: parseEtherWeiEthBalance,
                                                        data: (new slaveContractETH.Contract(
                                                            createMemeContractSlaveAbi,
                                                            createMemeContractSlaveAddress
                                                        )).methods[crossFunctionName](
                                                            crossToChainId, address,
                                                            npMinus(Number(parseEtherWeiEthBalance), Number(estimatedInternetFees))
                                                        ).encodeABI(),
                                                    }).then(gas =>
                                                        slaveContractETH.getGasPrice().then(gasPrice => {
                                                            const value = npMinus(
                                                                Number(sendETHBalance),
                                                                npPlus(
                                                                    numberFormatEther(estimatedInternetFees),
                                                                    npTimes(numberFormatEther(npTimes(Number(gas), Number(gasPrice))), gasMultiple)
                                                                )
                                                            )
                                                            setNewAmount(value > 0 ? value : 0)
                                                        })
                                                    ).catch(e => toast(checkData([e, 'innerError', 'message'], e.message))))
                                                : setNewAmount(crossMax)
                                        } else {
                                            setNewAmount(tokenBalance)
                                        }
                                    },
                                    toggleShowCross = showCross => setData({showCross}),
                                    myDrop = (() => {
                                        const dataClaimAmount = checkData0(data, 'claimAmount'),
                                            claimAmount = numberETH(dataClaimAmount),
                                            canClaim = isMasterContract && claimAmount > 0 && !isClaimed && isTwitterAuthorized && isTGAuthorized
                                        return currentCoinNameIsNotNull && !countdownEndsTrue && isNewMemeNoGATO &&
                                            <div
                                                className={`${isPhoneClientWidth ? 'width100 childrenSpacingTopPhone' : 'childrenSpacingTopBig'} boxSizingBorderBox`}
                                                style={{
                                                    padding: `${isPhoneClientWidth ? 1.5 : .31}rem`,
                                                    borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                    boxShadow: `0 0 ${isPhoneClientWidth ? 1.5 : .5}rem #FFB9C2`,
                                                    border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                                }}>
                                                <div
                                                    className={'flexCenter justifyContentSpaceBetween childrenSpacingLeft'}>
                                                                    <pre className={'saf'}
                                                                         style={{fontSize: `${isPhoneClientWidth ? 1.2 : .4}rem`}}>{`My Airdrop`}</pre>
                                                    <div
                                                        style={{
                                                            transform: 'scale(.7)',
                                                            transformOrigin: 'right'
                                                        }}
                                                        className={`flexCenter justifyContentFlexEnd ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} flexGrow1`}>
                                                        {countdown.map(countdownContent)}
                                                    </div>
                                                </div>
                                                <div style={{borderRadius: `${isPhoneClientWidth ? 1.2 : .4}rem`}}
                                                     className={`${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopBigPhone' : 'paddingBigger childrenSpacingTopBig'} borderRadius overflowVisible backgroundWhite`}
                                                >
                                                    <div
                                                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBig'}`}>
                                                        <div
                                                            className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                            <div
                                                                className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}>{currentCoinName}:
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{claimAmount}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`flex ${isPhoneClientWidth ? 'childrenSpacingLeftPhone borderRadiusPhone paddingPhone' : 'childrenSpacingLeft borderRadius padding'}`}
                                                    style={{background: 'rgba(255,255,255,.3)'}}
                                                >
                                                    <img src={requireDefault('tips')} alt=""
                                                         style={square(`${isPhoneClientWidth ? .9 : .3}rem`)}/>
                                                    <pre
                                                        style={{
                                                            lineHeight: 1.5,
                                                            fontSize: `${isPhoneClientWidth ? .7 : .27}rem`
                                                        }}>{`·  Switch network to VIZING
·  Users are required to complete verification on X
·  Users are required to complete verification on TG`}</pre>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (canClaim) {
                                                            toggleShowLoading(true)
                                                            signBiz('airdrop', currentCoinName, undefined, checkData0(dataClaimAmount).toString(), true).then(signTrue =>
                                                                isNotNull(signTrue) && wC(rewardClaimContractAddress, rewardClaimContractAbi, 'claimAirdrop', undefined, [currentCoinName, signTrue],
                                                                    hash => setTimeout(
                                                                        () => {
                                                                            toggleShowLoading()
                                                                            setClaimAmount()
                                                                            getTokenBalance().then(tokenBalance => setData({tokenBalance}))
                                                                            toggleShowLoading()
                                                                            toastSuccessful(hash)
                                                                        },
                                                                        commonChainTime
                                                                    )
                                                                ))
                                                        }
                                                    }}
                                                    className={`borderRadius999 ${canClaim ? 'backgroundBlack' : 'backGroundColorLightGray'} flexCenter cursorPointer width100 ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBig'}`}
                                                    style={{
                                                        padding: `${isPhoneClientWidth ? .75 : .25}rem 0`,
                                                        marginTop: `${isPhoneClientWidth ? 1.2 : .4}rem`
                                                    }}>
                                                    <div
                                                        className={`saf ${canClaim ? 'activeColor' : 'colorWhite'}`}
                                                        style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                                        {`CLAIM${isClaimed ? 'ED' : ''}`}
                                                    </div>
                                                </div>
                                            </div>
                                    })(),
                                    toggleShowCreatedClaim = showCreatedClaim => {
                                        if (showCreatedClaim) {
                                            toggleShowLoading(true)
                                            getEstimateGasClaimFee('claimFee', claimFeeCoinAddress, address).then(([pongFee, estimateGas] = []) =>
                                                setData({
                                                    showCreatedClaim,
                                                    showLoading: false,
                                                    claimFeeEstimateGas: estimateGas
                                                })
                                            ).catch(() => toggleShowLoading())
                                        } else {
                                            setData({showCreatedClaim})
                                        }
                                    },
                                    claimFeeAmountETH = numberETH(claimFeeAmount),
                                    claimFeeEstimateGas = checkData0(data, 'claimFeeEstimateGas'),
                                    createdData = [
                                        ['Amount', `${claimFeeAmountETH} ETH`],
                                        ['Estimated Internet Fees', `${numberETH(claimFeeEstimateGas)} ${ethBNB}`]
                                    ],
                                    rGLML = () => {
                                        claimFeeCoinsPage = firstCoinsPage
                                        return getListedMemeList(undefined, undefined,
                                            claimFeeCoinsPage, ...claimFeeParams.slice(3))
                                    },
                                    toggleClaimFeeShowSelectCoins = claimFeeShowSelectCoins => {
                                        setData({
                                            claimFeeShowSelectCoins,
                                            isMemesCreated: claimFeeShowSelectCoins
                                        })
                                        if (claimFeeShowSelectCoins) {
                                            setDataSearchSymbolEmpty()
                                            searchListedMeme(true, rGLML)
                                        }
                                    },
                                    memesCreated = (() => {
                                        const canClaim = !isMasterContract && claimFeeAmount > 0
                                        return <div className={`boxSizingBorderBox ${isPhoneClientWidth ? 'width100' : ''}`}
                                                    style={{
                                                        padding: `${isPhoneClientWidth ? 1.5 : .31}rem`,
                                                        borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                        boxShadow: `0 0 ${isPhoneClientWidth ? 1.5 : .5}rem #FFB9C2`,
                                                        border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                                    }}>
                                            <pre className={'saf'}
                                                 style={{fontSize: `${isPhoneClientWidth ? 1.2 : .4}rem`}}>{`Memes created:`}</pre>
                                            <div className={'flex'}
                                                 style={{marginTop: `${isPhoneClientWidth ? 1.2 : .4}rem`}}>
                                                <div
                                                    className={`backgroundWhite overflowVisible ${isPhoneClientWidth ? 'paddingBigPhone' : 'paddingBig'}`}
                                                    style={{
                                                        borderRadius: `${isPhoneClientWidth ? 1.2 : .4}rem`
                                                    }}>
                                                    <div
                                                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                        {(() => {
                                                            const {name} = claimFeeCoin
                                                            return isNotNullOrEmpty(name)
                                                                ? selectCoinsFunc(toggleClaimFeeShowSelectCoins, claimFeeCoins, claimFeeCoin.logo, name, setClaimFeeCoin)
                                                                : 'NONE'
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                borderRadius: `${isPhoneClientWidth ? 1.2 : .4}rem`,
                                                marginTop: `${isPhoneClientWidth ? 1.2 : .4}rem`
                                            }}
                                                 className={`${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopBigPhone' : 'paddingBigger childrenSpacingTopBig'} overflowVisible backgroundWhite`}
                                            >
                                                <div
                                                    className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                                    <div
                                                        className={`flexCenter childrenSpacingLeft`}>
                                                        <div
                                                            className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}>
                                                            Transaction fees obtained:
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>
                                                        {claimFeeAmountETH} ETH
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={() => canClaim && toggleShowCreatedClaim(true)}
                                                 className={`borderRadius999 ${canClaim ? 'backgroundBlack' : 'backGroundColorLightGray'} flexCenter cursorPointer width100 ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBig'}`}
                                                 style={{
                                                     padding: `${isPhoneClientWidth ? .75 : .25}rem 0`,
                                                     marginTop: `${isPhoneClientWidth ? 1.2 : .4}rem`
                                                 }}>
                                                <div
                                                    className={`saf ${canClaim ? 'activeColor' : 'colorWhite'}`}
                                                    style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                                    CLAIM
                                                </div>
                                            </div>
                                        </div>
                                    })(),
                                    estimatedInternetFeesETH = numberETH(estimatedInternetFees),
                                    toggleCrossItemIndex = crossItemIndex => setData({crossItemIndex}),
                                    crossChainContent = currentCoinNameIsNotNull && (!isBNB || isNewMeme) &&
                                        <div className={'width100'}
                                             style={{
                                                 borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                 boxShadow: `0 ${isPhoneClientWidth ? 1.5 : .5}rem ${isPhoneClientWidth ? 1.5 : .5}rem #FFB9C2`
                                             }}
                                        >
                                            <div style={{
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center center',
                                                height: `${isPhoneClientWidth ? 3.25 : 1.165}rem`,
                                                backgroundImage: `url(${requireDefault(crossItem[1])})`,
                                            }}
                                                 className={'flex childrenFlexGrow1'}>
                                                {
                                                    crossList.map((v, i) => <div
                                                        onClick={() => toggleCrossItemIndex(i)}
                                                        className={`flexCenter height100 saf ${isPhoneClientWidth ? 'bigFontSizePhone' : 'biggerFontSize'} cursorPointer`}>
                                                        {v[0]}
                                                    </div>)
                                                }
                                            </div>
                                            <div
                                                className={`${isPhoneClientWidth ? 'width100 childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'} boxSizingBorderBox`}
                                                style={{
                                                    padding: `${isPhoneClientWidth ? 1.5 : .31}rem`,
                                                    borderBottomLeftRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                    borderBottomRightRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                    border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                                    background: 'linear-gradient(180deg, white 0%, #FFB9C2 100%)',
                                                }}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <pre className={'saf'}
                                                                         style={{fontSize: `${isPhoneClientWidth ? 1.2 : .4}rem`}}>{crossItem[9]}</pre>
                                                </div>
                                                <div
                                                    className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'} positionRelative`}>
                                                    {
                                                        crossChains.map((v, i) => {
                                                            const isInput = i === 0
                                                            return <div
                                                                className={`backgroundWhite ${isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopBigPhone' : 'paddingBig childrenSpacingTopBig'}`}
                                                                style={{borderRadius: `${isPhoneClientWidth ? 1.2 : .4}rem`}}>
                                                                <div
                                                                    className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div
                                                                        style={{fontSize: `${isPhoneClientWidth ? .9 : .3}rem`}}>{isInput ? 'From' : 'To'}</div>
                                                                    <div
                                                                        className={`flexCenter justifyContentFlexEnd ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                                        <div
                                                                            className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>Balance: {crossItem[isInput ? 6 : 7]}</div>
                                                                        {isInput && <div
                                                                            onClick={maxFunc}
                                                                            className={`borderRadius999 ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'} saf flexCenter cursorPointer backgroundActiveColor`}
                                                                            style={{
                                                                                border: `${isPhoneClientWidth ? .15 : .05}rem solid`,
                                                                                padding: `0 ${isPhoneClientWidth ? .6 : .2}rem`,
                                                                                height: `${isPhoneClientWidth ? 1 : .35}rem`,
                                                                            }}>
                                                                            MAX
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeft'}`}>
                                                                    {selectFunc(
                                                                        v[2],
                                                                        showSelectChains => setData({showSelectChains}),
                                                                        checkDataBool(data, 'showSelectChains'),
                                                                        v[1],
                                                                        v[0],
                                                                        portfolioChains,
                                                                        (v, i) => fromMaster ? portfolioChainIndex === i : currentChainId === v.id,
                                                                        (v, i) => fromMaster
                                                                            ? setData({portfolioChainIndex: i})
                                                                            : switchEthereumChain(
                                                                                undefined,
                                                                                undefined,
                                                                                true,
                                                                                portfolioChains[i]
                                                                            ),
                                                                        v => requireDefault(`chainIcons/${v.id}`),
                                                                        v => v.name,
                                                                        true
                                                                    )}
                                                                    <input
                                                                        style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}
                                                                        disabled={!isInput}
                                                                        value={newAmount}
                                                                        onChange={e => setNewAmount(e.target.value)}
                                                                        className={'saf flexGrow1 textAlignRight colorBlackInput'}
                                                                        type="text" name="" id=""/>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                    <div
                                                        className={`backgroundWhite borderRadius999 ${isPhoneClientWidth ? 'paddingBigPhone' : 'paddingBig'} flexCenter justifyContentSpaceBetween`}>
                                                        <div
                                                            className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>
                                                            Estimated Internet Fees
                                                        </div>
                                                        <div
                                                            className={'flexCenter childrenSpacingLeft'}>
                                                            <img src={requireDefault('cheerOn')} alt=""
                                                                 style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>
                                                            <div
                                                                style={{fontSize: `${isPhoneClientWidth ? .75 : .25}rem`}}>
                                                                {estimatedInternetFeesETH} {ethBNB}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img onClick={() => toggleFromMaster(!fromMaster)}
                                                         className={'positionAbsolute cursorPointer'}
                                                         src={requireDefault('SWAP')} alt=""
                                                         style={{
                                                             width: `${isPhoneClientWidth ? 2.7 : .8}rem`,
                                                             top: `${isPhoneClientWidth ? 4.7 : 1.4}rem`,
                                                             left: '44%'
                                                         }}
                                                    />
                                                </div>
                                                <div onClick={() => toggleShowCross(true)}
                                                     className={`borderRadius999 backgroundBlack flexCenter cursorPointer width100 ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBig'}`}
                                                     style={{
                                                         padding: `${isPhoneClientWidth ? .75 : .25}rem 0`,
                                                         marginTop: `${isPhoneClientWidth ? 1.2 : .4}rem`
                                                     }}>
                                                    <div
                                                        className={'saf activeColor'}
                                                        style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                                        SEND
                                                    </div>
                                                </div>
                                            </div>
                                        </div>,
                                    twitterTGAuthorize = [
                                        [isTwitterAuthorized, twitterAuthorizeData],
                                        [isTGAuthorized, {
                                            authorizeKey: tGAuthorizeAuthorizeKey,
                                            authorizePoints: tGAuthorizePoints,
                                            title: 'Join TG Group',
                                        }],
                                    ]
                                return <div>
                                    {
                                        isPhoneClientWidth
                                            ? pageTopFootPhone(
                                                <div
                                                    className={'zIndex1 positionRelative flexColumn justifyContentSpaceBetween'}
                                                    style={{
                                                        background: 'linear-gradient(180deg, #FFD3D5 0%, #FFFFFF 40%)',
                                                        minHeight: '100vh'
                                                    }}>
                                                    <img className={'positionAbsolute width100 zIndex-1'}
                                                         src={requireDefault('POINTS-bg2')}
                                                         alt=""
                                                         style={{bottom: 0, left: 0, right: 0}}
                                                    />
                                                    <div className={'flexColumnCenter childrenSpacingTopBigPhone'}
                                                         style={{padding: '6rem 2.5rem 2.5rem 2.5rem'}}
                                                    >
                                                        <div
                                                            className={'width100 borderRadius999 overflowVisible flexCenter childrenSpacingLeftPhone'}
                                                            style={{
                                                                padding: '.8rem 0',
                                                                border: 'solid .15rem white',
                                                            }}>
                                                            {selectCoins}
                                                        </div>
                                                        {myDrop}
                                                        <div
                                                            className={'width100 boxSizingBorderBox childrenSpacingTopBigPhone'}
                                                            style={{
                                                                borderRadius: '1.5rem',
                                                                padding: '1.5rem',
                                                                border: 'solid .15rem white',
                                                                boxShadow: '0 0 1.5rem #FFB9C2',
                                                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                                            }}>
                                                    <pre className={'saf'} style={{fontSize: '1.2rem'}}>{`cross-chain failure, 
assets locked on the Vizing Network:`}</pre>
                                                            <div
                                                                className={'paddingBigPhone borderRadiusPhone backgroundWhite childrenSpacingTopBigPhone'}>
                                                                {
                                                                    lockedData.map(v => <div
                                                                        className={'flex alignItemsCenter childrenSpacingLeftBigPhone'}>
                                                                        {v[2]
                                                                            ? <div
                                                                                className={'smallerFontSizePhone'}>{currentCoinName}:</div>
                                                                            : <div
                                                                                className={'smallerFontSizePhone'}>{v[0]}:</div>}
                                                                        <div
                                                                            className={'saf1 smallerFontSizePhone'}>{v[1]}</div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                            <div onClick={unlock}
                                                                 className={`borderRadius999 ${canUnlock ? 'backgroundBlack' : 'backGroundColorLightGray'}  flexCenter cursorPointer width100 childrenSpacingLeftPhone`}
                                                                 style={{padding: '1rem 0', fontSize: '1.5rem'}}>
                                                                {canUnlock && <img src={requireDefault('unlock')} alt=""
                                                                                   style={{width: '1.2rem'}}/>}
                                                                <div
                                                                    className={`saf ${canUnlock ? 'activeColor' : 'colorWhite'}`}
                                                                    style={{fontSize: '1.2rem'}}>
                                                                    UNLOCK
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {crossChainContent}
                                                        {memesCreated}
                                                        <div
                                                            className={'width100 childrenSpacingTopBigPhone boxSizingBorderBox positionRelative'}
                                                            style={{
                                                                borderRadius: '1.5rem',
                                                                padding: '3rem 1.5rem',
                                                                border: 'solid .15rem white',
                                                                boxShadow: '0 0 1.5rem #FFB9C2',
                                                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                                            }}>
                                                            <div className={'flex alignItemsCenter childrenSpacingLeftPhone'}>
                                                                <img src={requireDefault('integral')} alt=""
                                                                     style={square('1.7rem')}/>
                                                                <div className={'saf'} style={{fontSize: '1.7rem'}}>
                                                                    DROPLETS
                                                                </div>
                                                            </div>
                                                            <div className={'saf'}
                                                                 style={{fontSize: '2rem'}}>{totalPoints}</div>
                                                            <div style={{border: 'solid .05rem rgba(0,0,0,.3)'}}/>
                                                            <div className={'childrenSpacingTopPhone'}>
                                                                <div className={'saf'} style={{fontSize: '1.7rem'}}>
                                                                    Tasks
                                                                </div>
                                                                {
                                                                    pointsData.map(v => <div
                                                                        className={'flexCenter justifyContentSpaceBetween'}>
                                                                        <div
                                                                            className={'flexCenter childrenSpacingLeftSmallPhone'}>
                                                                            <div className={'smallerFontSizePhone'}>{v[0]}</div>
                                                                            <img className={'cursorPointer'}
                                                                                 onClick={() => toggleShowTaskContent(true, v[2])}
                                                                                 src={requireDefault('Frame')}
                                                                                 alt="" style={{width: '.7rem'}}/>
                                                                        </div>
                                                                        <div
                                                                            className={'flexCenter childrenSpacingLeftSmallPhone'}>
                                                                            <div
                                                                                className={'smallerFontSizePhone'}>{v[1]}</div>
                                                                            <img src={requireDefault('fluctuate')}
                                                                                 alt="" style={{width: '.7rem'}}/>
                                                                        </div>
                                                                    </div>)
                                                                }
                                                            </div>
                                                            <div style={{border: 'solid .05rem rgba(0,0,0,.3)'}}/>
                                                            <div className={'childrenSpacingTopPhone'}>
                                                                <div className={'saf'} style={{fontSize: '1.7rem'}}>
                                                                    Other tasks
                                                                </div>
                                                                {
                                                                    twitterTGAuthorize.map(v => {
                                                                        const isAuthorized = v[0], aD = v[1]
                                                                        return <div
                                                                            className={'borderRadiusPhone paddingPhone justifyContentSpaceBetween childrenSpacingTopPhone'}
                                                                            style={{background: 'linear-gradient(90deg, #FFFFFF 0%, #FFEDE7 100%)'}}
                                                                        >
                                                                            <div className={'childrenSpacingTopSmallPhone'}>
                                                                                <div className={'smallFontSizePhone'}>
                                                                                    {aD.title}
                                                                                </div>
                                                                                <div style={{fontSize: '.75rem'}}
                                                                                     className={'colorGray'}>
                                                                                    Complete {aD.authorizeKey.toLocaleUpperCase()} authorization
                                                                                    before
                                                                                    starting the task
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{border: 'solid .05rem rgba(0,0,0,.3)'}}/>
                                                                            <div
                                                                                className={'flexCenter justifyContentSpaceBetween childrenSpacingLeftBigPhone'}>
                                                                                <div
                                                                                    className={'saf1'}>{isAuthorized ? '' : '+ '}{aD.authorizePoints}</div>
                                                                                <div style={{
                                                                                    padding: '.3rem .9rem', ...ternaryObject(
                                                                                        isAuthorized,
                                                                                        {background: '#FF7789'}
                                                                                    )
                                                                                }}
                                                                                     onClick={() => !isAuthorized && toggleShowAuthorize(true, undefined, aD)}
                                                                                     className={'backgroundBlack flexCenter childrenSpacingLeftPhone borderRadius999 cursorPointer'}>
                                                                                    <div
                                                                                        className={'colorWhite smallFontSizePhone'}>
                                                                                        {isAuthorized ? 'Done' : 'Authorize'}
                                                                                    </div>
                                                                                    {isAuthorized &&
                                                                                        <img src={requireDefault('Ellipse 45')}
                                                                                             alt=""
                                                                                             style={{width: `.75rem`}}
                                                                                        />}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            <img className={'positionAbsolute'}
                                                                 src={requireDefault('catCat4')}
                                                                 alt=""
                                                                 style={{width: '6rem', top: '1.3rem', right: '1.3rem'}}/>
                                                        </div>
                                                    </div>
                                                    {pageFootPhone}
                                                </div>,
                                                false
                                            )
                                            : <div>
                                                {pageTop}
                                                <div
                                                    className={'positionRelative flexColumnCenter justifyContentSpaceBetween boxSizingBorderBox zIndex1'}
                                                    style={{
                                                        minHeight: '100vh',
                                                        paddingTop: '2rem',
                                                        background: 'linear-gradient(180deg, #FFD3D5 0%, #FFFFFF 64%)'
                                                    }}>
                                                    <div style={{paddingBottom: '4rem'}}
                                                         className={'flex justifyContentCenter width100 childrenSpacingLeftBig'}>
                                                        <div className={'flexColumn childrenSpacingTopBig'}
                                                             style={{width: '30%'}}>
                                                            <div className={'flex'}>
                                                                <div className={'borderRadius999 overflowVisible'} style={{
                                                                    padding: '.1rem .5rem',
                                                                    border: 'solid .05rem white',
                                                                }}>
                                                                    <div
                                                                        className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                        {selectCoins}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {myDrop}
                                                            <div className={'boxSizingBorderBox'}
                                                                 style={{
                                                                     padding: '.31rem',
                                                                     borderRadius: '.5rem',
                                                                     boxShadow: '0 0 .5rem #FFB9C2',
                                                                     border: 'solid .05rem white',
                                                                     background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                                                 }}>
                                                    <pre className={'saf'} style={{fontSize: '.43rem'}}>{`cross-chain failure, 
assets locked on the Vizing Network:`}</pre>
                                                                <div style={{borderRadius: '.3rem', marginTop: '.4rem'}}
                                                                     className={'paddingBigger borderRadius overflowVisible backgroundWhite childrenSpacingTopBig'}
                                                                >
                                                                    {
                                                                        lockedData.map(v => <div
                                                                            className={'flex alignItemsCenter childrenSpacingLeftBig'}>
                                                                            {v[2]
                                                                                ? <div
                                                                                    className={'flexCenter childrenSpacingLeft'}>
                                                                                    <div>{currentCoinName}:</div>
                                                                                </div>
                                                                                : <div>{v[0]}:</div>}
                                                                            <div className={'saf1'}>{v[1]}</div>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                                <div onClick={unlock}
                                                                     className={`borderRadius999 ${canUnlock ? 'backgroundBlack' : 'backGroundColorLightGray'} flexCenter cursorPointer width100 childrenSpacingLeftBig`}
                                                                     style={{padding: '.25rem 0', marginTop: '.4rem'}}>
                                                                    {canUnlock && <img src={requireDefault('unlock')} alt=""
                                                                                       style={{width: '.5rem'}}/>}
                                                                    <div
                                                                        className={`saf ${canUnlock ? 'activeColor' : 'colorWhite'}`}
                                                                        style={{fontSize: '.5rem'}}>
                                                                        UNLOCK
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {crossChainContent}
                                                            {memesCreated}
                                                        </div>
                                                        <div className={'flexColumn'} style={{width: '50%'}}>
                                                            <div
                                                                className={'childrenSpacingTopBigger boxSizingBorderBox positionRelative'}
                                                                style={{
                                                                    padding: '.7rem',
                                                                    borderRadius: '.5rem',
                                                                    boxShadow: '0 0 .5rem #FFB9C2',
                                                                    border: 'solid .05rem white',
                                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                                                }}>
                                                                <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                    <img src={requireDefault('integral')} alt=""
                                                                         style={square('.7rem')}/>
                                                                    <div className={'saf'} style={{fontSize: '.8rem'}}>
                                                                        DROPLETS
                                                                    </div>
                                                                </div>
                                                                <div className={'saf'}
                                                                     style={{fontSize: '1.25rem'}}>{totalPoints}</div>
                                                                <div style={{border: 'solid .015rem rgba(0,0,0,.3)'}}/>
                                                                <div className={'childrenSpacingTopBigger'}>
                                                                    <div className={'saf'} style={{fontSize: '.8rem'}}>
                                                                        Tasks
                                                                    </div>
                                                                    {
                                                                        pointsData.map(v => <div
                                                                            className={'flexCenter justifyContentSpaceBetween'}>
                                                                            <div
                                                                                className={'flexCenter childrenSpacingLeftSmall'}>
                                                                                <div className={'bigFontSize'}>{v[0]}</div>
                                                                                <img className={'cursorPointer'}
                                                                                     onClick={() => toggleShowTaskContent(true, v[2])}
                                                                                     src={requireDefault('Frame')}
                                                                                     alt="" style={{width: '.3rem'}}/>
                                                                            </div>
                                                                            <div
                                                                                className={'flexCenter childrenSpacingLeftSmall'}>
                                                                                <div className={'bigFontSize'}>{v[1]}</div>
                                                                                <img src={requireDefault('fluctuate')}
                                                                                     alt="" style={{width: '.5rem'}}/>
                                                                            </div>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                                <div style={{border: 'solid .015rem rgba(0,0,0,.3)'}}/>
                                                                <div className={'childrenSpacingTopBig'}>
                                                                    <div className={'saf'} style={{fontSize: '.8rem'}}>
                                                                        Other tasks
                                                                    </div>
                                                                    {
                                                                        twitterTGAuthorize.map(v => {
                                                                            const isAuthorized = v[0], aD = v[1]
                                                                            return <div
                                                                                className={'borderRadius paddingBig flexCenter justifyContentSpaceBetween childrenSpacingLeft'}
                                                                                style={{background: 'linear-gradient(90deg, #FFFFFF 0%, #FFEDE7 100%)'}}
                                                                            >
                                                                                <div>
                                                                                    <div
                                                                                        className={'bigFontSize'}>{aD.title}</div>
                                                                                    <div className={'colorGray'}>
                                                                                        Complete {aD.authorizeKey.toLocaleUpperCase()} authorization
                                                                                        before
                                                                                        starting the task
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={'flexCenter childrenSpacingLeftBigger'}>
                                                                                    <div
                                                                                        className={'bigFontSize'}>{isAuthorized ? '' : '+ '}{aD.authorizePoints}</div>
                                                                                    <div style={{
                                                                                        padding: '.1rem .3rem', ...ternaryObject(
                                                                                            isAuthorized,
                                                                                            {background: '#FF7789'}
                                                                                        )
                                                                                    }}
                                                                                         onClick={() => !isAuthorized && toggleShowAuthorize(true, undefined, aD)}
                                                                                         className={'backgroundBlack flexCenter childrenSpacingLeft borderRadius999 cursorPointer'}>
                                                                                        <div
                                                                                            className={'colorWhite smallFontSize'}>
                                                                                            {isAuthorized ? 'Done' : 'Authorize'}
                                                                                        </div>
                                                                                        {isAuthorized &&
                                                                                            <img
                                                                                                src={requireDefault('Ellipse 45')}
                                                                                                alt=""
                                                                                                style={{width: `.25rem`}}
                                                                                            />}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                                <img className={'positionAbsolute'}
                                                                     src={requireDefault('catCat4')}
                                                                     alt=""
                                                                     style={{width: '2.7rem', top: '.5rem', right: '.7rem'}}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'zIndex1 width100'}>{pageFoot}</div>
                                                    <img className={'positionAbsolute width100 zIndex-1'}
                                                         src={requireDefault('Rectangle 317')}
                                                         alt=""
                                                         style={{bottom: '1rem', left: 0, right: 0}}
                                                    />
                                                </div>
                                            </div>
                                    }
                                    {confirmExchange(toggleShowCross, checkDataBool(data, 'showCross'), ...(() => {
                                            const [item, item1] = crossChains
                                            return [item[1], item1[1], item[0], item1[0]]
                                        })(), newAmount, newAmount, crossFunctionName, estimatedInternetFeesETH,
                                        () => switchEthereumChain(
                                            () => {
                                                if (newAmountNumber > 0) {
                                                    toggleShowLoading(true);
                                                    (crossItem[3])(
                                                        crossFunctionName,
                                                        BigInt(npPlus(Number(estimatedInternetFees), isEthCrossTo ? Number(parseEtherWei(newAmount)) : 0)),
                                                        [crossToChainId, address, parseEtherWei(newAmount)],
                                                        hash => setTimeout(
                                                            () => {
                                                                toggleShowCross()
                                                                setNewAmountEmpty()
                                                                getCrossBalance()
                                                                getCrossToBalance()
                                                                getTokenBalance().then(tokenBalance => setData({tokenBalance}))
                                                                toggleShowLoading()
                                                                toastSuccessfulHash(hash)
                                                            },
                                                            pingCrossChainTime
                                                        )
                                                    )
                                                } else {
                                                    toastEmpty()
                                                }
                                            },
                                            undefined,
                                            true,
                                            fromMaster ? masterChain : currentPortfolioChain
                                        ))}
                                    {
                                        showCreatedClaim &&
                                        <div className={'positionFixed flexColumnCenter'}
                                             style={{
                                                 top: 0,
                                                 left: 0,
                                                 right: 0,
                                                 bottom: 0,
                                                 zIndex: 1000,
                                                 background: 'rgba(0,0,0,.56)',
                                             }}>
                                            <div
                                                className={`${isPhoneClientWidth ? 'childrenSpacingTopPhone borderRadiusPhone paddingPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                                                style={{
                                                    width: '25rem',
                                                    border: 'solid .15rem white',
                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                    ...ternaryObject(
                                                        !isPhoneClientWidth,
                                                        {
                                                            width: '12rem',
                                                            padding: '.7rem',
                                                            borderRadius: '.5rem',
                                                            border: 'solid .05rem white',
                                                        }
                                                    )
                                                }}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'saf'}
                                                         style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                                        Confirm
                                                    </div>
                                                    <img onClick={() => toggleShowCreatedClaim()}
                                                         className={'cursorPointer'}
                                                         src={requireDefault('close')}
                                                         alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                                </div>
                                                <div
                                                    className={`backgroundWhite ${isPhoneClientWidth ? 'paddingPhone childrenSpacingTopPhone borderRadiusPhone' : 'paddingBigger borderRadius childrenSpacingTopBig'}`}>
                                                    {
                                                        createdData.map((v, i) => [
                                                            ...ternaryArray(
                                                                i === createdData.length - 1,
                                                                [<div
                                                                    style={{border: `solid ${isPhoneClientWidth ? .06 : .02}rem #D8D8D8`}}/>]
                                                            ),
                                                            <div
                                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                                {v.map(v => <div
                                                                    style={{fontSize: `${isPhoneClientWidth ? .9 : .3}rem`}}>{v}</div>)}
                                                            </div>])
                                                    }
                                                    {gasIsHigh('claim', estimatedInternetFees)}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        toggleShowLoading(true)
                                                        getEstimateGasClaimFee('claimFee', claimFeeCoinAddress, address).then(([pongFee, estimateGas]) =>
                                                            createMemeSlaveWriteContract(
                                                                'claimFee',
                                                                estimateGas,
                                                                [
                                                                    pongFee,
                                                                    claimFeeCoinAddress, address, claimFeeAmount
                                                                ],
                                                                hash => setTimeout(
                                                                    () => {
                                                                        toggleShowLoading()
                                                                        toggleShowCreatedClaim()
                                                                        toastSuccessfulHash(hash)
                                                                        getClaimFeeAmount()
                                                                    },
                                                                    1000 * crossChainTimeSeconds
                                                                )
                                                            )).catch(() => toggleShowLoading())
                                                    }}
                                                    className={'borderRadius999 backgroundBlack saf flexCenter cursorPointer width100 activeColor'}
                                                    style={{
                                                        padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                                        fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                                    }}>
                                                    CONFIRM
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {selectCoinsContentFunc(
                                        claimFeeCoins,
                                        checkDataBool(data, 'claimFeeShowSelectCoins'),
                                        toggleClaimFeeShowSelectCoins,
                                        () => coinsScrollFunc(undefined, claimFeeCoins, claimFeeCoinsLengthKey, ...claimFeeParams),
                                        setClaimFeeCoin, rGLML)}
                                    {selectCoinsContent}
                                    {authorizeContent}
                                    {taskContent}
                                    {deployment}
                                    {loading}
                                    {previousTips}
                                    {drip}
                                </div>
                            })()
                        }
                    ),
                    ...ternaryObject(
                        votePrdOpen,
                        {
                            'vote/*': (() => {
                                const
                                    voteListContent = checkDataArray(data, 'voteListContent'),
                                    voteListContentIsNotNullOrEmpty = isNotNullOrEmpty(voteListContent),
                                    voteMemeIsNotNull = isNotNull(voteMeme.name),
                                    {
                                        tokenomics: voteMemeTokenomics,
                                        totalSupply: voteMemeTotalSupply = 0,
                                    } = voteMeme,
                                    voteMemeReceiveText = checkData([tokenomicsTextList(voteMeme.quota, voteMeme.presale), voteMemeTokenomics, 2, 0]),
                                    voteMemeReceiveIsNotNull = isNotNull(voteMemeReceiveText),
                                    totalVotes = launchFunds => Math.floor(npTimes(launchFunds, listingVotesMinRatio)),
                                    voteDone = vM => vM.votes >= totalVotes(vM.launchFunds),
                                    isVoted = checkDataBool(data, 'isVoted') || checkDataBool(data, 'voteMemeNotInRound') || (voteMemeIsNotNull && voteDone(voteMeme)),
                                    // cloudflareHumanContent = !signTrue && !isVoted && address && cloudflareHumanDiv,
                                    showInvite = checkDataBool(data, 'showInvite'),
                                    toggleShowInvite = (showInvite, inviteSymbol) => setData({
                                        showInvite,
                                        inviteSymbol,
                                    }),
                                    childInviteRecords = checkDataArray(data, 'childInviteRecords'),
                                    votePoints = checkData0(data, 'votePoints'),
                                    votePointsData = (() => {
                                        const allVotePoints = checkData0(data, 'allVotePoints')
                                        return [
                                            ['My voting points', votePoints],
                                            ['Total voting points', allVotePoints],
                                            ...ternaryArray(
                                                voteMemeReceiveIsNotNull,
                                                [[
                                                    'Claim upon listing',
                                                    `${(() => {
                                                        const claimAfterListing =
                                                            npTimes(
                                                                npDivide(
                                                                    npTimes(
                                                                        voteMemeTotalSupply,
                                                                        airdropProportion(voteMemeTokenomics, voteMeme.quota, voteMeme.presale),
                                                                    ),
                                                                    allVotePoints
                                                                ),
                                                                votePoints
                                                            )
                                                        return numberToFixed(isNaN(claimAfterListing) ? 0 : claimAfterListing);
                                                    })()} ${voteMeme.name}`
                                                ]]
                                            ),
                                        ]
                                    })(),
                                    iVA = true,//isVoted || isTwitterAuthorized,
                                    voteAchieved = (votes, launchFunds) => {
                                        const tV = totalVotes(launchFunds),
                                            vD = votes >= tV
                                        return <div
                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                            <img src={requireDefault(vD ? 'Achieved' : 'crown')} alt=""
                                                 style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>
                                            <div
                                                className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>{vD ? 'Achieved' : 'Required votes to launch'} :
                                            </div>
                                            <div
                                                className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}`}>
                                                {tV}{vD ? '' : '+'}
                                            </div>
                                        </div>
                                    },
                                    hotMemes = checkDataArray(data, 'hotMemes'),
                                    hotMemesLength = hotMemes.length,
                                    hotMemesSwiper = <div
                                        className={'borderRadius999 backgroundWhite'}
                                        style={{
                                            height: `${isPhoneClientWidth ? 2.5 : 1}rem`,
                                            marginLeft: `${isPhoneClientWidth ? 1 : 2}rem`,
                                            padding: `${isPhoneClientWidth ? .21 : .07}rem`,
                                        }}
                                    >
                                        <div
                                            className={`borderRadius999 height100 flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftBig'}`}
                                            style={{
                                                padding: `0 ${isPhoneClientWidth ? .5 : .7}rem`,
                                                background: 'linear-gradient(270deg, #FFB9C2 13.6%, #FFF9F0 100%)'
                                            }}
                                        >
                                            <img style={{height: '60%'}}
                                                 src={requireDefault('hot')}
                                                 alt=""/>
                                            {(() => {
                                                const hotMemeStep = 1
                                                return hotMemesLength > 0 && <Swiper loop
                                                                                     className={'height100'}
                                                                                     direction='vertical'
                                                                                     ref={swiperRef}
                                                                                     allowTouchMove={false}
                                                                                     autoplay={hotMemesLength > hotMemeStep
                                                                                         ? {
                                                                                             // delay: 3000, //轮播间隔，默认3000
                                                                                             // stopOnLastSlide: false, //是否最后一项轮播后停止，默认false，最后一项后会重置到第一个，loop=true时该属性无效
                                                                                             disableOnInteraction: false, //手动触发轮播后，是否停止自动轮播，默认为true，鼠标操作后会停止
                                                                                         }
                                                                                         : false
                                                                                     }
                                                >
                                                    {
                                                        [...Array(Math.ceil(npDivide(hotMemesLength, hotMemeStep))).keys()].map(v =>
                                                            hotMemes.slice(hotMemeStep * v, hotMemeStep * (v + 1))).map(v =>
                                                            isNotNullOrEmpty(v) && <SwiperSlide
                                                                className={'flex alignItemsCenter marginLeftBig height100'}>
                                                                <div
                                                                    className={'flexCenter childrenSpacingLeftBig height100'}>
                                                                    {
                                                                        v.map(v => <div
                                                                            style={{width: `${isPhoneClientWidth ? 6.5 : 2.3}rem`}}
                                                                            className={`flex alignItemsCenter height100 ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeft'}`}>
                                                                            <img className={'borderRadius999'}
                                                                                 style={{height: '60%'}}
                                                                                 src={v.logo}
                                                                                 alt=""/>
                                                                            <div
                                                                                className={`saf textOverflowEllipsis flexGrow1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'bigFontSize'}`}>
                                                                                {v.symbol}
                                                                            </div>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </SwiperSlide>)
                                                    }
                                                </Swiper>
                                            })()}
                                        </div>
                                    </div>,
                                    notesOn = <div
                                        className={`${isPhoneClientWidth ? 'borderRadiusPhone childrenSpacingLeftSmallPhone childrenSpacingTopPhone' : 'borderRadius childrenSpacingLeftSmall childrenSpacingTop'} backgroundActiveColor`}
                                        style={{
                                            background: 'rgba(255, 221, 225, .6)',
                                            padding: `${isPhoneClientWidth ? .7 : .2}rem`,
                                        }}
                                    >
                                        <div
                                            className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                            <img
                                                src={requireDefault('previousTips')}
                                                style={{width: `${isPhoneClientWidth ? 1 : .25}rem`}}
                                                alt=""/>
                                            <div
                                                className={isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}>
                                                Notes on claiming airdrops after launch:
                                            </div>
                                        </div>
                                        <div
                                            className={isPhoneClientWidth ? 'childrenSpacingTopSmallPhone' : 'childrenSpacingTopSmall'}>
                                            {
                                                [
                                                    'Users are required to complete verification on X',
                                                    'Users are required to complete verification on TG',
                                                    `Users are required to claim within three days, ${isPhoneClientWidth ? '' : '\n'}or the airdrop will become invalid`,
                                                ].map(v => <div
                                                    className={`flex ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                    <div
                                                        className={'borderRadius999 backgroundBlack'}
                                                        style={{
                                                            ...square(`${isPhoneClientWidth ? .15 : .03}rem`),
                                                            marginTop: `${isPhoneClientWidth ? .4 : .1}rem`
                                                        }}/>
                                                    <pre className={isPhoneClientWidth ? 'flexGrow1' : ''}
                                                         style={{fontSize: `${isPhoneClientWidth ? .8 : .21}rem`}}>{v}</pre>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                SwiperCore.use([Autoplay])
                                return <div>
                                    {
                                        voteSymbol
                                            ? (
                                                isPhoneClientWidth
                                                    ? pageTopFootPhone(
                                                        <div
                                                            className={'boxSizingBorderBox flexColumnCenter justifyContentSpaceBetween'}
                                                            style={{
                                                                minHeight: '100vh',
                                                                background: 'linear-gradient(180deg, #FFD3D5 0%, #FFF9F0 100%)'
                                                            }}>
                                                            <div style={{paddingTop: '4rem', paddingLeft: '1.5rem'}}
                                                                 className={'width100 flex boxSizingBorderBox'}>
                                                                <div onClick={() => windowReplace('/vote')}
                                                                     className={'flexCenter childrenSpacingLeftPhone cursorPointer'}>
                                                                    <img src={requireDefault('back')} alt=""
                                                                         style={{width: '1.5rem'}}/>
                                                                    <div className={'saf'}>BACK</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                voteMemeIsNotNull &&
                                                                <div
                                                                    className={'width100 flexGrow1Column flexColumn justifyContentSpaceBetween boxSizingBorderBox childrenSpacingTopBigPhone'}
                                                                    style={{minHeight: '30rem'}}>
                                                                    <div/>
                                                                    <div style={{padding: '0 1.5rem 1rem 1.5rem'}}
                                                                         className={'flexColumn flexGrow1Column childrenSpacingTopBigPhone overflowYScroll'}>
                                                                        <div
                                                                            className={'flexGrow1Column flexColumn childrenSpacingTopBigPhone'}>
                                                                            <div className={'childrenSpacingTopBigPhone'}>
                                                                                {
                                                                                    voteMemeReceiveIsNotNull && <div
                                                                                        style={{
                                                                                            marginTop: '2rem',
                                                                                            background: 'linear-gradient(270deg, #FFF9F0 51.05%, #FFB9C2 100%)',
                                                                                        }}
                                                                                        className={'borderRadiusPhone paddingPhone zIndex1 overflowVisible width100 positionRelative flexCenter boxSizingBorderBox justifyContentSpaceBetween childrenSpacingLeftPhone'}>
                                                                                        <div>
                                                                                            <img
                                                                                                src={requireDefault('Vote for rewards')}
                                                                                                alt=""
                                                                                                className={'positionAbsolute zIndex-1'}
                                                                                                style={{
                                                                                                    height: '100%',
                                                                                                    top: '-2rem',
                                                                                                    right: '3.5rem'
                                                                                                }}
                                                                                            />
                                                                                            <div style={{width: '60%'}}>
                                                                                                <div className={'saf1'}
                                                                                                     style={{fontSize: '.7rem'}}>
                                                                                                    The platform will airdrop <span
                                                                                                    style={{fontSize: '.7rem'}}
                                                                                                    className={'saf1'}>{voteMemeReceiveText}</span> of
                                                                                                    the listed meme to its voters and
                                                                                                    creator
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{
                                                                                                margin: '.5rem 0',
                                                                                                borderBottom: '.05rem solid rgba(0,0,0,.3)',
                                                                                            }}></div>
                                                                                            <div
                                                                                                style={{fontSize: '.5rem'}}>The more
                                                                                                users you
                                                                                                invite, the higher the proportion of
                                                                                                tokens you will
                                                                                                receive.
                                                                                            </div>
                                                                                        </div>
                                                                                        <img
                                                                                            src={requireDefault('Group 427320089')}
                                                                                            alt=""
                                                                                            onClick={() => toggleShowTaskContent(
                                                                                                true,
                                                                                                getAirdrop)}
                                                                                            className={'cursorPointer'}
                                                                                            style={{width: '2rem'}}/>
                                                                                    </div>
                                                                                }
                                                                                <div
                                                                                    className={'backgroundWhite borderRadiusPhone borderRadius childrenSpacingTop flexColumn ' +
                                                                                        'boxSizingBorderBox paddingBottom justifyContentSpaceBetween'}
                                                                                >
                                                                                    <div
                                                                                        className={'flex alignItemsFlexEnd width100 justifyContentSpaceBetween childrenSpacingLeft'}>
                                                                                        {/*{*/}
                                                                                        {/*    (() => {*/}
                                                                                        {/*        const isNo1 = i === 0*/}
                                                                                        {/*        return <div style={{*/}
                                                                                        {/*            width: '15%',*/}
                                                                                        {/*            height: '2rem',*/}
                                                                                        {/*            padding: '0 .9rem',*/}
                                                                                        {/*            borderTopRightRadius: '999px',*/}
                                                                                        {/*            borderBottomRightRadius: '999px',*/}
                                                                                        {/*            background: isNo1 ? '#FFDDE1' : '#EAF5FF',*/}
                                                                                        {/*        }}*/}
                                                                                        {/*                    className={'flexCenter childrenSpacingLeftSmallPhone'}>*/}
                                                                                        {/*            {isNo1 && <img style={{height: '60%'}}*/}
                                                                                        {/*                           src={requireDefault('fire1')}*/}
                                                                                        {/*                           alt=""/>}*/}
                                                                                        {/*            <div*/}
                                                                                        {/*                className={'saf smallFontSizePhone'}*/}
                                                                                        {/*                style={ternaryObject(isNo1, {color: '#FF3C5B'})}*/}
                                                                                        {/*            >NO.{npPlus(i, 1)}</div>*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*    })()*/}
                                                                                        {/*}*/}
                                                                                    </div>
                                                                                    <div
                                                                                        className={'paddingBigPhone childrenSpacingTopBigPhone'}>
                                                                                        <div
                                                                                            className={'flex childrenSpacingLeftPhone childrenFlexGrow1'}>
                                                                                            <div
                                                                                                className={'childrenSpacingLeftPhone flex alignItemsCenter'}>
                                                                                                <div style={square('4rem')}>
                                                                                                    <img
                                                                                                        className={'height100 width100 borderRadius999'}
                                                                                                        src={voteMeme.logo}
                                                                                                        alt=""/>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'saf'}>{voteMeme.name}</div>
                                                                                            </div>
                                                                                            <div
                                                                                                className={'flexColumn alignItemsFlexEnd childrenSpacingTopPhone'}>
                                                                                                <div
                                                                                                    className={'smallerFontSizePhone flexCenter childrenSpacingLeftPhone'}>
                                                                                                    {
                                                                                                        [
                                                                                                            [voteMeme.x, 'twitter2'],
                                                                                                            [voteMeme.tg, 'telegram2'],
                                                                                                        ].map(v => <img
                                                                                                            src={requireDefault(v[1])}
                                                                                                            alt=""
                                                                                                            style={{width: '1.5rem'}}
                                                                                                            onClick={() => windowOpen(v[0])}/>)
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {voteAchieved(voteMeme.votes, voteMeme.launchFunds)}
                                                                                        <div style={{
                                                                                            background: '#FFDDE1',
                                                                                            borderRadius: '.3rem'
                                                                                        }}
                                                                                             className={'paddingSmallPhone flex alignItemsCenter childrenSpacingLeftSmallPhone backgroundActiveColor'}
                                                                                        >
                                                                                            <img
                                                                                                src={requireDefault('rightTriangle')}
                                                                                                alt=""
                                                                                                style={{width: '.6rem'}}/>
                                                                                            <div
                                                                                                className={'smallerFontSizePhone'}>{voteMeme.desc}</div>
                                                                                        </div>
                                                                                        <div
                                                                                            className={'flex childrenSpacingLeftPhone childrenFlexGrow1'}>
                                                                                            {
                                                                                                [
                                                                                                    ['Omnichain Total Supply', voteMemeTotalSupply],
                                                                                                    ['Launch Funds (ETH)', voteMeme.launchFunds],
                                                                                                ].map(v => <div
                                                                                                    className={'childrenSpacingTopSmallPhone'}>
                                                                                                    <div
                                                                                                        className={'smallerFontSizePhone'}>
                                                                                                        {v[0]}:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={'saf1'}>{v[1]}</div>
                                                                                                </div>)
                                                                                            }
                                                                                        </div>
                                                                                        <div
                                                                                            className={'flex childrenSpacingLeftPhone childrenFlexGrow1'}>
                                                                                            <div
                                                                                                className={'childrenSpacingTopSmallPhone'}>
                                                                                                <div
                                                                                                    className={'smallerFontSizePhone'}>
                                                                                                    Tokenomics:
                                                                                                </div>
                                                                                                <pre
                                                                                                    className={'saf1'}>{checkDataString(tokenomicsData(voteMeme.quota, voteMeme.presale), 2, voteMemeTokenomics).split(', ').join(',\n')}</pre>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className={'flex childrenSpacingLeftPhone childrenFlexGrow1'}>
                                                                                            {
                                                                                                [
                                                                                                    ['Launch Hardcap (ETH)', voteMeme.hardcap],
                                                                                                    ['Launch Countdown (HOUR)', voteMeme.launchCountdown],
                                                                                                ].map(v => <div
                                                                                                    className={'childrenSpacingTopSmallPhone'}>
                                                                                                    <div
                                                                                                        className={'smallerFontSizePhone'}>
                                                                                                        {v[0]}:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={'saf1'}>{v[1]}</div>
                                                                                                </div>)
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={'borderRadiusPhone paddingPhone backgroundWhite'}>
                                                                                    <div
                                                                                        className={'childrenSpacingTopBigPhone'}>
                                                                                        <div className={'childrenSpacingTopPhone'}>
                                                                                            <div
                                                                                                className={'childrenSpacingTopPhone'}>
                                                                                                <div
                                                                                                    className={'paddingBigPhone childrenSpacingTopPhone'}
                                                                                                    style={{
                                                                                                        background: '#F7F7F7',
                                                                                                        borderRadius: '.9rem'
                                                                                                    }}>
                                                                                                    {
                                                                                                        votePointsData.map((v, i) =>
                                                                                                            <div
                                                                                                                className={'flex childrenSpacingLeftPhone'}>
                                                                                                                <div
                                                                                                                    className={'flex alignItemsCenter flexGrow1 childrenSpacingLeftPhone'}>
                                                                                                                    <div
                                                                                                                        style={{fontSize: '1rem'}}>{v[0]}:
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className={'saf1'}
                                                                                                                        style={{fontSize: '1rem'}}>{v[1]}</div>
                                                                                                                </div>
                                                                                                                {i === 0 && <img
                                                                                                                    src={requireDefault('Group 427320089')}
                                                                                                                    alt=""
                                                                                                                    className={'cursorPointer'}
                                                                                                                    onClick={() => toggleShowTaskContent(true, getAirdrop)}
                                                                                                                    style={square('1.2rem')}
                                                                                                                />}
                                                                                                            </div>)
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={'flexCenter'}>
                                                                                                <div
                                                                                                    className={'overflowYScroll'}
                                                                                                    style={{
                                                                                                        width: '11rem',
                                                                                                        height: '10rem',
                                                                                                        borderRadius: '.9rem',
                                                                                                        border: '.09rem solid',
                                                                                                    }}>
                                                                                                    {
                                                                                                        inviteRecordsIsNotNullOrEmpty
                                                                                                            ? inviteRecords.map((v, i) =>
                                                                                                                <div
                                                                                                                    onClick={() => setData({inviteRecordIndex: i})}
                                                                                                                    style={{
                                                                                                                        padding: '.5rem .8rem',
                                                                                                                        ...ternaryObject(
                                                                                                                            inviteRecordIndex === i,
                                                                                                                            {background: 'rgba(255, 186, 194, .3)'}
                                                                                                                        )
                                                                                                                    }}
                                                                                                                    className={'flex alignItemsCenter childrenSpacingLeftSmallPhone cursorPointer'}>
                                                                                                                    <img
                                                                                                                        style={{width: '.9rem'}}
                                                                                                                        src={requireDefault('Group 427320102')}
                                                                                                                        alt=""/>
                                                                                                                    <div
                                                                                                                        className={'smallerFontSizePhone'}>{leaveOut(v, 7, 5)}</div>
                                                                                                                    <img
                                                                                                                        style={{width: '.9rem'}}
                                                                                                                        src={requireDefault('toRight')}
                                                                                                                        alt=""/>
                                                                                                                </div>)
                                                                                                            : <div
                                                                                                                className={'width100 height100 flexCenter'}>
                                                                                                                <img
                                                                                                                    src={requireDefault('noData')}
                                                                                                                    style={{width: '80%'}}
                                                                                                                    alt=""/>
                                                                                                            </div>
                                                                                                    }
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'flexCenter flexGrow1'}>
                                                                                                    <img
                                                                                                        src={requireDefault('toRightBlack')}
                                                                                                        alt=""
                                                                                                        style={{width: '.9rem'}}/>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'borderRadius overflowYScroll'}
                                                                                                    style={{
                                                                                                        width: '11rem',
                                                                                                        height: '10rem',
                                                                                                        borderRadius: '.9rem',
                                                                                                        border: '.09rem solid #D8D8D8',
                                                                                                        ...ternaryObject(
                                                                                                            !inviteRecordsIsNotNullOrEmpty,
                                                                                                            {opacity: 0}
                                                                                                        )
                                                                                                    }}>
                                                                                                    {
                                                                                                        childInviteRecords.map(v =>
                                                                                                            <div
                                                                                                                style={{padding: '.5rem .8rem'}}
                                                                                                                className={'flex alignItemsCenter childrenSpacingLeftSmallPhone'}>
                                                                                                                <img
                                                                                                                    style={{width: '.9rem'}}
                                                                                                                    src={requireDefault('Group 427320102')}
                                                                                                                    alt=""/>
                                                                                                                <div
                                                                                                                    className={'smallerFontSizePhone'}>{leaveOut(v, 7, 5)}</div>
                                                                                                            </div>)
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    [
                                                                                        ['twitter2', voteMeme.x],
                                                                                        ['telegram1', voteMeme.tg],
                                                                                    ].map((v, i) => <div
                                                                                        className={'flexColumn alignItemsCenter childrenSpacingTopPhone'}>
                                                                                        <div
                                                                                            onClick={() => windowOpen(v[1])}
                                                                                            className={'flexColumn alignItemsCenter cursorPointer childrenSpacingTopPhone'}>
                                                                                            <img src={requireDefault(v[0])}
                                                                                                 style={{width: '2.1rem'}}
                                                                                                 alt=""/>
                                                                                            <div
                                                                                                style={{background: '#F7F7F7'}}
                                                                                                className={'paddingSmallPhone borderRadius999 smallFontSizePhone textAlignCenter'}>
                                                                                                {v[1]}
                                                                                            </div>
                                                                                        </div>
                                                                                        {i !== 0 && <div style={{
                                                                                            borderRadius: '.9rem',
                                                                                            padding: '.3rem',
                                                                                            background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)'
                                                                                        }}
                                                                                                         className={'borderRadius flexCenter'}>
                                                                                            <div style={{
                                                                                                borderRadius: '.9rem',
                                                                                                padding: '.3rem'
                                                                                            }}
                                                                                                 className={'borderRadius flexCenter backgroundWhite'}>
                                                                                                <QRCodeSVG value={v[1]}
                                                                                                           style={square('5.1rem')}/>
                                                                                            </div>
                                                                                        </div>}
                                                                                    </div>)
                                                                                }
                                                                                {notesOn}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={'width100 saf boxSizingBorderBox childrenSpacingTopPhone paddingBigPhone'}
                                                                        style={{
                                                                            borderTopLeftRadius: '1rem',
                                                                            borderTopRightRadius: '1rem',
                                                                            background: 'linear-gradient(0deg, #FFF9F0 -177.11%, #FFB9C2 277.11%)',
                                                                        }}>
                                                                        {/*{cloudflareHumanContent}*/}
                                                                        <div className={'flexCenter childrenSpacingLeftPhone'}>
                                                                            <div
                                                                                onClick={() => toggleShowInvite(true, voteMeme.symbol)}
                                                                                className={'borderRadius999 saf flexCenter cursorPointer childrenSpacingLeftSmallPhone cursorPointer'}
                                                                                style={{
                                                                                    height: '1.5rem',
                                                                                    padding: '0 .9rem',
                                                                                    border: '.12rem solid'
                                                                                }}>
                                                                                <img src={requireDefault('invite')} alt=""
                                                                                     style={{height: '70%'}}/>
                                                                                <div
                                                                                    className={'saf1 smallFontSizePhone'}>INVITE
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                onClick={() => iVA ? (!isVoted && toggleShowVote(true)) : toggleShowAuthorize(true)}
                                                                                className={`borderRadius999 colorWhite saf flexGrow1 flexCenter cursorPointer ${isVoted ? 'backGroundColorLightGray' : 'backgroundBlack'}  childrenSpacingLeftPhone cursorPointer`}
                                                                                style={{
                                                                                    height: '2.5rem',
                                                                                    ...ternaryObject(
                                                                                        iVA,
                                                                                        {color: '#E832CC'}
                                                                                    )
                                                                                }}>
                                                                                {
                                                                                    iVA
                                                                                        ? [
                                                                                            <img
                                                                                                src={requireDefault(isVoted ? 'voted' : 'vote')}
                                                                                                alt=""
                                                                                                style={{height: '50%'}}/>,
                                                                                            <div
                                                                                                className={'saf colorWhite'}>VOTE</div>,
                                                                                            <div style={{
                                                                                                height: '50%',
                                                                                                border: '.01rem solid rgba(255,255,255,.5)'
                                                                                            }}/>,
                                                                                            <div
                                                                                                className={'saf1 colorWhite'}>{voteMeme.votes}</div>
                                                                                        ]
                                                                                        : 'Authorized'
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className={'zIndex1 width100'}>{pageFootPhone}</div>
                                                        </div>,
                                                        false,
                                                    )
                                                    : <div>
                                                        {pageTop}
                                                        <div
                                                            className={'boxSizingBorderBox flexColumnCenter justifyContentSpaceBetween'}
                                                            style={{
                                                                minHeight: '100vh',
                                                                background: 'linear-gradient(180deg, #FFD3D5 0%, #FFF9F0 100%)'
                                                            }}>
                                                            <div style={{paddingTop: '1.5rem', paddingLeft: '4rem'}}
                                                                 className={'width100 flex boxSizingBorderBox'}>
                                                                <div onClick={() => windowReplace('/vote')}
                                                                     className={'flexCenter childrenSpacingLeft cursorPointer'}>
                                                                    <img src={requireDefault('back')} alt=""
                                                                         style={{width: '.5rem'}}/>
                                                                    <div className={'saf'}>BACK</div>
                                                                </div>
                                                            </div>
                                                            {voteMemeIsNotNull
                                                                && <div
                                                                    className={'flexGrow1Column flexColumn justifyContentSpaceBetween width100 boxSizingBorderBox childrenSpacingTopBig'}
                                                                    style={{padding: '0 4rem .5rem 4rem', minHeight: '10rem'}}>
                                                                    <div/>
                                                                    <div
                                                                        className={'width100 boxSizingBorderBox overflowYScroll'}
                                                                        style={{
                                                                            ...ternaryObject(
                                                                                !isPhoneClientWidth,
                                                                                {borderRadius: '.5rem'}
                                                                            ),
                                                                            overflowX: 'hidden',
                                                                            alignContent: 'flex-start',
                                                                            boxShadow: '0 0 .5rem #FFB9C2',
                                                                            border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                                                            background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                                            padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                                                        }}>
                                                                        <div className={'width100 overflowVisible'}>
                                                                            <div style={{
                                                                                height: '2.37rem',
                                                                                paddingRight: '.3rem',
                                                                                backgroundSize: 'cover',
                                                                                backgroundRepeat: 'no-repeat',
                                                                                backgroundPosition: 'center center',
                                                                                backgroundImage: `url(${requireDefault('voteRectangle')})`,
                                                                            }}
                                                                                 className={'flex justifyContentSpaceBetween boxSizingBorderBox'}>
                                                                                <div
                                                                                    className={'flexCenter childrenSpacingLeftSmall'}
                                                                                    style={{
                                                                                        padding: '0 0 0 .5rem'
                                                                                    }}>
                                                                                    <img className={'borderRadius999'}
                                                                                         src={voteMeme.logo} alt=""
                                                                                         style={{width: '1.2rem'}}/>
                                                                                    <div className={'childrenSpacingTop'}>
                                                                                        <div className={'saf'}
                                                                                             style={{fontSize: '.5rem'}}>
                                                                                            {voteMeme.name}
                                                                                        </div>
                                                                                        {/*<div className={'flexCenter childrenSpacingLeftSmall'}>*/}
                                                                                        {/*    <img style={{height: '60%'}}*/}
                                                                                        {/*                   src={requireDefault('fire1')}*/}
                                                                                        {/*                   alt=""/>*/}
                                                                                        {/*    <div className={'saf smallFontSizePhone'}*/}
                                                                                        {/*         style={ternaryObject(isNo1, {color: '#FF3C5B'})}*/}
                                                                                        {/*    >NO.1</div>*/}
                                                                                        {/*</div>*/}
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    voteMemeReceiveIsNotNull
                                                                                    && <div
                                                                                        className={'borderRadius999 zIndex1 overflowVisible positionRelative flexCenter boxSizingBorderBox justifyContentFlexEnd childrenSpacingLeftBigger'}
                                                                                        style={{
                                                                                            width: '71.5%',
                                                                                            height: '1.3rem',
                                                                                            padding: '.05rem  .7rem .05rem 1rem',
                                                                                            background: 'linear-gradient(270deg, #FFF9F0 51.05%, #FFB9C2 100%)'
                                                                                        }}>
                                                                                        <div>
                                                                                            <img
                                                                                                src={requireDefault('Vote for rewards')}
                                                                                                alt=""
                                                                                                className={'positionAbsolute zIndex-1'}
                                                                                                style={{
                                                                                                    height: '120%',
                                                                                                    top: '-.3rem',
                                                                                                    left: '.8rem'
                                                                                                }}
                                                                                            />
                                                                                            <div className={'saf1'}
                                                                                                 style={{fontSize: '.23rem'}}>The
                                                                                                platform will airdrop <span
                                                                                                    style={{fontSize: '.23rem'}}
                                                                                                    className={'saf1'}>{voteMemeReceiveText}</span> of
                                                                                                the listed meme to its voters and
                                                                                                creator
                                                                                            </div>
                                                                                            <div style={{fontSize: '.2rem'}}>The
                                                                                                more
                                                                                                users
                                                                                                you
                                                                                                invite, the higher the
                                                                                                proportion of
                                                                                                tokens
                                                                                                you
                                                                                                will
                                                                                                receive.
                                                                                            </div>
                                                                                        </div>
                                                                                        <img
                                                                                            src={requireDefault('Group 427320089')}
                                                                                            alt=""
                                                                                            onClick={() => toggleShowTaskContent(
                                                                                                true,
                                                                                                getAirdrop)}
                                                                                            className={'cursorPointer'}
                                                                                            style={{width: '.5rem'}}/>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className={'backgroundWhite childrenSpacingTopBig'}
                                                                                 style={{
                                                                                     padding: '0 .5rem .5rem .5rem',
                                                                                     borderBottomLeftRadius: '.4rem',
                                                                                     borderBottomRightRadius: '.4rem',
                                                                                 }}>
                                                                                <div
                                                                                    className={'flex justifyContentSpaceBetween childrenSpacingLeft'}>
                                                                                    <div className={'childrenSpacingTopBigger'}
                                                                                         style={{width: '60%'}}
                                                                                    >
                                                                                        <div
                                                                                            className={'flex childrenFlexGrow1 childrenSpacingLeft'}>
                                                                                            {
                                                                                                [
                                                                                                    ['Omnichain Total Supply', voteMemeTotalSupply],
                                                                                                    ['Launch Funds (ETH)', voteMeme.launchFunds],
                                                                                                ].map(v => <div
                                                                                                    className={'childrenSpacingTopSmall'}>
                                                                                                    <div
                                                                                                        className={'smallFontSize'}>{v[0]}:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={'saf1'}>{v[1]}</div>
                                                                                                </div>)
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={'flexColumn alignItemsFlexEnd childrenSpacingTop'}>
                                                                                        {voteAchieved(voteMeme.votes, voteMeme.launchFunds)}
                                                                                        <div style={{
                                                                                            background: '#FFDDE1',
                                                                                            borderRadius: '.1rem'
                                                                                        }}
                                                                                             className={'paddingSmall flex alignItemsCenter childrenSpacingLeftSmall backgroundActiveColor'}
                                                                                        >
                                                                                            <img
                                                                                                src={requireDefault('rightTriangle')}
                                                                                                alt=""
                                                                                                style={{width: '.2rem'}}/>
                                                                                            <div
                                                                                                className={'smallFontSize'}>{voteMeme.desc}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={'childrenSpacingTopSmall'}>
                                                                                    <div className={'smallFontSize'}>
                                                                                        Tokenomics:
                                                                                    </div>
                                                                                    <div
                                                                                        className={'saf1'}>{checkDataString(tokenomicsData(voteMeme.quota, voteMeme.presale), 2, voteMemeTokenomics)}</div>
                                                                                </div>
                                                                                <div className={'childrenSpacingTopBigger'}
                                                                                     style={{width: '60%'}}
                                                                                >
                                                                                    <div
                                                                                        className={'flex childrenFlexGrow1 childrenSpacingLeft'}>
                                                                                        {
                                                                                            [
                                                                                                ['Launch Hardcap (ETH)', voteMeme.hardcap],
                                                                                                ['Launch Countdown (HOUR)', voteMeme.launchCountdown],
                                                                                            ].map(v => <div
                                                                                                className={'childrenSpacingTopSmall'}>
                                                                                                <div
                                                                                                    className={'smallFontSize'}>{v[0]}:
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'saf1'}>{v[1]}</div>
                                                                                            </div>)
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={'flex alignItemsFlexEnd childrenFlexGrow1 childrenSpacingLeftBigger'}>
                                                                                    <div className={'childrenSpacingTop'}>
                                                                                        <div className={'childrenSpacingTop'}>
                                                                                            <div
                                                                                                className={'paddingBig childrenSpacingTopBig'}
                                                                                                style={{
                                                                                                    background: '#F7F7F7',
                                                                                                    borderRadius: '.3rem'
                                                                                                }}>
                                                                                                {
                                                                                                    votePointsData.map((v, i) =>
                                                                                                        <div
                                                                                                            className={'flex childrenSpacingLeft'}>
                                                                                                            <div
                                                                                                                className={'flex alignItemsCenter flexGrow1 childrenSpacingLeftBig'}>
                                                                                                                <div>{v[0]}:</div>
                                                                                                                <div
                                                                                                                    className={'saf1'}
                                                                                                                    style={{fontSize: '.3rem'}}>{v[1]}</div>
                                                                                                            </div>
                                                                                                            {i === 0 && <img
                                                                                                                src={requireDefault('Group 427320089')}
                                                                                                                alt=""
                                                                                                                className={'cursorPointer'}
                                                                                                                onClick={() => toggleShowTaskContent(true, getAirdrop)}
                                                                                                                style={square('.4rem')}
                                                                                                            />}
                                                                                                        </div>)
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={'flexCenter'}>
                                                                                            <div
                                                                                                className={'overflowYScroll'}
                                                                                                style={{
                                                                                                    width: '4.5rem',
                                                                                                    height: '3.7rem',
                                                                                                    borderRadius: '.3rem',
                                                                                                    border: '.03rem solid',
                                                                                                }}>
                                                                                                {
                                                                                                    inviteRecordsIsNotNullOrEmpty
                                                                                                        ? inviteRecords.map((v, i) =>
                                                                                                            <div
                                                                                                                onClick={() => setData({inviteRecordIndex: i})}
                                                                                                                style={{
                                                                                                                    padding: '.2rem .3rem',
                                                                                                                    ...ternaryObject(
                                                                                                                        inviteRecordIndex === i,
                                                                                                                        {background: 'rgba(255, 186, 194, .3)'}
                                                                                                                    )
                                                                                                                }}
                                                                                                                className={'flex alignItemsCenter childrenSpacingLeftSmall cursorPointer'}>
                                                                                                                <img
                                                                                                                    style={{width: '.3rem'}}
                                                                                                                    src={requireDefault('Group 427320102')}
                                                                                                                    alt=""/>
                                                                                                                <div>{leaveOut(v, 7, 5)}</div>
                                                                                                                <img
                                                                                                                    style={{width: '.3rem'}}
                                                                                                                    src={requireDefault('toRight')}
                                                                                                                    alt=""/>
                                                                                                            </div>)
                                                                                                        : <div
                                                                                                            className={'width100 height100 flexCenter'}>
                                                                                                            <img
                                                                                                                src={requireDefault('noData')}
                                                                                                                style={{width: '80%'}}
                                                                                                                alt=""/>
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className={'flexCenter flexGrow1'}>
                                                                                                <img
                                                                                                    src={requireDefault('toRightBlack')}
                                                                                                    alt=""
                                                                                                    style={{width: '.3rem'}}/>
                                                                                            </div>
                                                                                            <div
                                                                                                className={'borderRadius overflowYScroll'}
                                                                                                style={{
                                                                                                    width: '3.5rem',
                                                                                                    height: '3.7rem',
                                                                                                    borderRadius: '.3rem',
                                                                                                    border: '.03rem solid #D8D8D8',
                                                                                                    ...ternaryObject(
                                                                                                        !inviteRecordsIsNotNullOrEmpty,
                                                                                                        {opacity: 0}
                                                                                                    )
                                                                                                }}>
                                                                                                {
                                                                                                    childInviteRecords.map(v => <div
                                                                                                        style={{padding: '.2rem .3rem'}}
                                                                                                        className={'flex alignItemsCenter childrenSpacingLeftSmall'}>
                                                                                                        <img
                                                                                                            style={{width: '.3rem'}}
                                                                                                            src={requireDefault('Group 427320102')}
                                                                                                            alt=""/>
                                                                                                        <div>{leaveOut(v, 7, 5)}</div>
                                                                                                    </div>)
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        paddingLeft: '.5rem',
                                                                                        borderLeft: '.03rem solid #D8D8D8'
                                                                                    }}>
                                                                                        <div
                                                                                            className={'flexColumn alignItemsCenter'}>
                                                                                            <div
                                                                                                className={'width100 positionRelative'}>
                                                                                                <div
                                                                                                    className={'flex childrenSpacingLeft childrenFlexGrow1'}>
                                                                                                    {
                                                                                                        [
                                                                                                            ['telegram1', voteMeme.tg],
                                                                                                            ['twitter2', voteMeme.x],
                                                                                                        ].map((v, i) => <div
                                                                                                            className={'flexColumn alignItemsCenter childrenSpacingTop'}>
                                                                                                            <div
                                                                                                                onClick={() => windowOpen(v[1])}
                                                                                                                className={'flexColumn alignItemsCenter cursorPointer childrenSpacingTop'}>
                                                                                                                <img
                                                                                                                    src={requireDefault(v[0])}
                                                                                                                    style={{width: '.7rem'}}
                                                                                                                    alt=""/>
                                                                                                                <div
                                                                                                                    style={{background: '#F7F7F7'}}
                                                                                                                    className={'paddingSmall borderRadius999 smallFontSize textAlignCenter'}>
                                                                                                                    {v[1]}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {i === 0 &&
                                                                                                                <div style={{
                                                                                                                    borderRadius: '.3rem',
                                                                                                                    padding: '.1rem',
                                                                                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)'
                                                                                                                }}
                                                                                                                     className={'borderRadius flexCenter'}>
                                                                                                                    <div style={{
                                                                                                                        borderRadius: '.3rem',
                                                                                                                        padding: '.1rem'
                                                                                                                    }}
                                                                                                                         className={'borderRadius flexCenter backgroundWhite'}>
                                                                                                                        <QRCodeSVG
                                                                                                                            value={v[1]}
                                                                                                                            style={square('1.6rem')}/>
                                                                                                                    </div>
                                                                                                                </div>}
                                                                                                        </div>)
                                                                                                    }
                                                                                                </div>
                                                                                                <div className={'positionAbsolute'}
                                                                                                     style={{right: 0, bottom: 0}}
                                                                                                >
                                                                                                    {notesOn}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{marginTop: '2rem'}}
                                                                                                 className={'flexColumn childrenSpacingTop'}>
                                                                                                {/*{cloudflareHumanContent}*/}
                                                                                                <div
                                                                                                    className={'childrenSpacingLeftSmall flexCenter'}>
                                                                                                    <div
                                                                                                        onClick={() => toggleShowInvite(true, voteMeme.symbol)}
                                                                                                        className={'borderRadius999 saf flexCenter cursorPointer childrenSpacingLeftSmall cursorPointer'}
                                                                                                        style={{
                                                                                                            height: '.5rem',
                                                                                                            padding: '0 .3rem',
                                                                                                            border: '.04rem solid'
                                                                                                        }}>
                                                                                                        <img
                                                                                                            src={requireDefault('invite')}
                                                                                                            alt=""
                                                                                                            style={{height: '70%'}}/>
                                                                                                        <div
                                                                                                            className={'saf1 smallFontSize'}>INVITE
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        onClick={() => iVA ? (!isVoted && toggleShowVote(true)) : toggleShowAuthorize(true)}
                                                                                                        className={`borderRadius999 saf flexCenter colorWhite cursorPointer ${isVoted ? 'backGroundColorLightGray' : 'backgroundBlack'} childrenSpacingLeft cursorPointer`}
                                                                                                        style={{
                                                                                                            height: '.7rem',
                                                                                                            padding: '0 .5rem',
                                                                                                            ...ternaryObject(
                                                                                                                iVA,
                                                                                                                {color: '#E832CC'}
                                                                                                            )
                                                                                                        }}>
                                                                                                        {
                                                                                                            iVA
                                                                                                                ? [
                                                                                                                    <img
                                                                                                                        src={requireDefault(isVoted ? 'voted' : 'vote')}
                                                                                                                        alt=""
                                                                                                                        style={{height: '50%'}}/>,
                                                                                                                    <div
                                                                                                                        className={'saf colorWhite'}>VOTE
                                                                                                                    </div>,
                                                                                                                    <div style={{
                                                                                                                        height: '50%',
                                                                                                                        border: '.01rem solid rgba(255,255,255,.5)'
                                                                                                                    }}/>,
                                                                                                                    <div
                                                                                                                        className={'saf1 colorWhite'}>{voteMeme.votes}</div>
                                                                                                                ]
                                                                                                                : 'Authorized'
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div/>
                                                                </div>}
                                                            <div className={'zIndex1 width100'}>{pageFoot}</div>
                                                        </div>
                                                    </div>)
                                            : (
                                                isPhoneClientWidth
                                                    ? pageTopFootPhone(
                                                        <div
                                                            className={'boxSizingBorderBox flexColumnCenter justifyContentSpaceBetween'}
                                                            style={{
                                                                minHeight: '100vh',
                                                                background: 'linear-gradient(180deg, #FFD3D5 0%, #FFF9F0 100%)'
                                                            }}>
                                                            <div/>
                                                            <div
                                                                className={'width100 flexGrow1Column flexColumn justifyContentSpaceBetween boxSizingBorderBox childrenSpacingTopBigPhone'}
                                                                style={{minHeight: '30rem'}}>
                                                                <div/>
                                                                <div style={{padding: '3rem 1.5rem 1rem 1.5rem'}}
                                                                     className={'flexColumn flexGrow1Column childrenSpacingTopBigPhone'}>
                                                                    <div
                                                                        className={'flexGrow1Column flexColumn childrenSpacingTopBigPhone'}>
                                                                        <div className={'childrenSpacingTopBigPhone'}>
                                                                            <div
                                                                                className={'borderRadius999 boxSizingBorderBox backgroundWhite overflowVisible positionRelative'}
                                                                                style={{
                                                                                    height: '3rem',
                                                                                    padding: '.21rem',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{background: 'linear-gradient(270deg, #FFB9C2 13.6%, #FFF9F0 100%)'}}
                                                                                    className={'width100 borderRadius999 height100 flexCenter childrenSpacingLeftPhone'}
                                                                                >
                                                                                    <img style={{height: '70%'}}
                                                                                         className={'borderRadius999'}
                                                                                         src={lastListingMeme.logo}
                                                                                         alt=""/>
                                                                                    <div className={'saf'}
                                                                                         style={{fontSize: '1rem'}}>{lastListingMeme.symbol}</div>
                                                                                </div>
                                                                                <img src={requireDefault('lastListing')} alt=""
                                                                                     className={'positionAbsolute'}
                                                                                     style={{
                                                                                         width: '3rem',
                                                                                         top: '-.1rem',
                                                                                         left: '-.2rem'
                                                                                     }}
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                                                <div
                                                                                    className={'borderRadius999 boxSizingBorderBox saf flex flexGrow1 alignItemsCenter cursorPointer childrenSpacingLeftSmallPhone'}
                                                                                    style={{
                                                                                        height: '2.9rem',
                                                                                        border: '.21rem solid white',
                                                                                    }}>
                                                                                    <div onClick={() => searchCurrentRoundMeme()}
                                                                                         className={'height100 borderRadius999 backgroundActiveColor flexCenter'}
                                                                                         style={square('2.4rem')}>
                                                                                        <img style={{width: '50%'}}
                                                                                             src={requireDefault('search')} alt=""/>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                           className={'flexGrow1 saf1 smallerFontSizePhone'}
                                                                                           placeholder={'Please Enter'}
                                                                                           value={searchSymbol}
                                                                                           onChange={setSearchSymbol}
                                                                                           onKeyUp={e => searchSymbolOnKeyUp(e, searchCurrentRoundMeme)}
                                                                                    />
                                                                                    {searchSymbolIsNotNull && <div
                                                                                        className={'height100 flexCenter'}
                                                                                        style={{width: '2rem', marginLeft: 0}}>
                                                                                        <img onClick={() => {
                                                                                            setDataSearchSymbolEmpty()
                                                                                            searchCurrentRoundMeme(true)
                                                                                        }} style={{width: '70%'}}
                                                                                             src={requireDefault('searchDisabled')}
                                                                                             alt=""/>
                                                                                    </div>}
                                                                                </div>
                                                                                {hotMemesSwiper}
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={'width100 flexGrow1Column borderRadiusPhone boxSizingBorderBox overflowYScroll justifyContentSpaceBetween childrenSpacingTopPhone'}
                                                                            style={{
                                                                                paddingTop: '2.5rem',
                                                                                overflowX: 'hidden',
                                                                                alignContent: 'flex-start',
                                                                            }}>
                                                                            <div
                                                                                style={{background: 'linear-gradient(270deg, #FFF9F0 51.05%, #FFB9C2 100%)'}}
                                                                                className={'borderRadiusPhone paddingPhone zIndex1 overflowVisible width100 positionRelative flexCenter boxSizingBorderBox justifyContentSpaceBetween childrenSpacingLeftPhone'}>
                                                                                <div>
                                                                                    <img
                                                                                        src={requireDefault('Vote for rewards')}
                                                                                        alt=""
                                                                                        className={'positionAbsolute zIndex-1'}
                                                                                        style={{
                                                                                            height: '100%',
                                                                                            top: '-2.5rem',
                                                                                            right: '3.5rem'
                                                                                        }}
                                                                                    />
                                                                                    <div style={{width: '60%'}}>
                                                                                        <div className={'saf1'}
                                                                                             style={{fontSize: '.7rem'}}>The
                                                                                            platform
                                                                                            will airdrop <span
                                                                                                style={{fontSize: '.7rem'}}
                                                                                                className={'saf1'}>{defaultTokenomicsPercentage()}</span> of
                                                                                            the listed meme to its voters and
                                                                                            creator
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        margin: '.5rem 0',
                                                                                        borderBottom: '.05rem solid rgba(0,0,0,.3)',
                                                                                    }}></div>
                                                                                    <div
                                                                                        style={{fontSize: '.5rem'}}>The more users
                                                                                        you
                                                                                        invite, the higher the proportion of tokens
                                                                                        you will
                                                                                        receive.
                                                                                    </div>
                                                                                </div>
                                                                                <img
                                                                                    src={requireDefault('Group 427320089')}
                                                                                    alt=""
                                                                                    onClick={() => toggleShowTaskContent(
                                                                                        true,
                                                                                        getAirdrop)}
                                                                                    className={'cursorPointer'}
                                                                                    style={{width: '2rem'}}/>
                                                                            </div>
                                                                            {
                                                                                voteListContentIsNotNullOrEmpty
                                                                                    ? voteListContent.map((v, i) => {
                                                                                        const iVM = isVotedMeme(v.symbol),
                                                                                            canNotVote = iVM || voteDone(v)
                                                                                        return <div
                                                                                            style={ternaryObject(iVM, {
                                                                                                background: '#FFFAF1',
                                                                                                border: '.15rem solid #FF7789'
                                                                                            })}
                                                                                            className={'backgroundWhite borderRadiusPhone borderRadius childrenSpacingTop flexColumn' +
                                                                                                'boxSizingBorderBox paddingBottom justifyContentSpaceBetween positionRelative zIndex1'}
                                                                                        >
                                                                                            {iVM && <img
                                                                                                src={requireDefault('listVoted')}
                                                                                                alt=""
                                                                                                className={'positionAbsolute zIndex-1'}
                                                                                                style={{
                                                                                                    top: '7rem',
                                                                                                    width: '6rem',
                                                                                                    right: '2.5rem'
                                                                                                }}
                                                                                            />}
                                                                                            <div style={{marginTop: 0}}
                                                                                                 className={'flexCenter width100 justifyContentSpaceBetween childrenSpacingLeft'}>
                                                                                                {
                                                                                                    (() => {
                                                                                                        const ranking = checkData([v, 'ranking'], i),
                                                                                                            isNo1 = ranking === 0
                                                                                                        return <div style={{
                                                                                                            width: '15%',
                                                                                                            height: '2rem',
                                                                                                            padding: '0 .9rem',
                                                                                                            borderTopRightRadius: '999px',
                                                                                                            borderBottomRightRadius: '999px',
                                                                                                            background: isNo1 ? '#FFDDE1' : '#EAF5FF',
                                                                                                        }}
                                                                                                                    className={'flexCenter childrenSpacingLeftSmallPhone'}>
                                                                                                            {isNo1 &&
                                                                                                                <img
                                                                                                                    style={{height: '60%'}}
                                                                                                                    src={requireDefault('fire1')}
                                                                                                                    alt=""/>}
                                                                                                            <div
                                                                                                                className={'saf smallFontSizePhone'}
                                                                                                                style={ternaryObject(isNo1, {color: '#FF3C5B'})}
                                                                                                            >NO.{npPlus(ranking, 1)}</div>
                                                                                                        </div>
                                                                                                    })()
                                                                                                }
                                                                                                <div style={{
                                                                                                    background: '#FFDDE1',
                                                                                                    borderRadius: '.3rem'
                                                                                                }}
                                                                                                     className={'paddingSmallPhone flex alignItemsCenter childrenSpacingLeftSmallPhone backgroundActiveColor'}
                                                                                                >
                                                                                                    <img
                                                                                                        src={requireDefault('rightTriangle')}
                                                                                                        alt=""
                                                                                                        style={{width: '.6rem'}}/>
                                                                                                    <div
                                                                                                        style={{fontSize: '.5rem'}}>{v.desc}</div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'smallerFontSizePhone paddingRightPhone flexCenter childrenSpacingLeftPhone'}>
                                                                                                    {
                                                                                                        [
                                                                                                            [v.x, 'twitter2'],
                                                                                                            [v.tg, 'telegram2'],
                                                                                                        ].map(v => <img
                                                                                                            src={requireDefault(v[1])}
                                                                                                            alt=""
                                                                                                            style={{width: '1.5rem'}}
                                                                                                            onClick={() => windowOpen(v[0])}/>)
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className={'paddingBigPhone childrenSpacingTopBigPhone'}>
                                                                                                <div
                                                                                                    className={'flex childrenSpacingLeftPhone childrenFlexGrow1'}>
                                                                                                    <div
                                                                                                        className={'childrenSpacingLeftPhone flex alignItemsCenter'}>
                                                                                                        <div style={square('3rem')}>
                                                                                                            <img
                                                                                                                className={'height100 width100 borderRadius999'}
                                                                                                                src={v.logo}
                                                                                                                alt=""/>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className={'saf'}>{v.name}</div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={'childrenSpacingTopSmallPhone'}>
                                                                                                        <div
                                                                                                            className={'smallerFontSizePhone'}>
                                                                                                            Omnichain Total Supply:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className={'saf1'}>{v.totalSupply}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'flex childrenSpacingLeftPhone childrenFlexGrow1'}>
                                                                                                    {
                                                                                                        [
                                                                                                            ['Launch Funds (ETH)', v.launchFunds],
                                                                                                        ].map(v => <div
                                                                                                            className={'childrenSpacingTopSmallPhone'}>
                                                                                                            <div
                                                                                                                className={'smallerFontSizePhone'}>
                                                                                                                {v[0]}:
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={'saf1'}>{v[1]}</div>
                                                                                                        </div>)
                                                                                                    }
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'flexColumn alignItemsFlexEnd childrenSpacingTopPhone'}>
                                                                                                    {voteAchieved(v.votes, v.launchFunds)}
                                                                                                    <div
                                                                                                        className={'childrenSpacingLeftPhone flexCenter width100'}>
                                                                                                        <div
                                                                                                            onClick={() => toggleShowInvite(true, v.symbol)}
                                                                                                            className={'borderRadius999 backgroundWhite saf flexCenter cursorPointer childrenSpacingLeftSmallPhone cursorPointer'}
                                                                                                            style={{
                                                                                                                height: '1.5rem',
                                                                                                                padding: '0 .9rem',
                                                                                                                border: '.12rem solid'
                                                                                                            }}>
                                                                                                            <img
                                                                                                                src={requireDefault('invite')}
                                                                                                                alt=""
                                                                                                                style={{height: '70%'}}/>
                                                                                                            <div
                                                                                                                className={'saf1 smallFontSizePhone'}>INVITE
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            onClick={() => windowReplace(`/vote/${v.symbol}`)}
                                                                                                            className={'borderRadius999 saf flexGrow1 flexCenter cursorPointer backgroundBlack childrenSpacingLeftPhone cursorPointer'}
                                                                                                            style={{
                                                                                                                height: '2.5rem',
                                                                                                                ...ternaryObject(canNotVote, {
                                                                                                                    background: 'white',
                                                                                                                    border: '.12rem solid',
                                                                                                                })
                                                                                                            }}>
                                                                                                            <img
                                                                                                                src={requireDefault('vote')}
                                                                                                                alt=""
                                                                                                                style={{height: '50%'}}/>
                                                                                                            {!canNotVote && <div
                                                                                                                className={'saf colorWhite'}>VOTE
                                                                                                            </div>}
                                                                                                            <div style={{
                                                                                                                height: '50%',
                                                                                                                border: `.01rem solid ${canNotVote ? '#BFBFBF' : 'rgba(255,255,255,.5)'}`
                                                                                                            }}/>
                                                                                                            <div
                                                                                                                className={`saf1 ${canNotVote ? '' : 'colorWhite'}`}>{v.votes}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    })
                                                                                    : <div
                                                                                        className={'width100 height100 flexColumnCenter childrenSpacingTopPhone'}>
                                                                                        <img src={requireDefault('Group 427320250')}
                                                                                             alt=""
                                                                                             style={{width: '9rem'}}/>
                                                                                        <div className={'saf bigFontSizePhone'}>
                                                                                            No data...
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={'flexCenter'}>
                                                                    <div
                                                                        className={'width100 saf flexColumnCenter alignItemsCenter childrenSpacingTopPhone positionRelative'}
                                                                        style={{
                                                                            height: '5.5rem',
                                                                            borderTopLeftRadius: '1rem',
                                                                            borderTopRightRadius: '1rem',
                                                                            background: 'linear-gradient(270deg, #FFF9F0 35.84%, #FFD8D9 100%)',
                                                                        }}>
                                                                        <img className={'cursorPointer'}
                                                                             onClick={() => toggleShowCreateMeme(true)}
                                                                             src={requireDefault('CREATE', 'gif')} alt=""
                                                                             style={{width: '50%', marginTop: '-9%'}}/>
                                                                        <div className={'flexCenter'}>
                                                                            <div className={'childrenSpacingTopSmall'}>
                                                                                <div style={{fontSize: '.7rem'}}>
                                                                                    MEME with enough votes
                                                                                </div>
                                                                                <div
                                                                                    className={'flexCenter childrenSpacingLeftBig justifyContentSpaceBetween'}>
                                                                                    <div style={{
                                                                                        fontSize: '1rem',
                                                                                        color: '#FF7789'
                                                                                    }}
                                                                                         className={'saf1'}>
                                                                                        <span style={{fontSize: '1rem'}}
                                                                                              className={'saf1 colorBlack'}>Will enter onto the</span> Launch
                                                                                        List
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <img src={requireDefault('colon')} alt=""
                                                                             className={'positionAbsolute'}
                                                                             style={{width: '2rem', top: '.5rem', left: '5rem'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'zIndex1 width100'}>{pageFootPhone}</div>
                                                        </div>,
                                                        false,
                                                    )
                                                    : <div>
                                                        {pageTop}
                                                        <div
                                                            className={'boxSizingBorderBox flexColumnCenter justifyContentSpaceBetween'}
                                                            style={{
                                                                minHeight: '100vh',
                                                                background: 'linear-gradient(180deg, #FFD3D5 0%, #FFF9F0 100%)'
                                                            }}>
                                                            <div/>
                                                            <div
                                                                className={'flexGrow1Column flexColumn width100 boxSizingBorderBox childrenSpacingTopBig'}
                                                                style={{padding: '2rem 4rem .5rem 4rem', minHeight: '10rem'}}>
                                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                                    <div className={'flex alignItemsCenter childrenSpacingLeft'}>
                                                                        <div
                                                                            className={'borderRadius999 saf flex alignItemsCenter cursorPointer childrenSpacingLeft'}
                                                                            style={{
                                                                                width: '4rem',
                                                                                height: '1rem',
                                                                                border: '.08rem solid white',
                                                                            }}>
                                                                            <div onClick={() => searchCurrentRoundMeme()}
                                                                                 className={'height100 borderRadius999 backgroundActiveColor flexCenter'}
                                                                                 style={{width: '1rem'}}>
                                                                                <img style={{width: '50%'}}
                                                                                     src={requireDefault('search')} alt=""/>
                                                                            </div>
                                                                            <input value={searchSymbol} type="text"
                                                                                   className={'flexGrow1 saf'}
                                                                                   placeholder={'Please Enter'}
                                                                                   onChange={setSearchSymbol}
                                                                                   onKeyUp={e => searchSymbolOnKeyUp(e, searchCurrentRoundMeme)}
                                                                            />
                                                                            {searchSymbolIsNotNull && <div
                                                                                className={'height100 flexCenter'}
                                                                                style={{width: '1rem', marginLeft: 0}}>
                                                                                <img onClick={() => {
                                                                                    setDataSearchSymbolEmpty()
                                                                                    searchCurrentRoundMeme(true)
                                                                                }} style={{width: '70%'}}
                                                                                     src={requireDefault('searchDisabled')}
                                                                                     alt=""/>
                                                                            </div>}
                                                                        </div>
                                                                        <div
                                                                            className={'borderRadius999 backgroundWhite positionRelative overflowVisible'}
                                                                            style={{
                                                                                height: '1rem',
                                                                                marginLeft: '2rem',
                                                                                padding: '.07rem',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={'borderRadius999 height100 flexCenter childrenSpacingLeftBig'}
                                                                                style={{
                                                                                    padding: '0 1rem',
                                                                                    background: 'linear-gradient(270deg, #FFB9C2 13.6%, #FFF9F0 100%)'
                                                                                }}
                                                                            >
                                                                                <img style={{height: '70%'}}
                                                                                     className={'borderRadius999'}
                                                                                     src={lastListingMeme.logo}
                                                                                     alt=""/>
                                                                                <div
                                                                                    className={'saf'}>{lastListingMeme.symbol}</div>
                                                                            </div>
                                                                            <img src={requireDefault('lastListing')} alt=""
                                                                                 className={'positionAbsolute'}
                                                                                 style={{
                                                                                     width: '1rem',
                                                                                     top: '-.1rem',
                                                                                     left: '-.2rem'
                                                                                 }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {hotMemesSwiper}
                                                                </div>
                                                                <div
                                                                    className={'width100 flexGrow1Column boxSizingBorderBox flexColumn childrenSpacingTop'}
                                                                    style={{
                                                                        ...ternaryObject(
                                                                            !isPhoneClientWidth,
                                                                            {borderRadius: '.5rem'}
                                                                        ),
                                                                        overflowX: 'hidden',
                                                                        alignContent: 'flex-start',
                                                                        boxShadow: '0 0 .5rem #FFB9C2',
                                                                        border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                                        padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                                                    }}>
                                                                    <div
                                                                        className={'borderRadius999 zIndex1 overflowVisible width100 positionRelative flexCenter justifyContentSpaceBetween boxSizingBorderBox childrenSpacingLeftBigger'}
                                                                        style={{
                                                                            padding: '.2rem  .7rem .2rem 4rem',
                                                                            background: 'linear-gradient(270deg, #FFF9F0 51.05%, #FFB9C2 100%)'
                                                                        }}>
                                                                        <div>
                                                                            <img
                                                                                src={requireDefault('Vote for rewards')}
                                                                                alt=""
                                                                                className={'positionAbsolute zIndex-1'}
                                                                                style={{
                                                                                    height: '150%',
                                                                                    top: '-.6rem',
                                                                                    left: '1rem'
                                                                                }}
                                                                            />
                                                                            <img
                                                                                src={requireDefault('star')}
                                                                                alt=""
                                                                                className={'positionAbsolute zIndex-1'}
                                                                                style={{
                                                                                    height: '90%',
                                                                                    top: 0,
                                                                                    bottom: 0,
                                                                                    right: '3.6rem'
                                                                                }}
                                                                            />
                                                                            <div className={'saf1'}>The platform will airdrop <span
                                                                                className={'saf1'}>{defaultTokenomicsPercentage()}</span> of
                                                                                the listed meme to its voters and creator
                                                                            </div>
                                                                            <div style={{fontSize: '.25rem'}}>The more users you
                                                                                invite, the higher the proportion of tokens you will
                                                                                receive.
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={requireDefault('Group 427320089')}
                                                                            alt=""
                                                                            onClick={() => toggleShowTaskContent(
                                                                                true,
                                                                                getAirdrop)}
                                                                            className={'cursorPointer'}
                                                                            style={{width: '.5rem'}}/>
                                                                    </div>
                                                                    <div
                                                                        style={ternaryObject(!voteListContentIsNotNullOrEmpty, {
                                                                            borderRadius: '.4rem',
                                                                            background: 'rgba(255,255,255,.4)',
                                                                        })}
                                                                        className={'childrenSpacingTop flexGrow1Column overflowYScroll'}>
                                                                        {
                                                                            voteListContentIsNotNullOrEmpty
                                                                                ? voteListContent.map((v, i) => {
                                                                                    const iVM = isVotedMeme(v.symbol),
                                                                                        canNotVote = iVM || voteDone(v)
                                                                                    return <div
                                                                                        style={{
                                                                                            height: '3rem',
                                                                                            borderRadius: '.3rem',
                                                                                            ...ternaryObject(iVM, {
                                                                                                background: '#FFFAF1',
                                                                                                border: '.05rem solid #FF7789',
                                                                                            })
                                                                                        }}
                                                                                        className={'backgroundWhite borderRadius flex boxSizingBorderBox childrenSpacingLeft positionRelative zIndex1'}
                                                                                    >
                                                                                        {iVM && <img
                                                                                            src={requireDefault('listVoted')}
                                                                                            alt=""
                                                                                            className={'positionAbsolute zIndex-1'}
                                                                                            style={{
                                                                                                width: '1.7rem',
                                                                                                top: '.1rem',
                                                                                                right: '2rem'
                                                                                            }}
                                                                                        />}
                                                                                        <div style={{width: '15%', marginLeft: 0}}
                                                                                             className={'height100 flexColumn justifyContentSpaceBetween'}>
                                                                                            {
                                                                                                (() => {
                                                                                                    const ranking = checkData([v, 'ranking'], i),
                                                                                                        isNo1 = ranking === 0
                                                                                                    return <div style={{
                                                                                                        width: '70%',
                                                                                                        height: '.8rem',
                                                                                                        padding: '0 .3rem',
                                                                                                        borderTopRightRadius: '999px',
                                                                                                        borderBottomRightRadius: '999px',
                                                                                                        background: isNo1 ? '#FFDDE1' : '#EAF5FF',
                                                                                                    }}
                                                                                                                className={'flexCenter childrenSpacingLeftSmall'}>
                                                                                                        {isNo1 &&
                                                                                                            <img style={{height: '70%'}}
                                                                                                                 src={requireDefault('fire1')}
                                                                                                                 alt=""/>}
                                                                                                        <div
                                                                                                            className={'saf bigFontSize'}
                                                                                                            style={ternaryObject(isNo1, {color: '#FF3C5B'})}
                                                                                                        >NO.{npPlus(ranking, 1)}</div>
                                                                                                    </div>
                                                                                                })()
                                                                                            }
                                                                                            <div style={{
                                                                                                borderRight: '.03rem solid #D8D8D8',
                                                                                            }}
                                                                                                 className={'width100 childrenSpacingTop flexColumnCenter'}>
                                                                                                <div style={square('1rem')}>
                                                                                                    <img
                                                                                                        className={'height100 width100 borderRadius999'}
                                                                                                        src={v.logo}
                                                                                                        alt=""/>
                                                                                                </div>
                                                                                                <div className={'saf'}>{v.name}</div>
                                                                                            </div>
                                                                                            <div/>
                                                                                        </div>
                                                                                        <div
                                                                                            className={'flexColumn flexGrow1 height100 padding'}>
                                                                                            <div
                                                                                                className={'flexCenter justifyContentSpaceBetween childrenSpacingLeft'}>
                                                                                                <div style={{
                                                                                                    background: '#FFDDE1',
                                                                                                    borderRadius: '.1rem'
                                                                                                }}
                                                                                                     className={'paddingSmall flex alignItemsCenter childrenSpacingLeftSmall backgroundActiveColor'}
                                                                                                >
                                                                                                    <img
                                                                                                        src={requireDefault('rightTriangle')}
                                                                                                        alt=""
                                                                                                        style={{width: '.2rem'}}/>
                                                                                                    <div>{v.desc}</div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'flexCenter childrenSpacingLeft paddingRight'}>
                                                                                                    {
                                                                                                        [
                                                                                                            [v.x, 'twitter2'],
                                                                                                            [v.tg, 'telegram2'],
                                                                                                        ].map(v => <img
                                                                                                            src={requireDefault(v[1])}
                                                                                                            alt=""
                                                                                                            className={'cursorPointer'}
                                                                                                            style={{width: '.5rem'}}
                                                                                                            onClick={() => windowOpen(v[0])}/>)
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className={'flexGrow1Column width100 boxSizingBorderBox paddingRight flex alignItemsCenter childrenSpacingLeft'}>
                                                                                                <div style={{width: '60%'}}
                                                                                                     className={'childrenSpacingTop flexColumn justifyContentSpaceBetween'}>
                                                                                                    <div
                                                                                                        className={'flex childrenFlexGrow1 childrenSpacingLeft'}>
                                                                                                        {
                                                                                                            [
                                                                                                                ['Omnichain Total supply', v.totalSupply],
                                                                                                                ['Launch Funds (ETH)', v.launchFunds],
                                                                                                            ].map(v => <div
                                                                                                                className={'childrenSpacingTopSmall'}>
                                                                                                                <div
                                                                                                                    className={'smallFontSize'}>{v[0]}:
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className={'saf1'}>{v[1]}</div>
                                                                                                            </div>)
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={'childrenSpacingTop flexColumn alignItemsFlexEnd flexGrow1'}>
                                                                                                    {voteAchieved(v.votes, v.launchFunds)}
                                                                                                    <div
                                                                                                        className={'childrenSpacingLeft flexCenter justifyContentFlexEnd'}>
                                                                                                        <div
                                                                                                            onClick={() => toggleShowInvite(true, v.symbol)}
                                                                                                            className={'borderRadius999 backgroundWhite saf flexCenter cursorPointer childrenSpacingLeftSmall cursorPointer'}
                                                                                                            style={{
                                                                                                                height: '.5rem',
                                                                                                                padding: '0 .3rem',
                                                                                                                border: '.05rem solid'
                                                                                                            }}>
                                                                                                            <img
                                                                                                                src={requireDefault('invite')}
                                                                                                                alt=""
                                                                                                                style={{height: '70%'}}/>
                                                                                                            <div
                                                                                                                className={'saf1 smallFontSize'}>INVITE
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            onClick={() => windowReplace(`/vote/${v.symbol}`)}
                                                                                                            className={'borderRadius999 saf flexCenter boxSizingBorderBox cursorPointer backgroundBlack childrenSpacingLeft cursorPointer'}
                                                                                                            style={{
                                                                                                                height: '.7rem',
                                                                                                                padding: '0 .5rem',
                                                                                                                ...ternaryObject(canNotVote, {
                                                                                                                    background: 'white',
                                                                                                                    border: '.05rem solid',
                                                                                                                })
                                                                                                            }}>
                                                                                                            <img
                                                                                                                src={requireDefault('vote')}
                                                                                                                alt=""
                                                                                                                style={{height: '50%'}}/>
                                                                                                            {!canNotVote && <div
                                                                                                                className={'saf colorWhite'}>VOTE
                                                                                                            </div>}
                                                                                                            <div style={{
                                                                                                                height: '50%',
                                                                                                                border: `.01rem solid ${canNotVote ? '#BFBFBF' : 'rgba(255,255,255,.5)'}`
                                                                                                            }}/>
                                                                                                            <div
                                                                                                                className={`saf1 ${canNotVote ? '' : 'colorWhite'}`}>{v.votes}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                                : <div
                                                                                    className={'width100 height100 flexColumnCenter childrenSpacingTop'}>
                                                                                    <img src={requireDefault('Group 427320250')} alt=""
                                                                                         style={{width: '3rem'}}/>
                                                                                    <div className={'saf bigFontSize'}>No data...</div>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className={'flexCenter'}>
                                                                    <div
                                                                        className={'borderRadius999 saf flex alignItemsCenter childrenSpacingLeft overflowVisible positionRelative'}
                                                                        style={{
                                                                            width: '12rem',
                                                                            height: '1rem',
                                                                            border: '.03rem solid white',
                                                                            background: 'linear-gradient(270deg, #FFF9F0 35.84%, #FFD8D9 100%)'
                                                                        }}>
                                                                        <div className={'flexGrow1 flexCenter'}>
                                                                            <div className={'childrenSpacingTopSmall'}>
                                                                                <div className={'smallFontSize'}>
                                                                                    MEME with enough votes
                                                                                </div>
                                                                                <div
                                                                                    className={'flex childrenSpacingLeftBig alignItemsCenter'}>
                                                                                    <div style={{
                                                                                        fontSize: '.27rem',
                                                                                        color: '#FF7789'
                                                                                    }}
                                                                                         className={'saf1'}>
                                                                                        <span className={'saf1 colorBlack'}>Will enter onto the</span> Launch
                                                                                        List
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <img className={'cursorPointer'}
                                                                             onClick={() => toggleShowCreateMeme(true)}
                                                                             src={requireDefault('CREATE', 'gif')} alt=""
                                                                             style={{height: '120%'}}/>
                                                                        <img src={requireDefault('colon')} alt=""
                                                                             className={'positionAbsolute'}
                                                                             style={{
                                                                                 width: '.5rem',
                                                                                 top: '.05rem',
                                                                                 left: '.05rem'
                                                                             }}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'zIndex1 width100'}>{pageFoot}</div>
                                                        </div>
                                                    </div>
                                            )
                                    }
                                    {showInvite &&
                                        <div className={'positionFixed flexColumnCenter'}
                                             style={{
                                                 top: 0,
                                                 left: 0,
                                                 right: 0,
                                                 bottom: 0,
                                                 zIndex: 1000,
                                                 background: 'rgba(0,0,0,.56)',
                                             }}>
                                            <div
                                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                                                style={{
                                                    width: isPhoneClientWidth ? '90%' : '10rem',
                                                    maxHeight: '100vh',
                                                    ...ternaryObject(
                                                        !isPhoneClientWidth,
                                                        {borderRadius: '.5rem'}
                                                    ),
                                                    padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                                    border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                                }}>
                                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                                    <div className={'saf'}
                                                         style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                                        invite
                                                    </div>
                                                    <img onClick={() => toggleShowInvite()}
                                                         className={'cursorPointer'}
                                                         src={requireDefault('close')}
                                                         alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                                </div>
                                                {
                                                    (() => {
                                                        const url = `${location.protocol}//${location.host}/vote/${checkDataString(data, 'inviteSymbol')}${address ? `?invite=${address}` : ''}`
                                                        return [
                                                            <div className={'flexCenter'}>
                                                                <div
                                                                    onClick={() => windowOpen(`https://telegram.me/share/url?url=${url}`)}
                                                                    className={`${isPhoneClientWidth ? 'paddingPhone' : 'paddingBig'} cursorPointer borderRadius999 backgroundWhite flexCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftBiggerPhone' : 'childrenSpacingLeftBigger'}`}>
                                                                    <div
                                                                        className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                                        <img src={requireDefault('telegram1')} alt=""
                                                                             style={{width: `${isPhoneClientWidth ? 3 : 1}rem`}}/>
                                                                        <div className={'saf1'}>Telegram</div>
                                                                    </div>
                                                                    <img src={requireDefault('toRightBlack')} alt=""
                                                                         style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                                                </div>
                                                            </div>,
                                                            <div
                                                                className={`${isPhoneClientWidth ? 'paddingPhone' : 'padding'} borderRadius999 flexCenter`}
                                                                style={{
                                                                    background: 'rgba(255,255,255,.4)',
                                                                    border: `solid ${isPhoneClientWidth ? .15 : .05}rem`,
                                                                }}>
                                                                <div
                                                                    className={'flexGrow1 textOverflowEllipsis'}>{url}</div>
                                                                <div
                                                                    style={{padding: `${isPhoneClientWidth ? '.6rem 1.2rem' : '.2rem .4rem'}`}}
                                                                    onClick={() => copyText(url)}
                                                                    className={`borderRadius999 backgroundBlack flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} cursorPointer`}>
                                                                    <img src={requireDefault('copy')} alt=""
                                                                         style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>
                                                                    <div className={'colorWhite saf1'}>COPY</div>
                                                                </div>
                                                            </div>
                                                        ]
                                                    })()
                                                }
                                            </div>
                                        </div>
                                    }
                                    {createMemeContent}
                                    {voteCreateContent}
                                    {notYet}
                                    {authorizeContent}
                                    {taskContent}
                                    {loading}
                                    {previousTips}
                                    {drip}
                                </div>
                            })()
                        }
                    ),
                },
                pathNames = Object.keys(paths),
                [firstPath] = pathNames,
                chainChange = [slaveContractAddress, currentChainId],
                useRefEffect = (fn = () => {
                }, inputs = []) => {
                    const didMountRef = useRef(false)
                    useEffect(() => {
                        fn(didMountRef.current)
                        if (!didMountRef.current) didMountRef.current = true
                    }, inputs);
                },
                getListedMemeListCondition = isHome || isLaunch || isSwap || isPortfolio || (isVotePage && !isVoteDetail);
            [
                [
                    () => {
                        isTelegramMiniApp && OKXUniversalProvider.init({
                            dappMetaData: {
                                name: 'LIKWID',
                                icon: 'https://likwid.meme/logo.png'
                            },
                        }).then(v => {
                            okxUniversalProvider = v
                            setData({okxAddress: getOkxAddress(checkDataObject(okxUniversalProvider, 'session'))})
                            // okxUniversalProvider.is
                            // const aaa = {
                            //     "topic": "004c4b07cba0d3b8007ff8ba49a0b6459d32983e6ee9ed17d88d05b6b0adeb61",
                            //     "sessionConfig": {
                            //         "dappInfo": {
                            //             "url": "192.168.31.226",
                            //             "name": "LIKWID",
                            //             "icon": "https://likwid.meme/logo.png"
                            //         }, "openUniversalUrl": true, "redirect": "metamask://"
                            //     },
                            //     "namespaces": {
                            //         "eip155": {
                            //             "chains": ["eip155:8453", "eip155:59144", "eip155:534352", "eip155:42161", "eip155:10", "eip155:1101", "eip155:81457", "eip155:60808", "eip155:167000", "eip155:56", "eip155:1", "eip155:1"],
                            //             "accounts": ["eip155:8453:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:59144:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:534352:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:42161:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:10:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:1101:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:81457:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:60808:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:167000:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:56:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:1:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba", "eip155:1:0x3f1c5e3f04a72315a7fc3ce3d1a3782b6be74dba"],
                            //             "methods": ["personal_sign", "eth_signTypedData_v4", "eth_sendTransaction", "wallet_addEthereumChain", "wallet_watchAsset", "wallet_switchEthereumChain"],
                            //             "rpcMap": {"1": "https://cloudflare-eth.com"},
                            //             "defaultChain": "1"
                            //         }
                            //     }
                            // }
                        })//.catch(e => toast(JSON.stringify(e)))
                        setData({init: true})
                        if (isHome) {
                            const getRandomMeme = () => requestApi('token/symbol/random').then(v => {
                                setData({isVisible: false})
                                setData({
                                    isVisible: true,
                                    randomMeme: memeDataIsApi(v)
                                })
                            })
                            getRandomMeme().then(() => setInterval(() => {
                                getRandomMeme()
                                isNotNull(superGif) && superGif.play()
                            }, 5000))
                        }
                        requestApi('sys/config/status').then(v => setData({
                            dripText: checkDataString(v, 'content'),
                            showDrip: checkData([v, 'status']) === 1,
                        }))
                        // if (isPortfolio || isVotePage) {
                        if (isPortfolio) {
                            isNotNullOrEmpty(twitterCode) && address && token() && bindCheck().then(
                                isTwitterBinded => !isTwitterBinded && getTask().then(v => getPoints(v, 4) <= 0 && toggleShowAuthorize(true, true))
                            )
                        }
                        if (isContest) {
                            getCreateContestSession()
                        }
                    },
                    undefined,
                    true
                ],
                [
                    () => isContest && switchEthereumChain(() => {
                    }, undefined, true),
                    [...chainChange],
                    true,
                ],
                [
                    () => isContest && address && isNotNull(phaseId) && requestApi(`contest/claim/${phaseId}/${address}`).then(v =>
                        capHolder.map((v1, i1) => {
                            const symbol = checkDataString(data, v1[6]), v2 = checkDataObject(v, symbol)
                            return contestContractRoundClaimAmount(symbol).then(claimed => setData({
                                [v1[7]]: numberPointFixed(checkData0(v2, 'tokens')),
                                [v1[8]]: checkDataBool(v2, 'holder'),
                                [v1[9]]: checkDataBool(v2, 'creator'),
                                [`estimated${i1}`]: checkDataBool(v2, 'estimated'),
                                [`claimed${i1}`]: claimed,
                            }))
                        })
                    ),
                    [phaseId, address],
                    true,
                ],
                [
                    () => (isVoteDetail || isPortfolio || isLaunch || isSwap) && setData({signTrue: undefined}),
                    [address],
                    true
                ],
                [
                    () => {
                        if (isNotNullOrEmpty(cloudflareHumanToken)) {
                            if (isVoteDetail) {
                                signBiz('vote', voteSymbol, cloudflareHumanToken)
                            } else if (isPortfolio) {
                                requestApi(
                                    `${authorizeKey}/bind`,
                                    {
                                        redirectURI,
                                        code: twitterCode,
                                        token: cloudflareHumanToken,
                                        codeVerifier: checkDataString(urlState, 0)
                                    },
                                    'post'
                                ).then(r => isNotNull(r) && setData({
                                    ...ternaryObject(
                                        isTwitterAuthorizeAuthorize(authorizeKey),
                                        {isTwitterBinded: true}
                                    ),
                                    showAuthorize: true
                                }))
                            } else {
                                const signBizBuy = biz => signBiz(
                                    biz,
                                    currentCoinName,
                                    cloudflareHumanToken,
                                    BigInt(npPlus(numberParseEtherWei(newAmount), checkData0(estimateGas))).toString()
                                )
                                if (isLaunch) {
                                    signBizBuy(isNewMemeNoUTKOrPUPPET ? 'buy' : 'deposit')
                                } else if (isSwap && isNewMemeNoUTKOrPUPPET) {
                                    signBizBuy('buy')
                                }
                            }
                        }
                    },
                    [cloudflareHumanToken],
                    true
                ],
                [
                    () => {
                        if (isVoteDetail || isPortfolio || isLaunch || isSwap) {
                            // console.log('cloudflareUpdate');
                            const setIntervalCloudflareUpdate = checkData([data, 'setIntervalCloudflareUpdate'])
                            if (isNotNull(setIntervalCloudflareUpdate)) {
                                clearInterval(setIntervalCloudflareUpdate)
                            }
                            setData({
                                setIntervalCloudflareUpdate: setInterval(() =>
                                    setData({cloudflareUpdate: checkData0(data, 'cloudflareUpdate') + 1}), 1000)
                            })
                            if (isNotNull(cloudflareHuman)) {
                                // console.log('cloudflareHuman', cloudflareHuman)
                                _turnstileCb(cloudflareHuman)
                                // const scriptTurnstileCbId = 'scriptTurnstileCb',
                                //     scriptCloudflareId = 'scriptCloudflare';
                                // [scriptTurnstileCbId, scriptCloudflareId].map(v => {
                                //     const scriptHtml = document.getElementById(v)
                                //     isNotNull(scriptHtml) && document.body.removeChild(scriptHtml)
                                // })
                                // const scriptTurnstileCb = document.createElement('script')
                                // scriptTurnstileCb.id = scriptTurnstileCbId
                                // scriptTurnstileCb.innerHTML = `
                                //     turnstile.render(cloudflareHuman, {
                                //         sitekey: "0x4AAAAAAAdGQ7LApEsWh5s1",
                                //         theme: "light",
                                //         callback: token => cloudflareHumanToken = token,
                                //     })
                                // `
                                // document.body.appendChild(scriptTurnstileCb)
                            }
                        }
                    },
                    [cloudflareHuman],
                    true
                ],
                [
                    () => isNewMeme
                        ? masterContractCall('nativeMax').then(
                            nativeMax => setData({maxETHBalance: numberETH(nativeMax)}))
                        : setData({maxETHBalance: undefined})
                ],
                [
                    () => {
                        if (devCount === 10) {
                            sessionDev
                                ? sessionStorage.removeItem('dev')
                                : sessionStorage.setItem('dev', 1)
                            location.reload()
                        }
                    },
                    [devCount],
                    true
                ],
                [
                    () => {
                        if (!isHome && !isVotePage && currentCoinNameIsNotNull) {
                            const errorFunc = () => {
                                    // console.log('errorFunc', noContractMemes);
                                    setData({
                                        // ...ternaryObject(
                                        //     !isLaunch,
                                        //     {noContractMemes: [...noContractMemes, currentCoinName]}
                                        // ),
                                        showDeployment: true
                                    })
                                    toggleShowLoading()
                                },
                                masterContractAddress = currentCoinContracts[masterChainId],
                                getMasterContract = (obj = {}) => {
                                    getContractAbi(masterChainId).then(masterContractAbi => setData({
                                        masterContract: {
                                            abi: masterContractAbi,
                                            address: masterContractAddress,
                                            rpc: require(`./rpc/${masterChainId}`).default,
                                            blockExplorersTX: `${masterChain.blockExplorers.default.url}/tx/`,
                                        },
                                        ...obj,
                                    })).catch(errorFunc)
                                }
                            if (Object.keys(currentCoinContracts).length > 0) {
                                const slaveContractAddress = currentCoinContracts[chainId]
                                if (!slaveContractAddress) {
                                    errorFunc()
                                    if (masterContractAddress) {
                                        getMasterContract()
                                    }
                                } else {
                                    // console.log('currentCoinName', currentCoinName);
                                    !['', 'about', 'contest'].includes(pNs0) && toggleShowLoading(true);
                                    getContractAbi(chainId).then(slaveContractAbi =>
                                        getMasterContract({
                                            slaveContract: {
                                                abi: slaveContractAbi,
                                                address: slaveContractAddress
                                            },
                                            showLoading: false,
                                            showDeployment: false,
                                        })
                                    ).catch(errorFunc)
                                }
                            } else {
                                errorFunc()
                            }
                        }
                    },
                    [JSON.stringify(currentCoinContracts), currentCoinName, currentChainId],
                    true
                ],
                // [
                //     () => {
                //         if ((isLaunch || isSwap) && currentCoinNameIsNotNull) {
                //             tokenMemeDetail(currentCoinName)
                //                 .then(r => setData({currentCoinContracts: checkDataObject(r, 'addressList')}))
                //                 .catch(() => setData({currentCoinContracts: undefined}))
                //         }
                //     },
                //     [currentCoinName],
                //     true
                // ],
                [
                    () => {
                        checkDataFunction({
                            ...ternaryObject(
                                launchPrdOpen,
                                {
                                    launch: () => {
                                        masterContractCall('launched').then(launched => {
                                            // masterContractETHGetEvents('PongfeeFailed', 0).then(v => console.log('PongfeeFailed', v))
                                            // launched = false
                                            setData({
                                                launched,
                                                ...ternaryObject(
                                                    launched,
                                                    {depositWithdraw: withdrawIndex}
                                                ),
                                                ...ternaryObject(
                                                    isNewMemeNoUTKOrPUPPET,
                                                    {depositWithdraw: undefined}
                                                )
                                            })
                                            !launched && !isNewMemeNoUTKOrPUPPET && (isNewMeme ? rC : masterContractCall)('launchTime').then(launchTime =>
                                                (isNewMemeNoGATO ? rC('cutShortTime') : new Promise(resolve => resolve(0))).then(cutShortTime => {
                                                    setCountdown(npMinus(Number(launchTime), Number(cutShortTime)))
                                                    masterContractCall('launchTime').then(masterLaunchTime =>
                                                        isNewMeme && masterContractCall('nowTime').then(nowTime => setCountdown(
                                                            Number(masterLaunchTime),
                                                            Number(nowTime),
                                                            'setIntervalCountdownWithdraw',
                                                            'countdownEndsWithdraw',
                                                            'countdownWithdraw',
                                                        )))
                                                }))
                                            setOmniChain()
                                        })
                                        switchEthereumChain(() => {
                                            if (address) {
                                                setOmniDeposited()
                                                getBalance().then(ethBalance =>
                                                    (isNewMemeNoUTKOrPUPPET ? new Promise(resolve => resolve(0)) : getDeposit()).then(deposit =>
                                                        (isNewMemeNoUTKOrPUPPET ? new Promise(resolve => resolve(0)) : getClaimed()).then(claimed =>
                                                            masterContractCall('presaleOf', chainId, address).then(presaleOf =>
                                                                getTokenBalance().then(tokenBalance =>
                                                                    setData({
                                                                        deposit,
                                                                        claimed,
                                                                        ethBalance,
                                                                        tokenBalance,
                                                                        refund: numberETH(checkData0(presaleOf, 'refund')),
                                                                        coinAmount: numberETH(checkData0(presaleOf, 'amount')),
                                                                    }))))))
                                                isNewMemeNoTAIBOI && getDepositTotal().then(depositTotal =>
                                                    ethToBNB(depositTotal).then(depositTotal => rC('depositTotal', currentCoinCreator).then(creatorDepositTotal =>
                                                        ethToBNB(creatorDepositTotal).then(creatorDepositTotal => rC('signSwitch').then(signSwitch => setData({
                                                            signSwitch,
                                                            depositTotal: numberETH(depositTotal),
                                                            creatorDepositTotal: numberETH(creatorDepositTotal)
                                                        })))))
                                                )
                                            }
                                        }, undefined, true)
                                    }
                                }
                            ),
                            ...ternaryObject(
                                swapPrdOpen,
                                {
                                    swap: () => switchEthereumChain(() => {
                                        setOmniDeposited()
                                        getBalance().then(ethBalance => setData({ethBalance}));
                                        // masterContractETHGetEvents('PongfeeFailed', 0).then(v => console.log('PongfeeFailed', v))
                                        (!currentCoinLongSymbol || !isV8) && rC('launched').then(launched => setData({launched}));
                                        // masterContractETHGetEvents('PongfeeFailed', 0).then(v => console.log('PongfeeFailed', v))
                                        // launched = false
                                        (isNewMemeNoUTKOrPUPPET ? getDepositTotal() : new Promise(resolve => resolve(0))).then(depositTotal => setData({
                                            depositTotal: numberETH(depositTotal),
                                        }))
                                        getTokenBalance().then(tokenBalance => setData({tokenBalance}));
                                        // isNewMemeNoUTKOrPUPPET
                                        isNewMemeNoGATO && !currentCoinLongSymbol && (isNewMemeNoUTKOrPUPPET
                                            ? new Promise(resolve => resolve(currentCoinLaunchTime))
                                            : rC('launchTime')).then(launchTime =>
                                            rC('swapTime').then(swapTime =>
                                                setCountdown(npPlus(Number(launchTime), Number(swapTime)))))
                                    }, undefined, true)
                                }
                            ),
                            vote: () => {
                                switchEthereumChain(() => {
                                }, undefined, true)
                            }
                        }, pNs0)()
                    },
                    [...chainChange, address]
                ],
                [
                    () => {
                        if (isVotePage || isLaunch || isHome || isContest) {
                            // masterContractETH.getBlockNumber().then(v => lastBlock = Number(v)).then(
                            //     () => masterContractETHGetEvents('Vote', 0, 10000).then(v =>
                            //         console.log(v.length, '++++++++++++++')))
                            isVotePage && createMemeContractMasterCall('listingVotesMin').then(listingVotesMin =>
                                createMemeContractSlaveCall('launchFundsMin').then(launchFundsMin =>
                                    createMemeContractMasterCall('basicPoints').then(basicPoints =>
                                        createMemeContractMasterCall('parentsPoints').then(parentsPoints =>
                                            createMemeContractMasterCall('grandpaPoints').then(grandpaPoints =>
                                                setData({
                                                    basicPoints,
                                                    parentsPoints,
                                                    grandpaPoints,
                                                    listingVotesMinRatio: npDivide(Number(listingVotesMin), numberETH(launchFundsMin)),
                                                })))))
                            )
                            if (isVoteDetail) {
                                getVoteMeme()
                                getAllVotePoints()
                            } else {
                                isVotePage && getRoundMemeList()
                                createMemeContractSlaveCall('totalSupplyMin').then(totalSupplyMin =>
                                    createMemeContractSlaveCall('totalSupplyMax').then(totalSupplyMax =>
                                        createMemeContractSlaveCall('launchFundsMin').then(launchFundsMin =>
                                            createMemeContractSlaveCall('launchFundsMax').then(launchFundsMax =>
                                                createMemeContractSlaveCall('launchCountdownMin').then(launchCountdownMin =>
                                                    createMemeContractSlaveCall('launchCountdownMax').then(launchCountdownMax =>
                                                        createMemeContractSlaveCall('hardcapMax').then(hardcapMax =>
                                                            createMemeContractSlaveCall('quotaMin').then(quotaMin =>
                                                                createMemeContractSlaveCall('quotaMax').then(quotaMax =>
                                                                    setData({
                                                                        hardcapMax: numberETH(hardcapMax),
                                                                        totalSupplyMin: numberETH(totalSupplyMin),
                                                                        totalSupplyMax: numberETH(totalSupplyMax),
                                                                        launchFundsMin: sessionDev ? 0.1 : 1,//numberETH(launchFundsMin),
                                                                        launchFundsMax: numberETH(launchFundsMax),
                                                                        quotaInterval: (() => {
                                                                            quotaMin = npTimes(numberETH(quotaMin), 100)
                                                                            return Array.from({length: npTimes(numberETH(quotaMax), 100) - quotaMin + 1}, (_, i) => quotaMin + i)
                                                                        })(),
                                                                        launchCountdownMin: Math.ceil(npDivide(Number(launchCountdownMin), 3600)),
                                                                        launchCountdownMax: Math.floor(npDivide(Number(launchCountdownMax), 3600)),
                                                                    })
                                                                )))))))))
                            }
                        }
                    },
                    [...chainChange],
                    true
                ],
                [
                    () => {
                        if (isVotePage) {
                            if (address) {
                                if (isVoteDetail) {
                                    getVotePointsChildrenVoted()
                                } else {
                                    requestApi('vote/meme/symbol', {address}).then(votedMemeSymbols => setData({votedMemeSymbols}))
                                }
                            }
                        }
                    },
                    [address]
                ],
                [
                    () => {
                        if (isVoteDetail) {
                            const inviteRecord = inviteRecords[inviteRecordIndex]
                            isNotNull(inviteRecord) && getInvites(inviteRecord).then(childInviteRecords =>
                                setData({childInviteRecords})
                            )
                        }
                    },
                    [JSON.stringify(inviteRecords), inviteRecordIndex],
                ],
                [
                    () => {
                        if (isSwap) {
                            !ethUSDTIsNotNull && requestApi('tool/eth/price').then(ethUSDT =>
                                setData({ethUSDT})
                            );
                            getSwapEvent()
                        }
                    },
                    [swapPage]
                ],
                [
                    () => (isSwap || (isLaunch && isNewMemeNoUTKOrPUPPET)) && (
                        newAmountNumberGT0
                            ? getAmountOut(isNotNullOrEmpty(newAmount) ? newAmount : 0, isLaunchSwapBuy).then(priceAmount =>
                                setData({priceAmount})
                            )
                            : setData({priceAmount: 0})
                    ),
                    [newAmount, isBNB]
                ],
                [
                    async () => currentShortMasterAddress && newAmountNumberGT0 && shortMasterCall(
                        isSwapBuy ? 'getClaimAmount' : 'getGiveAmount',
                        await masterContractCall('getCurrentPrice'),
                        await masterContractCall(isSwapBuy ? 'getBuyPrice' : 'getSellPrice', parseEtherWeiNewAmount),
                    ).then(profitsLoss => setData({profitsLoss: numberETH(profitsLoss)})),
                    [newAmount, isSwapBuy]
                ],
                [
                    () => isLaunch && isNewMemeNoUTKOrPUPPET && setNewAmountEmpty(),
                    [isWithdraw]
                ],
                [
                    () => {
                        if (isSwap) {
                            setNewAmountEmpty()
                        }
                    },
                    [isSwapBuy]
                ],
                [
                    () => {
                        if (isSwap) {
                            getAmountOut(1).then(exchangeRate => setData({exchangeRate}))
                            // !isSwapBuy && getAmountOut(maxETHBalance, true).then(maxTokenBalance => setData({maxTokenBalance}))
                        }
                    },
                    [isSwapBuy, isBNB]
                ],
                [
                    () => {
                        if (isSwap) {
                            masterContractCall('omniSupply').then(v => setData({omniSupply: numberETH(v)}))
                            getAmountOutFunc(1, false).then(tokenPrice => setData({tokenPrice}))
                            getInitialPrice().then(initialPrice => setData({initialPrice}))
                            swapStatusEndReload();
                            if (sMCCondition) {
                                sMC('shortStart').then(
                                    shortStart => sMC('getReserves').then(
                                        reserves => setData({
                                            // longPrice,
                                            // liquidated,
                                            // shortMasterLaunched,
                                            shortStart: numberETH(shortStart),
                                            shortReserves0: numberETH(checkData0(reserves, 0)),
                                            // initLongPrice: initLongPrice === 0n ? longPrice : numberETH(initLongPrice),
                                        })));
                            }
                            if (currentCoinLongSymbol) {
                                masterContractCall('getCurrentPrice').then(shortCurrentPrice =>
                                    longMasterCall('getReserves').then(reserves =>
                                        masterContractCall('getPaymentNativeMax').then(paymentNativeMax =>
                                            ethToBNB(paymentNativeMax).then(pNM =>
                                                setData({
                                                    shortCurrentPrice,
                                                    paymentNativeMax: numberETH(pNM),
                                                    longReserves0: numberETH(checkData0(reserves, 0)),
                                                })))))
                            }
                            // isNotNull(createBlockNumber) && contractCreateTimestamp().then(
                            //     timestamp => {
                            //         const date = new Date(npTimes(Number(timestamp), 1000))
                            //         setData({contractCreateTime: [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('.')})
                            //     }
                            // )
                        }
                    }
                ],
                [
                    async () => {
                        if (isSwap) {
                            isMasterContract
                                ? setData({estimatedInternetFees: 0})
                                : (isNotNullOrEmpty(newAmount) && newAmountNumberGT0
                                    ? (address && getEstimateGas(await amountOutMinimum(), true, isSwapBuy ? 'buy' : 'sell', parseEtherWei(newAmount)).then(([pongFee, estimateGas] = []) =>
                                        setData({estimateGas, estimatedInternetFees: numberETH(estimateGas)})))
                                    : setData({estimateGas: undefined, estimatedInternetFees: ''}))
                        }
                    },
                    [newAmount, address, ...chainChange, maxSlippageNumber]
                ],
                [
                    () => {
                        if (isSwap) {
                            setChart()
                        }
                    },
                    [timeSlotIndex]
                ],
                [
                    () => {
                        if (isVotePage && isVoteSearch) {
                            searchCurrentRoundMeme()
                        }
                    },
                    [JSON.stringify(currentRoundMemeList)]
                ],
                [() => setFistSwapPage()],
                [
                    () => {
                        if (address) {
                            const setIntervalPointTask = checkData([data, 'setIntervalPointTask']),
                                pointTask = () => getTask().then(v => setData({
                                    memePoints: getPoints(v, 0),
                                    depositPoints: getPoints(v, 1),
                                    taskVotePoints: getPoints(v, 6),
                                    tGAuthorizePoints: getPoints(v, 5),
                                    twitterAuthorizePoints: getPoints(v, 4),
                                    totalPoints: getPoints(v, -1, false),
                                    swapPoints: npPlus(getPoints(v, 2), getPoints(v, 3)),
                                }))
                            if (isNotNull(setIntervalPointTask)) {
                                clearInterval(setIntervalPointTask)
                            }
                            pointTask()
                            setData({setIntervalPointTask: setInterval(pointTask, 10000)})
                        }
                    },
                    [address],
                    true
                ],
                [
                    () => (isLaunch || isSwap) && currentCoinNameIsNotNull && requestApi(
                        (isLaunch && !isNewMemeNoUTKOrPUPPET) ? 'token/depositor/count' : 'token/holder/count',
                        {symbol: currentCoinName.toLocaleUpperCase()}
                    ).then(v =>
                        setData({holders: checkData0(v)})),
                    [currentCoinName],
                    true
                ],
                [
                    () => {
                        if (isVotePage && !isVoteDetail) {
                            setData({
                                voteListContent: [...(isVoteSearch ? searchItemList : currentRoundMemeList)].sort(a => isVotedMeme(a.symbol) ? -1 : 1)
                            })
                        }
                    },
                    [isVoteSearch, JSON.stringify(votedMemeSymbols), JSON.stringify(searchItemList), JSON.stringify(currentRoundMemeList)],
                ],
                [
                    () => {
                        if (isPortfolio) {
                            address && getCrossBalance()
                            let pCI = 0;
                            (fM
                                ? new Promise(resolve => {
                                    pCI = portfolioChainIndex
                                    resolve()
                                })
                                : Promise.all(
                                    portfolioChains.map((v, i) => {
                                        if (v.id === chainId) {
                                            pCI = i
                                        }
                                    })
                                )).then(() => setData({fromMaster: fM, portfolioChainIndex: pCI}))
                        }
                    },
                    [...chainChange, address, crossItemIndex]
                ],
                [
                    () => {
                        if (isPortfolio && address) {
                            getCrossToBalance()
                            if (isEthCrossTo) {
                                crossCall('crossMax').then(crossMax => setData({crossMax: numberETH(crossMax)}))
                            }
                        }
                    },
                    [...chainChange, address, portfolioChainIndex, crossItemIndex]
                ],
                [
                    () => isPortfolio && setNewAmountEmpty(),
                    [fromMaster]
                ],
                [
                    () => isNotNullOrEmpty(newAmount)
                        ? (isPortfolio && address && crossCallEstimateGas(parseEtherWei(newAmount)).then(estimatedInternetFees =>
                            setData({estimatedInternetFees})))
                        : setData({estimatedInternetFees: 0}),
                    [...chainChange, crossToChainId, address, newAmount, crossItemIndex]
                ],
                [
                    () => {
                        if (isPortfolio) {
                            switchEthereumChain(getLocked, undefined, true)
                            if (address) {
                                claimFeeCoinsPage = firstCoinsPage
                                getListedMemeList(undefined, undefined,
                                    claimFeeCoinsPage, ...claimFeeParams.slice(3)).then(() =>
                                    setClaimFeeCoin(0, claimFeeCoins1)
                                )
                            }
                        }
                    },
                    [address]
                ],
                [
                    () => isPortfolio && address && claimFeeCoinAddress && getClaimFeeAmount(),
                    [claimFeeCoinAddress, address, ...chainChange]
                ],
                [
                    () => {
                        if (isPortfolio && address && isNewMemeNoGATO) {
                            rewardClaimContractCall('airdropTime').then(timestamp => setCountdown(timestamp))
                            setClaimAmount()
                        }
                    },
                    [address]
                ],
                [
                    () => {
                        const setIntervalGetContractStatu = checkData([data, 'setIntervalGetContractStatu']),
                            getContractStatu = () => contractStatusKeys().map(tx => {
                                    const contractStatu = contractStatus[tx]
                                    if (Date.now() >= checkData0(contractStatu, 'lastTime')) {
                                        setContractStatuData(tx, undefined, undefined, true, undefined, true)
                                    } else {
                                        const piD = pingDone(tx)
                                        axios(`${
                                            sessionDev
                                                ? 'https://testnet-openapi.vizing.com/sdk/transaction/status'
                                                : 'https://openapi.vizing.com/sdk/transaction/status'
                                        }/${piD ? checkDataString(contractStatu, 'ping', 'targetId') : tx}`).then(
                                            v => {
                                                const result = checkDataObject(v, 'data', 'result')
                                                // console.log(tx, piD,
                                                //     checkDataString(contractStatu, tx, 'ping', 'targetId'), result.status,
                                                //     result.opStatus,
                                                //     result.targetId, '+++++++++++++++++');
                                                setContractStatuData(tx, undefined, undefined, true,
                                                    ...piD && pingPongDone(undefined, undefined, result)
                                                        ? [undefined, true]
                                                        : [{
                                                            [piD ? 'pong' : 'ping']: {
                                                                status: result.status,
                                                                opStatus: result.opStatus,
                                                                targetId: result.targetId,
                                                            }
                                                        }]
                                                )
                                            }
                                        )
                                    }
                                }
                            )
                        if (isNotNull(setIntervalGetContractStatu)) {
                            clearInterval(setIntervalGetContractStatu)
                        }
                        // getContractStatu()
                        setData({setIntervalGetContractStatu: setInterval(getContractStatu, 5000)})
                    },
                    [JSON.stringify(contractStatus)],
                    true
                ],
                [
                    () => ((isVotePage && !isVoteDetail) || isLaunch) && getCreateVotePrice().then(createVotePrice => {
                        // console.log(createVotePrice, '+++++++++++++++++++');
                        setData({createVotePrice})
                    }),
                    getCreatePriceParams
                ],
                [
                    () => isLaunch && countdownEnds && setData({depositWithdraw: withdrawIndex}),
                    [countdownEnds],
                ],
                [
                    () => {
                        if (getListedMemeListCondition) {
                            (isSwap && pNsSymbolIsNotNullOrEmpty
                                    ? shortMemeDetailFunc(pNsSymbolUpperCase).then(v => checkData([v, 'longSymbol']))
                                    : new Promise(resolve => resolve())
                            ).then(v => {
                                currentCoinLongSymbol1 = v
                                getListedMemeList(isVotePage ? 1 : undefined)
                            })
                            // createMemeContractMasterCall('getMemesLength').then(
                            //     listedMemeListLength => {
                            //         listedMemeListLength = Number(listedMemeListLength)
                            //         setData({coinsLength: listedMemeListLength})
                            //
                            //     }
                            // )
                        }
                    },
                    undefined,
                    true
                ],
                [
                    () => {
                        getListedMemeListCondition && isLaunchSearch && !searchSymbolIsNotNull && (isMemesCreated ? (() => {
                            claimFeeCoinsPage = firstCoinsPage
                            return getListedMemeList(undefined, undefined,
                                claimFeeCoinsPage, ...claimFeeParams.slice(3))
                        }) : reGetListedMemeList)()
                            .then(() => setData({isLaunchSearch: false}))
                            .catch(() => setData({isLaunchSearch: false}))
                    },
                    [searchSymbol],
                    true
                ],
                [
                    async () => {
                        if (isSwap && currentCoinLongSymbol && (coinsPage - 1) === firstCoinsPage && !isLaunchSearch) {
                            const shortSymbols = coins, obj = {...shortOverStatusList}
                            let shortSymbol = checkData([shortSymbols, 0, 'symbol']),
                                shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId]),
                                shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                            await (currentCoinLongSymbol === 'LSTD'
                                ? new Promise(reserves => reserves(true))
                                : contractCall(masterContractETH, shortMasterAddress, shortMasterAbi, 'checkCurrent')).then(async checkCurrent => {
                                    if (checkCurrent) {
                                        obj[shortSymbol] = await contractCall(masterContractETH, shortMasterAddress, shortMasterAbi, 'liquidated') ? 2 : 1
                                    } else {
                                        obj[shortSymbol] = 0
                                    }
                                    const shortSymbol1 = checkData([shortSymbols, 1, 'symbol'])
                                    if (!checkCurrent && isNotNull(shortSymbol1)) {
                                        shortSymbol = shortSymbol1
                                        shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId])
                                        shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                                        obj[shortSymbol] = await contractCall(masterContractETH, shortMasterAddress, shortMasterAbi, 'liquidated') ? 2 : 1
                                    }
                                }
                            )
                            // console.log(obj, '++++++++++');
                            setData({shortOverStatusList: obj})
                        }
                    },
                    [JSON.stringify(coins.map(v => v.symbol))]
                ]
            ].map(v => {
                const needNotMasterContract = v[2]
                return useEffect(
                    () => {
                        if (needNotMasterContract || isNotNullOrEmpty(masterContractAddress)) {
                            checkDataFunction(v, 0)()
                        }
                    },
                    [...checkDataArray(v, 1), ...ternaryArray(!needNotMasterContract, [masterContractAddress])]
                )
            });
            [
                [
                    isLoadEnd => {
                        isLoadEnd && sessionStorage.removeItem('token')
                        isPortfolio && token() && bindCheck(twitterAuthorizeAuthorizeKey).then(isTwitterBinded => setData({isTwitterBinded}))
                    },
                    [address]
                ],
                [
                    isLoadEnd => isHome && isLoadEnd && reGetListedMemeList(),
                    [sortTypeIndex, isPositiveSequence]
                ],
                [
                    isLoadEnd => isLoadEnd && reloadForm(),
                    [isMyTrade]
                ],
                [
                    isLoadEnd => isLoadEnd && isMyTrade && reloadForm(),
                    [address]
                ]
            ].map(v => useRefEffect(...v));

            function replaceHome() {
                windowReplace(firstPath)
            }

            function rC(functionName, ...args) {
                return slaveContractCall(functionName, ...args)
                // return readContract(
                //     {
                //         args,
                //         functionName,
                //         abi: slaveContractAbi,
                //         address: slaveContractAddress,
                //     },
                // )
            }

            useAccount({
                onConnect: () => {
                },
                onDisconnect: () => {
                },
            })
            useChainId()
            return [
                contextHolder,
                <Routes onChang={(() => document.title = `LIKWID${pathname ? `-${pNs1 ? pathname : pNs0}` : ''}`)()}>
                    <Route path='*' element={<Navigate to={firstPath}/>}/>
                    {pathNames.map(v => <Route key={v} path={v} element={paths[v]}/>)}
                </Routes>
            ]
        }
    )
createWeb3Modal({
    wagmiConfig, projectId, chains,
    themeVariables: {'--w3m-accent': '#FFB9C2'},
    featuredWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
        '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
        '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
    ],
    chainImages: (() => {
        const walletConnectChainIcon = chain => requireDefault(`walletConnectChainIcons/${chain}`)
        return {
            1: walletConnectChainIcon('ETH'),
            56: walletConnectChainIcon('BNB'),
            97: walletConnectChainIcon('BNB'),
            59144: walletConnectChainIcon('Linea'),
            8453: walletConnectChainIcon('Base'),
            534352: walletConnectChainIcon('Scroll'),
            42161: walletConnectChainIcon('Arbitrum'),
            10: walletConnectChainIcon('Optimism'),
            1101: walletConnectChainIcon('Polygon zkEVM'),
            81457: walletConnectChainIcon('Blast'),
            60808: walletConnectChainIcon('BOB'),
            421614: walletConnectChainIcon('Arbitrum'),
            2442: walletConnectChainIcon('Polygon zkEVM'),
            111: walletConnectChainIcon('BOB'),
            167000: walletConnectChainIcon('Taiko'),
            28516: walletConnectChainIcon('Vizing'),
            28518: walletConnectChainIcon('Vizing'),
        }
    })()
})
export default () => [
    <WagmiConfig config={wagmiConfig}><Profile/></WagmiConfig>,
    // <Web3Modal projectId={projectId} defaultChain={defaultChain}
    //            chainImages={(() => {
    //                const walletConnectChainIcon = chain => requireDefault(`walletConnectChainIcons/${chain}`)
    //                return {
    //                    59144: walletConnectChainIcon('Linea'),
    //                    8453: walletConnectChainIcon('Base'),
    //                    534352: walletConnectChainIcon('Scroll'),
    //                    42161: walletConnectChainIcon('Arbitrum'),
    //                    10: walletConnectChainIcon('Optimism'),
    //                    1101: walletConnectChainIcon('Polygon zkEVM'),
    //                    81457: walletConnectChainIcon('Blast'),
    //                    60808: walletConnectChainIcon('BOB'),
    //                    421614: walletConnectChainIcon('Arbitrum'),
    //                    2442: walletConnectChainIcon('Polygon zkEVM'),
    //                    111: walletConnectChainIcon('BOB'),
    //                    28516: walletConnectChainIcon('Vizing'),
    //                    28518: walletConnectChainIcon('Vizing'),
    //                }
    //            })()}
    //            ethereumClient={ethereumClient} themeMode={'dark'} themeVariables={{
    //     '--w3m-accent-color': '#FFB9C2',
    //     '--w3m-background-color': '#FFB9C2',
    //     '--w3m-accent-fill-color': 'black',
    // }}/>
]